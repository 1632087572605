import React from "react";
import SoundSystemPage from "../component/soundSystem/soundSystem";
function SoundSystem() {
  return (
    <div>
      <SoundSystemPage />
    </div>
  );
}

export default SoundSystem;
