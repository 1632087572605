import {
    FETCH_ALL_CUSTOMER_REQUEST,
    FETCH_ALL_CUSTOMER_SUCCESS,
    FETCH_ALL_CUSTOMER_FAILURE
  } from "../types";
  import axios from "axios";
  import { url } from "../apiUrl/url";
  import { basicAuth } from "../apiUrl/basicAuth";
  export const fetchAllCustomerRequest = () => {
    return {
      type: FETCH_ALL_CUSTOMER_REQUEST,
    };
  };
  export const fetchAllCustomerSuccess = (Data) => {
    return {
      type: FETCH_ALL_CUSTOMER_SUCCESS,
      payload: Data,
    };
  }
  
  export const fetchAllCustomerFailure = (Error) => {
    return {
      type: FETCH_ALL_CUSTOMER_FAILURE,
      payload: Error,
    };
  };
  
  
  export const fetchAllCustomer = () => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
      'Authorization': basicAuth,
    };
    return (dispatch) => {
      dispatch(fetchAllCustomerRequest);
      axios
        .get(url + "getAllCustomer", {
          headers: headers,
        })
        .then(function (response) {
          dispatch(fetchAllCustomerSuccess(response.data));
        })
        .catch(function (error) {
          dispatch(fetchAllCustomerFailure(error));
        });
  
    };
  };