import {
    DELETE_ARTIST_PREFERRED_SETUP_REQUEST,
    DELETE_ARTIST_PREFERRED_SETUP_SUCCESS,
    DELETE_ARTIST_PREFERRED_SETUP_FAILURE,
  } from "../../types";
  import axios from "axios";
  import { url } from "../../apiUrl/url"
  import { basicAuth } from "../../apiUrl/basicAuth";
  import { fetchAllArtistPreferredSetup } from "./getAllPreferredSetup"
  export const deleteArtistPreferredSetupByIdRequest = () => {
    return {
      type: DELETE_ARTIST_PREFERRED_SETUP_REQUEST,
    };
  };
  
  export const deleteArtistPreferredSetupByIdSuccess = (Data) => {
    return {
      type: DELETE_ARTIST_PREFERRED_SETUP_SUCCESS,
      payload: Data,
    };
  };
  
  export const deleteArtistPreferredSetupByIdFailure = (Error) => {
    return {
      type: DELETE_ARTIST_PREFERRED_SETUP_FAILURE,
      payload: Error,
    };
  };
  
  export const deleteArtistPreferredSetupById = (ArtistPreferredSetupId) => {
  
    const headers = {
      "Content-Type": "application/json",
      'Authorization': basicAuth,
    };
    return (dispatch) => {
      dispatch(deleteArtistPreferredSetupByIdRequest());
      axios
        .delete(url + `deleteArtistPreferredSetupById/${ArtistPreferredSetupId}`, {
          headers: headers,
        })
        .then(async (response) => {
          await dispatch(deleteArtistPreferredSetupByIdSuccess(response.data));
          localStorage.setItem("message", "ArtistPreferredSetupDeleteSuccess");
          dispatch(fetchAllArtistPreferredSetup())
        })
        .catch((error) => {
          dispatch(deleteArtistPreferredSetupByIdFailure(error));
          localStorage.setItem("message", "ArtistPreferredSetupDeleteFailed");
  
        });
    };
  };
  