import {
    ADD_TRIGGERS_REQUEST,
    ADD_TRIGGERS_SUCCESS,
    ADD_TRIGGERS_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllTrigger } from "./getAllTrigger";

export const addTriggerRequest = () => {
    return {
        type: ADD_TRIGGERS_REQUEST,
    };
};

export const addTriggerSuccess = (Data) => {
    return {
        type: ADD_TRIGGERS_SUCCESS,
        payload: { data: Data.data, message: Data.message },
    };
};

export const addTriggerFailure = (Error) => {
    return {
        type: ADD_TRIGGERS_FAILURE,
        payload: Error,
    };
};
export const addTrigger = (data) => {
    var headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };

    if (data.hasTimeTrigger) {


    }

    return (dispatch) => {
        dispatch(addTriggerRequest());
        axios
            .post(url + `api/flows/createFlow`, {
                name: data.name,
                active: data.active,
                entityName: data.entityName,
                webhookUrl: data.webhookUrl,
                hasTimeTrigger: data.hasTimeTrigger ? true : false,
                eventType: data.hasTimeTrigger ? null : data.eventType,
                dayOffset: data.dayOffset ? data.dayOffset : 0,
                triggerTimePrecise: data.isTriggerTimePrecise ? true : false,
                timeOffset: data.isTriggerTimePrecise ? data.timeOffset : 0,
                timeFieldGetterName: data.hasTimeTrigger ? data.timeFieldGetterName : null,
                condition: data.condition ? data.condition : null
            }, { headers: headers })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addTriggerSuccess");
                    await dispatch(addTriggerSuccess(response.data));
                    await dispatch(fetchAllTrigger());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "addTriggerfailed");
                dispatch(
                    addTriggerFailure("Please Check Your Internet Connection !!")
                );
            });
    };
};
