import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import {
  numberValidate,
  emailValidate,
  stringValidate,
  selectinputValidate,
  contactNumberValidation,
  postalCodeValidation,
  websiteValidation,
} from "../../utils/Validations";
import $ from "jquery";
import {
  updateUserdetails,
  updateUserRequest,
} from "../../actions/User action/EditUserDetails";

import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const EditUser = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    updateUserDetails,
    message,
    error,
    Userdetails,
    refreshform,
    paginationvalues,
  } = props;

  const CurrentOptions = [
    { title: "Yes", value: true },
    { title: "No", value: false },
  ];

  //----------------  ADD CUSTOMER STATE TO HANDLE INPUT VALUES ------------***//
  const [selectdata, setselectdata] = useState({});
  const [user, setuser] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    aadhaarno: "",
    panNumber: "",
    currentInvestment: "",
    currentHoldings: "",
    currentFracValue: "",
    currentNoInvestPro: "",
    lastPaymentdate: "",
    nextPaymentdate: "",
    userimage: "",
    panimage: "",
    adharimage: "",
    userimagePath: "",
    panimagePath: "",
    adharimagePath: "",
    // name: "deepakupdated",
    // email: "sagar@gmail.com",
    // phone: 1234567811,
    // aadhaarno: "232232323212",
    // panNumber: "6262626262",
    // currentInvestment: 111111,
    // currentHoldings: "PanNumber",
    // currentFracValue: 11.2121,
    // currentNoInvestPro: 121212,
    // lastPaymentdate: "13-12-2022",
    // nextPaymentdate: "13-12-2022",
  });

  const [imageData, setImagedata] = useState({
    imageBase: "",
    imageError: "",
  });

  useEffect(() => {
    setuser({
      ...user,
      id: Userdetails.userId,
      name: Userdetails.name,
      email: Userdetails.email,
      phone: Userdetails.phone,
      aadhaarno: Userdetails.aadhaarno,
      panNumber: Userdetails.panNumber,
      currentInvestment: Userdetails.currentInvestment,
      currentHoldings: Userdetails.currentHoldings,
      currentFracValue: Userdetails.currentFracValue,
      currentNoInvestPro: Userdetails.currentNoInvestPro,
      lastPaymentdate: Userdetails.lastPaymentdate,
      nextPaymentdate: Userdetails.nextPaymentdate,
      userimage: Userdetails.userImage,
      panimage: Userdetails.panImage,
      adharimage: Userdetails.adharImage,
      userimagePath: Userdetails.userimagePath,
      panimagePath: Userdetails.panimagePath,
      adharimagePath: Userdetails.adharimagePath,
    });
  }, [Userdetails]);

  //----------------  ADD CUSTOMER FIELDS ERROR ------------***//
  const [addusererrors, setAdduserError] = useState({
    name: null,
    email: null,
    phone: null,
    aadhaarno: null,
    panNumber: null,
    currentInvestment: null,
    currentHoldings: null,
    currentFracValue: null,
    currentNoInvestPro: null,
    lastPaymentdate: null,
    nextPaymentdate: null,
    userimage: null,
    panimage: null,
    adharimage: null,
    userimagePath: null,
    panimagePath: null,
    adharimagePath: null,
  });

  const clearState = () => {
    setuser({
      ...user,
      name: "",
      email: "",
      phone: "",
      aadhaarno: "",
      panNumber: "",
      currentInvestment: "",
      currentHoldings: "",
      currentFracValue: "",
      currentNoInvestPro: "",
      lastPaymentdate: "",
      nextPaymentdate: "",
      userimage: "",
      panimage: "",
      adharimage: "",
      userimagePath: "",
      panimagePath: "",
      adharimagePath: "",
    });
  };

  const handleValidation = () => {
    setAdduserError({
      ...addusererrors,
      name: stringValidate(user.name, "Name"),
      aadhaarno: numberValidate(user.aadhaarno, "Aadhaarno"),
      email: emailValidate(user.email, "Email"),
      phone: contactNumberValidation(user.phone, "Phone"),
      panNumber: numberValidate(user.panNumber, "PanNumber"),
      currentInvestment: numberValidate(
        user.currentInvestment,
        "CurrentInvestment"
      ),
      currentHoldings: stringValidate(user.currentHoldings, "CurrentHoldings"),
      currentFracValue: numberValidate(
        user.currentFracValue,
        "CurrentFracValue"
      ),
      currentNoInvestPro: numberValidate(
        user.currentNoInvestPro,
        "CurrentNoInvestPro"
      ),
      lastPaymentdate: stringValidate(user.lastPaymentdate, "LastPaymentdate"),
      nextPaymentdate: stringValidate(user.nextPaymentdate, "NextPaymentdate"),
      userimagePath: stringValidate(user.userimagePath, "UserimagePath"),
      panimagePath: stringValidate(user.panimagePath, "PanimagePath"),
      adharimagePath: stringValidate(user.adharimagePath, "AdharimagePath"),
    });
  };

  useEffect(() => {
    if (localStorage.getItem("message") === "UserFailed") {
      enqueueSnackbar(error, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 1500,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
      refreshform();
    }

    if (localStorage.getItem("message") === "UserSuccess") {
      handleClearValidationError();

      enqueueSnackbar("User Updated successfully", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 1500,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
      $(".close").click();
      refreshform();
    }
  }, [message, error]);

  //---------------------------------------  ADD userS FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
  const handleAdduser = (e) => {
    handleValidation();
    if (
      stringValidate(user.name, "Name") === null &&
      numberValidate(user.aadhaarno, "Aadhaarno") === null &&
      emailValidate(user.email, "Email") === null &&
      contactNumberValidation(user.phone, "Phone") === null &&
      numberValidate(user.panNumber, "PanNumber") === null &&
      numberValidate(user.currentInvestment, "CurrentInvestment") === null &&
      stringValidate(user.currentHoldings, "CurrentHoldings") === null &&
      numberValidate(user.currentFracValue, "CurrentFracValue") === null &&
      numberValidate(user.currentNoInvestPro, "CurrentNoInvestPro") === null &&
      stringValidate(user.lastPaymentdate, "LastPaymentdate") === null &&
      stringValidate(user.nextPaymentdate, "NextPaymentdate") === null &&
      stringValidate(user.userimagePath, "UserimagePath") === null &&
      stringValidate(user.panimagePath, "PanimagePath") === null &&
      stringValidate(user.adharimagePath, "AdharimagePath") === null
    ) {
      handleClearValidationError();

      updateUserDetails(user, paginationvalues, imageData.imageBase);
    }
  };

  //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
  const handleClearValidationError = () => {
    setAdduserError({
      ...addusererrors,
      name: null,
      email: null,
      phone: null,
      aadhaarno: null,
      panNumber: null,
      currentInvestment: null,
      currentHoldings: null,
      currentFracValue: null,
      currentNoInvestPro: null,
      lastPaymentdate: null,
      nextPaymentdate: null,
      userimage: null,
      panimage: null,
      adharimage: null,
      userimagePath: null,
      panimagePath: null,
      adharimagePath: null,
    });

    clearState();
  };

  const onlyNumberInputPhone = (evt) => {
    //const trimval = evt.target.value.split(" ");
    if (/^[0-9-+() ]*$/i.test(evt.target.value)) {
      setuser({
        ...user,
        mobileNumber: evt.target.value,
      });
    }
  };

  const changeimage = (event) => {
    setuser({
      ...user,
      imageUrl: "",
    });
    const file = event.target.files[0];
    if (file !== undefined) {
      var reader = new FileReader();
      if (file.size > 2e6) {
        setImagedata({
          ...imageData,
          imageError: "File size can not be more than 2 MB",
          imageBase: "",
        });
      } else {
        reader.onload = (evt) => {
          setuser({
            ...user,
            uploadedImage: evt.target.result,
            imageUrl: evt.target.result,
          });

          setImagedata({
            ...imageData,
            imageError: "",
            imageBase: evt.target.result,
          });
        };
      }
      reader.readAsDataURL(file);
    }
  };

  return (
    // <!-- The Modal -->
    <div className="modal" id="editCustomerModal">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          {/* <!-- Modal Header --> */}
          <div className="modal-header">
            <h4 className="modal-title">Edit User</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClearValidationError}
            >
              &times;
            </button>
          </div>

          {/* <!-- Modal body --> */}
          <div className="modal-body">
            <div id="accordion">
              <div className="card">
                <div className="card-header Accordian" id="headingOne">
                  <div className="mb-0 ">
                    <button
                      className="btn btn-text d-flex w-100 "
                      style={{ height: "50px" }}
                      data-toggle="collapse"
                      data-target="#maininfo"
                      aria-expanded="true"
                      aria-controls="headingOne"
                    >
                      <h5
                        className="pb-4"
                        style={
                          addusererrors.name == null
                            ? { color: "grey" }
                            : { color: "red" }
                        }
                      >
                        Main Information
                      </h5>
                      {addusererrors.name == null ? (
                        ""
                      ) : (
                        <div>
                          <i className="fas fa-exclamation-circle text-danger ml-1"></i>
                        </div>
                      )}
                    </button>
                  </div>
                </div>

                <div
                  id="maininfo"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Name <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="subs"
                            className="form-control"
                            placeholder="First Name"
                            id="subs"
                            // ref={NameRef}
                            value={user.name}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                name: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                name: stringValidate(user.name, "Name"),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.name}
                          </p>
                        </div>
                        <div className="form-group">
                          <label>
                            User Image
                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                          </label>
                          <div className="image" style={{ width: "300px" }}>
                            {" "}
                            {user.userimagePath !== "" ? (
                              <img
                                src={user.userimagePath}
                                className="rounded-circle noimageUser"
                                alt=""
                              />
                            ) : (
                              <img
                                src="img/noimage.png"
                                className="rounded-circle noimageUser"
                                alt=""
                              />
                            )}
                            <div className=" mr-3  bottomright">
                              <input
                                type="file"
                                id="actual-btnb"
                                hidden
                                onChange={(e) =>
                                  setuser({
                                    ...user,
                                    userimagePath: e.target.files[0],
                                  })
                                }
                              />
                              <label htmlFor="actual-btnb">
                                <i className="bg-warning p-2 border-white rounded-circle fa fa-pencil-alt"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Aadhar Number{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="subs"
                            className="form-control"
                            placeholder="Last Name"
                            id="subs"
                            // ref={NameRef}
                            value={user.aadhaarno}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                aadhaarno: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                aadhaarno: stringValidate(
                                  user.aadhaarno,
                                  "Last Name"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.aadhaarno}
                          </p>
                        </div>
                        <div className="form-group">
                          <label>
                            Aadhar Image
                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                          </label>
                          <div className="image" style={{ width: "300px" }}>
                            {" "}
                            {user.adharimagePath !== "" ? (
                              <img
                                src={user.adharimagePath}
                                className="rounded-circle noimageUser"
                                alt=""
                              />
                            ) : (
                              <img
                                src="img/noimage.png"
                                className="rounded-circle noimageUser"
                                alt=""
                              />
                            )}
                            <div className=" mr-3  bottomright">
                              <input
                                type="file"
                                id="actual-btnb"
                                hidden
                                onChange={(e) =>
                                  setuser({
                                    ...user,
                                    adharimagePath: e.target.files[0],
                                  })
                                }
                              />
                              <label htmlFor="actual-btnb">
                                <i className="bg-warning p-2 border-white rounded-circle fa fa-pencil-alt"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-header Accordian" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-text d-flex w-100"
                      data-toggle="collapse"
                      data-target="#contactinfo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                      style={{ height: "50px" }}
                    >
                      <h5
                        className="pb-4"
                        style={
                          addusererrors.email == null &&
                          addusererrors.phone == null
                            ? { color: "grey" }
                            : { color: "red" }
                        }
                      >
                        Contact Information
                      </h5>
                      {addusererrors.email == null &&
                      addusererrors.phone == null ? (
                        ""
                      ) : (
                        <div>
                          <i className="fas fa-exclamation-circle text-danger ml-1"></i>
                        </div>
                      )}
                    </button>
                  </h5>
                </div>
                <div
                  id="contactinfo"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Email <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type=""
                            className="form-control"
                            placeholder="Email"
                            id=""
                            // ref={EmailRef}
                            value={user.email}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                email: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                email: emailValidate(user.email, "Email"),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.email}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Phone No <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type=""
                            className="form-control"
                            placeholder="Phone No"
                            id=""
                            disabled
                            value={user.phone}
                            // onChange={(e) => onlyNumberInputPhone(e)}
                            // onChange={(e) => {
                            //   setuser({
                            //     ...user,
                            //     phone: e.target.value,
                            //   });
                            // }}
                            // ref={mobileNumberRef}
                            // onBlur={() =>
                            //   setAdduserError({
                            //     ...addusererrors,
                            //     phone: contactNumberValidation(
                            //       user.phone,
                            //       "Phone"
                            //     ),
                            //   })
                            // }
                          />
                          {/* <p className="profile-input-errors">
                            {addusererrors.phone}
                          </p> */}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Pan Image
                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                          </label>
                          <div className="image" style={{ width: "300px" }}>
                            {" "}
                            {user.panimagePath !== "" ? (
                              <img
                                src={user.panimagePath}
                                className="rounded-circle noimageUser"
                                alt=""
                              />
                            ) : (
                              <img
                                src="img/noimage.png"
                                className="rounded-circle noimageUser"
                                alt=""
                              />
                            )}
                            <div className=" mr-3  bottomright">
                              <input
                                type="file"
                                id="actual-btnb"
                                hidden
                                onChange={(e) =>
                                  setuser({
                                    ...user,
                                    panimagePath: e.target.files[0],
                                  })
                                }
                              />
                              <label htmlFor="actual-btnb">
                                <i className="bg-warning p-2 border-white rounded-circle fa fa-pencil-alt"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-header Accordian" id="headerfive">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-text d-flex w-100 "
                      data-toggle="collapse"
                      data-target="#address"
                      aria-expanded="true"
                      aria-controls="headerfive"
                      style={{ height: "50px" }}
                    >
                      <h5
                        className="pb-4"
                        style={
                          addusererrors.currentInvestment == null &&
                          addusererrors.currentHoldings == null &&
                          addusererrors.currentFracValue == null &&
                          addusererrors.currentNoInvestPro == null &&
                          addusererrors.lastPaymentdate == null &&
                          addusererrors.nextPaymentdate == null &&
                          addusererrors.panNumber == null
                            ? { color: "grey" }
                            : { color: "red" }
                        }
                      >
                        Documents
                      </h5>
                      {addusererrors.currentInvestment == null &&
                      addusererrors.currentHoldings == null &&
                      addusererrors.currentFracValue == null &&
                      addusererrors.currentNoInvestPro == null &&
                      addusererrors.lastPaymentdate == null &&
                      addusererrors.nextPaymentdate == null &&
                      addusererrors.panNumber == null ? (
                        ""
                      ) : (
                        <div>
                          <i className="fas fa-exclamation-circle text-danger ml-1"></i>
                        </div>
                      )}
                    </button>
                  </h5>
                </div>
                <div
                  id="address"
                  className="collapse show"
                  aria-labelledby="headerfive"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Pan Number{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pan Number"
                            id=""
                            value={user.panNumber}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                panNumber: e.target.value,
                              })
                            }
                            // ref={panNumberRef}
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                panNumber: stringValidate(
                                  user.panNumber,
                                  "PanNumber"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.panNumber}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Current Investment{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="currentInvestment"
                            id=""
                            value={user.currentInvestment}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                currentInvestment: e.target.value,
                              })
                            }
                            // ref={AddressRef}
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                currentInvestment: stringValidate(
                                  user.currentInvestment,
                                  "CurrentInvestment"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.currentInvestment}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          {/* <label>State <span style={{color: '#d20d0d'}}>*</span></label> */}
                          <label>Current Holdings </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="currentHoldings"
                            id=""
                            value={user.currentHoldings}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                currentHoldings: e.target.value,
                              })
                            }
                            // ref={AddressRef}
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                currentHoldings: stringValidate(
                                  user.currentHoldings,
                                  "CurrentHoldings"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.currentHoldings}
                          </p>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            CurrentNoInvestPro{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type=""
                            className="form-control"
                            placeholder="CurrentNoInvestPro"
                            id=""
                            value={user.currentNoInvestPro}
                            // ref={postalCodeRef}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                currentNoInvestPro: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                currentNoInvestPro: numberValidate(
                                  user.currentNoInvestPro,
                                  "CurrentNoInvestPro"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.currentNoInvestPro}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            CurrentFracValue{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="currentFracValue"
                            id=""
                            value={user.currentFracValue}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                currentFracValue: e.target.value,
                              })
                            }
                            // ref={AddressRef}
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                currentFracValue: stringValidate(
                                  user.currentFracValue,
                                  "CurrentFracValue"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.currentFracValue}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Last Payment Date{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Payment Date"
                            id=""
                            value={user.lastPaymentdate}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                lastPaymentdate: e.target.value,
                              })
                            }
                            // ref={AddressRef}
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                lastPaymentdate: stringValidate(
                                  user.lastPaymentdate,
                                  "LastPaymentdate"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.lastPaymentdate}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            NextPaymentdate
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="NextPaymentdate"
                            id=""
                            value={user.nextPaymentdate}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                nextPaymentdate: e.target.value,
                              })
                            }
                            // ref={AddressRef}
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                nextPaymentdate: stringValidate(
                                  user.nextPaymentdate,
                                  "NextPaymentdate"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.nextPaymentdate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              // data-dismiss="modal"
              onClick={handleAdduser}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    loading: state.UpdateUsers.loading,
    message: state.UpdateUsers.message,
    error: state.UpdateUsers.error,
    Userdetails: state.getuser.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDetails: (user, paginationvalues, imageData) =>
      dispatch(updateUserdetails(user, paginationvalues, imageData)),
    refreshform: () => dispatch(updateUserRequest()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditUser));
