import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import Artists from "../pages/artists";
import ArtistsDetails from "../component/artists/artistsDetails";
import ArtistPreferredSetup from "../component/artists/artistPreferredSetup/getAllPreferredSetup";
import Profilepage from "../component/artists/profile"
import VideoManagement from "../component/artists/video_management/videoManagement"
import CalendarManagement from "../component/artists/calender_management/calenderManagement"

export const artistsroutes = [
  {
    path: "/",
    exact: true,
    layout: Layout,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/Artists",
    exact: true,
    layout: Layout,
    component: Artists,
  },
  {
    path: "/Artists_Details",
    exact: true,
    layout: Layout,
    component: ArtistsDetails,
  },
  {
    path: "/artist_profile",
    exact: true,
    layout: Layout,
    component: Profilepage,
  },
  {
    path: "/artist_prefferedsetup",
    exact: true,
    layout: Layout,
    component: ArtistPreferredSetup,
  },
  {
    path: "/artist_videos",
    exact: true,
    layout: Layout,
    component: VideoManagement,
  },
  {
    path: "/artist_calendar",
    exact: true,
    layout: Layout,
    component: CalendarManagement,
  },
];
