import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  updateAgent,
  updateAgentRequest,
} from "../../actions/agents/updateAgentById";
import {
  emailValidate,
  stringValidate,
  selectinputValidate,
  contactNumberValidation,
  postalCodeValidation,
  websiteValidation,
  passwordValidate,
} from "../../utils/Validations";
import $ from "jquery";

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const UpdateAgent = (props) => {
  const [sameasaddress, setSameAsAddress] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const NameRef = useRef("");
  const WebsiteRef = useRef("");
  const DescriptionRef = useRef("");
  const EmailRef = useRef("");
  const Phone_NoRef = useRef("");
  const OtherRef = useRef("");
  const AddressRef = useRef("");
  const Postal_CodeRef = useRef("");
  const Billing_Address_AddressRef = useRef("");
  const Billing_Address_Postal_CodeRef = useRef("");

  const { handleUpdateAgent, message, error, getAgentByIdData, refreshform } = props;

  //----------------  ADD CUSTOMER STATE TO HANDLE INPUT VALUES ------------***//

  const [user, setuser] = useState({
    id: "",
    userName: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    secondaryPhone: ""
  });



  //----------------  ADD CUSTOMER FIELDS ERROR ------------***//
  const [addusererrors, setAdduserError] = useState({
    userName: null,
    password: null,
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    secondaryPhone: null
  });

  const clearState = () => {
    setuser({
      ...user,
      userName: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      secondaryPhone: ""
    });
  };

  useEffect(() => {
    setuser({
      ...user,
      id: getAgentByIdData.agentId,
      userName: getAgentByIdData?.agentUser?.userName,
      password: getAgentByIdData?.agentUser?.password,
      email: getAgentByIdData?.agentUser?.email,
      firstName: getAgentByIdData?.agentUser?.firstName,
      lastName: getAgentByIdData?.agentUser?.lastName,
      phoneNumber: getAgentByIdData?.agentUser?.phoneNumber,
      secondaryPhone: getAgentByIdData?.agentUser?.secondaryPhone
    });
  }, [getAgentByIdData])
  const handleValidation = () => {
    setAdduserError({
      ...addusererrors,
      userName: stringValidate(user.userName, "UserName"),
      // password: passwordValidate(user.password, "Password"),
      // email: emailValidate(user.email, "Email"),
      firstName: stringValidate(user.firstName, "FirstName"),
      lastName: stringValidate(user.lastName, "LastName"),
      // phoneNumber: contactNumberValidation(user.phoneNumber, "PhoneNumber")
    });
  };

  useEffect(() => {
    if (localStorage.getItem("message") === "Record Updated Failed") {
      enqueueSnackbar("Something went wrong", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 1500,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
      refreshform();
    }

    if (localStorage.getItem("message") === "Record Updated Successfully") {
      setSameAsAddress(false);
      handleClearValidationError();

      enqueueSnackbar("Updated successfully", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 1500,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
      $(".close").click();
      refreshform();
    }
  }, [message, error]);

  //---------------------------------------  ADD userS FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
  const handleAgentuser = (e) => {
    handleValidation();
    if (
      stringValidate(user.userName, "UserName") === null &&
      // passwordValidate(user.password, "Password") === null &&
      // emailValidate(user.email, "Email") === null &&
      stringValidate(user.firstName, "FirstName") === null &&
      stringValidate(user.lastName, "LastName") === null
      // &&
      // contactNumberValidation(user.phoneNumber, "PhoneNumber") === null
    ) {
      handleClearValidationError();
      handleUpdateAgent(user);
    }
  };

  //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
  const handleClearValidationError = () => {
    setAdduserError({
      ...addusererrors,
      userName: null,
      password: null,
      email: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      secondaryPhone: null
    });

    clearState();
  };

  return (
    // <!-- The Modal -->
    <div className="modal" id="updateAgentModal">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          {/* <!-- Modal Header --> */}
          <div className="modal-header">
            <h4 className="modal-title">Add New Agent</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClearValidationError}
            >
              &times;
            </button>
          </div>

          {/* <!-- Modal body --> */}
          <div className="modal-body">
            <div id="accordion">
              <div className="card">


                <div
                  id="maininfo"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            User Name{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="subs"
                            className="form-control"
                            placeholder="User Name"
                            id="subs"
                            // ref={NameRef}
                            value={user.userName}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                userName: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                userName: stringValidate(
                                  user.userName,
                                  "UserName"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.userName}
                          </p>
                        </div>

                      </div>
                      {/* <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Password{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="subs"
                            className="form-control"
                            placeholder="Password"
                            id="subs"
                            // ref={NameRef}
                            value={user.password}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                password: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                password: passwordValidate(
                                  user.password,
                                  "Password"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.password}
                          </p>
                        </div>

                      </div> */}
                      {/* <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Email{" "}
                            <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type="subs"
                            className="form-control"
                            placeholder="Email"
                            id="subs"
                            value={user.email}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                email: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                email: emailValidate(
                                  user.email,
                                  "Email"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.email}
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-2">

                <div
                  id="contactinfo"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            First Name <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type=""
                            className="form-control"
                            placeholder="First Name"
                            id=""
                            // ref={EmailRef}
                            value={user.firstName}
                            onChange={(e) =>
                              setuser({
                                ...user,
                                firstName: e.target.value,
                              })
                            }
                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                firstName: stringValidate(
                                  user.firstName,
                                  "FirstName"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.firstName}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Last Name <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type=""
                            className="form-control"
                            placeholder="Last Name"
                            id=""
                            value={user.lastName}
                            onChange={(e) => {
                              setuser({
                                ...user,
                                lastName: e.target.value,
                              });
                            }}

                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                lastName: stringValidate(
                                  user.lastName,
                                  "LastName"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.lastName}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Phone Number <span style={{ color: "#d20d0d" }}>*</span>
                          </label>
                          <input
                            type=""
                            className="form-control"
                            placeholder="Phone Number"
                            id=""
                            value={user.phoneNumber}

                            onChange={(e) => {
                              setuser({
                                ...user,
                                phoneNumber: e.target.value,
                              });
                            }}

                            onBlur={() =>
                              setAdduserError({
                                ...addusererrors,
                                phoneNumber: contactNumberValidation(
                                  user.phoneNumber,
                                  "PhoneNumber"
                                ),
                              })
                            }
                          />
                          <p className="profile-input-errors">
                            {addusererrors.phoneNumber}
                          </p>
                        </div>
                      </div> */}
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>
                            Secondary Phone
                          </label>
                          <input
                            type=""
                            className="form-control"
                            placeholder="Secondary Phone"
                            id=""
                            value={user.secondaryPhone}
                            onChange={(e) => {
                              setuser({
                                ...user,
                                secondaryPhone: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              // data-dismiss="modal"
              onClick={handleAgentuser}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {

  return {
    loading: state.updateAgentById.loading,
    message: state.updateAgentById.message,
    error: state.updateAgentById.error,
    getAgentByIdData: state.getAgentById.data,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleUpdateAgent: (user) => dispatch(updateAgent(user)),
    refreshform: () => dispatch(updateAgentRequest()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateAgent));
