import {
  ADD_WEBSITE_BANNER_REQUEST,
  ADD_WEBSITE_BANNER_SUCCESS,
  ADD_WEBSITE_BANNER_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllWebsiteBanner } from "./getAllWebsiteBanner";

export const addWebsiteBannerRequest = () => {
  return {
    type: ADD_WEBSITE_BANNER_REQUEST,
  };
};

export const addWebsiteBannerSuccess = (Data) => {
  return {
    type: ADD_WEBSITE_BANNER_SUCCESS,
    payload: { data: Data.data, message: Data.message },
  };
};

export const addWebsiteBannerFailure = (Error) => {
  return {
    type: ADD_WEBSITE_BANNER_FAILURE,
    payload: Error,
  };
};
export const addWebsiteBanner = (data) => {
  var headers = {
    Accept: "Application/json",
    "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,
  };

  let formdata = new FormData();
  // var myData = {

  //   title: data.title,
  //   specification: data.specification,
  //   maxNumberOfGuest: data.maxNumberOfGuest,
  //   chargeAmount: data.chargeAmount
  // };

  // let dataa = JSON.stringify(myData);
  // formdata.append("data", dataa);
  formdata.append("imagefile", data.imageFile);



  return (dispatch) => {
    dispatch(addWebsiteBannerRequest());
    axios
      .post(url + `addWebsiteTlsBanner`, formdata, { headers: headers })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "addWebsiteBannerSuccess");
          await dispatch(addWebsiteBannerSuccess(response.data));
          await dispatch(fetchAllWebsiteBanner());
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "addWebsiteBannerfailed");
        dispatch(
          addWebsiteBannerFailure("Please Check Your Internet Connection !!")
        );
      });
  };
};
