import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddBooking from "./addBooking";
import Delete_Booking from "./deleteBooking"
import Update_Booking from "./updateBooking"
import ChangeState from "./changeState"

import { fetchAllBooking } from "../../actions/manageBooking/getAllBooking"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
import { fetchBookingById } from "../../actions/manageBooking/fetchBookingById"

function BookingRequest(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [contactId, setcontactId] = useState(0);
    const [bookingId, setBookingId] = useState("")
    const [paginationvalues, setPaginationValues] = useState({});

    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 2,
        sortingorder: "desc",
        search: null,
        columnname: "",
    });

    const {
        fetchBookingList,
        loading,
        bookingData,
        error,
        message,
        fetchpropertybyid,
        propertyId,
        bookingDataByBookingId,
        getBookingDetailsById,
        loggedIn

    } = props;

    useEffect(() => {
        localStorage.setItem("View_Name", "Booking_Request");
        checkRoute();
    }, [loggedIn]);


    useEffect(() => {
        fetchdata();
    }, [])

    const fetchdata = () => {
        fetchBookingList();

    };

    const handleContactDetailView = async (id) => {
        history.push({
            pathname: "/propertyDeatils",
            state: { contactid: id },
        });
    };

    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
        setCurrentPage(1); // Reset page when search keyword changes
    };

    // Filter the data based on the search keyword

    const filteredData = Object.entries(bookingData).length !== 0 ? bookingData.filter(item =>
        item?.hostName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.customer?.customerUser?.firstName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.customer?.customerUser?.lastName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.hostPhone?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.creationDate?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.eventDate?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.venueAddress?.city?.toLowerCase().includes(searchKeyword.toLowerCase())

    ) : []


    const sortedData = filteredData.slice().sort((a, b) => {
        const aValue = (sortColumn == "hostName" ? a.hostName : sortColumn == "hostPhone" ? a.hostPhone : sortColumn == "creationDate" ? a.creationDate : sortColumn == "eventDate" ? a.eventDate : sortColumn == "city" ? a.venueAddress.city : a[sortColumn])
        const bValue = (sortColumn == "hostName" ? b.hostName : sortColumn == "hostPhone" ? b.hostPhone : sortColumn == "creationDate" ? b.creationDate : sortColumn == "eventDate" ? b.eventDate : sortColumn == "city" ? b.venueAddress.city : b[sortColumn]);
        if (sortDirection === 'asc') {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
            return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
    }).reverse();
    // Confirmed

    const filteredDataOfRequestFields = sortedData.filter((item) => item.bookingState == "Requested");

    // Extract "state" field from filtered data and create a new array
    const stateArray = filteredDataOfRequestFields.map((item) => item.state);
    // Calculate the total number of pages
    const totalPages = Math.ceil(stateArray.length / itemsPerPage);

    // Get the current page of items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredDataOfRequestFields.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle column sorting
    const handleColumnSort = (column) => {
        if (column === sortColumn) {
            setSortDirection((prevSortDirection) =>
                prevSortDirection === 'asc' ? 'desc' : 'asc'
            );
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const handleHistoryDetailView = async (id) => {
        history.push({
            pathname: `/Booking_Details/${id}`,
            state: { bookingid: id },
        });

    };

    const handleCustomerDetailView = async (id) => {
        history.push({
            pathname: `/Customer_Details/${id}`,
            state: { bookingid: id },
        });
    };

    return !loading ? (
        error ? (
            <div className="cover-spin"></div>
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Booking Requests</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("/Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Booking Requests
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                        <div className="col-lg-4">
                                            <div class="search-box">
                                                <input class="search-input" type="text" placeholder="Search.." value={searchKeyword}
                                                    onChange={handleSearchChange} />
                                                <button class="search-btn"><i class="fas fa-search"></i></button>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 pt-2"></div>
                                        <div className="col-lg-4 pt-2 text-right">
                                            {" "}
                                            <a
                                                href={() => false}
                                                className="btn btn-primary "
                                                data-toggle="modal"
                                                data-target="#createCustomerModal"
                                            >
                                                New Booking
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th >
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            Booking Id
                                                        </div>
                                                    </th>
                                                    <th onClick={() => handleColumnSort('hostName')}>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >

                                                            Customer Name {sortColumn === 'hostName' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>State
                                                            </div>
                                                        </div>
                                                    </th>
                                                    {/* <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Update State
                                                            </div>
                                                        </div>
                                                    </th> */}

                                                    {/* <th onClick={() => handleColumnSort('hostPhone')}>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            Phone {sortColumn === 'hostPhone' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </div>
                                                    </th> */}

                                                    <th onClick={() => handleColumnSort('creationDate')}>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            Creation Date {sortColumn === 'creationDate' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </div>
                                                    </th>

                                                    <th onClick={() => handleColumnSort('eventDate')}>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            Event Date {sortColumn === 'eventDate' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th onClick={() => handleColumnSort('street')}>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            Street	 {sortColumn === 'street' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th onClick={() => handleColumnSort('city')}>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            City of Event {sortColumn === 'city' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </div>
                                                    </th>


                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems !== null && currentItems !== undefined ? (
                                                    currentItems.map((item) => {
                                                        return (
                                                            item.bookingState == "Requested" ?
                                                                <tr>

                                                                    <td>
                                                                        <a
                                                                            className="breadcrumb-link"
                                                                            href={() => false}
                                                                            onClick={() =>
                                                                                handleHistoryDetailView(item.bookingId)
                                                                            }
                                                                        >
                                                                            {item.bookingId}
                                                                            {/* {item.customer?.customerUser?.userName} */}
                                                                            {/* {item.customer?.customerUser?.lastName} */}

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            className="breadcrumb-link"
                                                                            href={() => false}
                                                                            onClick={() =>
                                                                                handleCustomerDetailView(item?.customer?.customerId)
                                                                            }
                                                                        >
                                                                            {item?.hostName}
                                                                            {/* {item.customer?.customerUser?.userName} */}
                                                                            {/* {item.customer?.customerUser?.lastName} */}

                                                                        </a>
                                                                    </td>
                                                                    <td>{item.bookingState}</td>

                                                                    {/* <td>{item.hostPhone}</td> */}
                                                                    <td>{formatDate(item?.creationDate)}</td>
                                                                    {/* <td>{item.creationDate}</td> */}
                                                                    <td>{item.eventDate}</td>
                                                                    <td>{item?.venueAddress?.street}</td>
                                                                    <td>{item.venueAddress.city}</td>
                                                                    {/* <td >{item.numberOfGuests}</td> */}
                                                                    {/* <td>{item.profileSetupType}</td>
                                                                <td>{item.artist}</td>
                                                                <td>{item.soundVendor}</td> */}

                                                                    <td>
                                                                        <a
                                                                            href={() => false}
                                                                            className="cursor"
                                                                            data-toggle="modal"
                                                                            data-target="#editBookingModal"
                                                                            onClick={() => getBookingDetailsById(item.bookingId)}
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                        </a>{" "}
                                                                        <a
                                                                            href={() => false}
                                                                            className="cursor"
                                                                            data-toggle="modal"
                                                                            data-target="#deleteBookingModal"
                                                                            onClick={() => {
                                                                                setBookingId(item.bookingId);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                : ""
                                                        );
                                                    })
                                                ) : currentItems.length === 0 ? (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div>
                                                                <p>
                                                                    <strong style={{ color: "grey" }}>
                                                                        No records found
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div className="loading1"></div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <div>
                                            {Array.from({ length: totalPages }, (_, i) => (
                                                <button
                                                    key={i + 1}
                                                    onClick={() => handlePageChange(i + 1)}
                                                    disabled={currentPage === i + 1}
                                                    style={{ borderRadius: "40px", width: "40px", height: "35px" }}
                                                >
                                                    {i + 1}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddBooking />
                <Delete_Booking bookingId={bookingId} />
                <Update_Booking
                    bookingDetailsById={bookingDataByBookingId} />
                <ChangeState bookingDetailsById={bookingDataByBookingId} />
                <ReactTooltip />
            </>
        )
    ) : (
        <div className="cover-spin"></div>
    );
}

function formatDate(dateString) {
    if (!dateString) return ""; // Handle null or undefined case
  
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }
const mapStateToProps = (state, props) => {

    return {
        bookingData: state.getAllBooking.data,
        loading: state.getAllBooking.loading,
        bookingDataByBookingId: state.getBookingById.data,
        loggedIn: state.loggedIn,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBookingList: () => dispatch(fetchAllBooking()),
        getBookingDetailsById: async (bookingId) => dispatch(fetchBookingById(bookingId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingRequest);
