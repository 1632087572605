import {
    DELETE_WEBSITE_BANNER_REQUEST,
    DELETE_WEBSITE_BANNER_SUCCESS,
    DELETE_WEBSITE_BANNER_FAILURE,
} from "../types";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllWebsiteBanner } from "./getAllWebsiteBanner"
export const deleteWebsiteBannerByIdRequest = () => {
    return {
        type: DELETE_WEBSITE_BANNER_REQUEST,
    };
};

export const deleteWebsiteBannerByIdSuccess = (Data) => {
    return {
        type: DELETE_WEBSITE_BANNER_SUCCESS,
        payload: Data,
    };
};

export const deleteWebsiteBannerByIdFailure = (Error) => {
    return {
        type: DELETE_WEBSITE_BANNER_FAILURE,
        payload: Error,
    };
};

export const deleteWebsiteBannerById = (bannerId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deleteWebsiteBannerByIdRequest());
        axios
            .delete(url + `deleteTlsBannerById/${bannerId}`, {
                headers: headers,
            })
            .then(async (response) => {
                await dispatch(deleteWebsiteBannerByIdSuccess(response.data));
                localStorage.setItem("message", "WebsiteBannerDeleteSuccess");
                dispatch(fetchAllWebsiteBanner())
            })
            .catch((error) => {
                dispatch(deleteWebsiteBannerByIdFailure(error));
                localStorage.setItem("message", "WebsiteBannerDeleteFailed");

            });
    };
};
