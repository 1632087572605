import {
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
} from "../../actions/types";

const initialState = {
  loading: false,
  user: [],
  error: "",
  message: "",
};

export const createUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case POST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: "",
        message: "User Created Successfully !!",
      };
    case POST_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload,
        message: "failed",
      };
    default:
      return state;
  }
};
