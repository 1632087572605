import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchLogin, fetchLoginRequest } from "../../actions/authAction";
import { useHistory } from "react-router-dom";
import { stringValidate, emailValidate } from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import Logo1 from "../../styles/img/logo1.png";
import Logo2 from "../../styles/img/TheLiveShowImages/Logos/Black_Logo.png";
function LoginPage(props) {
  const history = useHistory();

  const loggeIn = localStorage.getItem("loggedIn");
  const remember_me = localStorage.getItem("Remember_be");

  const { handleLogin, message, loggedIn, error, refreshForm } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [user, setuser] = useState({
    username: "",
    password: "",
    remember_be: false,
  });
  const [loginformerror, setLoginFormError] = useState({
    emailerror: "",
    emailsuccess: "",
    passworderror: "",
    passwordsuccess: "",
  });

  useEffect(() => {
    if (loggeIn === "true" || remember_me === "true") {
      history.push("/Booking_Request");
    }
  }, [loggedIn, remember_me, history, loggeIn]);

  //-------------------------  CHECKING USER INPUT VALIDATIONS AND RUNIING LOGIN FUNCTION IF FINDS TRUE  ----------------- //
  const userLogin = (e) => {
    e.preventDefault();
    let emailerr;
    const isemail = user.username.indexOf("@");
    if (isemail !== -1) {
      emailerr = emailValidate(user.username, "Username");
    } else {
      emailerr = stringValidate(user.username, "Username");
    }

    const passerr = stringValidate(user.password, "Password");

    setLoginFormError({
      ...loginformerror,
      passworderror: passerr,
      emailerror: emailerr,
    });
    if (passerr === null && emailerr === null) {
      handleLogin(user);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("message") === "User LoggedIn Successfully !!") {
      const message = "LoggedIn Successfully !!";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");

    }
    if (localStorage.getItem("message") === "Unauthorized Login Access !!") {
      const message = "Unauthorized Login";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
      refreshForm();

    }
  }, [message, error]);

  return (
    <div>
      <div
        className="auth-wrapper d-flex no-block justify-content-center align-items-center"
        style={{
          background: "#f4f4f4",
          // backgroundImage: "url('img/login-bg2.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <div className="auth-box">
          <div id="loginform">
            <div className="logo">
              <span>
                <img src={Logo2} alt="FracSpace" width={"80px"} />
              </span>
            </div>
            {/* <!-- Form --> */}
            <div className="row">
              <div className="col-12">
                <form className="form-horizontal" onSubmit={userLogin}>
                  <div
                    className="form-horizontal"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) =>
                          setuser({
                            ...user,
                            username: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="profile-input-errors">
                      {loginformerror.emailerror}
                    </p>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon2">
                          <i className="fa fa-lock"></i>
                        </span>
                      </div>
                      <input
                        className="form-control "
                        type="password"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        onChange={(e) =>
                          setuser({
                            ...user,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="profile-input-errors">
                      {loginformerror.passworderror}
                    </p>
                    <div className="form-group row">
                      <div className="col-md-12">
                        {/* <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            onChange={(e) =>
                              setuser({
                                ...user,
                                remember_be: e.target.checked,
                              })
                            }
                          />
                          <label
                            className="custom-control-label cursor"
                            htmlFor="customCheck1"
                          >
                            Remember me
                          </label>
                          <a
                            onClick={() => history.push("/forgetpassword")}
                            className="text-dark float-right cursor"
                            href={() => false}
                          >
                            <i className="fa fa-lock"></i> Forgot Password ?
                          </a>
                        </div> */}

                        <a
                          onClick={() => history.push("/loginAsAgent")}
                          className="text-dark float-right cursor"
                          href={() => false}
                        >
                          <i className="fa fa-lock"></i> Login as agent
                        </a>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <div className="col-xs-12 p-b-20">
                        <button
                          className="btn btn-block  btn-info"
                          type="submit"
                          onClick={userLogin}
                        >
                          Log In
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    loggedIn: state.login.loggedIn,
    isActive: state.login.isActive,
    data: state.login.data,
    error: state.login.error,
    message: state.login.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin: (user) => dispatch(fetchLogin(user)),
    refreshForm: () => dispatch(fetchLoginRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
