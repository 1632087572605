import React from "react";
import WebisteBanner from "../component/websiteBanner/getAllWebsiteBanner";
function WebisteBannerPage() {
    return (
        <div>
            <WebisteBanner />
        </div>
    );
}

export default WebisteBannerPage;
