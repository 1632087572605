import {
    FETCH_ALL_PRICE_SLAB_REQUEST,
    FETCH_ALL_PRICE_SLAB_SUCCESS,
    FETCH_ALL_PRICE_SLAB_FAILURE
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllPriceSlabRequest = () => {
    return {
        type: FETCH_ALL_PRICE_SLAB_REQUEST,
    };
};
export const fetchAllPriceSlabSuccess = (Data) => {
    return {
        type: FETCH_ALL_PRICE_SLAB_SUCCESS,
        payload: Data,
    };
}

export const fetchAllPriceSlabFailure = (Error) => {
    return {
        type: FETCH_ALL_PRICE_SLAB_FAILURE,
        payload: Error,
    };
};

export const fetchAllPriceSlab = () => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };

    return (dispatch) => {

        dispatch(fetchAllPriceSlabRequest);
        axios
            .get(url + "getAllPriceSlab", {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllPriceSlabSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllPriceSlabFailure(error));
            });

    };
};