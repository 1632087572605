import {
  FETCH_LOGOUT_REQUEST,
  FETCH_LOGOUT_SUCCESS,
  FETCH_LOGOUT_FAILURE,
} from "../../actions/types";

const initialState = {
  loading: false,
  loggedIn: true,
  isActive: "",
  data: [],
  error: "",
};
export const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGOUT_REQUEST:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        data: {},
      };
    default:
      return state;
  }
};
