import {
  DELETE_ARTISTS_REQUEST,
  DELETE_ARTISTS_SUCCESS,
  DELETE_ARTISTS_FAILURE,
} from "../types";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllArtists } from "./getAllArtists"
export const deleteArtistRequest = () => {
  return {
    type: DELETE_ARTISTS_REQUEST,
  };
};

export const deleteArtistSuccess = (Data) => {
  return {
    type: DELETE_ARTISTS_SUCCESS,
    payload: Data,
  };
};

export const deleteArtistFailure = (Error) => {
  return {
    type: DELETE_ARTISTS_FAILURE,
    payload: Error,
  };
};

export const deleteArtist = (artistId) => {

  const headers = {
    "Content-Type": "application/json",
    'Authorization': basicAuth,
  };
  return (dispatch) => {
    dispatch(deleteArtistRequest());
    axios
      .delete(url + `deleteArtistUserById/${artistId}`, {
        headers: headers,
      })
      .then(async (response) => {
        await dispatch(deleteArtistSuccess(response.data));
        localStorage.setItem("message", "ArtistDeleteSuccess");
        dispatch(fetchAllArtists())
      })
      .catch((error) => {
        dispatch(deleteArtistFailure(error));
        localStorage.setItem("message", "ArtistDeleteFailed");
      });
  };
};
