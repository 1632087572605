import React, { useEffect, useState, useRef } from "react";
import {
    addBooking,
    addBookingRequest,
} from "../../actions/manageBooking/addBooking";
import { updateBookingState, updateBookingStateRequest } from "../../actions/manageBooking/updateBookingState"
import { connect } from "react-redux";

//VALIDATION FUNCTION
import {
    emailValidate,
    stringValidate,
    numberValidate,
    contactNumberValidation,
    postalCodeValidation,
    // websiteValidation,
} from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import { Dropdown } from "react-bootstrap";
import $ from "jquery";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const ChangeState = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        handleUpdateBookingState,
        message,
        error,
        bookingDetailsById,
        refreshform,
        paginationvalues,

    } = props;

    const CurrentOptions = [
        { title: "Yes", value: true },
        { title: "No", value: false },
    ];


    //----------------  ADD CUSTOMER STATE TO HANDLE INPUT VALUES ------------***//
    const isArtistSoundData = [
        {
            name: "true ",
        },
        {
            name: "false",
        },
    ];
    const bookingStateData = [
        {
            name: "Enquiry",
        },
        {
            name: "Requested",
        },
        {
            name: "Deleted",
        },
        {
            name: "PaymentAwaited",
        },
        {
            name: "Abandoned",
        },
        {
            name: "Confirmed",
        },
        {
            name: "Completed",
        },
        {
            name: "Settled",
        },
        {
            name: "Canceled",
        },
    ];
    const [selectdata, setselectdata] = useState({});
    const [updatebooking, setUpdateBooking] = useState({
        bookingId: "",
        bookingState: "",
    });

    const [imageData, setImagedata] = useState({
        imageBase: "",
        imageError: "",
    });

    useEffect(() => {
        setUpdateBooking({
            ...updatebooking,
            id: bookingDetailsById.bookingId,
            bookingState: bookingDetailsById.bookingState,
        });
    }, [bookingDetailsById]);

    //----------------  ADD CUSTOMER FIELDS ERROR ------------***//
    const [addupdatebookingerrors, setAddUpdateBookingError] = useState({

        bookingState: null,


    });

    const clearState = () => {
        setUpdateBooking({
            ...updatebooking,
            bookingId: "",

            bookingState: "",

        });
    };

    const handleValidation = () => {
        setAddUpdateBookingError({
            ...addupdatebookingerrors,
            bookingState: stringValidate(updatebooking.bookingState, "BookingState"),
        });
    };

    useEffect(() => {
        if (localStorage.getItem("message") === "RecordUpdatedFailed") {
            enqueueSnackbar(error, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform();
        }

        if (localStorage.getItem("message") === "RecordUpdatedSuccessfully") {
            handleClearValidationError();

            enqueueSnackbar("Booking Updated successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //---------------------------------------  ADD userS FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleUpdateBooking = (e) => {
        handleValidation();
        if (
            stringValidate(updatebooking.bookingState, "BookingState") === null
        ) {
            handleClearValidationError();
            handleUpdateBookingState(updatebooking);
        }
    };

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
    const handleClearValidationError = () => {
        setAddUpdateBookingError({
            ...addupdatebookingerrors,
            bookingState: null
        });

        clearState();
    };

    return (
        // <!-- The Modal -->
        <div className="modal" id="updateState">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Update State</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card mt-2">
                                <div className="card-header Accordian" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#contactinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        >
                                            <h5 className="pb-4">Booking Information</h5>
                                        </button>
                                    </h5>
                                </div>
                                <div

                                >
                                    <div className="card-body" style={{ height: "50vh" }}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        Booking State<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>

                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {updatebooking.bookingState
                                                                ? updatebooking.bookingState
                                                                : "Booking State"}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {bookingStateData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        setUpdateBooking({
                                                                            ...updatebooking,
                                                                            bookingState: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() => {
                                                                        setAddUpdateBookingError({
                                                                            ...addupdatebookingerrors,
                                                                            bookingState: stringValidate(
                                                                                updatebooking.bookingState,
                                                                                "BookingState"
                                                                            ),
                                                                        });
                                                                    }}
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addupdatebookingerrors.bookingState}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleUpdateBooking}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {

    return {
        loading: state.updateBookingState.loading,
        message: state.updateBookingState.message,
        error: state.updateBookingState.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleUpdateBookingState: (booking) => dispatch(updateBookingState(booking)),
        refreshform: () => dispatch(updateBookingStateRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ChangeState));
