import {
    UPDATE_AGENT_BY_ID_REQUEST,
    UPDATE_AGENT_BY_ID_SUCCESS,
    UPDATE_AGENT_BY_ID_FAILURE,
} from "../types";

import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllAgent } from "./getAllAgents";

export const updateAgentRequest = () => {
    return {
        type: UPDATE_AGENT_BY_ID_REQUEST,
    };
};

export const updateAgentSuccess = (Data) => {
    return {
        type: UPDATE_AGENT_BY_ID_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updateAgentFailure = (Error) => {
    return {
        type: UPDATE_AGENT_BY_ID_FAILURE,
        payload: { message: "updation failed", error: Error },
    };
};

export const updateAgent = (data) => {
    let formdata = new FormData();
    var myData = {
        agentUser: {
            userName: data.userName,
            firstName: data.firstName,
            lastName: data.lastName,
            secondaryPhone: data.secondaryPhone
        }
    };
    let dataa = JSON.stringify(myData);
    formdata.append("data", dataa);
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updateAgentRequest());
        axios
            .put(url + `updateAgentUserById/${data.id}`, {
                agentUser: {
                    userName: data.userName,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    secondaryPhone: data.secondaryPhone
                }
            }, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "Record Updated Successfully");
                    await dispatch(updateAgentSuccess(response.data));
                    await dispatch(fetchAllAgent());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "Record Updated Failed");
                dispatch(updateAgentFailure(error));
            });
    };
};
