import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddPriceSlab from "./addPriceSlab";
import UpdatePriceSlab from "./updatePriceSlab"
import Delete_Price_Slab from "./deletePriceSlab"
import { fetchAllPriceSlab } from "../../actions/priceSlab/getAllPriceSlab"
import { fetchPriceSlabById } from "../../actions/priceSlab/getPriceSlabById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";

function PriceSlab(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [priceSlabId, setPriceSlabId] = useState(0);
    const [paginationvalues, setPaginationValues] = useState({});

    const {
        loading,
        error,
        message,
        handleFetchPriceSlabById,
        handleGetPriceSlab,
        priceSlabData,
        getPriceSlabByIdData
    } = props;

    useEffect(() => {
        localStorage.setItem("View_Name", "priceSlab");
        checkRoute();
        checkRoute();
    }, [priceSlabData]);

    useEffect(() => {
        handleGetPriceSlab()
    }, [])





    return !loading ? (

        <>
            <div className="container-fluid">
                {/* <!-- Page Heading --> */}
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-5 align-self-center">
                            <h4 className="page-title">Price Slab</h4>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-1">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                onClick={() => history.push("/Booking_Request")}
                                                href={() => false}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Price Slab
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-7 align-self-center">
                            <div className="d-flex no-block justify-content-end align-items-center">
                                <div className="m-r-10">
                                    <div className="lastmonth"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4 pt-2"></div>
                                    <div className="col-lg-4 pt-2 text-right">
                                        {" "}
                                        <a
                                            href={() => false}
                                            className="btn btn-primary "
                                            data-toggle="modal"
                                            data-target="#createPriceSlabModal"
                                        >
                                            Add Price Slab
                                        </a>{" "}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>ID</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Price Slab Name</div>
                                                    </div>
                                                </th>
                                                {/* <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Amount</div>
                                                        </div>
                                                    </th> */}

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {priceSlabData !== null && priceSlabData !== undefined && Object.entries(priceSlabData).length !== 0 ? (
                                                priceSlabData?.map((item) => {
                                                    return (
                                                        <tr>

                                                            <td>
                                                                <a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: "/price_slab_details",
                                                                        });
                                                                        localStorage.setItem(
                                                                            "priceSlabId",
                                                                            item.priceSlabId
                                                                        );
                                                                    }}
                                                                >

                                                                    <td>{item.priceSlabId}</td>
                                                                </a>
                                                            </td>
                                                            <td> {item.priceSlabName}</td>
                                                            {/* <td>{item.amount}</td> */}
                                                            <td>
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#updatePriceSlabModal"
                                                                    onClick={() => handleFetchPriceSlabById(item.priceSlabId)}
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </a>{" "}
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#deletePriceSlabModal"
                                                                    onClick={() => {
                                                                        setPriceSlabId(item.priceSlabId);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : priceSlabData.length === 0 ? (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div>
                                                            <p>
                                                                <strong style={{ color: "grey" }}>
                                                                    No records found
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className="loading1"></div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddPriceSlab />
            <UpdatePriceSlab
                getPriceSlabByIdData={getPriceSlabByIdData}
            />
            <Delete_Price_Slab priceSlabId={priceSlabId} />
            <ReactTooltip />
        </>
    )
        : (
            <div className="cover-spin"></div>
        );
}

const mapStateToProps = (state, props) => {

    return {
        priceSlabData: state.fetchAllPriceSlab.data,
        loading: state.fetchAllPriceSlab.loading,
        error: state.fetchAllPriceSlab.error,
        getPriceSlabByIdData: state.getPriceSlabById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetPriceSlab: () => dispatch(fetchAllPriceSlab()),
        handleFetchPriceSlabById: (id) => dispatch(fetchPriceSlabById(id)),


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceSlab);
