import {
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAILURE,
} from "../types";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllBooking } from "./getAllBooking"

export const updateBookingRequest = () => {
  return {
    type: UPDATE_BOOKING_REQUEST,
  };
};

export const updateBookingSuccess = (Data) => {
  return {
    type: UPDATE_BOOKING_SUCCESS,
    payload: { message: Data.message },
  };
};

export const updateBookingFailure = (Error) => {
  return {
    type: UPDATE_BOOKING_FAILURE,
    payload: { message: " updation failed", error: Error },
  };
};

export const updateBooking = (data, addressData, customerUserData) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const headers = {
    "Content-Type": "application/json",
    'Authorization': basicAuth,
  };
  return (dispatch) => {
    dispatch(updateBookingRequest());
    axios
      .put(url + `updateBookingById/${data.id}`, {
        customerType: data.customerType,
        bookingState: data.bookingState,
        startTime: data.startTime,
        eventDate: data.eventDate,
        endTime: data.endTime,
        hostName: customerUserData.hostName,
        hostPhone: customerUserData.hostPhone,
        hostPhone2: data.hostPhone2 ? data.hostPhone2 : "",
        specialRequest: data.specialRequest,
        numberOfGuests: data.numberOfGuests,
        occasion: data.occasion,
        artistSound: data.isArtistSound == "true" ? true : false,
        bookingClosingDate: today,
        companyShare: data.companyShare,
        venueAddress: {
          addressType: "Venue Address",
          googleMapLocation: "",
          street: addressData.street,
          city: addressData.city,
          state: addressData.state,
          zipCode: data.zipCode,
          country: addressData.country,
          longitude: addressData.longitude,
          latitude: addressData.latitude,
          addressName: addressData.addressName
        },
        profileSetupType: {
          setupTypeId: data.setupTypeId
        },
        profileDuration: {
          durationId: data.durationId
        },
        customer: {
          customerId: data.customerId
        },
        artist: {
          artistId: data.artistId
        },
        selectedSoundSystem: data.soundSystemId ? {
          soundSystemId: data.soundSystemId
        } : null,
        soundVendor: data.soundVendorId ? {
          soundVendorId: data.soundVendorId
        } : null

      }, {
        headers: headers,
      })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "RecordUpdatedSuccessfully");
          await dispatch(updateBookingSuccess(response.data));
          await dispatch(fetchAllBooking());

        }
      })
      .catch((error) => {

        localStorage.setItem("message", "RecordUpdatedFailed");
        dispatch(updateBookingFailure(error));

      });
  };
};
