import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import { connect } from "react-redux";
import { fetchCustomerById } from "../../actions/cutomer/getCustomerById";
import { fetchSoundSystemById } from "../../actions/soundSystem/getSoundSystemById"

function Customerdetails(props) {
    // to go to parent module
    const { getUser, user, error, loading } = props;
    const history = useHistory();
    let id = localStorage.getItem("soundSystemId");

    useEffect(() => {
        localStorage.setItem("View_Name", "soundSystem");
        checkRoute();
        fetchUserData(id);
    }, []);

    const fetchUserData = (Id) => {
        getUser(Id);
    };
   
    return !loading ? (
        error ? (
            "oops....something goes wrong"
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Sound System</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item cursor">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item cursor"
                                                aria-current="page"
                                            >
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("soundSystem")}
                                                    href={() => false}
                                                >
                                                    Sound System
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Sound System Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex align-items-center bg-light p-2">
                                        {/* <h5></h5> */}
                                        <div className="ml-auto d-flex no-block align-items-center">
                                            <a
                                                onClick={() => {
                                                    history.goBack();
                                                    localStorage.removeItem("soundSystemId");
                                                }}
                                                className="btn btn-dark mr-3 "
                                                href={() => false}
                                            >
                                                Back
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <br className="clearfix" />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <h5 className="card-header">Information</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Title</th>
                                                                <td>{user?.soundSystem?.title}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>Charge Amount</th>
                                                                <td>{user?.soundSystem?.chargeAmount}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Max Number Of Guest</th>
                                                                <td>{user?.soundSystem?.maxNumberOfGuest}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Image</th>
                                                                <td><img src={user?.soundSystem?.soundSystemPhotoPath} alt="Neon Cocktails GIF" height="150" width="150" /></td>
                                                            </tr>
                                                            {/* soundSystemVideoPath */}
                                                            <tr>
                                                                <th>Video</th>
                                                                <td>

                                                                    <video height="200" width="200" controls>
                                                                        <source src={user?.soundSystem?.soundSystemVideoPath} type="video/mp4" />
                                                                        <source src={user?.soundSystem?.soundSystemVideoPath} type="video/ogg" />
                                                                    </video>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Specification
</th>
                                                                <td>{user?.soundSystem?.specification}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid -->  */}
                {/* <Edit_customers customerdata={data} /> */}
            </>
        )
    ) : (
        ""
    );
}

const mapStateToProps = (state, props) => {

    return {
        loading: state.getSoundSystemById.loading,
        user: state.getSoundSystemById.data,
        error: state.getSoundSystemById.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (user_ID) => dispatch(fetchSoundSystemById(user_ID)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Customerdetails);
