import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddTrigger from "./addTrigger";
import Update_Trigger from "./updateTrigger"
import Delete_Trigger from "./deleteTrigger"
import { fetchAllTrigger } from "../../actions/triggerActions/getAllTrigger"
import { fetchTriggerById } from "../../actions/triggerActions/getTriggerById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";

function Trigger(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [triggerId, setTriggerId] = useState("");

    const {
        loading,
        error,
        message,
        handleFetchTriggerById,
        handleGetAllTrigger,
        triggerData,
        getTriggerByIdData
    } = props;

    useEffect(() => {
        checkRoute();
    }, [triggerData]);
    useEffect(() => {
        localStorage.setItem("View_Name", "Trigger");
        checkRoute();
        handleGetAllTrigger()
    }, []);


    return !loading ? (
        error ? (
            <div className="cover-spin"></div>
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Trigger</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("/Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Trigger
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-4 pt-2"></div>
                                        <div className="col-lg-4 pt-2 text-right">
                                            {" "}
                                            <a
                                                href={() => false}
                                                className="btn btn-primary "
                                                data-toggle="modal"
                                                data-target="#createTriggerModal"
                                            >
                                                Add Trigger
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Flow Id</div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Name</div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Active</div>
                                                        </div>
                                                    </th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {triggerData !== null && triggerData !== undefined && Object.entries(triggerData).length !== 0 ? (
                                                    triggerData?.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <a
                                                                        className="breadcrumb-link"
                                                                        href={() => false}
                                                                        onClick={() => {
                                                                            history.push({
                                                                                pathname: "/Trigger_Detail",
                                                                            });
                                                                            localStorage.setItem(
                                                                                "flowId",
                                                                                item.flowId
                                                                            );
                                                                        }}
                                                                    >

                                                                        {item.flowId}
                                                                    </a>

                                                                </td>
                                                                <td>{item.name}</td>
                                                                <td>{item.active.toString()}</td>

                                                                <td>
                                                                    <a
                                                                        href={() => false}
                                                                        className="cursor"
                                                                        data-toggle="modal"
                                                                        data-target="#updateTriggerModal"
                                                                        onClick={() => handleFetchTriggerById(item.flowId)}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                    </a>{" "}
                                                                    <a
                                                                        href={() => false}
                                                                        className="cursor"
                                                                        data-toggle="modal"
                                                                        data-target="#deleteTriggerModal"
                                                                        onClick={() => {
                                                                            setTriggerId(item.flowId);
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : triggerData.length === 0 ? (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div>
                                                                <p>
                                                                    <strong style={{ color: "grey" }}>
                                                                        No records found
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div className="loading1"></div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddTrigger />

                <Update_Trigger
                    getTriggerByIdData={getTriggerByIdData}
                />

                <Delete_Trigger triggerId={triggerId} />
                <ReactTooltip />
            </>
        )
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        triggerData: state.getAllTrigger.data,
        loading: state.getAllSurgePrice.loading,
        error: state.getAllSurgePrice.error,
        getTriggerByIdData: state.getTriggerById.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetAllTrigger: () => dispatch(fetchAllTrigger()),
        handleFetchTriggerById: (id) => dispatch(fetchTriggerById(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
