import React from 'react'
import LoginAsAgent from "../component/auth/loginAsAgent"

function Login() {
    return (
        <div>
            <LoginAsAgent/>
        </div>
    )
}

export default Login
