import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ChangePasswordUser,
  fetchChangePasswordRequest,
} from "../../actions/Auth/changePassword";
import { stringValidate, passwordValidate } from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { fetchLogout } from "../../actions/authAction";
import Logo1 from "../../styles/img/logo1.png";
function ChangePassword(props) {
  const { handleChangePassword, email, message, refreshForm, handleLogout } =
    props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [ChangePassword, setChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
    email: email,
    confirmpass: "",
  });

  const [error, setError] = useState({
    oldPassword: null,
    newPassword: null,
    match: null,
  });

  // FUNCTION TO CHANGE PASSWORD FOR USER
  const changePassword = (e) => {
    e.preventDefault();

    setError({
      ...error,
      oldPassword: stringValidate(ChangePassword.oldPassword, "Old Password"),
      newPassword: passwordValidate(ChangePassword.newPassword, "password"),
    });

    if (
      stringValidate(ChangePassword.oldPassword, "Password") === null &&
      passwordValidate(ChangePassword.newPassword, "Password") === null
    ) {
      if (ChangePassword.newPassword === ChangePassword.confirmpass) {
        handleChangePassword(ChangePassword, email);
      } else {
        setError({
          ...error,
          oldPassword: "",
          newPassword: "",
          match: "Passwords didn't match",
        });
      }
    }
  };

  // CLEAR ERROR IF AFTER FUNCTION HIT
  const clearErr = () => {
    setError({
      ...error,
      oldPassword: null,
      newPassword: null,
      match: null,
    });
  };

  // CLEAR FIELDS AFTER SUCCESS
  const clearField = () => {
    setChangePassword({
      ...ChangePassword,
      oldPassword: "",
      newPassword: "",
      email: "",
      confirmpass: "",
    });
  };

  const handleModalClose = () => {
    clearErr();
    clearField();
  };

  useEffect(() => {
    if (message === "Old password are not matched.") {
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 1000,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });

      refreshForm();
      clearField();
    }
    if (message === "Password updated successfully.") {
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 1000,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });

      refreshForm();
      clearField();
      handleLogout();
    }
  }, [message]);

  return (
    <div
      className="modal fade"
      id="changepassword"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Change Password
            </h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleModalClose}
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </button>
          </div>
          <div className="modal-body pt-0">
            <form className="form-horizontal pt-0 " onSubmit={changePassword}>
              {/* <div className="form-group row">
                
                <div className="col-12 ">
                  <label>Email Address</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    disabled
                  />
                 
                </div>
              </div> */}
              <div className="form-group row">
                <div className="col-12 ">
                  <label>Old Password</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Old Password"
                    value={ChangePassword.oldPassword}
                    onChange={(e) =>
                      setChangePassword({
                        ...ChangePassword,
                        oldPassword: e.target.value,
                      })
                    }
                  />
                  <p className="profile-input-errors">{error.oldPassword}</p>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12 ">
                  <label>New Password</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="New Password"
                    value={ChangePassword.newPassword}
                    onChange={(e) =>
                      setChangePassword({
                        ...ChangePassword,
                        newPassword: e.target.value,
                      })
                    }
                  />
                  <p className="profile-input-errors">{error.newPassword}</p>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12 ">
                  <label>Confirm Password</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Confirm Password"
                    value={ChangePassword.confirmpass}
                    onChange={(e) =>
                      setChangePassword({
                        ...ChangePassword,
                        confirmpass: e.target.value,
                      })
                    }
                  />
                  <p className="profile-input-errors">{error.match}</p>
                </div>
              </div>

              <div className="form-group text-center ">
                <div className="col-xs-12 p-b-20 ">
                  <button
                    className="btn btn-block btn-success "
                    type="submit "
                    onClick={changePassword}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    data: state.changepassword.data,
    error: state.changepassword.error,
    message: state.changepassword.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePassword: (user, email) =>
      dispatch(ChangePasswordUser(user, email)),
    refreshForm: () => dispatch(fetchChangePasswordRequest()),
    handleLogout: () => dispatch(fetchLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
