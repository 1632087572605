import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../../utils/checkroute";
import { fetchAllArtistVideo } from "../../../actions/artists/videoManagement/getAllArtistVideos"
import { fetchArtistVideoById } from "../../../actions/artists/videoManagement/getArtistVideosById"
// All modal for adding , delete , editing
import AddVideo from "./addVideo";
import UpdateVideo from "./updateVideo";
import Delete_Artist_Video from "./deleteVideo"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
function VideoManagement(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [videoId, setVideoId] = useState("");
    const [paginationvalues, setPaginationValues] = useState({});

    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 2,
        sortingorder: "desc",
        search: null,
        columnname: "",
    });

    const {
        handleGetArtistVideosById,
        loading,
        error,
        getArtistVideosByIdData,
        handleGetAllArtistVideos,
        allVideosData,
    } = props;

    useEffect(() => {
        checkRoute();

    }, [allVideosData]);


    useEffect(() => {
        handleGetAllArtistVideos();
    }, [])

    const handleContactDetailView = async (id) => {
        history.push({
            pathname: "/propertyDeatils",
            state: { contactid: id },
        });
    };

    return !loading ? (
        error ? (
            <div className="cover-spin"></div>
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <h4 className="page-title">Videos</h4>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                        <div className="col-lg-5"></div>
                                        <div className="col-lg-6 pt-2"></div>
                                        <div className="col-lg-1 pt-2 text-right">
                                            {" "}
                                            <a
                                                href={() => false}
                                                className="btn btn-primary "
                                                data-toggle="modal"
                                                data-target="#createCustomerModal"
                                            >
                                                Add
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Video Id</div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Video Description</div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Video Type
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Video Link
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Video URL
                                                            </div>
                                                        </div>
                                                    </th>

                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allVideosData === null || allVideosData === undefined || Object.keys(allVideosData).length !== 0 ?
                                                    Object.keys(allVideosData).length === 0 ? (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <div>
                                                                    <p>
                                                                        <strong style={{ color: "grey" }}>
                                                                            No records found
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    ) : (
                                                        allVideosData.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td>{item?.videoId}</td>
                                                                    <td>{item?.videoDesc}</td>
                                                                    <td>{item?.videoType}</td>
                                                                    <td>{item?.videoLink}</td>

                                                                    <td>
                                                                        <a
                                                                            className="breadcrumb-link"
                                                                            href={item?.videoPath}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >

                                                                            {item?.video}
                                                                        </a>
                                                                    </td>


                                                                    <td>
                                                                        <a
                                                                            href={() => false}
                                                                            data-toggle="modal"
                                                                            data-target="#updateVideoModal"
                                                                            onClick={() =>
                                                                                handleGetArtistVideosById(item.videoId)
                                                                            }
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                        </a>{" "}
                                                                        <a
                                                                            href={() => false}
                                                                            data-toggle="modal"
                                                                            data-target="#deleteArtistVideoModal"
                                                                            onClick={() => setVideoId(item.videoId)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <div className="loading1"></div>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddVideo />
                <UpdateVideo
                    getArtistVideosByIdData={getArtistVideosByIdData}
                />
                <Delete_Artist_Video videoId={videoId} />
                <ReactTooltip />

            </>
        )
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {

    return {

        allVideosData: state.getAllArtistVideos.data,
        getArtistVideosByIdData: state.getArtistVideosById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleGetAllArtistVideos: () => dispatch(fetchAllArtistVideo()),
        handleGetArtistVideosById: (id) => dispatch(fetchArtistVideoById(id)),


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoManagement);
