import {
  ADD_SOUND_VENDER_REQUEST,
  ADD_SOUND_VENDER_SUCCESS,
  ADD_SOUND_VENDER_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllSoundVender } from "./getAllSoundVender";
export const addSoundVenderRequest = () => {
  return {
    type: ADD_SOUND_VENDER_REQUEST,
  };
};

export const addSoundVenderSuccess = (Data) => {
  return {
    type: ADD_SOUND_VENDER_SUCCESS,
    payload: { data: Data.data, message: Data.message },
  };
};

export const addSoundVenderFailure = (Error) => {
  return {
    type: ADD_SOUND_VENDER_FAILURE,
    payload: Error,
  };
};
export const addSoundVender = (data, addressData) => {
  var headers = {
    Accept: "Application/json",
    "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,
  };

  let formdata = new FormData();
  var myData = {
    companyName: data.companyName,
    gSTIN: data.gSTIN,
    websiteUrl: data.websiteUrl,
    designation: data.designation,
    operatingState: data.operatingState,
    soundVendorUser: {
      userName: "",
      password: "",
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      secondaryPhone: data.secondaryPhone
    },
    officeAddress: {
      googleMapLocation: data.googleMapLocation,
      street: addressData.street,
      city: addressData.city,
      state: addressData.state,
      zipCode: data.zipCode,
      country: addressData.country,
      longitude: addressData.longitude,
      latitude: addressData.latitude,
      addressName: addressData.addressName
    }
  };
  let dataa = JSON.stringify(myData);
  formdata.append("data", dataa);

  return (dispatch) => {
    dispatch(addSoundVenderRequest());
    axios
      .post(url + `addSoundVendor`, formdata, { headers: headers })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "addSoundVenderSuccess");
          await dispatch(addSoundVenderSuccess(response.data));
          await dispatch(fetchAllSoundVender());
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "addSoundVenderFailed");
        dispatch(
          addSoundVenderFailure("Please Check Your Internet Connection !!")
        );
      });
  };
};
