import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import { connect } from "react-redux";
import { fetchCustomerById } from "../../actions/cutomer/getCustomerById";

function Customerdetails(props) {
    // to go to parent module
    const { getUser, user, error, loading } = props;
    const history = useHistory();
    // let id = localStorage.getItem("customerId");
    const { id } = useParams();
    useEffect(() => {
        localStorage.setItem("View_Name", "Customer_Management");
        checkRoute();
        fetchUserData(id);
    }, []);

    const fetchUserData = (Id) => {
        getUser(Id);
    };
    const handleHistoryDetailView = async (id) => {
        history.push({
            pathname: `/Booking_Details/${id}`,
            state: { bookingid: id },
        });
        // "/Customer_Details/:id"
    };

    return !loading ? (
        error ? (
            "oops....something goes wrong"
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Customer</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item cursor">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item cursor"
                                                aria-current="page"
                                            >
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("Customer_Management")}
                                                    href={() => false}
                                                >
                                                    Customer
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Customer Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex align-items-center bg-light p-2">
                                        {/* <h5></h5> */}
                                        <div className="ml-auto d-flex no-block align-items-center">
                                            <a
                                                onClick={() => {
                                                    history.goBack();
                                                    localStorage.removeItem("customerId");
                                                }}
                                                className="btn btn-dark mr-3 "
                                                href={() => false}
                                            >
                                                Back
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <br className="clearfix" />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <h5 className="card-header">Profile Information</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Name</th>
                                                                <td>{user?.customerUser?.userName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Image</th>
                                                                <td>
                                                                    {user.customerProfilePath !== "" ? (
                                                                        <img
                                                                            src={user.customerProfilePath}
                                                                            className="rounded-circle noimageUser"
                                                                            alt=""
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src="img/noimage.png"
                                                                            className="rounded-circle noimageUser"
                                                                            alt=""
                                                                        />
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Customer Type</th>
                                                                <td>{user.customerType
                                                                }</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Default Venue Address </h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>

                                                            <tr>
                                                                <th>Address Type</th>
                                                                <td>{user?.defaultVenueAddress?.addressType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Country</th>
                                                                <td>{user?.defaultVenueAddress?.country}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>State</th>
                                                                <td>{user?.defaultVenueAddress?.state}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>city</th>
                                                                <td>{user?.defaultVenueAddress?.city}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Zip Code
                                                                </th>
                                                                <td>{user?.defaultVenueAddress?.zipCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Street </th>
                                                                <td>{user?.defaultVenueAddress?.street}</td>
                                                            </tr>




                                                            <tr>
                                                                <th>Full Address </th>
                                                                <td>{user?.defaultVenueAddress?.addressName}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> */}
                                            <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Billing Address</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Address Type </th>
                                                                <td>{user?.billingAddress?.addressType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Street</th>
                                                                <td>{user?.billingAddress?.street}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>City</th>
                                                                <td>{user?.billingAddress?.city}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>ZipCode</th>
                                                                <td>{user?.billingAddress?.zipCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>State</th>
                                                                <td>{user?.billingAddress?.state}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Country</th>
                                                                <td>{user?.billingAddress?.country}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>Full Address</th>
                                                                <td>{user?.billingAddress?.addressName}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <h5 className="card-header">General Information</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Created Date</th>
                                                                <td>{user?.customerUser?.createdDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Email Address</th>
                                                                <td>
                                                                    <a
                                                                        href="*"
                                                                        onClick={() =>
                                                                            window.open(
                                                                                `mailto:${user.email}`,
                                                                                "_blank"
                                                                            )
                                                                        }
                                                                        target="blank"
                                                                    >
                                                                        {user?.customerUser?.email}
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th>First Name</th>
                                                                <td> {user?.customerUser?.firstName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Last Name</th>
                                                                <td> {user?.customerUser?.lastName
                                                                }</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Modified Date</th>
                                                                <td> {user?.customerUser?.modifiedDate
                                                                }</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Phone Number</th>
                                                                <td> {user?.customerUser?.phoneNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Secondary Phone</th>
                                                                <td> {user?.customerUser?.secondaryPhone}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>User Type</th>
                                                                <td> {user?.customerUser?.userType}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Booking History</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            {user.bookingList === undefined || user.bookingList === null || Object.keys(user.bookingList).length === 0 ? (
                                                                ""
                                                            ) : (
                                                                Object.values(user.bookingList).map((item, index) => (
                                                                    <tr key={index}>
                                                                        <th>Booking ID</th>
                                                                        <td>
                                                                            <a
                                                                                className="breadcrumb-link"
                                                                                href={() => false}
                                                                                onClick={() => handleHistoryDetailView(item.bookingId)}
                                                                            >
                                                                                {item.bookingId}
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                            )
                                                            }
                                                            <br />
                                                            <tr>
                                                                <th>Total Number of booking
                                                                </th>
                                                                <td>{user.bookingList === undefined || user.bookingList === null || Object.keys(user.bookingList).length === 0 ? "" : user?.bookingList.length}</td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {/* <div className="card">
                        <h5 className="card-header">Property Information</h5>
                        <div className="card-body pb-1">
                          <table className="table table1 table-borderless table-responsive table-sm">
                            <tbody>
                              <tr>
                                <th>Bought Property</th>
                                {
                                  user.boughtProperty.map((e)=>(
                                    <td>{user.boughtProperty}</td>
                                  ))
                                }
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid -->  */}
                {/* <Edit_customers customerdata={data} /> */}

            </>
        )
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {

    return {
        loading: state.getCustomerById.loading,
        user: state.getCustomerById.data,
        error: state.getCustomerById.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (user_ID) => dispatch(fetchCustomerById(user_ID)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Customerdetails);
