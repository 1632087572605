import {
    UPDATE_ARTIST_VIDEOS_REQUEST,
    UPDATE_ARTIST_VIDEOS_SUCCESS,
    UPDATE_ARTIST_VIDEOS_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
import { fetchAllArtistVideo } from "./getAllArtistVideos"
export const updateArtistVideoRequest = () => {
    return {
        type: UPDATE_ARTIST_VIDEOS_REQUEST,
    };
};

export const updateArtistVideoSuccess = (Data) => {
    return {
        type: UPDATE_ARTIST_VIDEOS_SUCCESS,
        payload: { data: Data.data, message: Data.message },
    };
};

export const updateArtistVideoFailure = (Error) => {
    return {
        type: UPDATE_ARTIST_VIDEOS_FAILURE,
        payload: Error,
    };
};
export const updateArtistVideo = (data) => {
    var headers = {
        Accept: "Application/json",
        "Content-Type": "multipart/formdata",
        'Authorization': basicAuth,
    };
    const artistId = localStorage.getItem("artistId");

    let formdata = new FormData();
    var myData = {
        videoLink: data.videoLink,
        videoDesc: data.videoDesc,
        videoType: data.videoType,
        artist: {
            artistId: artistId
        }
    };
    let dataa = JSON.stringify(myData);
    formdata.append("data", dataa);
    formdata.append("videoFile", data.videoFile);


    return (dispatch) => {
        dispatch(updateArtistVideoRequest());
        axios
            .put(url + `updateArtistVideosById/${data.id}`, formdata, { headers: headers })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "updateArtistVideoSuccess");
                    await dispatch(updateArtistVideoSuccess(response.data));
                    await dispatch(fetchAllArtistVideo());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "updateArtistVideofailed");
                dispatch(
                    updateArtistVideoFailure("Please Check Your Internet Connection !!")
                );
            });
    };
};
