import React from 'react'
import Metrics from '../component/metrics/metrics'
function MetricsPage() {
    return (
        <div>
            <Metrics/>
        </div>
    )
}

export default MetricsPage
