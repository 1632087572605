import React from "react";
import { Redirect } from "react-router-dom";

// route view
import Login from "../pages/login";
import AuthLayout from "../component/Layout/AuthLayout";
import Register from "../pages/register";
import LoginAsAgent from "../pages/loginAsAgent";
import ChangePassword from "../pages/changePassword";

export const authroutes = [
  {
    path: "/",
    exact: true,
    layout: AuthLayout,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    layout: AuthLayout,
    component: Login,
  },
  {
    path: "/register",
    layout: AuthLayout,
    component: Register,
  },
  {
    path: "/loginAsAgent",
    layout: AuthLayout,
    component: LoginAsAgent,
  },
  {
    path: "/changepassword/:id",
    layout: AuthLayout,
    component: ChangePassword,
  },
];
