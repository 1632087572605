import {
  POST_CHANGEPASSWORD_REQUEST,
  POST_CHANGEPASSWORD_SUCCESS,
  POST_CHANGEPASSWORD_FAILURE,
} from "../types";
import axios from "axios";


export const fetchChangePasswordRequest = () => {
  return {
    type: POST_CHANGEPASSWORD_REQUEST,
  };
};

export const fetchChangePasswordSuccess = (Data) => {
  return {
    type: POST_CHANGEPASSWORD_SUCCESS,
    payload: Data.message,
  };
};

export const fetchChangePasswordFailure = (Error) => {
  return {
    type: POST_CHANGEPASSWORD_FAILURE,
    payload: Error,
  };
};


export const ChangePasswordUser = (data , email) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("Auth_token")}`,
  };
  return (dispatch) => {
    dispatch(fetchChangePasswordRequest);
    // axios
    //   .post(
    //     process.env.REACT_APP_API_KEY + "/Account/ChangePassword",
    //     {
    //       emailAddress: email,
    //       password: data.newPassword,
    //       oldPassword: data.oldPassword,
    //     },
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then(function (response) {
        dispatch(fetchChangePasswordSuccess("Change Password"));
      // })
      // .catch(function (error) {
      //   dispatch(fetchChangePasswordFailure(error));
      // });
  };
};
