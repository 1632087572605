import {
    UPDATE_SURGE_PRICE_BY_ID_REQUEST,
    UPDATE_SURGE_PRICE_BY_ID_SUCCESS,
    UPDATE_SURGE_PRICE_BY_ID_FAILURE,
} from "../types";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllSurgePrice } from "./getAllSurgePrice"

export const updateSurgePriceRequest = () => {
    return {
        type: UPDATE_SURGE_PRICE_BY_ID_REQUEST,
    };
};

export const updateSurgePriceSuccess = (Data) => {
    return {
        type: UPDATE_SURGE_PRICE_BY_ID_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updateSurgePriceFailure = (Error) => {
    return {
        type: UPDATE_SURGE_PRICE_BY_ID_FAILURE,
        payload: { message: " updation failed", error: Error },
    };
};

export const updateSurgePrice = (data) => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updateSurgePriceRequest());
        axios
            .put(url + `updateSurgePriceById/${data.id}`, {
                surgrePricingName: data.surgrePricingName,
                startDate: data.startDate,
                endDate: data.endDate,
                startTime: data.startTime,
                endTime: data.endTime,
                surgeMultiplier: data.surgeMultiplier
            }, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "RecordUpdatedSuccessfully");
                    await dispatch(updateSurgePriceSuccess(response.data));
                    await dispatch(fetchAllSurgePrice());

                }
            })
            .catch((error) => {
                localStorage.setItem("message", "RecordUpdatedFailed");
                dispatch(updateSurgePriceFailure(error));
            });
    };
};
