import React from 'react';
import Index from "../component/auth/register"

function Register() {

    return (
       <div>
           <Index/>
       </div>
    )
}

export default Register
