import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import { fetchAllAgent } from "../../actions/agents/getAllAgents";
import ReactTooltip from "react-tooltip";
import { fetchAgentById } from "../../actions/agents/getAgentById";
import CreateAgent from "./addAgent";
import UpdateAgent from "./updateAgent"
function Agents(props) {
    const matches = useMediaQuery("(max-width:400px)");
    const history = useHistory();
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const [paginationvalues, setPaginationValues] = useState({
        pagenumber: 1,
        pagesize: 10,
        activepage: "",
        totalrecords: 0,
        totalpages: 0,
        filtercol: null,
        filtervalue: null,
        CurrentRecord: 0,
        currentData: "",
    });

    const CurrentUser = localStorage.getItem("UserId");

    const [UserId, setUserId] = useState("");

    //------------------------------ SHOWING PAGE SEARCH AND ORDER -------------------//
    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 10,
        sortingorder: "desc",
        search: null,
        columnname: "",
        contactdropdown: false,
    });

    const {
        fetchAgentListData,
        loading,
        user,
        error,
        handleFetchAgentById,
        data,
        getAgentByIdData
    } = props;
    const SuperAdmin = localStorage.getItem("Super_Admin");
    useEffect(() => {
        if (SuperAdmin == "true") {
            setIsSuperAdmin(true);
        }
        else {
            history.push("/Booking_Request")
            setIsSuperAdmin(false)
        }
    }, [SuperAdmin]);

    useEffect(() => {
        localStorage.setItem("View_Name", "Agents");
        checkRoute();

    }, [user]);
    useEffect(() => {
        fetchAgentListData()

    }, [])

    return !loading ? (
        <>
            <div className="container-fluid">
                {/* <!-- Page Heading --> */}
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-5 align-self-center">
                            <h4 className="page-title">Agents</h4>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-1">
                                        <li className="breadcrumb-item cursor">
                                            <a
                                                className="breadcrumb-link"
                                                onClick={() => history.push("Booking_Request")}
                                                href={() => false}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Agents
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-7 align-self-center">
                            <div className="d-flex no-block justify-content-end align-items-center">
                                <div className="m-r-10">
                                    <div className="lastmonth"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4 pt-2"></div>
                                    <div className="col-lg-4 pt-2 text-right">
                                        {" "}
                                        <a
                                            href={() => false}
                                            className="btn btn-primary "
                                            data-toggle="modal"
                                            data-target="#createCustomerModal"
                                        >
                                            Add Agent
                                        </a>{" "}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="cursor">
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>userName</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>First Name</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Last Name</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Email</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Phone</div>
                                                    </div>
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data !== undefined && data !== null && Object.entries(data).length !== 0 ? (
                                                data?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>

                                                                {item?.agentUser?.userName}

                                                            </td>

                                                            <td>
                                                                {item?.agentUser?.firstName}
                                                            </td>
                                                            <td>
                                                                {item?.agentUser?.lastName}
                                                            </td>


                                                            <td>
                                                                {item?.agentUser?.email}
                                                            </td>
                                                            <td>
                                                                {item?.agentUser?.phoneNumber}
                                                            </td>
                                                            <td>
                                                                <a
                                                                    href={() => false}
                                                                    data-toggle="modal"
                                                                    data-target="#updateAgentModal"
                                                                    onClick={() => handleFetchAgentById(item.agentId)}
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </a>{" "}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : data.total === 0 ? (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div>
                                                            <p>
                                                                <strong style={{ color: "grey" }}>
                                                                    No records found
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className="loading1"></div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateAgent />
            <UpdateAgent
                getAgentByIdData={getAgentByIdData}
            />
            {/* <DeleteUser UserId={UserId} paginationvalues={paginationvalues} /> */}
            {/* <ReactTooltip /> */}
        </>
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.fetchAllAgent.loading,
        data: state.fetchAllAgent.data,
        error: state.fetchAllAgent.error,
        getAgentByIdData: state.getAgentById.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAgentListData: () => dispatch(fetchAllAgent()),
        handleFetchAgentById: (id) => dispatch(fetchAgentById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agents);
