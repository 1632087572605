import {
    FETCH_ALL_SOUND_SYSTEM_REQUEST,
    FETCH_ALL_SOUND_SYSTEM_SUCCESS,
    FETCH_ALL_SOUND_SYSTEM_FAILURE
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllSoundSystemRequest = () => {
    return {
        type: FETCH_ALL_SOUND_SYSTEM_REQUEST,
    };
};
export const fetchAllSoundSystemSuccess = (Data) => {
    return {
        type: FETCH_ALL_SOUND_SYSTEM_SUCCESS,
        payload: Data,
    };
}

export const fetchAllSoundSystemFailure = (Error) => {
    return {
        type: FETCH_ALL_SOUND_SYSTEM_FAILURE,
        payload: Error,
    };
};


export const fetchAllSoundSystem = () => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllSoundSystemRequest);
        axios
            .get(url + "getAllSoundSystem", {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllSoundSystemSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllSoundSystemFailure(error));
            });

    };
};