import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../../utils/checkroute";
import { fetchAllArtistPreferredSetup } from "../../../actions/artists/artistPreferredSetup/getAllPreferredSetup"
import { fetchArtistPreferredSetupById } from "../../../actions/artists/artistPreferredSetup/getPreferredSetupById"

// All modal for adding , delete , editing
import AddPreferredSetup from "./addArtistPreferredSetup";
import UpdatePreferredSetup from "./updatePreferredSetupById";
import Delete_Artist_Preferred_Setup from "./deleteArtistPreferredSetup"
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
function AllPreferredSetup(props) {
    const history = useHistory();
    const {
        handleGetArtistPreferredSetupById,
        loading,
        error,
        handleGetAllArtistPreferredSetupByArtistId,
        allArtistPreferredSetupDataByArtistId,
        fetchArtistPreferredSetupByIdData
    } = props;

    const [preferredSetupId, setPreferredSetupId] = useState("")

    useEffect(() => {
        checkRoute();

    }, [allArtistPreferredSetupDataByArtistId]);


    useEffect(() => {
        handleGetAllArtistPreferredSetupByArtistId();
    }, [])

    return !loading ? (
        error ? (
            <div className="cover-spin"></div>
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <h4 className="page-title">Artist Preferred Setup</h4>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                        <div className="col-lg-5"></div>
                                        <div className="col-lg-6 pt-2"></div>
                                        <div className="col-lg-1 pt-2 text-right">
                                            {" "}
                                            <a
                                                href={() => false}
                                                className="btn btn-primary "
                                                data-toggle="modal"
                                                data-target="#addPreferredSetupModal"
                                            >
                                                Add
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Profilfewafe Photo</div>
                                                        </div>
                                                    </th>
                                                    {/* <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Preferred Setup Id</div>
                                                        </div>
                                                    </th> */}
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Is Artist Default Setup</div>
                                                        </div>
                                                    </th>

                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Setup Type
                                                            </div>
                                                        </div>
                                                    </th>

                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allArtistPreferredSetupDataByArtistId === null || allArtistPreferredSetupDataByArtistId === undefined || Object.keys(allArtistPreferredSetupDataByArtistId).length !== 0 ?
                                                    Object.keys(allArtistPreferredSetupDataByArtistId).length === 0 ? (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <div>
                                                                    <p>
                                                                        <strong style={{ color: "grey" }}>
                                                                            No records found
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    ) : (
                                                        allArtistPreferredSetupDataByArtistId.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {item.profilePhotoPath !== "" ? (
                                                                            <img
                                                                                src={item?.profilePhotoPath}
                                                                                className="rounded-circle noimageUser"
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="img/noimage.png"
                                                                                className="rounded-circle noimageUser"
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    {/* <td>{item?.preferredSetupId}</td> */}
                                                                    <td>{item?.isArtistDefaultSetup.toString().toUpperCase()}</td>
                                                                    <td>{item?.setType?.setupType}</td>
                                                                    <td>
                                                                        <a
                                                                            href={() => false}
                                                                            data-toggle="modal"
                                                                            data-target="#UpdatePreferredSetupModal"
                                                                            onClick={() =>
                                                                                handleGetArtistPreferredSetupById(item.preferredSetupId)
                                                                            }
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                        </a>{" "}
                                                                        <a
                                                                            href={() => false}
                                                                            data-toggle="modal"
                                                                            data-target="#deleteArtistPreferredSetupModal"
                                                                            onClick={() => setPreferredSetupId(item.preferredSetupId)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <div className="loading1"></div>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddPreferredSetup />
                <UpdatePreferredSetup
                    fetchArtistPreferredSetupByIdData={fetchArtistPreferredSetupByIdData}
                />
                <Delete_Artist_Preferred_Setup preferredSetupId={preferredSetupId} />
                <ReactTooltip />
            </>
        )
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        allArtistPreferredSetupDataByArtistId: state.fetchAllArtistPreferredSetup.data,
        loading: state.fetchAllArtistPreferredSetup.loading,
        getArtistVideosByIdData: state.getArtistVideosById.data,
        fetchArtistPreferredSetupByIdData: state.fetchArtistPreferredSetupById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetAllArtistPreferredSetupByArtistId: () => dispatch(fetchAllArtistPreferredSetup()),
        handleGetArtistPreferredSetupById: (id) => dispatch(fetchArtistPreferredSetupById(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPreferredSetup);
