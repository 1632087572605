import {
    GET_USER_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_FAILURE,

  } from "../../actions/types";
  
  const initialState = {
    loading: false,
    user: [],
    error: "",
    message: "",
  };
  
  export const getuserReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_USER_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case GET_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
          error: "",
        };
  
      case GET_USER_FAILURE:
        return {
          ...state,
          loading: false,
          user: [],
          error: action.payload,
        };
  
      default:
        return state;
    }
  };