import React, { useEffect, useState, useRef } from "react";
import {
    addBooking,
    addBookingRequest,
} from "../../actions/manageBooking/addBooking";
import {
    fetchAllProfileSetupType,
    fetchAllProfileDuration,
    fetchAllCustomerDropdown,
    fetchAllArtistDropdown,
    fetchAllSoundSystemDropdown,
    fetchAllSoundVenderDropdown
} from "../../actions/common/getAllDropdown"
import { fetchArtistById } from "../../actions/artists/getArtistById"
import { fetchCustomerById } from "../../actions/cutomer/getCustomerById"
import { connect } from "react-redux";
//VALIDATION FUNCTION
import {
    emailValidate,
    stringValidate,
    numberValidate,
    contactNumberValidation,
    postalCodeValidation,
    // websiteValidation,
} from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import { Dropdown } from "react-bootstrap";
import Select from 'react-select';
import $ from "jquery";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "react-google-autocomplete";
//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

const AddartistListing = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [profileSetupTypeselectedOption, setProfileSetupTypeselectedOption] = useState("");
    const [soundSystemSelectedOption, setSoundSystemSelectedOption] = useState("");
    const [soundVendorSelectedOption, setSoundVendorSelectedOption] = useState("");

    const [profileDurationselectedOption, setProfileDurationselectedOption] = useState("");
    const [customerselectedOption, setCustomerselectedOption] = useState("");
    const [artistselectedOption, setArtistselectedOption] = useState("");
    const [artistSoundSelectedOption, setArtistSoundSelectedOption] = useState("");

    const { Addartist,
        message,
        error,
        refreshform,
        loading,
        handleFetchAllProfileSetupType,
        handleFetchAllProfileDuration,
        handleFetchAllCustomerDropdown,
        handleFetchAllArtistDropdown,
        handleFetchAllSoundSystemDropdown,
        soundSystemDropdownData,
        ProfileSetupTypeData,
        ProfileDurationData,
        CustomerDropdownData,
        ArtistDropdownData,
        handleFetchArtistById,
        hnaldeFetchCustomerById,
        customerByIdData,
        handleFetchAllSoundVenderDropdown,
        soundVendorDropdownData
    } = props;

    //----------------  ADD artist STATE TO HANDLE INPUT VALUES ------------***//
    const isArtistSoundData = [

        { value: "true", label: "Yes" },
        { value: "false", label: "No" },

    ];
    const customerTypeDropDownData = [
        {
            name: "Individual",
        },
        {
            name: "Venue",
        },
        {
            name: "Event Manager",
        },
        {
            name: "Corporate",
        },
    ]
    const leadSourceDropDownData = [
        {
            name: "Google Ad",
        },
        {
            name: "Referral",
        },
        {
            name: "Facebook Ad",
        },
    ]

    const addressTypeDropDownData = [
        {
            name: "Home",
        },
        {
            name: "Work",
        },
        {
            name: "Other",
        },

    ]


    const bookingStateData = [
        {
            name: "Enquiry",
        },
        {
            name: "Requested",
        },
        {
            name: "Deleted",
        },
        {
            name: "PaymentAwaited",
        },
        {
            name: "Abandoned",
        },
        {
            name: "Confirmed",
        },
        {
            name: "Completed",
        },
        {
            name: "Settled",
        },
        {
            name: "Canceled",
        },
    ];

    useEffect(() => {
        handleFetchAllCustomerDropdown()
        handleFetchAllProfileDuration()
        handleFetchAllProfileSetupType()
        handleFetchAllArtistDropdown()
        handleFetchAllSoundSystemDropdown()
        handleFetchAllSoundVenderDropdown()
    }, [])

    const [addressData, setAddressData] = useState({
        longitude: "",
        latitude: "",
        addressName: "",
        street: "",
        city: "",
        state: "",
        country: "",
    })
    const [customerUserData, setCustomerUserData] = useState({
        hostName: "",
        hostPhone: "",
    })
    const [booking, setBooking] = useState({
        customerType: "",
        bookingState: "",
        startTime: "",
        eventDate: "",
        endTime: "",
        hostName: "",
        hostPhone: "",
        hostPhone2: "",
        specialRequest: "",
        numberOfGuests: "",
        occasion: "",
        location: "",
        isArtistSound: "",
        artistPerformanceCharge: "",
        soundSetupCharge: "",
        subtotal: "",
        discountCode: "",
        discountPercentage: "",
        discountAmount: "",
        artistTransportationCharge: "",
        gST: "",
        grandTotal: "",
        creationDate: "",
        modificationDate: "",
        bookingClosingDate: "",
        companyShare: "",
        artistShare: "",
        soundVendorShare: "",
        totalAmount: "",
        addressType: "",
        googleMapLocation: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        longitude: "",
        latitude: "",
        soundSystemId: "",
        soundVendorId: "",
        setupTypeId: "",
        durationId: "",
        customerId: "",
        artistId: "",

    });
    useEffect(() => {
        setCustomerUserData({
            ...customerUserData,
            hostName: customerByIdData?.customerUser?.userName,
            hostPhone: customerByIdData?.customerUser?.phoneNumber,
        });
    }, [customerByIdData])
    //----------------  ADD artist FIELDS ERROR ------------***//
    const [addbookingerrors, setAddBookingErrors] = useState({
        customerType: null,
        bookingState: null,
        startTime: null,
        eventDate: null,
        endTime: null,
        hostName: null,
        hostPhone: null,
        hostPhone2: null,
        specialRequest: null,
        numberOfGuests: null,
        occasion: null,
        location: null,
        isArtistSound: null,
        artistPerformanceCharge: null,
        soundSetupCharge: null,
        subtotal: null,
        discountCode: null,
        discountPercentage: null,
        discountAmount: null,
        artistTransportationCharge: null,
        gST: null,
        grandTotal: null,
        creationDate: null,
        modificationDate: null,
        bookingClosingDate: null,
        companyShare: null,
        artistShare: null,
        soundVendorShare: null,
        totalAmount: null,
        addressType: null,
        googleMapLocation: null,
        street: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        longitude: null,
        latitude: null,
        soundSystemId: null,
        soundVendorId: null,
        setupTypeId: null,
        durationId: null,
        customerId: null,
        artistId: null,

    });

    const clearState = () => {
        setBooking({
            ...booking,
            customerType: "",
            bookingState: "",
            startTime: "",
            eventDate: "",
            endTime: "",
            hostName: "",
            hostPhone: "",
            hostPhone2: "",
            specialRequest: "",
            numberOfGuests: "",
            occasion: "",
            location: "",
            isArtistSound: "",
            artistPerformanceCharge: "",
            soundSetupCharge: "",
            subtotal: "",
            discountCode: "",
            discountPercentage: "",
            discountAmount: "",
            artistTransportationCharge: "",
            gST: "",
            grandTotal: "",
            creationDate: "",
            modificationDate: "",
            bookingClosingDate: "",
            companyShare: "",
            artistShare: "",
            soundVendorShare: "",
            totalAmount: "",
            addressType: "",
            googleMapLocation: "",
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            longitude: "",
            latitude: "",
            soundSystemId: "",
            soundVendorId: "",
            setupTypeId: "",
            durationId: "",
            customerId: "",
            artistId: "",
        });
    };
    const clearAddressState = () => {
        setAddressData({
            ...addressData,
            longitude: "",
            latitude: "",
            addressName: "",
            street: "",
            city: "",
            state: "",
            country: "",
        })
    }

    const handleSoundSystem = (option) => {

        setSoundSystemSelectedOption(option.label)
        setBooking({
            ...booking,
            soundSystemId: option.value,
        })
    }
    const handleSoundVendor = (option) => {
        setSoundVendorSelectedOption(option.label)
        setBooking({
            ...booking,
            soundVendorId: option.value,
        })
    }
    const handleArtistSound = (option) => {
        setArtistSoundSelectedOption(option.label)
        setBooking({
            ...booking,
            isArtistSound: option.value,
        })
    }
    const handleProfileSetupType = (option) => {

        setProfileSetupTypeselectedOption(option.label)
        setBooking({
            ...booking,
            setupTypeId: option.value,
        })
    }
    const handleProfileDuration = (option) => {


        setProfileDurationselectedOption(option.label)
        setBooking({
            ...booking,
            durationId: option.value,
        })
    }

    const handleCustomer = (option) => {

        hnaldeFetchCustomerById(option.value)
        setCustomerselectedOption(option.label)
        setBooking({
            ...booking,
            customerId: option.value,
        })
    }
    const handleArtist = (option) => {
        handleFetchArtistById(option.value)
        setArtistselectedOption(option.label)
        setBooking({
            ...booking,
            artistId: option.value,
        })
    }


    const handleValidation = () => {
        setAddBookingErrors({
            ...addbookingerrors,
            customerType: stringValidate(booking.customerType, "CustomerType"),
            bookingState: stringValidate(booking.bookingState, "BookingState"),
            startTime: stringValidate(booking.startTime, "StartTime"),
            eventDate: stringValidate(booking.eventDate, "EventDate"),
            // endTime: stringValidate(booking.endTime, "EndTime"),
            // hostName: stringValidate(booking.hostName, "HostName"),
            // hostPhone: contactNumberValidation(booking.hostPhone, "HostPhone"),
            numberOfGuests: numberValidate(booking.numberOfGuests, "NumberOfGuests"),
            occasion: stringValidate(booking.occasion, "Occasion"),
            // location: stringValidate(booking.location, "Location"),
            // isArtistSound: stringValidate(booking.isArtistSound, "IsArtistSound"),
            // artistPerformanceCharge: numberValidate(booking.artistPerformanceCharge, "ArtistPerformanceCharge"),
            // soundSetupCharge: numberValidate(booking.soundSetupCharge, "SoundSetupCharge"),
            // subtotal: numberValidate(booking.subtotal, "Subtotal"),
            // discountCode: stringValidate(booking.discountCode, "DiscountCode"),
            // discountPercentage: numberValidate(booking.discountPercentage, "DiscountPercentage"),
            // discountAmount: numberValidate(booking.discountAmount, "DiscountAmount"),
            // artistTransportationCharge: numberValidate(booking.artistTransportationCharge, "ArtistTransportationCharge"),
            // gST: numberValidate(booking.gST, "GST"),
            // grandTotal: numberValidate(booking.grandTotal, "GrandTotal"),
            // bookingClosingDate: stringValidate(booking.bookingClosingDate, "BookingClosingDate"),
            // companyShare: numberValidate(booking.companyShare, "CompanyShare"),
            // artistShare: numberValidate(booking.artistShare, "ArtistShare"),
            // soundVendorShare: numberValidate(booking.soundVendorShare, "SoundVendorShare"),
            // totalAmount: numberValidate(booking.totalAmount, "TotalAmount"),
            // addressType: stringValidate(booking.addressType, "AddressType"),
            // googleMapLocation: stringValidate(booking.googleMapLocation, "GoogleMapLocation"),
            // street: stringValidate(booking.street, "Street"),
            // city: stringValidate(booking.city, "City"),
            // state: stringValidate(booking.state, "State"),
            // zipCode: numberValidate(booking.zipCode, "ZipCode"),
            // country: stringValidate(booking.country, "Country"),
            longitude: stringValidate(addressData.longitude, "Longitude"),
            latitude: stringValidate(addressData.latitude, "Latitude"),
            setupTypeId: numberValidate(booking.setupTypeId, "SetupTypeId"),
            durationId: numberValidate(booking.durationId, "DurationId"),
            customerId: numberValidate(booking.customerId, "CustomerId"),
            artistId: numberValidate(booking.artistId, "ArtistId"),
        });
    };

    const onPlacesChanged = (place) => {
        const { geometry } = place;
        const formattedAddress = place.formatted_address;
        if (geometry && geometry.location) {
            const { lat, lng } = geometry.location;

            const addressParts = formattedAddress.split(', ');
            const street = addressParts[0]; // The street
            const city = addressParts[1]; // The city
            const state = addressParts[2]; // The state
            const country = addressParts[3]; // The country
            let pinCode = '';
            for (let i = addressParts.length - 1; i >= 0; i--) {
                if (/\d/.test(addressParts[i])) {
                    pinCode = addressParts[i];
                    break;
                }
            }
            setAddressData({
                ...addressData,
                latitude: lat(),
                longitude: lng(),
                addressName: formattedAddress,
                street: street,
                city: city,
                state: state,
                country: country,
            })

        }
    };
    //---------------------------------------  ADD artist FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleAddbooking = (e) => {

        handleValidation();
        if (
            stringValidate(booking.customerType, "CustomerType") === null &&
            stringValidate(booking.bookingState, "BookingState") === null &&
            stringValidate(booking.startTime, "StartTime") === null &&
            stringValidate(booking.eventDate, "EventDate") === null &&
            // stringValidate(booking.endTime, "EndTime") === null &&
            stringValidate(booking.occasion, "Occasion") === null &&
            // contactNumberValidation(booking.hostPhone, "HostPhone") === null &&
            numberValidate(booking.numberOfGuests, "NumberOfGuests") === null &&
            // stringValidate(booking.isArtistSound, "IsArtistSound") === null &&
            // numberValidate(booking.artistPerformanceCharge, "ArtistPerformanceCharge") === null &&
            // numberValidate(booking.soundSetupCharge, "SoundSetupCharge") === null &&
            // numberValidate(booking.subtotal, "Subtotal") === null &&
            // stringValidate(booking.discountCode, "DiscountCode") === null &&
            // numberValidate(booking.discountPercentage, "DiscountPercentage") === null &&
            // numberValidate(booking.discountAmount, "DiscountAmount") === null &&
            // numberValidate(booking.artistTransportationCharge, "ArtistTransportationCharge") === null &&
            // numberValidate(booking.gST, "GST") === null &&
            // numberValidate(booking.grandTotal, "GrandTotal") === null &&
            // stringValidate(booking.bookingClosingDate, "BookingClosingDate") === null &&
            // numberValidate(booking.companyShare, "CompanyShare") === null &&
            // numberValidate(booking.artistShare, "ArtistShare") === null &&
            // numberValidate(booking.soundVendorShare, "SoundVendorShare") === null &&
            // numberValidate(booking.totalAmount, "TotalAmount") === null &&
            // stringValidate(booking.addressType, "AddressType") === null &&
            // stringValidate(booking.googleMapLocation, "GoogleMapLocation") === null &&
            // stringValidate(booking.street, "Street") === null &&
            // stringValidate(booking.city, "City") === null &&
            // stringValidate(booking.state, "State") === null &&
            // numberValidate(booking.zipCode, "ZipCode") === null &&
            // stringValidate(booking.country, "Country") === null &&
            numberValidate(addressData.longitude, "Longitude") === null &&
            numberValidate(addressData.latitude, "Latitude") === null &&
            numberValidate(booking.setupTypeId, "SetupTypeId") === null &&
            numberValidate(booking.durationId, "DurationId") === null &&
            numberValidate(booking.customerId, "CustomerId") === null &&
            numberValidate(booking.artistId, "ArtistId") === null
        ) {
            Addartist(booking, addressData, customerUserData);
        }
    };

    //------------------------- USEFFECT HOOK EXECUTING ON FIRST RENDER OF VIEW -------------------------**//

    useEffect(() => {
        if (localStorage.getItem("message") === "addBookingfailed") {
            enqueueSnackbar(error, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "addBookingSuccess") {
            setSameAsAddress(false);
            handleClearValidationError();

            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//

    const handleClearValidationError = () => {
        setAddBookingErrors({
            ...addbookingerrors,
            customerType: null,
            bookingState: null,
            startTime: null,
            eventDate: null,
            endTime: null,
            hostName: null,
            hostPhone: null,
            hostPhone2: null,
            specialRequest: null,
            numberOfGuests: null,
            occasion: null,
            location: null,
            isArtistSound: null,
            artistPerformanceCharge: null,
            soundSetupCharge: null,
            subtotal: null,
            discountCode: null,
            discountPercentage: null,
            discountAmount: null,
            artistTransportationCharge: null,
            gST: null,
            grandTotal: null,
            creationDate: null,
            modificationDate: null,
            bookingClosingDate: null,
            companyShare: null,
            artistShare: null,
            soundVendorShare: null,
            totalAmount: null,
            addressType: null,
            googleMapLocation: null,
            street: null,
            city: null,
            state: null,
            zipCode: null,
            country: null,
            longitude: null,
            latitude: null,
            soundSystemId: null,
            setupTypeId: null,
            durationId: null,
            customerId: null,
            artistId: null,
        });
        clearState();
        clearAddressState()
    };
    const handleTimeChange = (event) => {
        if (profileDurationselectedOption == "1 hour") {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 1);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setBooking({
                ...booking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }
        else if (profileDurationselectedOption == "2 hour") {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 2);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setBooking({
                ...booking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }
        else if (profileDurationselectedOption == "3 hour") {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 3);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setBooking({
                ...booking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }
        else {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 4);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setBooking({
                ...booking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }

    };

    return (
        <>
            {/* <!-- The Modal --> */}
            <div className="modal" id="createCustomerModal">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
                            <h4 className="modal-title">Add Booking</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={handleClearValidationError}
                            >
                                &times;
                            </button>
                        </div>
                        {/* <!-- Modal body --> */}
                        <div className="modal-body">
                            <div id="accordion">
                                <div className="card mt-2">
                                    <div className="card-header Accordian" id="headingOne">
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-text d-flex w-100"
                                                data-toggle="collapse"
                                                data-target="#bokinginfo"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                                style={{ height: "50px" }}
                                            >
                                                <h5 className="pb-4">Booking Information</h5>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id="bokinginfo"
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Customer Type{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        {/* <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="customerType"
                                                        id="subs"
                                                        value={booking.customerType}
                                                        onChange={(e) =>
                                                            setBooking({
                                                                ...booking,
                                                                customerType: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddBookingErrors({
                                                                ...addbookingerrors,
                                                                customerType: stringValidate(
                                                                    booking.customerType,
                                                                    "CustomerType"
                                                                ),
                                                            })
                                                        }
                                                    /> */}

                                                        <Dropdown class="formDropDown">
                                                            <Dropdown.Toggle
                                                                id="dropdown-basic"
                                                                class="formDropDownToggle"
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                {booking.customerType
                                                                    ? booking.customerType
                                                                    : "Customer Type"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {customerTypeDropDownData.map((option) => (
                                                                    <Dropdown.Item
                                                                        key={option}
                                                                        onClick={() =>
                                                                            setBooking({
                                                                                ...booking,
                                                                                customerType: option.name,
                                                                            })
                                                                        }
                                                                        onBlur={() =>
                                                                            setAddBookingErrors({
                                                                                ...addbookingerrors,
                                                                                customerType: stringValidate(
                                                                                    booking.customerType,
                                                                                    "CustomerType"
                                                                                ),
                                                                            })
                                                                        }
                                                                    >
                                                                        {option.name}
                                                                    </Dropdown.Item>
                                                                ))}

                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.customerType}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Booking State<span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>

                                                        <Dropdown class="formDropDown">
                                                            <Dropdown.Toggle
                                                                id="dropdown-basic"
                                                                class="formDropDownToggle"
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                {booking.bookingState
                                                                    ? booking.bookingState
                                                                    : "Booking State"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {bookingStateData.map((option) => (
                                                                    <Dropdown.Item
                                                                        key={option}
                                                                        onClick={() =>
                                                                            setBooking({
                                                                                ...booking,
                                                                                bookingState: option.name,
                                                                            })
                                                                        }
                                                                        // ref={FromPriceRef}
                                                                        onBlur={() => {
                                                                            setAddBookingErrors({
                                                                                ...addbookingerrors,
                                                                                bookingState: stringValidate(
                                                                                    booking.bookingState,
                                                                                    "BookingState"
                                                                                ),
                                                                            });
                                                                        }}
                                                                    >
                                                                        {option.name}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.bookingState}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Artist{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4" style={{ width: "100%" }}>
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {artistselectedOption ? artistselectedOption : "Select Artist"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {/* {ArtistDropdownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleArtist(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddBookingErrors({
                                                                            ...addbookingerrors,
                                                                            artistId: numberValidate(
                                                                                booking.artistId,
                                                                                "ArtistId"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))} */}
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={ArtistDropdownData}
                                                                    value={artistselectedOption}
                                                                    onChange={handleArtist}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.artistId}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Customer{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {customerselectedOption ? customerselectedOption : "Select Customer"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {/* {CustomerDropdownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleCustomer(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddBookingErrors({
                                                                            ...addbookingerrors,
                                                                            customerId: numberValidate(
                                                                                booking.customerId,
                                                                                "CustomerId"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))} */}
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={CustomerDropdownData}
                                                                    value={customerselectedOption}
                                                                    onChange={handleCustomer}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.customerId}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Profile Setup Type{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {profileSetupTypeselectedOption ? profileSetupTypeselectedOption : "Profile Setup Type"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>

                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={ProfileSetupTypeData}
                                                                    value={profileSetupTypeselectedOption}
                                                                    onChange={handleProfileSetupType}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.setupTypeId}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Profile Duration{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {profileDurationselectedOption ? profileDurationselectedOption : "Profile Duration"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {/* {ProfileDurationData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleProfileDuration(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddBookingErrors({
                                                                            ...addbookingerrors,
                                                                            durationId: numberValidate(
                                                                                booking.durationId,
                                                                                "DurationId"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))} */}
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={ProfileDurationData}
                                                                    value={profileDurationselectedOption}
                                                                    onChange={handleProfileDuration}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.durationId}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Event Date{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Event Date"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={booking.eventDate}
                                                            onChange={(e) =>
                                                                setBooking({
                                                                    ...booking,
                                                                    eventDate: e.target.value,
                                                                })
                                                            }
                                                            onBlur={() =>
                                                                setAddBookingErrors({
                                                                    ...addbookingerrors,
                                                                    eventDate: stringValidate(
                                                                        booking.eventDate,
                                                                        "EventDate"
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.eventDate}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Start Time{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            rows="5"
                                                            id="comment"
                                                            placeholder="Start Time"
                                                            value={booking.startTime}
                                                            onChange={(e) =>
                                                                handleTimeChange(e)
                                                            }
                                                            onBlur={() =>
                                                                setAddBookingErrors({
                                                                    ...addbookingerrors,
                                                                    startTime: stringValidate(
                                                                        booking.startTime,
                                                                        "StartTime"
                                                                    ),
                                                                })
                                                            }
                                                        />

                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.startTime}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            End Time
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="End Time"
                                                            id=""
                                                            disabled
                                                            value={booking.endTime}

                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         endTime: stringValidate(booking.endTime, "EndTime"),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.endTime}
                                                    </p> */}
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Host Name
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type="subs"
                                                            className="form-control"
                                                            placeholder="Host Name"
                                                            id="subs"
                                                            // ref={ToPriceRef}
                                                            value={customerUserData.hostName}
                                                            onChange={(e) =>
                                                                setCustomerUserData({
                                                                    ...customerUserData,
                                                                    hostName: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         hostName: stringValidate(
                                                        //             booking.hostName,
                                                        //             "HostName"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.hostName}
                                                    </p> */}
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Host Phone
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type="subs"
                                                            className="form-control"
                                                            placeholder="Host Phone"
                                                            id="subs"
                                                            // ref={DiscountRef}
                                                            value={customerUserData.hostPhone}
                                                            onChange={(e) =>
                                                                setCustomerUserData({
                                                                    ...customerUserData,
                                                                    hostPhone: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         hostPhone: contactNumberValidation(
                                                        //             booking.hostPhone,
                                                        //             "HostPhone"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.hostPhone}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Host Phone 2{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type="subs"
                                                            className="form-control"
                                                            placeholder="hostPhone2"
                                                            id="subs"
                                                            // ref={AreaRef}
                                                            value={booking.hostPhone2}
                                                            onChange={(e) =>
                                                                setBooking({
                                                                    ...booking,
                                                                    hostPhone2: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         hostPhone2: stringValidate(
                                                        //             booking.hostPhone2,
                                                        //             "HostPhone2"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.hostPhone2}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Special Request{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Special Request"
                                                            id="subs"
                                                            // ref={FileRef}
                                                            value={booking.specialRequest}
                                                            onChange={(e) =>
                                                                setBooking({
                                                                    ...booking,
                                                                    specialRequest: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         specialRequest: stringValidate(
                                                        //             booking.specialRequest,
                                                        //             "SpecialRequest"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />

                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.specialRequest}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Number Of Guests{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Number Of Guests"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={booking.numberOfGuests}
                                                            onChange={(e) =>
                                                                setBooking({
                                                                    ...booking,
                                                                    numberOfGuests: e.target.value,
                                                                })
                                                            }
                                                            onBlur={() =>
                                                                setAddBookingErrors({
                                                                    ...addbookingerrors,
                                                                    numberOfGuests: numberValidate(
                                                                        booking.numberOfGuests,
                                                                        "NumberOfGuests"
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.numberOfGuests}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Occasion{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Occasion"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={booking.occasion}
                                                            onChange={(e) =>
                                                                setBooking({
                                                                    ...booking,
                                                                    occasion: e.target.value,
                                                                })
                                                            }
                                                            onBlur={() =>
                                                                setAddBookingErrors({
                                                                    ...addbookingerrors,
                                                                    occasion: stringValidate(
                                                                        booking.occasion,
                                                                        "Occasion"
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.occasion}
                                                        </p>
                                                    </div>
                                                </div>


                                                {/* <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Artist Has Own Sound System? {" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>

                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {artistSoundSelectedOption ? artistSoundSelectedOption
                                                                : "Artist Has Own Sound System?"
                                                            }
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {isArtistSoundData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleArtistSound(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddBookingErrors({
                                                                            ...addbookingerrors,
                                                                            isArtistSound: stringValidate(
                                                                                booking.isArtistSound,
                                                                                "IsArtistSound"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addbookingerrors.isArtistSound}
                                                    </p>
                                                </div>
                                            </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-header Accordian" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-text d-flex w-100"
                                                data-toggle="collapse"
                                                data-target="#vanueinfo"
                                                aria-expanded="true"
                                                aria-controls="collapseTwo"
                                                style={{ height: "50px" }}
                                            >
                                                <h5 className="pb-4">Sound System and Sound vendor</h5>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id="vanueinfo"
                                        className="collapse show"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Sound System{" "}

                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {soundSystemSelectedOption ? soundSystemSelectedOption : "Sound System"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>

                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={soundSystemDropdownData}
                                                                    value={soundSystemSelectedOption}
                                                                    onChange={handleSoundSystem}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.soundSystemId}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Sound Vendor{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {soundVendorSelectedOption ? soundVendorSelectedOption : "Sound Vendor"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>

                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={soundVendorDropdownData}
                                                                    value={soundVendorSelectedOption}
                                                                    onChange={handleSoundVendor}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.soundVendorId}
                                                    </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-header Accordian" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-text d-flex w-100"
                                                data-toggle="collapse"
                                                data-target="#vanueinfo"
                                                aria-expanded="true"
                                                aria-controls="collapseTwo"
                                                style={{ height: "50px" }}
                                            >
                                                <h5 className="pb-4">Venue Address</h5>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id="vanueinfo"
                                        className="collapse show"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Location{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Autocomplete
                                                            apiKey={apiKey}
                                                            style={{ width: "100%", height: "38px", borderRadius: "5px" }}

                                                            onPlaceSelected={(place) => {
                                                                onPlacesChanged(place);
                                                            }}
                                                            coordinates={true}
                                                            options={{
                                                                // types: ["(regions)"],
                                                                types: ["establishment"],
                                                                // types: [
                                                                //     "cafe",
                                                                //     "restaurant",
                                                                //     "stadium",
                                                                //     "school",
                                                                //     "amusement_park",
                                                                //   ],
                                                                componentRestrictions: { country: "IN" },
                                                            }}
                                                            defaultValue="Noida"
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addbookingerrors.longitude}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Street{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="street"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.street}
                                                            // setAddressData({
                                                            //     ...addressData,
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    street: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         street: stringValidate(
                                                        //             booking.street,
                                                        //             "Street"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.street}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            City{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="City"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.city}
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    city: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         city: stringValidate(
                                                        //             booking.city,
                                                        //             "City"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.city}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            State{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="state"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.state}
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    state: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         state: stringValidate(
                                                        //             booking.state,
                                                        //             "state"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.state}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Country{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Country"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.country}
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    country: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         country: stringValidate(
                                                        //             booking.country,
                                                        //             "Country"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.country}
                                                    </p> */}
                                                    </div>
                                                </div>


                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Zip Code{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="zipCode"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={booking.zipCode}
                                                            onChange={(e) =>
                                                                setBooking({
                                                                    ...booking,
                                                                    zipCode: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddBookingErrors({
                                                        //         ...addbookingerrors,
                                                        //         zipCode: numberValidate(
                                                        //             booking.zipCode,
                                                        //             "ZipCode"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addbookingerrors.zipCode}
                                                    </p> */}
                                                    </div>
                                                </div>






                                            </div>
                                            {/* <!-- Modal footer --> */}
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                // data-dismiss="modal"
                                                onClick={handleAddbooking}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {loading ? <div className="cover-spin"></div> : ""}
        </>
    );
};

const mapStateToProps = (state, props) => {

    return {
        loading: state.addBooking.loading,
        message: state.addBooking.message,
        error: state.addBooking.error,
        ProfileSetupTypeData: state.ProfileSetupType.ProfileSetupTypeData,
        ProfileDurationData: state.ProfileDuration.ProfileDurationData,
        CustomerDropdownData: state.customerDropdown.CustomerDropdown,
        ArtistDropdownData: state.artistDropdown.ArtistDropdown,
        customerByIdData: state.getCustomerById.data,
        soundSystemDropdownData: state.soundSystemDropdown.SoundSystemDropdown,
        soundVendorDropdownData: state.soundVendorDropdown.SoundVenderDropdown
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        Addartist: (booking, addressData, customerUserData) => dispatch(addBooking(booking, addressData, customerUserData)),
        refreshform: () => dispatch(addBookingRequest()),
        handleFetchAllProfileSetupType: () => dispatch(fetchAllProfileSetupType()),
        handleFetchAllProfileDuration: () => dispatch(fetchAllProfileDuration()),
        handleFetchAllSoundVenderDropdown: () => dispatch(fetchAllSoundVenderDropdown()),
        handleFetchAllCustomerDropdown: () => dispatch(fetchAllCustomerDropdown()),
        handleFetchAllArtistDropdown: () => dispatch(fetchAllArtistDropdown()),
        handleFetchAllSoundSystemDropdown: () => dispatch(fetchAllSoundSystemDropdown()),
        handleFetchArtistById: (id) => dispatch(fetchArtistById(id)),
        hnaldeFetchCustomerById: (id) => dispatch(fetchCustomerById(id))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddartistListing));
