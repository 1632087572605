
import {
    FETCH_ARTIST_VIDEOS_BY_ID_REQUEST,
    FETCH_ARTIST_VIDEOS_BY_ID_SUCCESS,
    FETCH_ARTIST_VIDEOS_BY_ID_FAILURE
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
export const fetchArtistVideoByIdRequest = () => {
    return {
        type: FETCH_ARTIST_VIDEOS_BY_ID_REQUEST,
    };
};
export const fetchArtistVideoByIdSuccess = (Data) => {
    return {
        type: FETCH_ARTIST_VIDEOS_BY_ID_SUCCESS,
        payload: Data,
    };
}

export const fetchArtistVideoByIdFailure = (Error) => {
    return {
        type: FETCH_ARTIST_VIDEOS_BY_ID_FAILURE,
        payload: Error,
    };
};


export const fetchArtistVideoById = (id) => {

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };

    return (dispatch) => {

        dispatch(fetchArtistVideoByIdRequest);
        axios
            .get(url + `getArtistVideosById/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchArtistVideoByIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchArtistVideoByIdFailure(error));
            });

    };
};