import React from "react";
import { Redirect } from "react-router-dom";

// routes views
import Layout from "../component/Layout/Layout";
import Agents from "../pages/agents";
// import Agents from "../component/Banner/bannerDetails";

export const agentsroutes = [

  {
    path: "/",
    exact: true,
    layout: Layout,
    component: () => <Redirect to="/login" />,
  },

  {
    path: "/Agents",
    exact: true,
    layout: Layout,
    component: Agents,
  },
  //   {
  //     path: "/Agents_Details",
  //     exact: true,
  //     layout: Layout,
  //     component: Agents,
  //   },
];
