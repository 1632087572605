
import {
    FETCH_SOUNDER_VENDER_BY_ID_REQUEST,
    FETCH_SOUNDER_VENDER_BY_ID_SUCCESS,
    FETCH_SOUNDER_VENDER_BY_ID_FAILURE,
} from "../types.js";
import axios from "axios";
import { fetchLogout } from "../authAction";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
const fetchSoundVenderByIdRequest = () => {
    return { type: FETCH_SOUNDER_VENDER_BY_ID_REQUEST };
};

const fetchSoundVenderByIdSuccess = (Data) => {
    return { type: FETCH_SOUNDER_VENDER_BY_ID_SUCCESS, payload: Data };
};

const fetchSoundVenderByIdFailure = (error) => {
    return { type: FETCH_SOUNDER_VENDER_BY_ID_FAILURE, payload: error };
};

export const fetchSoundVenderById = (SoundVenderId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(fetchSoundVenderByIdRequest());
        axios
            .get(url + `getSoundVendorById/${SoundVenderId}`, {
                headers: headers,
            })

            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addSoundVenderSuccess");
                    await dispatch(fetchSoundVenderByIdSuccess(response.data));
                }
            })
            .catch(function (error) {
                if (error.message === "Request failed with status code 401") {
                    dispatch(fetchLogout());
                }
                dispatch(fetchSoundVenderByIdFailure(error));
            });
    };
};
