import React, { useEffect } from "react";
import { deleteArtistPreferredSetupById, deleteArtistPreferredSetupByIdRequest } from "../../../actions/artists/artistPreferredSetup/deleteArtistPreferredSetup";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
function Delete_Video(props) {
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { handleDeleteArtistPreferredSetupById, preferredSetupId, message, refreshform, loading, error } = props;

    useEffect(() => {
        if (localStorage.getItem("message") === "ArtistPreferredSetupDeleteSuccess") {
            const message = "Artist Preferred Setup Deleted Successfully";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()

        }
        if (localStorage.getItem("message") === "ArtistPreferredSetupDeleteFailed") {
            const message = "Artist Preferred Setup Deleted Failed";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()
        }
    }, [message, error]);

    return (
        <>
            <div
                className="modal fade"
                id="deleteArtistPreferredSetupModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Delete Artist Preferred Setup
                            </h5>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                {" "}
                                <span aria-hidden="true">×</span>{" "}
                            </button>
                        </div>
                        <div className="modal-body">
                            Do you really want to this Artist Preferred Setup ?
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={async () => await handleDeleteArtistPreferredSetupById(preferredSetupId)}
                            >
                                Delete
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="cover-spin"></div> : ""}
        </>
    );
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.deletePreferredSetup.loading,
        message: state.deletePreferredSetup.message,
        error: state.deletePreferredSetup.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleDeleteArtistPreferredSetupById: (preferredSetupId) => dispatch(deleteArtistPreferredSetupById(preferredSetupId)),
        refreshform: () => dispatch(deleteArtistPreferredSetupByIdRequest()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Delete_Video);
