import {
    FETCH_ALL_PROFILE_SETUP_TYPE_REQUEST,
    FETCH_ALL_PROFILE_SETUP_TYPE_SUCCESS,
    FETCH_ALL_PROFILE_SETUP_TYPE_FAILURE,
    //
    FETCH_ALL_PROFILE_PROFILE_DURATION_REQUEST,
    FETCH_ALL_PROFILE_PROFILE_DURATION_SUCCESS,
    FETCH_ALL_PROFILE_PROFILE_DURATION_FAILURE,
    //
    FETCH_ALL_PRICE_SLAB_DROPDOWN_REQUEST,
    FETCH_ALL_PRICE_SLAB_DROPDOWN_SUCCESS,
    FETCH_ALL_PRICE_SLAB_DROPDOWN_FAILURE,
    //
    FETCH_ALL_CUSTOMER_DROPDOWN_REQUEST,
    FETCH_ALL_CUSTOMER_DROPDOWN_SUCCESS,
    FETCH_ALL_CUSTOMER_DROPDOWN_FAILURE,
    //
    FETCH_ALL_ARTIST_DROPDOWN_REQUEST,
    FETCH_ALL_ARTIST_DROPDOWN_SUCCESS,
    FETCH_ALL_ARTIST_DROPDOWN_FAILURE,
    //
    FETCH_ALL_SOUND_SYSTEM_DROPDOWN_REQUEST,
    FETCH_ALL_SOUND_SYSTEM_DROPDOWN_SUCCESS,
    FETCH_ALL_SOUND_SYSTEM_DROPDOWN_FAILURE,
    //
    FETCH_ALL_SOUND_VENDER_DROPDOWN_REQUEST,
    FETCH_ALL_SOUND_VENDER_DROPDOWN_SUCCESS,
    FETCH_ALL_SOUND_VENDER_DROPDOWN_FAILURE

} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
// Profile Setup Type Dropdown

const fetchAllProfileSetupTypeRequest = () => {
    return {
        type: FETCH_ALL_PROFILE_SETUP_TYPE_REQUEST,
        fetching: true
    };
};

const fetchAllProfileSetupTypeSuccess = (fetchedOptions) => {
    return {
        type: FETCH_ALL_PROFILE_SETUP_TYPE_SUCCESS,
        fetching: false,
        payload: fetchedOptions,
    };
};

const fetchAllProfileSetupTypeFailure = (error) => {
    return {
        type: FETCH_ALL_PROFILE_SETUP_TYPE_FAILURE,
        fetching: false,
        payload: error,
    };
};

export const fetchAllProfileSetupType = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllProfileSetupTypeRequest());
        axios
            .get(url + "getAllProfileSetupType", {
                headers: headers,
            })
            .then((response) => {

                if (response.status === 200) {
                    const fetchedOptions = response.data.map(option => {
                        return { value: option.setupTypeId, label: option.setupType };
                    });
                    dispatch(fetchAllProfileSetupTypeSuccess(fetchedOptions));
                }
            })
            .catch((error) => {
                dispatch(fetchAllProfileSetupTypeFailure(error));
            });
    };
};

// Get All Profile Duration

const fetchAllProfileDurationRequest = () => {
    return {
        type: FETCH_ALL_PROFILE_PROFILE_DURATION_REQUEST,
        fetching: true
    };
};

const fetchAllProfileDurationSuccess = (response) => {
    return {
        type: FETCH_ALL_PROFILE_PROFILE_DURATION_SUCCESS,
        fetching: false,
        payload: response,
    };
};

const fetchAllProfileDurationFailure = (error) => {
    return {
        type: FETCH_ALL_PROFILE_PROFILE_DURATION_FAILURE,
        fetching: false,
        payload: error,
    };
};

export const fetchAllProfileDuration = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllProfileDurationRequest());
        axios
            .get(url + "getAllProfileDuration", {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedOptions = response.data.map(option => {
                        return { value: option.durationId, label: option.durationType };
                    });
                    dispatch(fetchAllProfileDurationSuccess(fetchedOptions));
                }
            })
            .catch((error) => {
                dispatch(fetchAllProfileDurationFailure(error));
            });
    };
};
// Get All Price Slab

const fetchAllPriceSlabDropdownRequest = () => {
    return {
        type: FETCH_ALL_PRICE_SLAB_DROPDOWN_REQUEST,
        fetching: true
    };
};

const fetchAllPriceSlabDropdownSuccess = (response) => {
    return {
        type: FETCH_ALL_PRICE_SLAB_DROPDOWN_SUCCESS,
        fetching: false,
        payload: response,
    };
};

const fetchAllPriceSlabDropdownFailure = (error) => {
    return {
        type: FETCH_ALL_PRICE_SLAB_DROPDOWN_FAILURE,
        fetching: false,
        payload: error,
    };
};

export const fetchAllPriceSlabDropdown = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllPriceSlabDropdownRequest());
        axios
            .get(url + "getAllPriceSlab", {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
        
                    const fetchedOptions = response.data.map(option => {
                        return { value: option.priceSlabId, label: option.priceSlabName };
                    });
                    dispatch(fetchAllPriceSlabDropdownSuccess(fetchedOptions));
                }
            })
            .catch((error) => {
                dispatch(fetchAllPriceSlabDropdownFailure(error));
            });
    };
};

// Get All Customer Dropdown

const fetchAllCustomerDropdownRequest = () => {
    return {
        type: FETCH_ALL_CUSTOMER_DROPDOWN_REQUEST,
        fetching: true
    };
};

const fetchAllCustomerDropdownSuccess = (response) => {
    return {
        type: FETCH_ALL_CUSTOMER_DROPDOWN_SUCCESS,
        fetching: false,
        payload: response,
    };
};

const fetchAllCustomerDropdownFailure = (error) => {
    return {
        type: FETCH_ALL_CUSTOMER_DROPDOWN_FAILURE,
        fetching: false,
        payload: error,
    };
};

export const fetchAllCustomerDropdown = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllCustomerDropdownRequest());
        axios
            .get(url + "getAllCustomer", {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedOptions = response.data.map(option => {
                        return { value: option.customerId, label: option.customerUser?.userName };
                    });

                    dispatch(fetchAllCustomerDropdownSuccess(fetchedOptions));
                }
            })
            .catch((error) => {
                dispatch(fetchAllCustomerDropdownFailure(error));
            });
    };
};



// Get All Artist Dropdown

const fetchAllArtistDropdownRequest = () => {
    return {
        type: FETCH_ALL_ARTIST_DROPDOWN_REQUEST,
        fetching: true
    };
};

const fetchAllArtistDropdownSuccess = (response) => {
    return {
        type: FETCH_ALL_ARTIST_DROPDOWN_SUCCESS,
        fetching: false,
        payload: response,
    };
};

const fetchAllArtistDropdownFailure = (error) => {
    return {
        type: FETCH_ALL_ARTIST_DROPDOWN_FAILURE,
        fetching: false,
        payload: error,
    };
};

export const fetchAllArtistDropdown = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllArtistDropdownRequest());
        axios
            .get(url + "getAllArtistUser", {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedOptions = response.data.map(option => {
                        return { value: option.artistId, label: option.profileName };
                    });
                    dispatch(fetchAllArtistDropdownSuccess(fetchedOptions));
                }
            })
            .catch((error) => {
                dispatch(fetchAllArtistDropdownFailure(error));
            });
    };
};


// Get All Sound System Dropdown

const fetchAllSoundSystemDropdownRequest = () => {
    return {
        type: FETCH_ALL_SOUND_SYSTEM_DROPDOWN_REQUEST,
        fetching: true
    };
};

const fetchAllSoundSystemDropdownSuccess = (response) => {
    return {
        type: FETCH_ALL_SOUND_SYSTEM_DROPDOWN_SUCCESS,
        fetching: false,
        payload: response,
    };
};

const fetchAllSoundSystemDropdownFailure = (error) => {
    return {
        type: FETCH_ALL_SOUND_SYSTEM_DROPDOWN_FAILURE,
        fetching: false,
        payload: error,
    };
};

export const fetchAllSoundSystemDropdown = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllSoundSystemDropdownRequest());
        axios
            .get(url + "getAllSoundSystem", {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedOptions = response.data.map(option => {
                        return { value: option.soundSystemId, label: option.title };
                    });
                    dispatch(fetchAllSoundSystemDropdownSuccess(fetchedOptions));
                }
            })
            .catch((error) => {
                dispatch(fetchAllSoundSystemDropdownFailure(error));
            });
    };
};

//Sound Vender 

const fetchAllSoundVenderDropdownRequest = () => {
    return {
        type: FETCH_ALL_SOUND_VENDER_DROPDOWN_REQUEST,
        fetching: true
    };
};

const fetchAllSoundVenderDropdownSuccess = (response) => {
    return {
        type: FETCH_ALL_SOUND_VENDER_DROPDOWN_SUCCESS,
        fetching: false,
        payload: response,
    };
};

const fetchAllSoundVenderDropdownFailure = (error) => {
    return {
        type: FETCH_ALL_SOUND_VENDER_DROPDOWN_FAILURE,
        fetching: false,
        payload: error,
    };
};

export const fetchAllSoundVenderDropdown = () => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllSoundVenderDropdownRequest());
        axios
            .get(url + "getAllSoundVendor", {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    const fetchedOptions = response.data.map(option => {
                        return { value: option.soundVendorId, label: option.companyName };
                    });
                    dispatch(fetchAllSoundVenderDropdownSuccess(fetchedOptions));
                }
            })
            .catch((error) => {
                dispatch(fetchAllSoundVenderDropdownFailure(error));
            });
    };
};