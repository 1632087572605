import {
    FETCH_GLOBAL_DISCOUNT_REQUEST,
    FETCH_GLOBAL_DISCOUNT_SUCCESS,
    FETCH_GLOBAL_DISCOUNT_FAILURE,
} from "../types.js";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
const fetchGlobalDiscountRequest = () => {
    return { type: FETCH_GLOBAL_DISCOUNT_REQUEST };
};

const fetchGlobalDiscountSuccess = (response) => {
    return { type: FETCH_GLOBAL_DISCOUNT_SUCCESS, payload: response.data };
};

const fetchGlobalDiscountFailure = (error) => {
    return { type: FETCH_GLOBAL_DISCOUNT_FAILURE, payload: error };
};

export const getGlobalDiscount = (userid) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(fetchGlobalDiscountRequest());
        axios
            .get(url + `getGlobalDiscountById/1`, {
                headers: headers,
            })
            .then(function (response) {
                localStorage.setItem("user_img", response.data.imageUrl);
                dispatch(fetchGlobalDiscountSuccess(response));
            })
            .catch(function (error) {
                if (error.message === "Request failed with status code 401") {
                   
                }
                dispatch(fetchGlobalDiscountFailure(error));
            });
    };
};
