import React from "react";
import { Redirect } from "react-router-dom";


// routes views
import Layout from "../component/Layout/Layout";
import UserDetails from "../component/Users/UserDetails";
import Users from "../pages/users"
// import SubscriptionDetail from "../component/subscription/subscriptiondetails";


export const userroutes = [

    {
        path: "/",
        exact: true,
        layout:Layout,
        component: () => <Redirect to="/login" />
      },
      {
        path: "/User",
        exact: true,
        layout:Layout,
        component:Users
      },
      {
        path: "/Userdetails",
        exact: true,
        layout:Layout,
        component:UserDetails
      },

     
]