import {
    UPDATE_TRIGGERS_BY_ID_REQUEST,
    UPDATE_TRIGGERS_BY_ID_SUCCESS,
    UPDATE_TRIGGERS_BY_ID_FAILURE,
} from "../types";

import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllTrigger } from "./getAllTrigger";
export const updateTriggerByIdRequest = () => {
    return {
        type: UPDATE_TRIGGERS_BY_ID_REQUEST,
    };
};

export const updateTriggerByIdSuccess = (Data) => {
    return {
        type: UPDATE_TRIGGERS_BY_ID_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updateTriggerByIdFailure = (Error) => {
    return {
        type: UPDATE_TRIGGERS_BY_ID_FAILURE,
        payload: { message: " updation failed", error: Error },
    };
};

export const updateTriggerById = (data) => {
    var headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updateTriggerByIdRequest());
        axios
            .put(url + `api/flows/updateFlowById/${data.id}`, {
                name: data.name,
                active: data.active,
                entityName: data.entityName,
                webhookUrl: data.webhookUrl,
                hasTimeTrigger: data.hasTimeTrigger ? true : false,
                eventType: data.hasTimeTrigger ? null : data.eventType,
                dayOffset: data.dayOffset ? data.dayOffset : 0,
                triggerTimePrecise: data.isTriggerTimePrecise ? true : false,
                timeOffset: data.isTriggerTimePrecise ? data.timeOffset : 0,
                timeFieldGetterName: data.hasTimeTrigger ? data.timeFieldGetterName : null,
                condition: data.condition ? data.condition : null
            }, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "RecordUpdatedSuccessfully");
                    await dispatch(updateTriggerByIdSuccess(response.data));
                    await dispatch(fetchAllTrigger());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "RecordUpdatedFailed");
                dispatch(updateTriggerByIdFailure(error));
            });
    };
};
