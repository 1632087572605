import {
  POST_CHANGEFORGETPASSWORD_REQUEST,
  POST_CHANGEFORGETPASSWORD_SUCCESS,
  POST_CHANGEFORGETPASSWORD_FAILURE,
} from "../types";
import axios from "axios";

export const fetchChangeforgetpasswordRequest = () => {
  return {
    type: POST_CHANGEFORGETPASSWORD_REQUEST,
  };
};

export const fetchChangeforgetpasswordSuccess = (Data) => {
  return {
    type: POST_CHANGEFORGETPASSWORD_SUCCESS,
    payload: Data.message,
  };
};

export const fetchChangeforgetpasswordFailure = (Error) => {
  return {
    type: POST_CHANGEFORGETPASSWORD_FAILURE,
    payload: Error,
  };
};

export const ChangeforgetpasswordUser = (password, userid) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return (dispatch) => {
    dispatch(fetchChangeforgetpasswordRequest);
    // axios
    //   .post(
    //     process.env.REACT_APP_API_KEY +
    //       `/Account/ResetPassword/${userid}/${password}`,

    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then(function (response) {
        dispatch(fetchChangeforgetpasswordSuccess("ChangePasswordMail"));
        localStorage.setItem('message' , "Success")
      // })
      // .catch(function (error) {
      //   localStorage.setItem('message' , "Failed")
      //   dispatch(fetchChangeforgetpasswordFailure(error));
      // });
  };
};
