import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import Booking from "../pages/bookings";
import BookingDetails from "../component/manage_booking/bookingDetails";
import BookingRequest from "../component/manage_booking/bookingRequest"
import OpenBooking from "../component/manage_booking/openBooking"

export const bookingsroutes = [

  {
    path: "/",
    exact: true,
    layout: Layout,
    component: () => <Redirect to="/login" />,
  },

  {
    path: "/All_Bookings",
    exact: true,
    layout: Layout,
    component: Booking,
  },
  {
    path: "/Open_Booking",
    exact: true,
    layout: Layout,
    component: OpenBooking,
  },

  {
    path: '/Booking_Details/:id',
    exact: true,
    layout: Layout,
    component: BookingDetails,
  },
];
