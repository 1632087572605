import {logoutToast} from "../common/commontoast"

var CronJob = require("cron").CronJob;

export var job = new CronJob(
  "*/60000 * * * * *",
  function cronjob() {
    
    const logindate = new Date(`${localStorage.getItem("LoggedIn_On")}`);
    const newdate = new Date();
    const newtime = newdate.getTime();
    const logintime = logindate.getTime();

    if (newtime - logintime > 2.88e+7) { //2.88e7 2.88e+7
      localStorage.setItem("loggedIn" , "false");
      localStorage.setItem("timeout" , "true");
      localStorage.setItem('Remember_be' , "true")
      logoutToast()
    }
  },
  null,
  true,
  "America/Los_Angeles"
);
