import React, { useEffect, useState, useRef } from "react";

import { updateCustomer, updateCustomerRequest } from "../../actions/cutomer/updateCustomer"

import { connect } from "react-redux";

//VALIDATION FUNCTION
import {
    emailValidate,
    stringValidate,
    numberValidate,
    contactNumberValidation,
    postalCodeValidation,
    passwordValidate,
    // websiteValidation,
} from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS

import $ from "jquery";
import { Dropdown } from "react-bootstrap";
import Geocode from 'react-geocode';
import axios from 'axios';
import Autocomplete from "react-google-autocomplete";
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const EditCustomerListing = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const customerNameRef = useRef("");
    const areaRef = useRef("");
    const LocationRef = useRef("");
    const ToPriceRef = useRef("");
    const AreaRef = useRef("");
    const ConfigurationRef = useRef("");
    const OfferRef = useRef("");
    const FileRef = useRef("");

    const { updateCustomer, message, error, refreshform, customerDataById } = props;

    //----------------  ADD customer STATE TO HANDLE INPUT VALUES ------------***//
    const customerTypeDropDownData = [
        {
            name: "Individual",
        },
        {
            name: "Venue",
        },
        {
            name: "Event Manager",
        },
        {
            name: "Corporate",
        },
    ]
    const leadSourceDropDownData = [
        {
            name: "Google Ad",
        },
        {
            name: "Referral",
        },
        {
            name: "Facebook Ad",
        },
        {
            name: "Other",
        },
    ]
    const [address, setAddress] = useState('');
    const [billingaddress, setBillingAddress] = useState('');

    const [addressData, setAddressData] = useState({
        longitude: "",
        latitude: "",
        addressName: ""
    })
    const [billingAddressData, setBillingAddressData] = useState({
        longitude: "",
        latitude: "",
        addressName: "",
        billingAddressStreet: "",
        billingAddressCity: "",
        billingAddressState: "",
        billingAddressCountry: "",
    })
    const [customer, setCustomer] = useState({
        customerId: "",
        source: "",
        customerType: "",
        userName: "",
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        secondaryPhone: "",
        googleMapLocation: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        longitude: "",
        latitude: "",
        billingAddressGoogleMapLocation: "",
        billingAddressStreet: "",
        billingAddressCity: "",
        billingAddressState: "",
        billingAddressZipCode: "",
        billingAddressCountry: "",
        billingAddressLongitude: "",
        billingAddressLatitude: "",
        imagefile: "",
        imageFileName: ""
    });

    useEffect(() => {
        setCustomer({
            ...customer,
            customerId: customerDataById.customerId,
            source: customerDataById.source,
            customerType: customerDataById.customerType,
            userName: customerDataById?.customerUser?.userName,
            password: customerDataById?.customerUser?.password,
            email: customerDataById?.customerUser?.email,
            firstName: customerDataById?.customerUser?.firstName,
            lastName: customerDataById?.customerUser?.lastName,
            phoneNumber: customerDataById?.customerUser?.phoneNumber,
            secondaryPhone: customerDataById?.customerUser?.secondaryPhone,
            googleMapLocation: customerDataById?.billingAddress?.googleMapLocation,
            street: customerDataById?.defaultVenueAddress?.street,
            city: customerDataById?.defaultVenueAddress?.city,
            state: customerDataById?.defaultVenueAddress?.state,
            zipCode: customerDataById?.defaultVenueAddress?.zipCode,
            country: customerDataById?.defaultVenueAddress?.country,
            longitude: customerDataById?.defaultVenueAddress?.longitude,
            latitude: customerDataById?.defaultVenueAddress?.latitude,
            billingAddressStreet: customerDataById?.billingAddress?.street,
            billingAddressCity: customerDataById?.billingAddress?.city,
            billingAddressState: customerDataById?.billingAddress?.state,
            billingAddressZipCode: customerDataById?.billingAddress?.zipCode,
            billingAddressCountry: customerDataById?.billingAddress?.country,
            billingAddressLongitude: customerDataById?.billingAddress?.longitude,
            billingAddressLatitude: customerDataById?.billingAddress?.latitude,
            imagefile: customerDataById.customerProfilePath,
            imageFileName: customerDataById.customerProfilePhoto

        });
        setAddressData({
            ...addressData,
            longitude: customerDataById?.defaultVenueAddress?.longitude,
            latitude: customerDataById?.defaultVenueAddress?.latitude,
            addressName: customerDataById?.defaultVenueAddress?.addressName
        })
        setBillingAddressData({
            ...billingAddressData,
            longitude: customerDataById?.billingAddress?.longitude,
            latitude: customerDataById?.billingAddress?.latitude,
            addressName: customerDataById?.billingAddress?.addressName,
            billingAddressStreet: customerDataById?.billingAddress?.street,
            billingAddressCity: customerDataById?.billingAddress?.city,
            billingAddressState: customerDataById?.billingAddress?.state,
            billingAddressCountry: customerDataById?.billingAddress?.country,

        })
    }, [customerDataById]);

    //----------------  ADD customer FIELDS ERROR ------------***//
    const [addcustomerserrors, setAddcustomersErrors] = useState({
        source: null,
        customerType: null,
        userName: null,
        password: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        secondaryPhone: null,
        googleMapLocation: null,
        street: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        longitude: null,
        latitude: null,
        billingAddressGoogleMapLocation: null,
        billingAddressStreet: null,
        billingAddressCity: null,
        billingAddressState: null,
        billingAddressZipCode: null,
        billingAddressCountry: null,
        billingAddressLongitude: null,
        billingAddressLatitude: null
    });
    const [billingAddressDataError, setBillingAddressDataError] = useState({
        longitude: null,
        latitude: null,
    })
    const [addressDataError, setAddressDataError] = useState({
        longitude: null,
        latitude: null,
    })
    const clearState = () => {
        setCustomer({
            ...customer,
            source: "",
            customerType: "",
            userName: "",
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            secondaryPhone: "",
            googleMapLocation: "",
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            longitude: "",
            latitude: "",
            billingAddressGoogleMapLocation: "",
            billingAddressStreet: "",
            billingAddressCity: "",
            billingAddressState: "",
            billingAddressZipCode: "",
            billingAddressCountry: "",
            billingAddressLongitude: "",
            billingAddressLatitude: "",
            imagefile: ""

        });
    };
    const clearAddressState = () => {
        setAddressData({
            ...addressData,
            longitude: "",
            latitude: "",
        })
    }
    const clearBillingAddressState = () => {
        setBillingAddressData({
            ...billingAddressData,
            longitude: "",
            latitude: "",
        })
    }

    const handleValidation = () => {
        setAddcustomersErrors({
            ...addcustomerserrors,
            source: stringValidate(customer.source, "Source"),
            customerType: stringValidate(customer.customerType, "CustomerType"),
            email: emailValidate(customer.email, "Email"),
            // userName: stringValidate(customer.userName, "UserName"),
            // password: passwordValidate(customer.password, "Password"),
            firstName: stringValidate(customer.firstName, "FirstName"),
            lastName: stringValidate(customer.lastName, "LastName"),
            // phoneNumber: contactNumberValidation(customer.phoneNumber, "PhoneNumber"),
            billingAddressStreet: stringValidate(billingAddressData.billingAddressStreet, "BillingAddressStreet"),
            billingAddressCity: stringValidate(billingAddressData.billingAddressCity, "BillingAddressCity"),
            billingAddressState: stringValidate(billingAddressData.billingAddressState, "BillingAddressState"),
            billingAddressZipCode: postalCodeValidation(customer.billingAddressZipCode, "BillingAddressZipCode"),
            billingAddressCountry: stringValidate(billingAddressData.billingAddressCountry, "BillingAddressCountry"),
        });

        // setAddressDataError({
        //     ...addressDataError,
        //     longitude: numberValidate(addressData.longitude, "Longitude"),
        // })

        setBillingAddressDataError({
            ...billingAddressDataError,
            longitude: numberValidate(billingAddressData.longitude, "Longitude"),

        })
    };

    //---------------------------------------  ADD customer FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleAddcustomer = (e) => {
        handleValidation();
        if (
            stringValidate(customer.source, "Source") === null &&
            stringValidate(customer.customerType, "CustomerType") === null &&
            emailValidate(customer.email, "Email") === null &
            // passwordValidate(customer.password, "Password") === null &&
            stringValidate(customer.firstName, "FirstName") === null &&
            stringValidate(customer.lastName, "LastName") === null &&
            // contactNumberValidation(customer.phoneNumber, "PhoneNumber") === null &&
            stringValidate(billingAddressData.billingAddressStreet, "BillingAddressStreet") === null &&
            stringValidate(billingAddressData.billingAddressCity, "BillingAddressCity") === null &&
            stringValidate(billingAddressData.billingAddressState, "BillingAddressState") === null &&
            postalCodeValidation(customer.billingAddressZipCode, "BillingAddressZipCode") === null &&
            stringValidate(billingAddressData.billingAddressCountry, "BillingAddressCountry") === null

        ) {
            updateCustomer(customer, addressData, billingAddressData);
        }
    };

    //------------------------- USEFFECT HOOK EXECUTING ON FIRST RENDER OF VIEW -------------------------**//

    useEffect(() => {
        if (localStorage.getItem("message") === "Record Updated Failed") {
            enqueueSnackbar("Customer Updated Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();s
        }
        if (localStorage.getItem("message") === "Record Updated Successfully") {
            setSameAsAddress(false);
            handleClearValidationError();

            enqueueSnackbar("Customer Updated Successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//

    const handleClearValidationError = () => {
        setAddcustomersErrors({
            ...addcustomerserrors,
            source: null,
            customerType: null,
            userName: null,
            password: null,
            email: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            secondaryPhone: null,
            googleMapLocation: null,
            street: null,
            city: null,
            state: null,
            zipCode: null,
            country: null,
            longitude: null,
            latitude: null,
            billingAddressGoogleMapLocation: null,
            billingAddressStreet: null,
            billingAddressCity: null,
            billingAddressState: null,
            billingAddressZipCode: null,
            billingAddressCountry: null,
            billingAddressLongitude: null,
            billingAddressLatitude: null
        });
        setAddressDataError({
            ...addressDataError,
            longitude: null
        })
        setBillingAddressDataError({
            ...billingAddressDataError,
            longitude: null
        })
        clearState();
        clearBillingAddressState()
        clearState();
    };

    const onPlacesChanged = (place) => {
        const { geometry, formatted_address } = place;

        if (geometry && geometry.location && formatted_address) {
            const { lat, lng } = geometry.location;

            setAddressData({
                ...addressData,
                longitude: lng(),
                latitude: lat(),
                addressName: formatted_address,

            })
        }
    };
    const onPlacesChanged1 = (place) => {
        const { geometry, formatted_address } = place;

        if (geometry && geometry.location && formatted_address) {
            const { lat, lng } = geometry.location;
            const addressParts = formatted_address.split(', ');
            const street = addressParts[0]; // The street
            const city = addressParts[1]; // The city
            const state = addressParts[2]; // The state
            const country = addressParts[3]; // The country
            let pinCode = '';
            for (let i = addressParts.length - 1; i >= 0; i--) {
                if (/\d/.test(addressParts[i])) {
                    pinCode = addressParts[i];
                    break;
                }
            }

            setBillingAddressData({
                ...billingAddressData,
                longitude: lng(),
                latitude: lat(),
                addressName: formatted_address,
                billingAddressStreet: street,
                billingAddressCity: city,
                billingAddressState: state,
                billingAddressCountry: country,
            })

        }
    };
    return (
        // <!-- The Modal -->
        <div className="modal" id="updateCustomerModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Update customer</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card mt-2">
                                <div className="card-header Accordian" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#contactinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        >
                                            <h5 className="pb-4">Customer Information</h5>
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="contactinfo"
                                    className="collapse show"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Lead Source{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    {/* <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Lead Source"
                                                        id="subs"
                                                        value={customer.source}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                source: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                source: stringValidate(
                                                                    customer.source,
                                                                    "Source"
                                                                ),
                                                            })
                                                        }
                                                    /> */}
                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {customer.source
                                                                ? customer.source
                                                                : "Lead Source"}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {leadSourceDropDownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        setCustomer({
                                                                            ...customer,
                                                                            source: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddcustomersErrors({
                                                                            ...addcustomerserrors,
                                                                            source: stringValidate(
                                                                                customer.source,
                                                                                "Source"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.source}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Customer Type{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    {/* <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Customer Type"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={customer.customerType}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                customerType: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                customerType: stringValidate(
                                                                    customer.customerType,
                                                                    "CustomerType"
                                                                ),
                                                            })
                                                        }
                                                    /> */}
                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {customer.customerType
                                                                ? customer.customerType
                                                                : "Customer Type"}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {customerTypeDropDownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        setCustomer({
                                                                            ...customer,
                                                                            customerType: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddcustomersErrors({
                                                                            ...addcustomerserrors,
                                                                            customerType: stringValidate(
                                                                                customer.customerType,
                                                                                "CustomerType"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.customerType}
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Email{" "}{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="Email"
                                                        value={customer.email}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                email: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                email: emailValidate(
                                                                    customer.email,
                                                                    "Email"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.email}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        First Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="First Name"
                                                        value={customer.firstName}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                firstName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                firstName: stringValidate(
                                                                    customer.firstName,
                                                                    "FirstName"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.firstName}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Last Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="Last Name"
                                                        value={customer.lastName}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                lastName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                lastName: stringValidate(
                                                                    customer.lastName,
                                                                    "lastName"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.lastName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Phone Number{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="phoneNumber"
                                                        value={customer.phoneNumber}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                phoneNumber: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                phoneNumber: contactNumberValidation(
                                                                    customer.phoneNumber,
                                                                    "PhoneNumber"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.phoneNumber}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Secondary Phone{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="Secondary Phone"
                                                        value={customer.secondaryPhone}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                secondaryPhone: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Profile Image{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="Profile Image"
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                imagefile: e.target.files[0],
                                                            })
                                                        }
                                                    />
                                                    <p >Image in use : <span style={{ color: "green" }}>{customer.imageFileName}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card mt-2">
                                <div className="card-header Accordian" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#contactinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        >
                                            <h5 className="pb-4">Default Venue Address</h5>
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="contactinfo"
                                    className="collapse show"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Address{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Autocomplete
                                                        apiKey={apiKey}
                                                        style={{ width: "100%", height: "38px", borderRadius: "5px" }}
                                                        onPlaceSelected={(place) => {
                                                            onPlacesChanged(place);
                                                        }}
                                                        options={{
                                                            types: ["(regions)"],
                                                            componentRestrictions: { country: "IND" },
                                                        }}
                                                        defaultValue={addressData.addressName}
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addressDataError.longitude}
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Street{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Street"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={customer.street}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                street: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                street: stringValidate(
                                                                    customer.street,
                                                                    "Street"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.street}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        City<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="City"
                                                        id=""
                                                        value={customer.city}
                                                        // onChange={(e) => onlyNumberInputPhone(e)}
                                                        onChange={(e) => {
                                                            setCustomer({
                                                                ...customer,
                                                                city: e.target.value,
                                                            });
                                                        }}
                                                        // ref={FromPriceRef}
                                                        onBlur={() => {
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                city: stringValidate(
                                                                    customer.city,
                                                                    "City"
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.city}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        State<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="State"
                                                        id=""
                                                        value={customer.state}
                                                        // onChange={(e) => onlyNumberInputPhone(e)}
                                                        onChange={(e) => {
                                                            setCustomer({
                                                                ...customer,
                                                                state: e.target.value,
                                                            });
                                                        }}
                                                        // ref={FromPriceRef}
                                                        onBlur={() => {
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                state: stringValidate(
                                                                    customer.state,
                                                                    "State"
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.state}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Zip Code{" "}{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="Zip Code"
                                                        value={customer.zipCode}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                zipCode: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                zipCode: numberValidate(
                                                                    customer.zipCode,
                                                                    "ZipCode"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.zipCode}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Country{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="First Name"
                                                        value={customer.country}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                country: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                country: stringValidate(
                                                                    customer.country,
                                                                    "Country"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.country}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card mt-2">
                                <div className="card-header Accordian" id="headingT">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#addressinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        >
                                            <h5 className="pb-4">Billing Address</h5>
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="addressinfo"
                                    className="collapse show"
                                    aria-labelledby="headingT"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Address{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>

                                                    <Autocomplete
                                                        apiKey={apiKey}
                                                        style={{ width: "100%", height: "38px", borderRadius: "5px" }}
                                                        onPlaceSelected={(place) => {
                                                            onPlacesChanged1(place);
                                                        }}
                                                        options={{
                                                            types: ["(regions)"],
                                                            // types: [
                                                            //     "cafe",
                                                            //     "restaurant",
                                                            //     "stadium",
                                                            //     "school",
                                                            //     "amusement_park",
                                                            //   ],
                                                            componentRestrictions: { country: "IND" },
                                                        }}
                                                        defaultValue={billingAddressData.addressName}
                                                    />
                                                    <p className="profile-input-errors">
                                                        {billingAddressDataError.longitude}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Street{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Street"
                                                        id="subs"
                                                        value={billingAddressData.billingAddressStreet}
                                                        onChange={(e) =>
                                                            setBillingAddressData({
                                                                ...billingAddressData,
                                                                billingAddressStreet: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                billingAddressStreet: stringValidate(
                                                                    billingAddressData.billingAddressStreet,
                                                                    "BillingAddressStreet"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.billingAddressStreet}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        City{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="City"
                                                        id="subs"
                                                        value={billingAddressData.billingAddressCity}
                                                        onChange={(e) =>
                                                            setBillingAddressData({
                                                                ...billingAddressData,
                                                                billingAddressCity: e.target.value,
                                                            })
                                                        }

                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                billingAddressCity: stringValidate(
                                                                    billingAddressData.billingAddressCity,
                                                                    "BillingAddressCity"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.billingAddressCity}
                                                    </p>

                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        ZipCode{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="ZipCode"
                                                        id="subs"
                                                        value={customer.billingAddressZipCode}
                                                        onChange={(e) =>
                                                            setCustomer({
                                                                ...customer,
                                                                billingAddressZipCode: e.target.value,
                                                            })
                                                        }

                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                billingAddressZipCode: stringValidate(
                                                                    customer.billingAddressZipCode,
                                                                    "BillingAddressZipCode"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.billingAddressZipCode}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        State{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="State"
                                                        id="subs"
                                                        value={billingAddressData.billingAddressState}
                                                        onChange={(e) =>
                                                            setBillingAddressData({
                                                                ...billingAddressData,
                                                                billingAddressState: e.target.value,
                                                            })
                                                        }

                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                billingAddressState: stringValidate(
                                                                    billingAddressData.billingAddressState,
                                                                    "BillingAddressState"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.billingAddressState}
                                                    </p>

                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Country{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Country"
                                                        id="subs"
                                                        value={billingAddressData.billingAddressCountry}
                                                        onChange={(e) =>
                                                            setBillingAddressData({
                                                                ...billingAddressData,
                                                                billingAddressCountry: e.target.value,
                                                            })
                                                        }

                                                        onBlur={() =>
                                                            setAddcustomersErrors({
                                                                ...addcustomerserrors,
                                                                billingAddressCountry: stringValidate(
                                                                    billingAddressData.billingAddressCountry,
                                                                    "BillingAddressCountry"
                                                                ),
                                                            })
                                                        }
                                                    />

                                                    <p className="profile-input-errors">
                                                        {addcustomerserrors.billingAddressCountry}
                                                    </p>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleAddcustomer}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {

    return {
        loading: state.updateCustomer.loading,
        message: state.updateCustomer.message,
        error: state.updateCustomer.error,
        customerDataById: state.getCustomerById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCustomer: (customer, addressData, billingAddressData) => dispatch(updateCustomer(customer, addressData, billingAddressData)),
        refreshform: () => dispatch(updateCustomerRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditCustomerListing));
