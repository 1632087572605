import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import SoundVender from "../pages/soundvender";
import SoundVenderDetails from "../component/soundVenders/soundVenderDetails";
export const soundvendersroutes = [

  {
    path: "/",
    exact: true,
    layout: Layout,
    component: () => <Redirect to="/login" />,
  },

  {
    path: "/Sound_Venders",
    exact: true,
    layout: Layout,
    component: SoundVender,
  },
  {
    path: "/Sound_Vender_Details",
    exact: true,
    layout: Layout,
    component: SoundVenderDetails,
  },
];
