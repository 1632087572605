import React, { useEffect, useState, useRef } from "react";
import {
    addArtistPreferredSetup,
    addArtistPreferredSetupRequest,
} from "../../../actions/artists/artistPreferredSetup/addArtistPreferredSetup";
import { fetchAllProfileSetupType } from "../../../actions/common/getAllDropdown"

import { connect } from "react-redux";
//VALIDATION FUNCTION
import {
    stringValidate,
    numberValidate,
} from "../../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import { Dropdown } from "react-bootstrap";
import $ from "jquery";

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const AddPreferredSetup = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [setuptypeselectedOption, setSetupTypeselectedOption] = useState("");
    const [isArtistDefaultSetupSoundSelectedOption, setIsArtistDefaultSetupSelectedOption] = useState("");
    const { handleAddArtistPreferredSetup, message, error, refreshform, handleFetchAllProfileSetupType, ProfileSetupTypeDropdownData } = props;
    //UseReff
    const inputFile = useRef(null);

    //----------------  ADD artist STATE TO HANDLE INPUT VALUES ------------***//
    const isArtistSoundData = [
        {
            name: "true",
        },
        {
            name: "false",
        },
    ];

    const [artist, setArtist] = useState({
        setupTypeId: "",
        artistId: "",
        isArtistDefaultSetup: "",
        imageFile: ""
    });
    //----------------  ADD artist FIELDS ERROR ------------***//
    const [addartistserrors, setAddArtistsErrors] = useState({
        setupTypeId: null,
        artistId: null,
        isArtistDefaultSetup: null
    });

    const clearState = () => {
        setArtist({
            ...artist,
            setupTypeId: "",
            artistId: "",
            isArtistDefaultSetup: ""
        });
    };
    useState(() => {
        handleFetchAllProfileSetupType()
    }, [])
    const handleValidation = () => {
        setAddArtistsErrors({
            ...addartistserrors,
            setupTypeId: numberValidate(artist.setupTypeId, "SetupTypeId"),
            isArtistDefaultSetup: stringValidate(artist.isArtistDefaultSetup, "IsArtistDefaultSetup"),
            imageFile: stringValidate(artist.imageFile, "ImageFile")

        });
    };

    //---------------------------------------  ADD artist FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleAddVideo = (e) => {
        handleValidation();
        if (
            numberValidate(artist.setupTypeId, "SetupTypeId") === null &&
            stringValidate(artist.imageFile, "ImageFile") === null &&
            stringValidate(artist.isArtistDefaultSetup, "IsArtistDefaultSetup") === null
        ) {
            handleClearValidationError();
            handleAddArtistPreferredSetup(artist);
        }
    };

    //------------------------- USEFFECT HOOK EXECUTING ON FIRST RENDER OF VIEW -------------------------**//

    useEffect(() => {
        if (localStorage.getItem("message") === "addArtistPreferredSetupfailed") {
            enqueueSnackbar("Add Artist Preferred Setup Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "addArtistPreferredSetupSuccess") {
            handleClearValidationError();

            enqueueSnackbar("Add Artist Preferred Setup Success", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//

    const handleClearValidationError = () => {
        setAddArtistsErrors({
            ...addartistserrors,
            setupTypeId: null,
            artistId: null,
            isArtistDefaultSetup: null
        });

        setArtist({
            ...artist,
            setupTypeId: "",
            artistId: "",
            isArtistDefaultSetup: "",
            imageFile: ""
        });
        setSetupTypeselectedOption("")
        setIsArtistDefaultSetupSelectedOption("")
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    };
    const handleSetupType = (option) => {
        setSetupTypeselectedOption(option.label)
        setArtist({
            ...artist,
            setupTypeId: option.value,

        });

    }
    const handleArtistSound = (option) => {

        setIsArtistDefaultSetupSelectedOption(option.label)
        setArtist({
            ...artist,
            isArtistDefaultSetup: option.value,

        });
    }
    return (
        // <!-- The Modal -->
        <div className="modal" id="addPreferredSetupModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Add Preferred Setup</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">
                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Setup Type{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Dropdown class="formDropDown mt-4 mb-4" style={{ width: "100%" }}>
                                                        <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}>
                                                            {setuptypeselectedOption ? setuptypeselectedOption : "Setup Type"}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {ProfileSetupTypeDropdownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleSetupType(option)
                                                                    }
                                                                    onBlur={() => {
                                                                        setAddArtistsErrors({
                                                                            ...addartistserrors,
                                                                            setupTypeId: numberValidate(
                                                                                artist.setupTypeId,
                                                                                "SetupTypeId"
                                                                            ),
                                                                        });
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))}

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.setupTypeId}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Is Artist Default Setup<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {artist?.isArtistDefaultSetup ? artist?.isArtistDefaultSetup
                                                                : "Is Artist Default Setup"
                                                            }
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {isArtistSoundData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        setArtist({
                                                                            ...artist,
                                                                            isArtistDefaultSetup: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddArtistsErrors({
                                                                            ...addartistserrors,
                                                                            isArtistDefaultSetup: stringValidate(
                                                                                artist.isArtistDefaultSetup,
                                                                                "IsArtistDefaultSetup"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.isArtistDefaultSetup}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        Profile Photo <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="Profile Photo"
                                                        id=""
                                                        accept="image/png, image/gif, image/jpeg"
                                                        ref={inputFile}
                                                        onChange={(e) => {
                                                            setArtist({
                                                                ...artist,
                                                                imageFile: e.target.files[0],
                                                            });
                                                        }}
                                                        onBlur={() => {
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                imageFile: stringValidate(
                                                                    artist.imageFile,
                                                                    "ImageFile"
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.imageFile}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleAddVideo}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.addArtistPreferredSetup.loading,
        message: state.addArtistPreferredSetup.message,
        error: state.addArtistPreferredSetup.error,
        ProfileSetupTypeDropdownData: state.ProfileSetupType.ProfileSetupTypeData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleAddArtistPreferredSetup: (artist) => dispatch(addArtistPreferredSetup(artist)),
        refreshform: () => dispatch(addArtistPreferredSetupRequest()),
        handleFetchAllProfileSetupType: () => dispatch(fetchAllProfileSetupType())
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddPreferredSetup));
