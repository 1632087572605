import React from 'react';
import Profilepage from "../component/profile/profile"

function Profile() {

    return (
       <div>
           <Profilepage/>
       </div>
    )
}

export default Profile
