import React from "react";
import Discount from "../component/discount/discount";
function DiscountPage() {
  return (
    <div>
      <Discount />
    </div>
  );
}

export default DiscountPage;
