
import {
    FETCH_SOUND_SYSTEM_BY_ID_REQUEST,
    FETCH_SOUND_SYSTEM_BY_ID_SUCCESS,
    FETCH_SOUND_SYSTEM_BY_ID_FAILURE,
} from "../types.js";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
const fetchSoundSystemByIdRequest = () => {
    return { type: FETCH_SOUND_SYSTEM_BY_ID_REQUEST };
};

const fetchSoundSystemByIdSuccess = (Data) => {
    return { type: FETCH_SOUND_SYSTEM_BY_ID_SUCCESS, payload: Data };
};

const fetchSoundSystemByIdFailure = (error) => {
    return { type: FETCH_SOUND_SYSTEM_BY_ID_FAILURE, payload: error };
};

export const fetchSoundSystemById = (SoundSystemId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(fetchSoundSystemByIdRequest());
        axios
            .get(url + `getSoundSystemById/${SoundSystemId}`, {
                headers: headers,
            })

            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "SoundSystemFetchSuccess");
                    await dispatch(fetchSoundSystemByIdSuccess(response.data));
                }
            })
            .catch(function (error) {
                localStorage.setItem("message", "SoundSystemFetchSFailed");

                if (error.message === "Request failed with status code 401") {

                }
                dispatch(fetchSoundSystemByIdFailure(error));
            });
    };
};
