import {
    ADD_ARTIST_PREFERRED_SETUP_REQUEST,
    ADD_ARTIST_PREFERRED_SETUP_SUCCESS,
    ADD_ARTIST_PREFERRED_SETUP_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
import { fetchAllArtistPreferredSetup } from "./getAllPreferredSetup"
export const addArtistPreferredSetupRequest = () => {
    return {
        type: ADD_ARTIST_PREFERRED_SETUP_REQUEST,
    };
};

export const addArtistPreferredSetupSuccess = (Data) => {
    return {
        type: ADD_ARTIST_PREFERRED_SETUP_SUCCESS,
        payload: { data: Data.data, message: Data.message },
    };
};

export const addArtistPreferredSetupFailure = (Error) => {
    return {
        type: ADD_ARTIST_PREFERRED_SETUP_FAILURE,
        payload: Error,
    };
};
export const addArtistPreferredSetup = (data) => {
    var headers = {
        Accept: "Application/json",
        "Content-Type": "multipart/formdata",
        'Authorization': basicAuth,
    };
    const artistId = localStorage.getItem("artistId");

    let formdata = new FormData();
    var myData = {
        setType: {
            setupTypeId: data.setupTypeId
        },
        oneArtist: {
            artistId: artistId
        },
        isArtistDefaultSetup: data.isArtistDefaultSetup
    };

    let dataa = JSON.stringify(myData);
    formdata.append("data", dataa);
    formdata.append("setupProfilePhoto", data.imageFile);


    return (dispatch) => {
        dispatch(addArtistPreferredSetupRequest());
        axios
            .post(url + `addArtistPreferredSetup`, formdata, { headers: headers })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addArtistPreferredSetupSuccess");
                    await dispatch(addArtistPreferredSetupSuccess(response.data));
                    await dispatch(fetchAllArtistPreferredSetup());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "addArtistPreferredSetupfailed");
                dispatch(
                    addArtistPreferredSetupFailure("Please Check Your Internet Connection !!")
                );
            });
    };
};
