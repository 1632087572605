import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddWebsiteBanner from "./addWebsiteBanner";
import UpdateWebsiteBanner from "./updateWebsiteBannerById"
import Delete_Website_Banner from "./deleteWebsiteBannerById"
import { fetchAllWebsiteBanner } from "../../actions/websiteBanner/getAllWebsiteBanner"
import { fetchWebsiteBannerById } from "../../actions/websiteBanner/getWebsiteBannerById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
function WebsiteBanner(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [websiteBannerId, setWebsiteBannerId] = useState(0);
    const [paginationvalues, setPaginationValues] = useState({});
    useEffect(() => {
        localStorage.setItem("View_Name", "website_banner");
        checkRoute();

    }, []);
    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 2,
        sortingorder: "desc",
        search: null,
        columnname: "",
    });

    const {
        loading,
        error,
        message,
        getWebsiteBannerByIdData,
        handleGetWebsiteBanners,
        soundSystemData,
        handleFetchWebsiteBannerById,
        getAllWebsiteBannerData
    } = props;

    useEffect(() => {
        checkRoute();
    }, [getAllWebsiteBannerData]);

    useEffect(() => {
        handleGetWebsiteBanners()
    }, [])

    const handleContactDetailView = async (id) => {
        history.push({
            pathname: "/propertyDeatils",
            state: { contactid: id },
        });
    };

    return !loading ? (

        <>
            <div className="container-fluid">
                {/* <!-- Page Heading --> */}
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-5 align-self-center">
                            <h4 className="page-title">Website Banner</h4>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-1">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                onClick={() => history.push("/Booking_Request")}
                                                href={() => false}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Website Banner
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-7 align-self-center">
                            <div className="d-flex no-block justify-content-end align-items-center">
                                <div className="m-r-10">
                                    <div className="lastmonth"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4 pt-2"></div>
                                    <div className="col-lg-4 pt-2 text-right">
                                        {" "}
                                        <a
                                            href={() => false}
                                            className="btn btn-primary "
                                            data-toggle="modal"
                                            data-target="#createCustomerModal"
                                        >
                                            Add Website Banner
                                        </a>{" "}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>ID</div>
                                                    </div>
                                                </th>

                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Image</div>
                                                    </div>
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getAllWebsiteBannerData !== null && getAllWebsiteBannerData !== undefined && Object.entries(getAllWebsiteBannerData).length !== 0 ? (
                                                getAllWebsiteBannerData?.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: "/website_banner_detail",
                                                                        });
                                                                        localStorage.setItem(
                                                                            "tlsBannerId",
                                                                            item.tlsBannerId
                                                                        );
                                                                    }}
                                                                >
                                                                    {item.tlsBannerId}
                                                                </a>
                                                            </td>
                                                            <td>{item.websiteTlsBannerPath}</td>

                                                            <td>
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#updateWebsiteBannerModal"
                                                                    onClick={() => handleFetchWebsiteBannerById(item.tlsBannerId)}
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </a>{" "}
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#deleteWebsiteBannerModal"
                                                                    onClick={() => {
                                                                        setWebsiteBannerId(item.tlsBannerId);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : Object.entries(getAllWebsiteBannerData).length !== 0 ? (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div>
                                                            <p>
                                                                <strong style={{ color: "grey" }}>
                                                                    No records found
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className="loading1"></div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddWebsiteBanner />
            <UpdateWebsiteBanner
                getWebsiteBannerByIdData={getWebsiteBannerByIdData}
            />
            <Delete_Website_Banner websiteBannerId={websiteBannerId} />
            <ReactTooltip />
        </>
    )
        : (
            <div className="cover-spin"></div>
        );
}

const mapStateToProps = (state, props) => {
    return {
        getAllWebsiteBannerData: state.getAllWebsiteBanner.data,
        loading: state.getAllWebsiteBanner.loading,
        error: state.getAllWebsiteBanner.error,
        getWebsiteBannerByIdData: state.getWebsiteBannerById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleGetWebsiteBanners: () => dispatch(fetchAllWebsiteBanner()),
        handleFetchWebsiteBannerById: (id) => dispatch(fetchWebsiteBannerById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteBanner);
