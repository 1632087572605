import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import { connect } from "react-redux";
import { fetchPriceSlabById } from "../../actions/priceSlab/getPriceSlabById"

function PriceSlabDetails(props) {
    // to go to parent module
    const { getPriceSlab, user, error, loading, getPriceSlabByIdData } = props;
    const history = useHistory();
    let id = localStorage.getItem("priceSlabId");

    useEffect(() => {
        localStorage.setItem("View_Name", "priceSlab");
        checkRoute();
        fetchUserData(id);
    }, []);

    const fetchUserData = (Id) => {
        getPriceSlab(Id);
    };

    return !loading ? (
        error ? (
            "oops....something goes wrong"
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Price Slab</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item cursor">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item cursor"
                                                aria-current="page"
                                            >
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("priceSlab")}
                                                    href={() => false}
                                                >
                                                    Price Slab
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Price Slab Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex align-items-center bg-light p-2">
                                        {/* <h5></h5> */}
                                        <div className="ml-auto d-flex no-block align-items-center">
                                            <a
                                                onClick={() => {
                                                    history.goBack();
                                                    localStorage.removeItem("priceSlabId");
                                                }}
                                                className="btn btn-dark mr-3 "
                                                href={() => false}
                                            >
                                                Back
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <br className="clearfix" />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <h5 className="card-header">Information</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>ID</th>
                                                                <td>{getPriceSlabByIdData?.priceSlabId}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>Price Slab Name</th>
                                                                <td>{getPriceSlabByIdData?.priceSlabName}</td>
                                                            </tr>



                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <br className="clearfix" />
                                    <div className="card">
                                        <h5 className="card-header">Charges</h5>
                                        <div className="card-body pb-1">
                                            <table className="table table1 table-borderless table-responsive table-sm">
                                                <tbody>

                                                </tbody>

                                                {getPriceSlabByIdData.charges === undefined || getPriceSlabByIdData.charges === null || Object.keys(getPriceSlabByIdData.charges).length === 0 ? (
                                                    ""
                                                ) : (
                                                    Object.values(getPriceSlabByIdData.charges).map((item, index) => (
                                                        <tr key={index}>

                                                            <th>Amount</th>
                                                            <td>

                                                                {item.amount}

                                                            </td>
                                                            <th>Duration Type</th>
                                                            <td>
                                                                {item?.profileDuration?.durationType}

                                                            </td>
                                                            <th>Profile Setup Type</th>
                                                            <td>
                                                                {item?.profileSetupType?.setupType}
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                                }


                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- /.container-fluid -->  */}
                {/* <Edit_customers customerdata={data} /> */}
            </>
        )
    ) : (
        ""
    );
}

const mapStateToProps = (state, props) => {

    return {
        loading: state.getPriceSlabById.loading,
        error: state.getPriceSlabById.error,
        getPriceSlabByIdData: state.getPriceSlabById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPriceSlab: (user_ID) => dispatch(fetchPriceSlabById(user_ID)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PriceSlabDetails);
