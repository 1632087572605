import React, { useEffect, useState, useRef } from "react";
import {
    addPriceSlab,
    addPriceSlabRequest,
} from "../../actions/priceSlab/addPriceSlab";
import { connect } from "react-redux";
//VALIDATION FUNCTION
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import { fetchAllProfileSetupType, fetchAllProfileDuration } from "../../actions/common/getAllDropdown"
import {
    emailValidate,
    stringValidate,
    selectinputValidate,
    contactNumberValidation,
    postalCodeValidation,
    websiteValidation,
    passwordValidate,
    numberValidate,
} from "../../utils/Validations";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Dropdown } from "react-bootstrap";
import $ from "jquery";
//------------------ CREATE PRICE SLAB COMPONENT ----------------------//
const AddPriceSlab = (props) => {
    const { ProfileSetupTypeData,
        ProfileDurationData,
        message,
        error,
        refreshform,
        addPriceSlab,
        handleFetchAllProfileSetupType,
        handleFetchAllProfileDuration } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    //----------------  ADD PRICE SLAB STATE TO HANDLE INPUT VALUES ------------***//
    const [data, setData] = useState({
        priceSlabName: "",
        amount: "",
        setupTypeId: "",
        durationId: ""
    });
    useEffect(() => {
        handleFetchAllProfileSetupType()
        handleFetchAllProfileDuration()
    }, [])
    //----------------  ADD PRICE SLAB FIELDS ERROR ------------***//
    const [addpriceslaberrors, setAddPriceSlabError] = useState({
        priceSlabName: null,
        amount: null,
        setupTypeId: null,
        durationId: null
    });
    const clearState = () => {
        setData({
            ...data,
            priceSlabName: "",
            amount: "",
            setupTypeId: "",
            durationId: ""
        });
    };
    const handleValidation = () => {
        setAddPriceSlabError({
            ...addpriceslaberrors,
            priceSlabName: stringValidate(data.priceSlabName, "PriceSlabName"),
            amount: numberValidate(data.amount, "Amount"),
            setupTypeId: numberValidate(data.setupTypeId, "SetupTypeId"),
            durationId: numberValidate(data.durationId, "DurationId"),
        });
    };
    useEffect(() => {
        if (localStorage.getItem("message") === "addPriceSlabfailed") {
            enqueueSnackbar("Price Slab Creation Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "addPriceSlabSuccess") {
            handleClearValidationError();

            enqueueSnackbar("Price Slab Created successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
    const handleClearValidationError = () => {
        setAddPriceSlabError({
            ...addpriceslaberrors,
            priceSlabName: null,
            amount: null,
            setupTypeId: null,
            durationId: null
        });
        clearState();
    };
    const [formFields, setFormFields] = useState([
        {
            amount: "",
            profileSetupType: {
                setupTypeId: ""
            },
            profileDuration: {
                durationId: ""
            }
        }
    ]);
    const handleAddPriceSlab = (e) => {
        // handleValidation();
        // if (
        //     numberValidate(data.priceSlabName, "PriceSlabName") === null
        //  &&
        // numberValidate(formFields.amount, "Amount") === null &&
        // numberValidate(formFields.profileSetupType.setupTypeId, "SetupTypeId") === null &&
        // numberValidate(formFields.profileDuration.durationId, "DurationId") === null
        // ) {
        //     handleClearValidationError();
        addPriceSlab(data, formFields);
        // }
    };
    const handleFormChange = (event, index) => {
        const { name, value } = event.target;
        const data = [...formFields];
        data[index][name] = value;
        setFormFields(data);
    };

    const addFields = () => {
        const newField = {
            amount: "",
            profileSetupType: {
                setupTypeId: ""
            },
            profileDuration: {
                durationId: ""
            }
        };
        const newFieldLabel = {
            amount: "",
            profileSetupType: {
                setupTypeId: ""
            },
            profileDuration: {
                durationId: ""
            }
        };
        setFormFields([...formFields, newField]);
        setSelectedOption(null); // Reset selected option for profile setup type dropdown
        setSelectedProfileDurationOption(null); // Reset selected option for profile duration dropdown
    };

    const removeFields = (index) => {
        const data = [...formFields];
        data.splice(index, 1);
        setFormFields(data);
    };

    // Define the selected options and data for the dropdowns
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedProfileDurationOption, setSelectedProfileDurationOption] = useState(null);


    const handleProfileSetupType = (option, index) => {
        setSelectedOption(option.label);
        // Update the setupTypeId in the state formFields
        const updatedFormFields = [...formFields];
        updatedFormFields[index].profileSetupType.setupTypeId = option.value; // Assuming the option object has an "id" property for setupTypeId
        setFormFields(updatedFormFields);
    };

    const handleProfileDuration = (option, index) => {
        setSelectedProfileDurationOption(option.label);
        // Update the durationId in the state formFields
        const updatedFormFields = [...formFields];
        updatedFormFields[index].profileDuration.durationId = option.value; // Assuming the option object has an "id" property for durationId
        setFormFields(updatedFormFields);
    };
    // option.label


    return (
        // <!-- The Modal -->
        <div className="modal" id="createPriceSlabModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Add Price Slab</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                        // onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">
                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Price Slab Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Price Slab Name"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={data.priceSlabName}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                priceSlabName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddPriceSlabError({
                                                                ...addpriceslaberrors,
                                                                priceSlabName: stringValidate(
                                                                    data.priceSlabName,
                                                                    "PriceSlabName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addpriceslaberrors.priceSlabName}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <form> */}
                                            {formFields.map((form, index) => {
                                                return (
                                                    < >
                                                        <div className="col-sm-6">
                                                            <div className="form-group" key={index}>
                                                                <label>
                                                                    Amount{" "}
                                                                    <span style={{ color: "#d20d0d" }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="subs"
                                                                    name="amount"
                                                                    className="form-control"
                                                                    placeholder="Amount"
                                                                    onChange={(event) => handleFormChange(event, index)}
                                                                    value={form.amount}
                                                                    onBlur={() =>
                                                                        setAddPriceSlabError({
                                                                            ...addpriceslaberrors,
                                                                            amount: numberValidate(
                                                                                form.amount,
                                                                                "Amount"
                                                                            ),
                                                                        })
                                                                    }
                                                                />
                                                                <p className="profile-input-errors">
                                                                    {addpriceslaberrors.amount}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    Profile Setup Type{" "}
                                                                    <span style={{ color: "#d20d0d" }}>*</span>
                                                                </label>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        id={`dropdown-setup-type-${index}`}
                                                                        style={{
                                                                            width: "100%",
                                                                            background: "#D7263D",
                                                                            outline: "none",
                                                                            border: "none",
                                                                        }}
                                                                    >
                                                                        {form.profileSetupType.setupTypeId ? form.profileSetupType.setupTypeId : "Select Type"}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {ProfileSetupTypeData.map((option) => (
                                                                            <Dropdown.Item
                                                                                key={option.value}
                                                                                onClick={() => handleProfileSetupType(option, index)}
                                                                                onBlur={() =>
                                                                                    setAddPriceSlabError({
                                                                                        ...addpriceslaberrors,
                                                                                        setupTypeId: numberValidate(
                                                                                            form.profileSetupType.setupTypeId,
                                                                                            "SetupTypeId"
                                                                                        ),
                                                                                    })
                                                                                }
                                                                            >
                                                                                {option.label}
                                                                            </Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <p className="profile-input-errors">
                                                                    {addpriceslaberrors.setupTypeId}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    Profile Duration {" "}
                                                                    <span style={{ color: "#d20d0d" }}>*</span>
                                                                </label>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        id={`dropdown-duration-${index}`}
                                                                        style={{
                                                                            width: "100%",
                                                                            background: "#D7263D",
                                                                            outline: "none",
                                                                            border: "none",
                                                                        }}
                                                                    >
                                                                        {form.profileDuration.durationId ? form.profileDuration.durationId : "Select Duration"}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {ProfileDurationData.map((option) => (
                                                                            <Dropdown.Item
                                                                                key={option.value}
                                                                                onClick={() => handleProfileDuration(option, index)}

                                                                                onBlur={() =>
                                                                                    setAddPriceSlabError({
                                                                                        ...addpriceslaberrors,
                                                                                        durationId: numberValidate(
                                                                                            form.profileDuration.durationId,
                                                                                            "DurationId"
                                                                                        ),
                                                                                    })
                                                                                }
                                                                            >
                                                                                {option.label}
                                                                            </Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <p className="profile-input-errors">
                                                                    {addpriceslaberrors.durationId}
                                                                </p>
                                                            </div>
                                                        </div >
                                                        <br />
                                                        <button class="btn btn-primary btn-lg float-right mb-3" style={{ textAlign: "right", alignItems: "right", marginTop: "30px" }} onClick={() => removeFields(index)}>Remove</button>
                                                    </>
                                                )
                                            })}
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button"
                                                className="btn btn-primary"
                                                style={{ alignItems: "right", textAlign: "right" }}
                                                onClick={addFields}>Add More..</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal footer --> */}
                    </div>

                    <div className="modal-footer">

                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleAddPriceSlab}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {

    return {
        loading: state.addPriceSlab.loading,
        message: state.addPriceSlab.message,
        error: state.addPriceSlab.error,
        ProfileSetupTypeData: state.ProfileSetupType.ProfileSetupTypeData,
        ProfileDurationData: state.ProfileDuration.ProfileDurationData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addPriceSlab: (data, formFields) => dispatch(addPriceSlab(data, formFields)),
        refreshform: () => dispatch(addPriceSlabRequest()),
        handleFetchAllProfileSetupType: () => dispatch(fetchAllProfileSetupType()),
        handleFetchAllProfileDuration: () => dispatch(fetchAllProfileDuration())
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddPriceSlab));
