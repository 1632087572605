import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import { connect } from "react-redux";
import { fetchArtistById } from "../../actions/artists/getArtistById";
import { fetchAllArtistCalendarEvent } from "../../actions/artists/getArtistCalendarDates"
import UpdateArtists from "./updateArtists";
import Delete_Artist from "./deleteArtists"
import PublishUnpublish from "./publishUnpublish";


function ArtistDetails(props) {
    // to go to parent module

    const { getUser, user, error, loading, handleFetchAllArtistCalendarEvent, ArtistCalendarEventData, fetchArtistDetailsById, getArtistDataById } = props;
    const history = useHistory();
    const [bookingData, setBookingData] = useState([])
    let id = localStorage.getItem("artistId");

    useEffect(() => {
        localStorage.setItem("View_Name", "Artists");
        checkRoute();
        fetchUserData(id);
    }, []);

    const fetchUserData = (Id) => {
        getUser(Id);
        handleFetchAllArtistCalendarEvent(Id)
    };

    const handleHistoryDetailView = async (id) => {
        history.push({
            pathname: `/Booking_Details/${id}`,
            state: { bookingid: id },
        });

    };
    // if (Object.keys(user.bookingList).length !== 0) {

    // }
    // const isUndefinedOrNull = user.bookingList === undefined || user.bookingList === null;
    // const isEmpty = !isUndefinedOrNull && Object.keys(user.bookingList).length === 0;

    return <>
        {!loading ? (
            error ? (
                "oops....something goes wrong"
            ) : (
                <>
                    <div className="container-fluid">
                        {/* <!-- Page Heading --> */}
                        <div className="page-breadcrumb">
                            <div className="row">
                                <div className="col-5 align-self-center">
                                    <h4 className="page-title">Artist</h4>
                                    <div className="d-flex align-items-center">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-1">
                                                <li className="breadcrumb-item cursor">
                                                    <a
                                                        className="breadcrumb-link"
                                                        onClick={() => history.push("Booking_Request")}
                                                        href={() => false}
                                                    >
                                                        Home
                                                    </a>
                                                </li>
                                                <li
                                                    className="breadcrumb-item cursor"
                                                    aria-current="page"
                                                >
                                                    <a
                                                        className="breadcrumb-link"
                                                        onClick={() => history.push("Artists")}
                                                        href={() => false}
                                                    >
                                                        Artist
                                                    </a>
                                                </li>
                                                <li
                                                    className="breadcrumb-item active"
                                                    aria-current="page"
                                                >
                                                    Artist Details
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-7 align-self-center">
                                    <div className="d-flex no-block justify-content-end align-items-center">
                                        <div className="m-r-10">
                                            <div className="lastmonth"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-md-flex align-items-center bg-light p-2">
                                            {/* <h5></h5> */}
                                            <div className="ml-auto d-flex no-block align-items-center">
                                                <a
                                                    className="btn btn-dark mr-3 "
                                                    href={() => false}
                                                    data-toggle="modal"
                                                    data-target="#updateArtist"
                                                    onClick={() => fetchArtistDetailsById(user.artistId)}
                                                >
                                                    Update Artist
                                                </a>
                                                <a
                                                    className="btn btn-dark mr-3 "
                                                    href="/artist_prefferedsetup"
                                                >
                                                    PreferredSetup
                                                </a>
                                                <a
                                                    className="btn btn-dark mr-3 "
                                                    href="/artist_videos"
                                                >
                                                     Videos
                                                </a>

                                                <a
                                                    className="btn btn-dark mr-3 "
                                                    href="/artist_calendar"
                                                >
                                                     Calendar
                                                </a>
                                                <a
                                                    className="btn btn-dark mr-3 "
                                                    href={() => false}
                                                    data-toggle="modal"
                                                    data-target="#PublishorunpublishArtist"
                                                    onClick={() => fetchArtistDetailsById(user.artistId)}
                                                >
                                                    Publish Status
                                                </a>
                                                <a
                                                    className="btn btn-dark mr-3 "
                                                    href={() => false}
                                                    data-toggle="modal"
                                                    data-target="#deleteArtistModal"
                                                >
                                                    Delete
                                                </a>
                                            </div>
                                        </div>
                                        <br className="clearfix" />
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="card">
                                                    <h5 className="card-header">Personal Details</h5>
                                                    <div className="card-body pb-1">
                                                        <table className="table table1 table-borderless table-responsive table-sm">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Id</th>
                                                                    <td> {user?.artistId}  </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>User Id</th>
                                                                    <td> {user?.artistUser?.userId}  </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Published</th>
                                                                    <td> {user?.published == true ? "Yes" : "No"}  </td>
                                                                </tr>


                                                                <tr>
                                                                    <th>Name</th>
                                                                    <td> {user?.artistUser?.firstName}  {user?.artistUser?.lastName}</td>

                                                                </tr>
                                                                <tr>
                                                                    <th>Phone Number</th>
                                                                    <td> {user?.artistUser?.phoneNumber}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Email Address</th>
                                                                    <td>
                                                                        <a
                                                                            href="*"
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    `mailto:${user.email}`,
                                                                                    "_blank"
                                                                                )
                                                                            }
                                                                            target="blank"
                                                                        >
                                                                            {user?.artistUser?.email}
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Address</th>
                                                                    <td>{user?.baseLocation?.addressName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>BaseLocation Url</th>
                                                                    <td>{user.baseLocationUrl
                                                                    }</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br className="clearfix" />
                                                <div className="card">
                                                    <h5 className="card-header">Customization Fileds</h5>
                                                    <div className="card-body pb-1">
                                                        <table className="table table1 table-borderless table-responsive table-sm">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Artist Setups</th>
                                                                    <td>{user?.baseLocation?.addressType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Street</th>
                                                                    <td>{user?.baseLocation?.street}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>City</th>
                                                                    <td>{user?.baseLocation?.city}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>ZipCode</th>
                                                                    <td>{user?.baseLocation?.zipCode}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>State</th>
                                                                    <td>{user?.baseLocation?.state}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Country</th>
                                                                    <td>{user?.baseLocation?.country}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Latitude</th>
                                                                    <td>{user?.baseLocation?.latitude}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Longitude</th>
                                                                    <td>{user?.baseLocation?.longitude}</td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br className="clearfix" />
                                                <div className="card">
                                                    <h5 className="card-header">unavailable Dates</h5>
                                                    <div className="card-body pb-1">
                                                        <table className="table table1 table-borderless table-responsive table-sm">
                                                            <tbody>
                                                                {ArtistCalendarEventData === undefined || ArtistCalendarEventData === null || Object.keys(ArtistCalendarEventData).length === 0 ? (
                                                                    ""
                                                                ) : (
                                                                    Object.values(ArtistCalendarEventData).map((item, index) => (
                                                                        <tr key={index}>
                                                                            <th>unavailable Date</th>
                                                                            <td>
                                                                                {/* <a
                                                                                className="breadcrumb-link"
                                                                                href={() => false}
                                                                                onClick={() => handleHistoryDetailView(item.availabilityDate)}
                                                                            >
                                                                                {item.bookingId}
                                                                            </a> */}
                                                                                {item.availabilityDate}
                                                                            </td>
                                                                        </tr>
                                                                    ))

                                                                )
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-sm-6">
                                                <div className="card">
                                                    <h5 className="card-header">Standard Fields</h5>
                                                    <div className="card-body pb-1">
                                                        <table className="table table1 table-borderless table-responsive table-sm">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Profile Name</th>
                                                                    <td>{user?.profileName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Artist Type</th>
                                                                    <td> {user?.artistUser?.userType}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>Genre</th>
                                                                    <td>{user.genres}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th> BIO </th>
                                                                    <td> {user?.about}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Cover Image</th>
                                                                    <td>
                                                                        {user.backgroundPhotoPath !== "" ? (
                                                                            <img
                                                                                src={user.backgroundPhotoPath}
                                                                                className="rounded-circle noimageUser"
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="img/noimage.png"
                                                                                className="rounded-circle noimageUser"
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <th>Instagram profile </th>
                                                                    <td>{user.instagramProfileLink}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Rating</th>
                                                                    <td>{user.rating}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Years Of Experience</th>
                                                                    <td>{user.yearsOfExperience}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Sample Play list
                                                                    </th>
                                                                    <td>{user.samplePlaylist}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Shows performed
                                                                    </th>
                                                                    <td>{user.numberOfShowsPerformed}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br className="clearfix" />
                                                <div className="card">
                                                    <h5 className="card-header">Booking History</h5>
                                                    <div className="card-body pb-1">
                                                        <table className="table table1 table-borderless table-responsive table-sm">
                                                            <tbody>
                                                                {user.bookingRequestList === undefined || user.bookingRequestList === null || Object.keys(user.bookingRequestList).length === 0 ? (
                                                                    ""
                                                                ) : (
                                                                    Object.values(user.bookingRequestList).map((item, index) => (
                                                                        <tr key={index}>
                                                                            <th>Booking ID</th>
                                                                            <td>
                                                                                <a
                                                                                    className="breadcrumb-link"
                                                                                    href={() => false}
                                                                                    onClick={() => handleHistoryDetailView(item.bookingId)}
                                                                                >
                                                                                    {item.bookingId}
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    )).reverse()

                                                                )
                                                                }
                                                                <br />
                                                                <tr>
                                                                    <th>Total Number of booking
                                                                    </th>
                                                                    <td>{user.bookingRequestList === undefined || user.bookingRequestList === null || Object.keys(user.bookingRequestList).length === 0 ? "" : user?.bookingRequestList.length}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >

                    {/* <!-- /.container-fluid -->  */}
                    {/* <Edit_customers customerdata={data} /> */}
                </>
            )
        ) : (
            ""
        )}
        <UpdateArtists
            getArtistDataById={user.artistId}
        />
        <PublishUnpublish
            getArtistDataById={user.artistId}
        />
        <Delete_Artist artistId={user.artistId} />
    </>

}

const mapStateToProps = (state, props) => {
    return {
        loading: state.getArtistById.loading,
        user: state.getArtistById.data,
        error: state.getArtistById.error,
        ArtistCalendarEventData: state.getArtistCalendarDateById.data,
        getArtistDataById: state.getArtistById.data

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (user_ID) => dispatch(fetchArtistById(user_ID)),
        handleFetchAllArtistCalendarEvent: (user_ID) => dispatch(fetchAllArtistCalendarEvent(user_ID)),
        fetchArtistDetailsById: (id) => dispatch(fetchArtistById(id))

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ArtistDetails);
