import React from 'react'
import Agents from '../component/agent/agent'
function Agent() {
    return (
        <div>
            <Agents/>
        </div>
    )
}

export default Agent
