import {
    ADD_ARTIST_CALENDAR_DATE_REQUEST,
    ADD_ARTIST_CALENDAR_DATE_SUCCESS,
    ADD_ARTIST_CALENDAR_DATE_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
import { fetchAllArtistCalendarEvent } from "./getArtistCalendarDates"
export const addArtistCalendarDateEventRequest = () => {
    return {
        type: ADD_ARTIST_CALENDAR_DATE_REQUEST,
    };
};

export const addArtistCalendarDateEventSuccess = (Data) => {
    return {
        type: ADD_ARTIST_CALENDAR_DATE_SUCCESS,
        payload: { data: Data.data, message: Data.message },
    };
};

export const addArtistCalendarDateEventFailure = (Error) => {
    return {
        type: ADD_ARTIST_CALENDAR_DATE_FAILURE,
        payload: Error,
    };
};
export const addArtistCalendarDateEvent = (start, title) => {
    var headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };
    const artistId = localStorage.getItem("artistId");

    return (dispatch) => {
        dispatch(addArtistCalendarDateEventRequest());
        axios
            .post(url + `addCalendarEvent`, {
                availabilityDate: start,
                availabilityTime: "",
                artistAvailabilityStatus: true,
                artist: {
                    artistId: artistId
                },
                title: title,
                customer: null
            }, { headers: headers })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addArtistCalendarDateEventSuccess");
                    await dispatch(addArtistCalendarDateEventSuccess(response.data));
                    await dispatch(fetchAllArtistCalendarEvent());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "addArtistCalendarDateEventfailed");
                dispatch(
                    addArtistCalendarDateEventFailure("Please Check Your Internet Connection !!")
                );
            });
    };
};
