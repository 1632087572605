import {
  UPDATE_SOUNDER_VENDER_REQUEST,
  UPDATE_SOUNDER_VENDER_SUCCESS,
  UPDATE_SOUNDER_VENDER_FAILURE,
} from "../types";

import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
export const updateSoundVenderRequest = () => {
  return {
    type: UPDATE_SOUNDER_VENDER_REQUEST,
  };
};

export const updateSoundVenderSuccess = (Data) => {
  return {
    type: UPDATE_SOUNDER_VENDER_SUCCESS,
    payload: { message: Data.message },
  };
};

export const updateSoundVenderFailure = (Error) => {
  return {
    type: UPDATE_SOUNDER_VENDER_FAILURE,
    payload: { message: " updation failed", error: Error },
  };
};

export const updateSoundVender = (data, addressData) => {


  let formdata = new FormData();
  var myData = {
    companyName: data.companyName,
    gSTIN: data.gSTIN,
    websiteUrl: data.websiteUrl,
    designation: data.designation,
    operatingState: data.operatingState,
    soundVendorUser: {
      userName: "",
      password: "",
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      secondaryPhone: data.secondaryPhone
    },
    officeAddress: {
      googleMapLocation: data.googleMapLocation,
      street: addressData.street,
      city: addressData.city,
      state: addressData.state,
      zipCode: data.zipCode,
      country: addressData.country,
      longitude: addressData.longitude,
      latitude: addressData.latitude,
      addressName: addressData.addressName
    }
  };
  let dataa = JSON.stringify(myData);
  formdata.append("data", dataa);

  var headers = {
    Accept: "Application/json",
    "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,
  };
  return (dispatch) => {
    dispatch(updateSoundVenderRequest());
    axios
      .put(url + `updateSoundVendorUserById/${data.id}`, formdata, {
        headers: headers,
      })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "RecordUpdatedSuccessfully");
          await dispatch(updateSoundVenderSuccess(response.data));

        }
      })
      .catch((error) => {
        localStorage.setItem("message", "RecordUpdatedFailed");
        dispatch(updateSoundVenderFailure(error));
      });
  };
};
