
import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import PriceSlabPage from "../pages/priceSlab";
import PriceSlabDetailsPage from "../component/priceSlab/priceSlabDetails";
export const priceslabroutes = [

    {
        path: "/",
        exact: true,
        layout: Layout,
        component: () => <Redirect to="/login" />,
    },

    {
        path: "/priceSlab",
        exact: true,
        layout: Layout,
        component: PriceSlabPage,
    },
    {
        path: "/price_slab_details",
        exact: true,
        layout: Layout,
        component: PriceSlabDetailsPage,
    },

];
