import {
    UPDATE_ARTIST_PROFILE_REQUEST,
    UPDATE_ARTIST_PROFILE_SUCCESS,
    UPDATE_ARTIST_PROFILE_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
import { fetchArtistById } from "../getArtistById"

export const updateArtistRequest = () => {
    return {
        type: UPDATE_ARTIST_PROFILE_REQUEST,
    };
};

export const updateArtistSuccess = (Data) => {
    return {
        type: UPDATE_ARTIST_PROFILE_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updateArtistFailure = (Error) => {
    return {
        type: UPDATE_ARTIST_PROFILE_FAILURE,
        payload: { message: "Property updation failed", error: Error },
    };
};

export const updateArtist = (data, addressData) => {
    const artistId = localStorage.getItem("artistId");
    let formdata = new FormData();
    var myData = {

        "profileName": data.profileName,
        "instagramProfileLink": data.instagramProfileLink,
        "genres": data.genres,
        "rating": data.rating,
        "about": data.about,
        "inspirations": data.inspirations,
        "numberOfShowsPerformed": data.numberOfShowsPerformed,
        "yearsOfExperience": data.yearsOfExperience,
        "achievements": data.achievements,
        "individualSkill": data.individualSkill,
        "samplePlaylist": data.samplePlaylist,
        "hasOwnSoundSystem": data.hasOwnSoundSystem,
        "baseLocationUrl": data.baseLocationUrl,
        "maxServiceaablekm": data.maxServiceaablekm,
        "gSTIN": data.gSTIN,
        "operatingState": data.operatingState,
        "published": data.published,
        "region": data.region,
        "artistUser": {
            "userName": data.userName,
            "password": data.password,
            "email": data.email,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "phoneNumber": data.phoneNumber,
            "secondaryPhone": data.secondaryPhone
        },
        "baseLocation": {
            "googleMapLocation": data.googleMapLocation,
            "street": addressData.street,
            "city": addressData.city,
            "state": addressData.state,
            "zipCode": data.zipCode,
            "country": addressData.country,
            "longitude": addressData.longitude,
            "latitude": addressData.latitude,
            "addressName": addressData.addressName
        },
        "priceSlab": {
            "priceSlabId": data.priceSlabId
        }

    };
    let dataa = JSON.stringify(myData);
    formdata.append("data", dataa);
    formdata.append("backgroundImage", data.backgroundPhoto);
    const headers = {
        Accept: "Application/json",
        "Content-Type": "multipart/formdata",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updateArtistRequest());
        axios
            .put(url + `updateArtistUserById/${artistId}`, formdata, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "Record Updated Successfully");
                    await dispatch(updateArtistSuccess(response.data));
                    await dispatch(fetchArtistById())

                }
            })
            .catch((error) => {
                localStorage.setItem("message", "Record Updated Failed");
                dispatch(updateArtistFailure(error));
            });
    };
};
