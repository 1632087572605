import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../../utils/checkroute";
// Function For API
import { fetchAllArtistCalendarEvent } from "../../../actions/artists/calendarManagement/getArtistCalendarDates"
import { addArtistCalendarDateEvent } from "../../../actions/artists/calendarManagement/addArtistCalendarDates"
import { deleteArtistCalendarEventById } from "../../../actions/artists/calendarManagement/deleteArtistCalendarEvent"
//Style
import "../../../styles/css/calendarManagement.css"
//Pupop Message
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
// All modal for adding , delete , editing
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
// import 'react-calendar/dist/Calendar.css';
import $ from 'jquery';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
moment.locale("en-GB");
const localizer = momentLocalizer(moment);

function CalendarManagement(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [date, setDate] = useState(new Date());
    const [absentDates, setAbsentDates] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");

    const {
        handlefetchAllArtistCalendarEvent,
        loading,
        error,
        message,
        fetchArtistCalendarDates,
        handleaddArtistCalendarDateEvent,
        handleDeleteArtistCalendarEventById,
        deleteEventMessage,
        deleteEventError
    } = props;

    const handleContactDetailView = async (id) => {
        history.push({
            pathname: "/propertyDeatils",
            state: { contactid: id },
        });
    };

    useEffect(() => {
        checkRoute();
        fetchdata();

    }, []);

    const fetchdata = () => {
        handlefetchAllArtistCalendarEvent();

    };

    const [eventsData, setEventsData] = useState();


    const handleSelect = ({ start, end }) => {
        const currentDate = new Date();
        const inputDate = start;
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        if (start < currentDate) {
            alert("Past dates are not allowed.");
            return;
        }
        const title = window.prompt("New Event name");
        if (title) {
            setEventsData([
                ...eventsData,
                {
                    start,
                    end,
                    title
                }
            ]);

            handleaddArtistCalendarDateEvent(formattedDate, title)
        }
    };


    useEffect(() => {
        // Process the JSON data to create events in the desired format
        if (Array.isArray(fetchArtistCalendarDates)) {
            const processedEvents = fetchArtistCalendarDates.map(item => ({
                title: item?.title ? item?.title : "Not Available",
                start: new Date(`${item?.availabilityDate} ${item?.availabilityTime}`),
                end: new Date(`${item?.availabilityDate} ${item?.availabilityTime}`),
                ownCalendarId: item?.ownCalendarId
            }));
            setEventsData(processedEvents);
        }

    }, [fetchArtistCalendarDates]);



    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: 'red',
            color: 'white',

        };
        return {
            style,
        };
    };

    const handleSelectEvent = (id) => {
        setSelectedEvent(id);

        setShowDeleteConfirmation(true);
    };

    const handleDeleteEvent = () => {
        if (selectedEvent) {

            handleDeleteArtistCalendarEventById(selectedEvent)

            setShowDeleteConfirmation(false);
        }
    };
    //useEffect For Delete 
    useEffect(() => {
        if (localStorage.getItem("message") === "ArtistCalendarEventDleteFailed") {
            enqueueSnackbar("Delete Artist Event Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            setShowDeleteConfirmation(false);
        }

        if (localStorage.getItem("message") === "ArtistCalendarEventDleteSuccess") {

            enqueueSnackbar("Delete Artist Event Success", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            setShowDeleteConfirmation(false);
        }
    }, [deleteEventMessage, deleteEventError]);

    return !loading ? (
        error ? (
            <div className="cover-spin"></div>
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <h4 className="page-title">Calendar Management</h4>

                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className="card d-flex align-items-center " style={{ height: "100%" }}> */}
                            <Calendar
                                views={["month"]}
                                selectable
                                localizer={localizer}
                                defaultDate={new Date()}
                                defaultView="month"
                                events={eventsData}
                                style={{ height: "100vh" }}
                                // style={{ height: "400px" }}
                                // onSelectEvent={(event) => alert(event.title)}
                                onSelectEvent={(event) => handleSelectEvent(event.ownCalendarId)}
                                // ownCalendarId
                                eventPropGetter={eventStyleGetter}
                                onSelectSlot={handleSelect}
                            />
                            {showDeleteConfirmation && (
                                <div className="delete-confirmation-overlay">
                                    <div className="delete-confirmation">
                                        <p>Delete this event?</p>
                                        <button onClick={handleDeleteEvent}>OK</button>
                                        <button onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <ReactTooltip />
            </>
        )
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        fetchArtistCalendarDates: state.fetchArtistCalendarDates.data,
        loading: state.fetchArtistCalendarDates.loading,
        message: state.fetchArtistCalendarDates.message,
        // deleteArtistCalendarEvent
        deleteEventMessage: state.deleteArtistCalendarEvent.message,
        deleteEventError: state.deleteArtistCalendarEvent.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handlefetchAllArtistCalendarEvent: () => dispatch(fetchAllArtistCalendarEvent()),
        handleaddArtistCalendarDateEvent: (formattedDate, title) => dispatch(addArtistCalendarDateEvent(formattedDate, title)),
        handleDeleteArtistCalendarEventById: (id) => dispatch(deleteArtistCalendarEventById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarManagement);
