
import {
    FETCH_CUSTOMER_REQUEST_BY_ID,
    FETCH_CUSTOMER_SUCCESS_BY_ID,
    FETCH_CUSTOMER_FAILURE_BY_ID,
} from "../types.js";
import axios from "axios";

import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
const fetchCustomerByIdRequest = () => {
    return { type: FETCH_CUSTOMER_REQUEST_BY_ID };
};

const fetchCustomerByIdSuccess = (Data) => {
    return { type: FETCH_CUSTOMER_SUCCESS_BY_ID, payload: Data };
};

const fetchCustomerByIdFailure = (error) => {
    return { type: FETCH_CUSTOMER_FAILURE_BY_ID, payload: error };
};

export const fetchCustomerById = (customerId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(fetchCustomerByIdRequest());
        axios
            .get(url + `getCustomerById/${customerId}`, {
                headers: headers,
            })

            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addBookingSuccess");
                    await dispatch(fetchCustomerByIdSuccess(response.data));
                }
            })
            .catch(function (error) {
                if (error.message === "Request failed with status code 401") {
                }
                dispatch(fetchCustomerByIdFailure(error));
            });
    };
};
