import {
    UPDATE_WEBSITE_BANNER_REQUEST,
    UPDATE_WEBSITE_BANNER_SUCCESS,
    UPDATE_WEBSITE_BANNER_FAILURE,
} from "../types";

import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllWebsiteBanner } from "./getAllWebsiteBanner"

export const updateWebsiteBannerByIdRequest = () => {
    return {
        type: UPDATE_WEBSITE_BANNER_REQUEST,
    };
};

export const updateWebsiteBannerByIdSuccess = (Data) => {
    return {
        type: UPDATE_WEBSITE_BANNER_SUCCESS,
        payload: Data,
    };
};

export const updateWebsiteBannerByIdFailure = (Error) => {
    return {
        type: UPDATE_WEBSITE_BANNER_FAILURE,
        payload: { message: " updation failed", error: Error },
    };
};

export const updateWebsiteBannerById = (data) => {


    let formdata = new FormData();

    formdata.append("imagefile", data.imageFile);


    const headers = {
        "Content-Type": "multipart/formdata",
        'Authorization': basicAuth,

    };
    return (dispatch) => {
        dispatch(updateWebsiteBannerByIdRequest());
        axios
            .put(url + `updateWebsiteTlsBannerById/${data.id}`, formdata, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "RecordUpdatedSuccessfully");
                    await dispatch(updateWebsiteBannerByIdSuccess(response.data));
                    await dispatch(fetchAllWebsiteBanner())
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "RecordUpdatedFailed");
                dispatch(updateWebsiteBannerByIdFailure(error));
            });
    };
};
