import React, { useEffect, useState, useRef } from "react";
import {
    addSoundSystem,
    addSoundSystemRequest,
} from "../../actions/soundSystem/addSoundSystem";
import { connect } from "react-redux";

//VALIDATION FUNCTION

import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import {
    emailValidate,
    stringValidate,
    selectinputValidate,
    contactNumberValidation,
    postalCodeValidation,
    websiteValidation,
    passwordValidate,
    numberValidate,
} from "../../utils/Validations";
import $ from "jquery";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const AddSoundSystem = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { AddSoundSystem, message, error, AccountManagerdata, refreshform } = props;

    //UseReff
    const inputFile = useRef(null);
    const inputImageFile = useRef(null)
    //----------------  ADD CUSTOMER STATE TO HANDLE INPUT VALUES ------------***//

    const [data, setData] = useState({
        title: "",
        specification: "",
        maxNumberOfGuest: "",
        chargeAmount: "",
        soundVendor: {
            soundVendorId: ""
        },
        imageFile: "",
        videoFile: ""
    });

    // const [imageData, setImagedata] = useState({
    //     imageBase: "",
    //     imageError: "",
    // });

    //----------------  ADD CUSTOMER FIELDS ERROR ------------***//
    const [addsoundsystemerrors, setAddSoundSystemError] = useState({
        title: null,
        specification: null,
        maxNumberOfGuest: null,
        chargeAmount: null,
        soundVendor: {
            soundVendorId: null
        },
        imageFile: null,
        videoFile: null
    });


    const handleValidation = () => {
        setAddSoundSystemError({
            ...addsoundsystemerrors,
            title: stringValidate(data.title, "Title"),
            specification: stringValidate(data.specification, "Specification"),
            maxNumberOfGuest: numberValidate(data.maxNumberOfGuest, "MaxNumberOfGuest"),
            chargeAmount: numberValidate(data.chargeAmount, "ChargeAmount"),
            imageFile: stringValidate(data.imageFile, "ImageFile"),
            videoFile: stringValidate(data.videoFile, "VideoFile"),
        });
    };

    useEffect(() => {
        if (localStorage.getItem("message") === "addSoundSystemfailed") {
            enqueueSnackbar(error, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform();
        }

        if (localStorage.getItem("message") === "addSoundSystemSuccess") {
            setSameAsAddress(false);
            handleClearValidationError();
            enqueueSnackbar("Created successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //---------------------------------------  ADD dataS FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleAddSoundSystem = (e) => {
        handleValidation();
        if (
            stringValidate(data.title, "Title") === null &&
            stringValidate(data.specification, "Specification") === null &&
            numberValidate(data.maxNumberOfGuest, "MaxNumberOfGuest") === null &&
            numberValidate(data.chargeAmount, "ChargeAmount") === null &&
            stringValidate(data.imageFile, "ImageFile") === null &&
            stringValidate(data.videoFile, "VideoFile") === null
        ) {
            handleClearValidationError();
            AddSoundSystem(data);
        }
    };

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
    const handleClearValidationError = () => {
        setAddSoundSystemError({
            ...addsoundsystemerrors,
            title: null,
            specification: null,
            maxNumberOfGuest: null,
            chargeAmount: null,
            soundVendor: {
                soundVendorId: null
            },
            imageFile: null,
            videoFile: null
        });

        setData({
            ...data,
            title: "",
            specification: "",
            maxNumberOfGuest: "",
            chargeAmount: "",
            soundVendor: {
                soundVendorId: ""
            },
            imageFile: "",
            videoFile: ""
        });

        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
        if (inputImageFile.current) {
            inputImageFile.current.value = "";
            inputImageFile.current.type = "text";
            inputImageFile.current.type = "file";
        }

    };

    // const onlyNumberInputPhone = (evt) => {
    //     //const trimval = evt.target.value.split(" ");
    //     if (/^[0-9-+() ]*$/i.test(evt.target.value)) {
    //         setData({
    //             ...data,
    //             mobileNumber: evt.target.value,
    //         });
    //     }
    // };

    // const changeimage = (event) => {
    //     setData({
    //         ...data,
    //         imageUrl: "",
    //     });
    //     const file = event.target.files[0];
    //     // setData({
    //     //   ...data,
    //     //   imageUrl: URL.createObjectURL(event.target.files[0]),
    //     // });
    //     if (file !== undefined) {
    //         var reader = new FileReader();
    //         if (file.size > 2e6) {
    //             setImagedata({
    //                 ...imageData,
    //                 imageError: "File size can not be more than 2 MB",
    //                 imageBase: "",
    //             });
    //         } else {
    //             reader.onload = (evt) => {
    //                 setData({
    //                     ...data,
    //                     imageUrl: evt.target.result,
    //                 });
    //                 setImagedata({
    //                     ...imageData,
    //                     imageError: "",
    //                     imageBase: evt.target.result,
    //                 });
    //             };
    //         }
    //         reader.readAsDataURL(file);
    //     }
    // };
    return (
        // <!-- The Modal -->
        <div className="modal" id="createCustomerModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Add Sound System</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">

                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Title{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={data.title}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                title: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddSoundSystemError({
                                                                ...addsoundsystemerrors,
                                                                title: stringValidate(
                                                                    data.title,
                                                                    "Title"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addsoundsystemerrors.title}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Specification{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="specification"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={data.specification}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                specification: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddSoundSystemError({
                                                                ...addsoundsystemerrors,
                                                                specification: stringValidate(
                                                                    data.specification,
                                                                    "Specification"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addsoundsystemerrors.specification}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        MaxNumberOfGuest{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="maxNumberOfGuest"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={data.maxNumberOfGuest}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                maxNumberOfGuest: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddSoundSystemError({
                                                                ...addsoundsystemerrors,
                                                                maxNumberOfGuest: numberValidate(
                                                                    data.maxNumberOfGuest,
                                                                    "MaxNumberOfGuest"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addsoundsystemerrors.maxNumberOfGuest}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        ChargeAmount{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="chargeAmount"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={data.chargeAmount}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                chargeAmount: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddSoundSystemError({
                                                                ...addsoundsystemerrors,
                                                                chargeAmount: numberValidate(
                                                                    data.chargeAmount,
                                                                    "ChargeAmount"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addsoundsystemerrors.chargeAmount}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        imageFile{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="imageFile"
                                                        id="subs"
                                                        ref={inputImageFile}
                                                        accept="image/*"
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                imageFile: e.target.files[0],
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddSoundSystemError({
                                                                ...addsoundsystemerrors,
                                                                imageFile: stringValidate(
                                                                    data.imageFile,
                                                                    "ImageFile"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addsoundsystemerrors.imageFile}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        videoFile{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="videoFile"
                                                        id="subs"
                                                        accept="video/*"
                                                        ref={inputFile}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                videoFile: e.target.files[0],
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddSoundSystemError({
                                                                ...addsoundsystemerrors,
                                                                videoFile: stringValidate(
                                                                    data.videoFile,
                                                                    "VideoFile"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addsoundsystemerrors.videoFile}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleAddSoundSystem}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {

    return {
        loading: state.addSoundSystem.loading,
        message: state.addSoundSystem.message,
        error: state.addSoundSystem.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddSoundSystem: (data) => dispatch(addSoundSystem(data)),
        refreshform: () => dispatch(addSoundSystemRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddSoundSystem));
