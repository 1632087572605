import {
    DELETE_ARTIST_VIDEOS_BY_ID_REQUEST,
    DELETE_ARTIST_VIDEOS_BY_ID_SUCCESS,
    DELETE_ARTIST_VIDEOS_BY_ID_FAILURE,
  } from "../../../actions/types";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
  };
  export const deleteVideoReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_ARTIST_VIDEOS_BY_ID_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
          error: "",
        };
      case DELETE_ARTIST_VIDEOS_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: "Video Deleted Successfully !!",
        };
      case DELETE_ARTIST_VIDEOS_BY_ID_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "failed",
        };
      default:
        return state;
    }
  };
  