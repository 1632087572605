import React, { useEffect } from "react";
import { deleteWebsiteBannerById, deleteWebsiteBannerByIdRequest } from "../../actions/websiteBanner/deleteBannerById";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
function Delete_Website_Banner(props) {
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { DeleteWebsiteBannerById, websiteBannerId, message, refreshform, loading, error } = props;

    useEffect(() => {
        if (localStorage.getItem("message") === "WebsiteBannerDeleteSuccess") {
            const message = "Website Banner deleted successfully";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()

        }
        if (localStorage.getItem("message") === "WebsiteBannerDeleteFailed") {
            const message = "Website Banner Deleted Failed";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()
        }
    }, [message, error]);

    return (
        <>
            <div
                className="modal fade"
                id="deleteWebsiteBannerModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Delete Website Banner
                            </h5>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                {" "}
                                <span aria-hidden="true">×</span>{" "}
                            </button>
                        </div>
                        <div className="modal-body">
                            Do you really want to this  Website Banner ?
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={async () => await DeleteWebsiteBannerById(websiteBannerId)}
                            >
                                Delete
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="cover-spin"></div> : ""}
        </>
    );
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.deleteWebsiteBanner.loading,
        message: state.deleteWebsiteBanner.message,
        error: state.deleteWebsiteBanner.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteWebsiteBannerById: (websiteBannerId) => dispatch(deleteWebsiteBannerById(websiteBannerId)),
        refreshform: () => dispatch(deleteWebsiteBannerByIdRequest()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Delete_Website_Banner);
