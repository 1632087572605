import React, { useEffect, useState } from "react";
import { checkRoute } from "../../utils/checkroute";
import { connect } from "react-redux";
import { fetchArtistById } from "../../actions/artists/getArtistById";
import { updateArtist, updateArtistRequest } from "../../actions/artists/profile/updateArtistProfile"
import {
  emailValidate,
  stringValidate,
  selectinputValidate,
  postalCodeValidation,
} from "../../utils/Validations";
import { withRouter } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import Autocomplete from "react-google-autocomplete";
import { regions } from '../../utils/regions';
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

function Profilepage(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    fetchArtistProfile,
    user,
    error,
    loading,
    updateArtistProfile,
    history,
    message,
    ArtistDataById
  } = props;
  const [userdata, setUserData] = useState({
    profileName: "",
    instagramProfileLink: "",
    genres: "",
    rating: "",
    about: "",
    inspirations: "",
    numberOfShowsPerformed: "",
    yearsOfExperience: "",
    achievements: "",
    individualSkill: "",
    samplePlaylist: "",
    hasOwnSoundSystem: "",
    baseLocationUrl: "",
    maxServiceaablekm: "",
    published: "",
    gSTIN: "",
    operatingState: "",
    region: "",
    area: "",
    userName: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    secondaryPhone: "",
    googleMapLocation: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: "",
    addressName: "",
    priceSlabId: "",
    backgroundPhoto: "",
    backgroundPhotoName: ""
  });
  const [imageData, setImagedata] = useState({
    imageBase: "",
    imageError: "",
  });

  const [addressData, setAddressData] = useState({
    longitude: "",
    latitude: "",
    addressName: "",
    street: "",
    city: "",
    state: "",
    country: "",
  })

  const [errors, setErrors] = useState({
    profileName: null,
    instagramProfileLink: null,
    genres: null,
    rating: null,
    about: null,
    inspirations: null,
    numberOfShowsPerformed: null,
    yearsOfExperience: null,
    achievements: null,
    individualSkill: null,
    samplePlaylist: null,
    hasOwnSoundSystem: null,
    baseLocationUrl: null,
    maxServiceaablekm: null,
    published: null,
    gSTIN: null,
    operatingState: null,
    region: null,
    area: null,
    userName: null,
    password: null,
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    secondaryPhone: null,
    googleMapLocation: null,
    street: null,
    city: null,
    state: null,
    zipCode: null,
    country: null,
    latitude: null,
    longitude: null,
    addressName: null,
    priceSlabId: null,
    backgroundPhoto: null,

  });

  useEffect(() => {
    localStorage.setItem("View_Name", "profile");
    checkRoute();

  }, [user]);
  useEffect(() => { fetchArtistProfile() }, [])

  useEffect(() => {
    setUserData({
      ...userdata,
      profileName: ArtistDataById?.profileName,
      instagramProfileLink: ArtistDataById?.instagramProfileLink,
      genres: ArtistDataById?.genres,
      rating: ArtistDataById?.rating,
      about: ArtistDataById?.about,
      inspirations: ArtistDataById?.inspirations,
      numberOfShowsPerformed: ArtistDataById?.numberOfShowsPerformed,
      yearsOfExperience: ArtistDataById?.yearsOfExperience,
      achievements: ArtistDataById?.achievements,
      individualSkill: ArtistDataById?.individualSkill,
      samplePlaylist: ArtistDataById?.samplePlaylist,
      hasOwnSoundSystem: ArtistDataById?.hasOwnSoundSystem,
      baseLocationUrl: ArtistDataById?.baseLocationUrl,
      maxServiceaablekm: ArtistDataById?.maxServiceaablekm,
      published: ArtistDataById?.published,
      gSTIN: ArtistDataById?.gSTIN,
      operatingState: ArtistDataById?.operatingState,
      region: ArtistDataById?.region,
      area: ArtistDataById?.area,
      userName: ArtistDataById?.artistUser?.userName,
      password: ArtistDataById?.artistUser?.password,
      email: ArtistDataById?.artistUser?.email,
      firstName: ArtistDataById?.artistUser?.firstName,
      lastName: ArtistDataById?.artistUser?.lastName,
      phoneNumber: ArtistDataById?.artistUser?.phoneNumber,
      secondaryPhone: ArtistDataById?.artistUser?.secondaryPhone,
      googleMapLocation: ArtistDataById?.baseLocation?.googleMapLocation,
      street: ArtistDataById?.baseLocation?.street,
      city: ArtistDataById?.baseLocation?.city,
      state: ArtistDataById?.baseLocation?.state,
      zipCode: ArtistDataById?.baseLocation?.zipCode,
      country: ArtistDataById?.baseLocation?.country,
      latitude: ArtistDataById?.baseLocation?.latitude,
      longitude: ArtistDataById?.baseLocation?.longitude,
      addressName: ArtistDataById?.baseLocation?.addressName,
      priceSlabId: ArtistDataById?.priceSlab?.priceSlabId,
      backgroundPhoto: ArtistDataById?.backgroundPhotoPath,
      backgroundPhotoName: ArtistDataById?.backgroundPhoto
    })
    setAddressData({
      ...addressData,
      longitude: ArtistDataById?.baseLocation?.longitude,
      latitude: ArtistDataById?.baseLocation?.latitude,
      addressName: ArtistDataById?.baseLocation?.addressName,
      street: ArtistDataById?.baseLocation?.street,
      city: ArtistDataById?.baseLocation?.city,
      state: ArtistDataById?.baseLocation?.state,
      country: ArtistDataById?.baseLocation?.country,
    })

  }, [ArtistDataById])


  useEffect(() => {
    if (localStorage.getItem("message") === "Record Updated Successfully") {
      const message = "Artist updated successfully";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
    }
    if (message === "Record Updated Failed") {
      const message = "Failed To Update Profile";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });
    }
  }, [message, error]);

  //------------------------------ FETCHING USER PROFILE DATA FROM API ------------------------//

  // const getUserData = async () => {
  //   if (user.length === 0) {
  //     fetchUserProfile(localStorage.getItem("UserId"));
  //   }
  //   setUserData({
  //     ...userdata,
  //     userid: user.userId,
  //     firstname: user.firstName,
  //     lastname: user.lastName,
  //     email: user.emailAddress,
  //     title: user.displayName,
  //     address: user.address,
  //     city: user.city,
  //     zip: user.postalCode,
  //     state: user.state,
  //     country: user.country,
  //     description: user.description,
  //     userImage: user.imageUrl, //user.imageUrl
  //   });
  // };

  //------------------------------------- FUNCTION HANDLING USER PROFILE UPDATE FORM INPUT VALIDATION ---------------------------//
  const handleProfileValidation = () => {
    setErrors({
      ...errors,
      profileName: stringValidate(userdata.profileName, "ProfileName"),
      firstName: stringValidate(userdata.firstName, "FirstName"),
      lastName: stringValidate(userdata.lastName, "LastName"),
      about: stringValidate(userdata.about, "About"),
      genres: stringValidate(userdata.genres, "Genres"),
      inspirations: stringValidate(userdata.inspirations, "Inspirations"),
      yearsOfExperience: stringValidate(userdata.yearsOfExperience, "yearOfExperience"),
      individualSkill: stringValidate(userdata.individualSkill, "IndividualSkill"),
      achievements: stringValidate(userdata.achievements, "Achievements"),
      samplePlaylist: stringValidate(userdata.samplePlaylist, "Sample Playlist"),
      instagramProfileLink: stringValidate(userdata.instagramProfileLink, "Instagram Profile Link"),
      region: stringValidate(userdata.region, "Region"),
      hasOwnSoundSystem: stringValidate(userdata.hasOwnSoundSystem, "HasOwnSoundSystem"),
      backgroundPhoto: stringValidate(userdata.backgroundPhoto, "BackgroundPhoto"),
      street: stringValidate(addressData.street, "Street"),
      city: stringValidate(addressData.city, "City"),
      state: stringValidate(addressData.state, "State"),
      zipCode: postalCodeValidation(userdata.zipCode, "ZipCode"),
      country: stringValidate(addressData.country, "Country"),
      longitude: stringValidate(addressData.longitude, "Longitude"),


    });
  };

  //-------------------------------------------- USER UPDATE FUNCTION FIRST CHEKING VALIDATION AFTERWARDS FETCHING UPDATING DATA --------------------------//
  const handleUserUpdate = async (e) => {
    handleProfileValidation();

    if (
      stringValidate(userdata.profileName, "ProfileName") === null &&
      stringValidate(userdata.firstName, "firstName") === null &&
      stringValidate(userdata.lastName, "LastName") === null &&
      stringValidate(userdata.about, "About") === null &&
      stringValidate(userdata.genres, "Genres") === null &&
      stringValidate(userdata.inspirations, "Inspirations") === null &&
      stringValidate(userdata.yearsOfExperience, "yearOfExperience") === null &&
      stringValidate(userdata.individualSkill, "IndividualSkill") === null &&
      stringValidate(userdata.achievements, "Achievements") === null &&
      stringValidate(userdata.samplePlaylist, "Sample Playlist") === null &&
      stringValidate(userdata.instagramProfileLink, "Instagram Profile Link") === null &&
      stringValidate(userdata.region, "Region") === null &&
      stringValidate(userdata.hasOwnSoundSystem, "HasOwnSoundSystem") === null &&
      stringValidate(userdata.backgroundPhoto, "BackgroundPhoto") === null &&
      stringValidate(addressData.street, "Street") === null &&
      stringValidate(addressData.city, "City") === null &&
      stringValidate(addressData.state, "State") === null &&
      stringValidate(userdata.zipCode, "ZipCode") === null &&
      stringValidate(addressData.country, "Country") === null &&
      stringValidate(addressData.longitude, "longitude") === null

    ) {

      updateArtistProfile(userdata, addressData);
    } else {
      // console.log("Please fill all details");
    }
  };

  //--------------------------------- IMAGE UPLOAD FUNCTION --------------------------//
  const changeimage = (event) => {
    setUserData({
      ...userdata,
      uploadpreview: "",
    });
    const file = event.target.files[0];
    setUserData({
      ...userdata,
      uploadpreview: URL.createObjectURL(event.target.files[0]),
    });
    var reader = new FileReader();
    if (file.size > 2e6) {
      setImagedata({
        ...imageData,
        imageError: "File size can not be more than 2 MB",
        imageBase: "",
      });
    } else {
      reader.onload = (evt) => {
        setImagedata({
          ...imageData,
          imageError: "",
          imageBase: evt.target.result,
        });
      };
    }
    reader.readAsDataURL(file);
  };


  const onPlacesChanged = (place) => {
    const { geometry } = place;
    const formattedAddress = place.formatted_address;
    if (geometry && geometry.location) {
      const { lat, lng } = geometry.location;

      const addressParts = formattedAddress.split(', ');
      const street = addressParts[0]; // The street
      const city = addressParts[1]; // The city
      const state = addressParts[2]; // The state
      const country = addressParts[3]; // The country
      let pinCode = '';
      for (let i = addressParts.length - 1; i >= 0; i--) {
        if (/\d/.test(addressParts[i])) {
          pinCode = addressParts[i];
          break;
        }
      }

      setAddressData({
        ...addressData,
        latitude: lat(),
        longitude: lng(),
        addressName: formattedAddress,
        street: street,
        city: city,
        state: state,
        country: country,
      })
    }
  };
 
  return (
    <>
      {loading ? (
        "loading ...."
      ) : error ? (
        "oops....something goes wrong"
      ) : (
        <>
          <div className="container-fluid">
            {/* <!-- Page Heading --> */}
            <div className="page-breadcrumb">
              <div className="row">
                <div className="col-5 align-self-center">
                  <h4 className="page-title">Profile</h4>
                  <div className="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-1">
                        <li className="breadcrumb-item cursor">
                          <a
                            className="breadcrumb-link"
                            onClick={() => history.push("dashboard")}
                          >
                            Home
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Profile
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div className="col-7 align-self-center">
                  <div className="d-flex no-block justify-content-end align-items-center">
                    <div className="m-r-10">
                      <div className="lastmonth"></div>
                    </div>
                    {/* <div className="">
                      <small>LAST MONTH</small>
                      <h4 className="text-info m-b-0 font-medium">$58,256</h4>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body p-3 ">

                    <div className="row pt-3">
                      <div className="col-lg-4">
                        <div className="card border-0 ">
                          <div className="card-header bg-white text-center">
                            {
                              ArtistDataById?.artistPreferredSetupsList?.map((ite, intex) => (

                                <div>
                                  {/* <option value={ite?.setType?.setupType} selected={ite.isArtistDefaultSetup == "true" ? true : false}>{ite?.setType?.setupType}</option> */}
                                  <div className="mb-1 mx-auto">
                                    <h3>{ite?.setType?.setupType}</h3>
                                    <div className="image">
                                      {" "}
                                      {ite?.profilePhotoPath !== "" ? (
                                        <img
                                          src={ite?.profilePhotoPath}
                                          className="rounded-circle noimage"
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src="img/noimage.png"
                                          className="rounded-circle noimage"
                                          alt=""
                                        />
                                      )}
                                      {/* <div className=" mr-3  bottomright">
                                        <input
                                          type="file"
                                          id="actual-btn"
                                          hidden
                                          onChange={(e) => changeimage(e)}
                                        />
                                        <label htmlFor="actual-btn">
                                          <i className="bg-warning p-2 border-white rounded-circle fa fa-pencil-alt"></i>
                                        </label>
                                      </div> */}
                                    </div>
                                    {/* <h4 className="mb-0">
                                    {userdata.firstname} {userdata.lastname}
                                  </h4> */}
                                    <span className="text-muted d-block mb-2">
                                      {/* Project Manager */}
                                      {/* {userdata.title} */}
                                    </span>
                                    {/* <hr /> */}
                                    {/* <ul className="list-group list-group-flush text-left">
                                <li className="list-group-item ">
                                  {" "}
                                  <strong className="text-muted d-block mb-2">
                                    Description
                                  </strong>{" "}
                                  <span>{userdata.description}</span>{" "}
                                </li>
                              </ul> */}
                                  </div>
                                </div>
                              ))}
                            <br />
                            <div className="mb-1 mx-auto">
                              <h3>Cover Photo</h3>
                              <div className="image">
                                {" "}
                                {userdata?.backgroundPhoto !== "" ? (
                                  <img
                                    src={userdata?.backgroundPhoto}
                                    className=" "
                                    alt=""
                                    style={{ width: "180px" }}
                                  />
                                ) : (
                                  <img
                                    src="img/noimage.png"
                                    className=" noimage"
                                    alt=""
                                  />
                                )}

                              </div>


                            </div>
                          </div>
                        </div>
                        {/* <!-- End Default Light Table -->  */}
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow rounded mb-4 p-3">
                          <div className="card-header border-bottom">
                            <div className="row">
                              <div className="col">
                                {" "}
                                <h5 className="m-0">Account Details</h5>
                              </div>
                              {/* <div className="col text-right">
                                <a
                                  href={() => false}
                                  className="cursor"
                                  data-toggle="modal"
                                  data-target="#changepassword"
                                >
                                  Change Password
                                </a>
                              </div> */}
                            </div>
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feFirstName">
                                        Profile Name{" "}
                                        <span style={{ color: "#d20d0d" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feFirstName"
                                        placeholder="Profile Name"
                                        value={userdata.profileName}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            profileName: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            profileName: stringValidate(
                                              userdata.profileName,
                                              "ProfileName"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.profileName}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feLastName">
                                        First Name{" "}
                                        <span style={{ color: "#d20d0d" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feLastName"
                                        placeholder="First Name"
                                        value={userdata.firstName}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            firstName: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            firstName: stringValidate(
                                              userdata.firstName,
                                              "FirstName"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.firstName}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feLastName">
                                        Last Name{" "}
                                        <span style={{ color: "#d20d0d" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feLastName"
                                        placeholder="Last Name"
                                        value={userdata.lastName}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            lastName: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            lastName: stringValidate(
                                              userdata.lastName,
                                              "LastName"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.lastName}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feEmailAddress">
                                        Email{" "}
                                        <span style={{ color: "#d20d0d" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="feEmailAddress"
                                        placeholder="Email"
                                        value={userdata.email}
                                        readOnly
                                      // onChange={(e) =>
                                      //   setUserData({
                                      //     ...userdata,
                                      //     email: e.target.value,
                                      //   })
                                      // }
                                      // onBlur={() =>
                                      //   setErrors({
                                      //     ...errors,
                                      //     emailerror: emailValidate(
                                      //       userdata.email,
                                      //       "Email"
                                      //     ),
                                      //   })
                                      // }
                                      />
                                      {/* <p className="profile-input-errors">
                                        {errors.email}
                                      </p> */}
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="feInputAbout">
                                      About
                                    </label>
                                    <span style={{ color: "#d20d0d" }}>
                                      *
                                    </span>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="feAbout"
                                        placeholder="About"
                                        value={userdata.about}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            about: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            about: stringValidate(
                                              userdata.about,
                                              "About"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.about}
                                      </p>
                                    
                                  </div>

                                  <div className="form-row">

                                    <div className="form-group col-md-6">
                                      <label htmlFor="feGenres">
                                        Genres
                                      </label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="feGenres"
                                        placeholder="Genres"
                                        value={userdata.genres}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            genres: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            genres: stringValidate(
                                              userdata.genres,
                                              "Genres"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.genres}
                                      </p>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label htmlFor="feInspirations">
                                        Inspirations
                                      </label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="feInspirations"
                                        placeholder="Inspirations"
                                        value={userdata.inspirations}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            inspirations: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            inspirations: stringValidate(
                                              userdata.inspirations,
                                              "Inspirations"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.inspirations}
                                      </p>
                                    </div>
                                    
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feYearsOfExperience">
                                        Experience(In years)
                                      </label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feYearsOfExperience"
                                        placeholder="Years of Experience"
                                        value={userdata.yearsOfExperience}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            yearsOfExperience: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            yearsOfExperience: stringValidate(
                                              userdata.yearsOfExperience,
                                              "yearOfExperience"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.yearsOfExperience}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feIndividualSkill">
                                        Individual Skill
                                      </label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feIndividualSkill"
                                        placeholder="Individual Skill"
                                        value={userdata.individualSkill}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            individualSkill: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            individualSkill: stringValidate(
                                              userdata.individualSkill,
                                              "IndividualSkill"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.individualSkill}
                                      </p>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label htmlFor="feAchievements">
                                        Achievements
                                      </label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="feAchievements"
                                        placeholder="Achievements"
                                        value={userdata.achievements}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            achievements: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            achievements: stringValidate(
                                              userdata.achievements,
                                              "Achievements"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.achievements}
                                      </p>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label htmlFor="feSamplePlaylist">
                                        Sample Playlist 
                                      </label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="feSamplePlaylist"
                                        placeholder="Sample Playlist"
                                        value={userdata.samplePlaylist}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            samplePlaylist: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            samplePlaylist: stringValidate(
                                              userdata.samplePlaylist,
                                              "Sample Playlist"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.samplePlaylist}
                                      </p>
                                    </div>
                                    
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feInstagramProfileLink">
                                        Instagram Profile Link
                                      </label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feInstagramProfileLink"
                                        placeholder="Instagram Profile Link"
                                        value={userdata.instagramProfileLink}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            instagramProfileLink: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            instagramProfileLink: stringValidate(
                                              userdata.instagramProfileLink,
                                              "Instagram Profile Link"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.instagramProfileLink}
                                      </p>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label htmlFor="region">Region</label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <select
                                        id="region"
                                        className="form-control"
                                        value={userdata.region}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            region: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            region: stringValidate(userdata.region, "Region"),
                                          })
                                        }
                                      >
                                        {regions.map((option) => (
                                          <option 
                                            key={option.value} 
                                            value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                      <p className="profile-input-errors">
                                        {errors.region}
                                      </p>
                                    </div>


                                    <div className="form-group col-md-6">
                                      <label htmlFor="feHasOwnSoundSystem">Do you have your own sound system?
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      </label>
                                      
                                      <div className="flex justify-around">
                                        <span className="w-1/2 px-2">
                                          <input
                                            type="radio"
                                            name="ownSoundSystem"
                                            id="soundSystemYes"
                                            value="true" // Value represents the true condition
                                            checked={userdata.hasOwnSoundSystem === true}
                                            onChange={(e) => 
                                              setUserData({
                                                ...userdata,
                                                hasOwnSoundSystem: e.target.value === "true", 
                                              })
                                            }
                                          />
                                          <label className="px-2" htmlFor="soundSystemYes"> Yes</label>
                                        </span>
                                        <span className="w-1/2 px-2">
                                          <input
                                            type="radio"
                                            name="ownSoundSystem"
                                            id="soundSystemNo"
                                            value="false" // Value represents the false condition
                                            checked={userdata.hasOwnSoundSystem === false}
                                            onChange={(e) => 
                                              setUserData({
                                                ...userdata,
                                                hasOwnSoundSystem: e.target.value === "true",
                                              })
                                            }
                                          />
                                          <label className="px-2" htmlFor="soundSystemNo">No</label>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label htmlFor="fePassword">Cover Photo</label>
                                      <span style={{ color: "#d20d0d" }}>
                                        *
                                      </span>
                                      <input
                                        type="file"
                                        className="form-control"
                                        id="title"
                                        placeholder="Background Photo"
                                        // value={userdata.backgroundPhoto}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            backgroundPhoto: e.target.files[0],
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            backgroundPhoto: stringValidate(userdata.backgroundPhoto, "BackgroundPhoto")
                                          })
                                        }
                                      />
                                      <p>Image in use : <span style={{ color: "green" }}>{userdata.backgroundPhotoName}</span></p>

                                      <p className="profile-input-errors">
                                        {errors.backgroundPhoto}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="feInputAddress">
                                      Address
                                    </label>
                                    <Autocomplete
                                      apiKey={apiKey}
                                      style={{ width: "100%", height: "38px", borderRadius: "5px" }}
                                      onPlaceSelected={(place) => {
                                        onPlacesChanged(place);
                                      }}
                                      options={{
                                        types: ["(regions)"],
                                        componentRestrictions: { country: "IND" },
                                      }}
                                      defaultValue={userdata?.addressName}
                                    />
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      id="feInputAddress"
                                      placeholder="Address"
                                      value={userdata.address}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userdata,
                                          address: e.target.value,
                                        })
                                      }
                                      onBlur={() =>
                                        setErrors({
                                          ...errors,
                                          addresserror: selectinputValidate(
                                            userdata.address,
                                            "Address"
                                          ),
                                        })
                                      }
                                    /> */}
                                    <p className="profile-input-errors">
                                      {errors.longitude}
                                    </p>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label>Street</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Street"
                                        id=""
                                        value={addressData.street}
                                        onChange={(e) =>
                                          setAddressData({
                                            ...addressData,
                                            street: e.target.value,
                                          })
                                        }
                                      // onBlur={() =>
                                      //   setErrors({
                                      //     ...errors,
                                      //     stateerror:  stringValidate(userdata.city, "State")
                                      //   })
                                      // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.street}
                                      </p>
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label>City </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        id=""
                                        value={addressData.city}
                                        onChange={(e) =>
                                          setAddressData({
                                            ...addressData,
                                            city: e.target.value,
                                          })
                                        }
                                      // onBlur={() =>
                                      //   setErrors({
                                      //     ...errors,
                                      //     stateerror:  stringValidate(userdata.city, "State")
                                      //   })
                                      // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.city}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label>State </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="State"
                                        id=""
                                        value={addressData.state}
                                        onChange={(e) =>
                                          setAddressData({
                                            ...addressData,
                                            state: e.target.value,
                                          })
                                        }
                                      // onBlur={() =>
                                      //   setErrors({
                                      //     ...errors,
                                      //     stateerror:  stringValidate(userdata.state, "State")
                                      //   })
                                      // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.state}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="inputZip">ZipCode </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="inputZip"
                                        placeholder="Zip"
                                        value={userdata.zipCode}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            zipCode: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            zipCode: postalCodeValidation(userdata.zipCode, "ZipCode")
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.zip}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label>Country </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Country"
                                        id=""
                                        value={addressData.country}
                                        onChange={(e) =>
                                          setAddressData({
                                            ...addressData,
                                            country: e.target.value,
                                          })
                                        }
                                      // onBlur={() =>
                                      //   setErrors({
                                      //     ...errors,
                                      //     countryerror:  stringValidate(userdata.country, "Country")
                                      //   })
                                      // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.country}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="text-center">
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={(e) => handleUserUpdate(e)}
                                    >
                                      Update Account
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.container-fluid -->  */}
          {/* modal to change password of user */}
          {/* <ChangePassword email={userdata.email} /> */}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state, ownprops) => {

  return {
    ArtistDataById: state.getArtistById.data,
    loading: state.getArtistById.loading,
    error: state.getArtistById.error,
    message: state.updateArtistProfileById.message,
    error: state.updateArtistProfileById.error,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArtistProfile: async () => dispatch(fetchArtistById()),
    updateArtistProfile: async (userdata, addressData) =>
      dispatch(updateArtist(userdata, addressData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profilepage));
