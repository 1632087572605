import {
    UPDATE_PRICE_SLAB_BY_ID_REQUEST,
    UPDATE_PRICE_SLAB_BY_ID_SUCCESS,
    UPDATE_PRICE_SLAB_BY_ID_FAILURE,
} from "../types";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllPriceSlab } from "./getAllPriceSlab"

export const updatePriceSlabRequest = () => {
    return {
        type: UPDATE_PRICE_SLAB_BY_ID_REQUEST,
    };
};

export const updatePriceSlabSuccess = (Data) => {
    return {
        type: UPDATE_PRICE_SLAB_BY_ID_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updatePriceSlabFailure = (Error) => {
    return {
        type: UPDATE_PRICE_SLAB_BY_ID_FAILURE,
        payload: { message: " updation failed", error: Error },
    };
};

export const updatePriceSlab = (data, charges) => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updatePriceSlabRequest());
        axios
            .put(url + `replaceChargeByPriceSlabId/${data.id}`, {
                priceSlabName: data.priceSlabName,
                charges: charges
            }, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "RecordUpdatedSuccessfully");
                    await dispatch(updatePriceSlabSuccess(response.data));
                    await dispatch(fetchAllPriceSlab());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "RecordUpdatedFailed");
                dispatch(updatePriceSlabFailure(error));
            });
    };
};
