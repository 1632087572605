import {
    UPDATE_BOOKING_STATE_BY_ID_REQUEST,
    UPDATE_BOOKING_STATE_BY_ID_SUCCESS,
    UPDATE_BOOKING_STATE_BY_ID_FAILURE
} from "../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
};
export const updateBookingStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BOOKING_STATE_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                message: "",
            };
        case UPDATE_BOOKING_STATE_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "Booking State Update Successfully !!",
            };
        case UPDATE_BOOKING_STATE_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "failed",
            };
        default:
            return state;
    }
};
