import {
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_FAILURE,
} from "../types";
import axios from "axios";

export const fetchRegisterRequest = () => {
  return {
    type: POST_REGISTER_REQUEST,
  };
};

export const fetchRegisterSuccess = (Data) => {
  return {
    type: POST_REGISTER_SUCCESS,
    payload: Data,
  };
};

export const fetchRegisterFailure = (Error) => {
  return {
    type: POST_REGISTER_FAILURE,
    payload: Error,
  };
};

const headers = {
  "Content-Type": "application/json",
};

export const registerUser = (data) => {
  return (dispatch) => {
    dispatch(fetchRegisterRequest);
    // axios
    //   .post(
    //     process.env.REACT_APP_API_KEY + "/Account/CreateUser",
    //     {
    //       // userId: 0,
    //       firstName: data.firstName,
    //       lastName: data.lastName,
    //       emailAddress: data.email,
    //       mobileNumber: "",
    //       address: "",
    //       postalCode: "",
    //       cityId: 0,
    //       stateId: 0,
    //       countryId: 0,
    //       roleId: 0,
    //       password: data.password,
    //       isActive: true,
    //       isDeleted: false,
         
    //     },
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then(function (response) {
        dispatch(fetchRegisterSuccess("Register"));
        localStorage.setItem("signup", "success");
      // })
      // .catch(function (error) {
      //   dispatch(fetchRegisterFailure(error));
      //   localStorage.setItem("signup", "failed");
      // });
  };
};
