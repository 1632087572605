import {
    FETCH_SURGE_PRICE_BY_ID_REQUEST,
    FETCH_SURGE_PRICE_BY_ID_SUCCESS,
    FETCH_SURGE_PRICE_BY_ID_FAILURE
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllSurgePriceByIdRequest = () => {
    return {
        type: FETCH_SURGE_PRICE_BY_ID_REQUEST,
    };
};
export const fetchAllSurgePriceByIdSuccess = (Data) => {
    return {
        type: FETCH_SURGE_PRICE_BY_ID_SUCCESS,
        payload: Data,
    };
}

export const fetchAllSurgePriceByIdFailure = (Error) => {
    return {
        type: FETCH_SURGE_PRICE_BY_ID_FAILURE,
        payload: Error,
    };
};

export const fetchAllSurgePriceById = (id) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };

    return (dispatch) => {

        dispatch(fetchAllSurgePriceByIdRequest);
        axios
            .get(url + `getSurgePriceById/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllSurgePriceByIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllSurgePriceByIdFailure(error));
            });

    };
};