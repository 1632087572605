
import React from 'react'
import SurgePrice from '../component/surgePrice/getAllSurgePrice'
function SurgePricePage() {
    return (
        <div>
            <SurgePrice/>
        </div>
    )
}

export default SurgePricePage
