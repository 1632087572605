import {
  FETCH_ALL_BOOKING_REQUEST,
  FETCH_ALL_BOOKING_SUCCESS,
  FETCH_ALL_BOOKING_FAILURE,
} from "../../actions/types";

const initialState = {
  loading: false,
  data: [],
  error: "",
  message: "",
};
export const getAllBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case FETCH_ALL_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
        message: "Created Successfully !!",
      };
    case FETCH_ALL_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
        message: "failed",
      };
    default:
      return state;
  }
};
