import $ from "jquery";
//---- FUNCTION TO CHECK CURRENT ROUTE AND LOGIN CONDITIONS CHECK ----//
export const checkRoute = () => {
  const loggeIn = localStorage.getItem("loggedIn");
  const timeout = localStorage.getItem("timeout");
  const remember_me = localStorage.getItem("Remember_be");

  const knowroute = localStorage.getItem("View_Name");
  $("#" + `${knowroute}`, function () {
    $("#" + `${knowroute}`).addClass("active");
  });

  if (timeout === "false" || timeout === null) {
    if (loggeIn === "false" || loggeIn === null) {
      localStorage.clear();
      return window.location.replace("/login");
    }
  }
  if (timeout === "true") {
    if (remember_me === "false" || remember_me == null) {
      localStorage.clear();
      return window.location.replace("/login");
    }
  }
};
