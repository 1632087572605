import {
  GET_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_FAILURE,
} from "../types.js";
import axios from "axios";
import { fetchLogout } from "../authAction";

const getUserRequest = () => {
  return { type: GET_USER_REQUEST };
};

const getUserSuccess = (response) => {
  return { type: GET_USER_SUCCESS, payload: response.data };
};

const getUserFailure = (error) => {
  return { type: GET_USER_FAILURE, payload: error };
};

export const getUser = (userid) => {
  var baseUrl = "https://fracspace.webbulldesign.com/";
  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${localStorage.getItem("Auth_token")}`,
  };

  return (dispatch) => {
    dispatch(getUserRequest());

    axios
      .get(baseUrl + `getuserbyId/${userid}`, {
        headers: headers,
      })
      .then(function (response) {
        dispatch(getUserSuccess(response));
      })
      .catch(function (error) {
        if (error.message === "Request failed with status code 401") {
          dispatch(fetchLogout());
        }
        dispatch(getUserFailure(error));
      });
  };
};
