import {
  DELETE_CUSTOMER_BY_ID_REQUEST,
  DELETE_CUSTOMER_BY_ID_SUCCESS,
  DELETE_CUSTOMER_BY_ID_FAILURE,
} from "../types";

import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllCustomer } from "./getAllCustomer"
export const deleteCustomerRequest = () => {
  return {
    type: DELETE_CUSTOMER_BY_ID_REQUEST,
  };
};

export const deleteCustomerSuccess = (Data) => {
  return {
    type: DELETE_CUSTOMER_BY_ID_SUCCESS,
    payload: Data,
  };
};

export const deleteCustomerFailure = (Error) => {
  return {
    type: DELETE_CUSTOMER_BY_ID_FAILURE,
    payload: Error,
  };
};

export const deleteCustomer = (customerId) => {

  const headers = {
    "Content-Type": "application/json",
    'Authorization': basicAuth,
  };
  return (dispatch) => {
    dispatch(deleteCustomerRequest());
    axios
      .delete(url + `deleteCustomerUserById/${customerId}`, {
        headers: headers,
      })
      .then(async (response) => {
        await dispatch(deleteCustomerSuccess(response.data));
        localStorage.setItem("message", "CustomerDeleteSuccess");
        dispatch(fetchAllCustomer())
      })
      .catch((error) => {
        dispatch(deleteCustomerFailure(error));
        localStorage.setItem("message", "CustomerDeleteFailed");

      });
  };
};
