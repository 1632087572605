
import {
    FETCH_AGENT_BY_ID_REQUEST,
    FETCH_AGENT_BY_ID_SUCCESS,
    FETCH_AGENT_BY_ID_FAILURE,
} from "../types.js";
import axios from "axios";

import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
const fetchAgentByIdRequest = () => {
    return { type: FETCH_AGENT_BY_ID_REQUEST };
};

const fetchAgentByIdSuccess = (Data) => {
    return { type: FETCH_AGENT_BY_ID_SUCCESS, payload: Data };
};

const fetchAgentByIdFailure = (error) => {
    return { type: FETCH_AGENT_BY_ID_FAILURE, payload: error };
};

export const fetchAgentById = (id) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(fetchAgentByIdRequest());
        axios
            .get(url + `getAgentUserById/${id}`, {
                headers: headers,
            })

            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "successfatched");
                    await dispatch(fetchAgentByIdSuccess(response.data));
                }
            })
            .catch(function (error) {
                localStorage.setItem("message", "fatchfailed");

                if (error.message === "Request failed with status code 401") {
                }
                dispatch(fetchAgentByIdFailure(error));
            });
    };
};
