import {
    ADD_ARTIST_VIDEOS_REQUEST,
    ADD_ARTIST_VIDEOS_SUCCESS,
    ADD_ARTIST_VIDEOS_FAILURE,
} from "../../types";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
import api from "axios";
// import { getAllArtistVideo } from "./getAllArtistVideos"
// import { api } from '../../utils/apiUtils';

const PART_SIZE = 5 * 1024 * 1024; // 5MB part size
const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 1GB in bytes

export const addArtistVideoRequest = () => ({ type: ADD_ARTIST_VIDEOS_REQUEST });
export const addArtistVideoSuccess = (message) => ({
    type: ADD_ARTIST_VIDEOS_SUCCESS,
    payload: message,
});
export const addArtistVideoFailure = (Error) => ({
    type: ADD_ARTIST_VIDEOS_FAILURE,
    payload: Error,
});


// TODO size limit check, retry logic
export const addArtistVideo = (data) => {
    return async (dispatch) => {
        dispatch(addArtistVideoRequest());
        const artistId = localStorage.getItem("artistId");


        try {

            if (data.videoFile.size > MAX_VIDEO_SIZE) {
                throw new Error(`Upload a video less than ${MAX_VIDEO_SIZE / 1024 / 1024} MB`);
            }

            const validVideoTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/quicktime'];
            if (!validVideoTypes.includes(data.videoFile.type)) {
                throw new Error('Invalid file type. Please upload a valid video file.');
            }

            // Initiate multipart upload
            const initResponse = await api.post(
                `${url}initiateMultipartUpload?fileSize=${data.videoFile.size}&artistId=${artistId}&contentType=${data.videoFile.type}`,
                {},
                {
                    headers: {
                        'Authorization': basicAuth,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const { uploadId, videoKey } = initResponse.data;

            // Prepare parts
            const file = data.videoFile;
            const parts = [];
            for (let i = 0; i < file.size; i += PART_SIZE) {
                parts.push(file.slice(i, Math.min(i + PART_SIZE, file.size)));
            }

            // Upload parts
            let uploadedBytes = 0;
            const totalBytes = file.size;
            const uploadedParts = await Promise.all(parts.map(async (part, index) => {
                const partNumber = index + 1;
                const urlResponse = await api.post(
                    `${url}getUploadPartUrl?uploadId=${uploadId}&videoKey=${videoKey}&partNumber=${partNumber}`,
                    {},
                    { headers: { 'Authorization': basicAuth } }
                );
                const { presignedUrl } = urlResponse.data;

                const uploadResponse = await api.put(presignedUrl, part, {
                    headers: { 'Content-Type': file.type },
                    onUploadProgress: (progressEvent) => {
                        const partProgress = progressEvent.loaded;
                        uploadedBytes = Math.min(uploadedBytes + partProgress, totalBytes);
                        const progress = Math.min(Math.round((uploadedBytes * 100) / totalBytes), 100);
                        let newProgress = progress * 0.9
                    },
                });

                return {
                    PartNumber: partNumber,
                    ETag: uploadResponse.headers.etag
                };
            }));
            // Complete multipart upload
            await api.post(url + 'completeMultipartUpload', {
                videoKey,
                uploadId,
                partETags: uploadedParts
            }, { headers: { 'Authorization': basicAuth } });

            // Save metadata
            const formData = new FormData();
            formData.append('data', JSON.stringify({
                videoLink: data.videoLink,
                videoDesc: data.videoDesc,
                videoType: data.videoType,
                video: data.videoFile.name,
                videoKey: videoKey,
                artist: { artistId: artistId },
            }));
            const metadataResponse = await api.post(url + 'addArtistVideoMetadata', formData, {
                headers: {
                    'Authorization': basicAuth,
                    'Content-Type': 'multipart/form-data' // why is multipart needed
                }
            });
            if (metadataResponse.status === 200) {
                dispatch(addArtistVideoSuccess("Video uploaded successfully."));
                // dispatch(getAllArtistVideo(artistId));
            }
            // TODO else case
        } catch (error) {
            console.error('Upload error:', error);
            dispatch(addArtistVideoFailure(error.message));
        }
    };
};
