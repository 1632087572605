import { useEffect, useState } from "react";
import { checkRoute } from "../../utils/checkroute";
import { useHistory } from "react-router-dom";
import Logo1 from "../../styles/img/FRAC/logo.png";
import Logo from "../../styles/img/TheLiveShowImages/Logos/Black_Logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Sidebar(props) {
  const { handleLogin, loggedIn, error, refreshForm } = props;
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const history = useHistory();
  const handleRoute = (viewname) => {
    localStorage.setItem("View_Name", viewname);
    checkRoute();
    history.push(`/${viewname}`);
  };
  const SuperAdmin = localStorage.getItem("Super_Admin");

  useEffect(() => {
    // const SuperAdmin = localStorage.getItem("Super_Admin")
    if (SuperAdmin == "true") {

      setIsSuperAdmin(true);
    }
    else {

      setIsSuperAdmin(false)
    }
  }, [SuperAdmin]);

  //   $("#sidebarToggle, #sidebarToggleTop", function () {
  //   if($("body").hasClass("sidebar-toggled")){
  //     $(".sidebar").addClass('toggled')
  //   }
  //   else{
  //     $(".sidebar").addClass('toggled')
  //   }

  //  });
  // console.log("")

  return (
    <>
      {/* <!-- Sidebar --> */}
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion "
        id="accordionSidebar"
      >
        <a
          className="sidebar-brand cursor"
          onClick={() => history.push("/Booking_Request")}
          href={() => false}
        >
          <div className="sidebar-brand-icon cursor">
            <img src={Logo} alt="" width={"40px"} />
          </div>
        </a>
        {/* <!-- Nav Item - Dashboard --> */}


        <p className="text-left ml-1" style={{ fontWeight: "700" }}>Booking</p>
        <li
          className="nav-item "
          id="Booking_Request"
          onClick={() => handleRoute("Booking_Request")}
        >
          {" "}
          <a
            className="nav-link cursor"
            href={() => false}
          // onClick={() => }
          >
            {" "}
            <i className="far fa-bookmark"></i> <span>Booking Requests</span>
          </a>
        </li>
        <li
          className="nav-item "
          id="Open_Booking"
          onClick={() => handleRoute("Open_Booking")}
        >
          {" "}
          <a className="nav-link cursor" href={() => false}>
            <i className="fa fa-folder-open-o"></i> <span>Open</span>
          </a>
        </li>

        <li
          className="nav-item "
          id="All_Bookings"
          onClick={() => handleRoute("All_Bookings")}
        >
          {" "}
          <a className="nav-link cursor" href={() => false}>
            <i className="far fa-bookmark"></i> <span>All Bookings</span>
          </a>
        </li>

        <hr />
        <p className="text-left ml-1" style={{ fontWeight: "700" }}>User Management</p>
        <li
          className="nav-item cursor"
          id="Customer_Management"
          onClick={() => handleRoute("Customer_Management")}
        >
          {" "}
          <a className="nav-link">
            <i className="fa fa-users"></i> <span>Customer</span>
          </a>{" "}
        </li>

        <li
          className="nav-item "
          id="Artists"
          onClick={() => handleRoute("Artists")}
        >
          {" "}
          <a className="nav-link cursor" href={() => false}>
            <i className="fas fa-guitar"></i>{" "}
            <span>Artist</span>
          </a>
        </li>
        <li
          className="nav-item"
          id="Sound_Venders"
          onClick={() => handleRoute("Sound_Venders")}
        >
          {" "}
          <a className="nav-link cursor" href={() => false}>
            {" "}

            <i className="fas fa-music"></i>{" "}
            <span>Sound Vendor</span>
          </a>{" "}
        </li>
        <hr />
        <p className="text-left ml-1" style={{ fontWeight: "1000" }}>
          <li
            className="nav-item"
            id="soundSystem"
            onClick={() => handleRoute("soundSystem")}
            style={{ textAlign: "left" }}
          >
            {" "}
            <a className="nav-link  cursor" href={() => false}>
              {" "}

              {/* <i className="fas fa-volume-up"></i>{" "} */}
              <span className="">Sound System</span>
            </a>{" "}
          </li>
        </p>

        <p className="text-left ml-1" style={{ fontWeight: "700" }}>Pricing</p>
        <li
          className="nav-item"
          id="surge_price"
          onClick={() => handleRoute("surge_price")}
        >
          {" "}
          <a className="nav-link cursor" href={() => false}>
            {" "}

            <i className="fa fa-money"></i>{" "}
            <span>Surge</span>
          </a>{" "}
        </li>
        <li
          className="nav-item"
          id="priceSlab"
          onClick={() => handleRoute("priceSlab")}
        >
          {" "}
          <a className="nav-link cursor" href={() => false}>
            {" "}

            <i className="fa fa-money"></i>{" "}
            <span>Price Slab</span>
          </a>{" "}
        </li>
        {/* <hr /> */}
        <p className="text-left ml-1" style={{ fontWeight: "1000" }}>
          <li
            className="nav-item cursor"
            id="website_banner"
            onClick={() => handleRoute("website_banner")}
          >
            {" "}
            <a className="nav-link">
              {/* <i className="fa fa-users"></i>  */}
              <span>Website Banner </span>
            </a>{" "}
          </li>
        </p>
        {/* <hr /> */}
        {isSuperAdmin ? (
          <>
            <p className="text-left ml-1" style={{ fontWeight: "1000" }}>
              <li
                className="nav-item cursor"
                id="discount"
                onClick={() => handleRoute("discount")}
              >
                {" "}
                <a className="nav-link">
                  {/* <i className="fa fa-users"></i>  */}
                  <span>Discount </span>
                </a>{" "}
              </li>
            </p>
          </>
        ) : ""}

        <p className="text-left ml-1" style={{ fontWeight: "1000" }}>
          <li
            className="nav-item cursor"
          // id="Agents"
          // onClick={() => handleRoute("Agents")}
          >
            {" "}
            <a className="nav-link">
              {/* <i className="fa fa-users"></i>  */}
              <span>Review and Rating  Vs Likes</span>
            </a>{" "}
          </li>
        </p>
        <p className="text-left ml-1" style={{ fontWeight: "1000" }}>
          <li
            className="nav-item cursor"
          // id="Agents"
          // onClick={() => handleRoute("Agents")}
          >
            {" "}
            <a className="nav-link">
              {/* <i className="fa fa-users"></i>  */}
              <span>Blogs and Pages</span>
            </a>{" "}
          </li>
        </p>

        <hr />
        <p className="text-left ml-1" style={{ fontWeight: "700" }}>Analytics</p>
        <li
          className="nav-item"
          id="Metrics"
          onClick={() => handleRoute("Metrics")}
        >
          {" "}
          <a className="nav-link cursor" href={() => false}>
            {" "}

            <i className="fa fa-dashboard"></i>{" "}
            <span>Metrics</span>
          </a>{" "}
        </li>
        {isSuperAdmin ? (
          <>
            <hr />
            <p className="text-left ml-1" style={{ fontWeight: "700" }}>Administration</p>
            {/* <p className="text-left ml-1" style={{ fontWeight: "1000" }}> */}
            <li
              className="nav-item cursor"
              id="Agents"
              onClick={() => handleRoute("Agents")}
            >
              {" "}
              <a className="nav-link">
                <i className="fa fa-users"></i>
                <span>Agents</span>
              </a>{" "}
            </li>
            {/* </p> */}
            <li
              className="nav-item cursor"
              id="Trigger"
            onClick={() => handleRoute("Trigger")}
            >
              {" "}
              <a className="nav-link">
                <i className="fa fa-repeat"></i>
                <span>Trigger</span>
              </a>{" "}
            </li>
            <li
              className="nav-item cursor"
              id="bulk_uploader_and_downloader"
            // onClick={() => handleRoute("bulk_uploader_and_downloader")}
            >
              {" "}
              <a className="nav-link">
                <i className="fa fa-upload"></i>
                <span>Bulk uploader and downloader</span>
              </a>{" "}
            </li>
          </>
        ) : ""}

        <li className="nav-item">
          <a
            // className="dropdown-item cursor"
            className="nav-link"
            href={() => false}
            data-toggle="modal"
            data-target="#logoutModal"
          >
            {" "}
            <i className="fas fa-sign-out-alt fa-sm fa-fw "></i>{" "}
            <span>Logout</span>
            {/* Logout{" "} */}
          </a>{" "}
        </li>
      </ul>
      {/* <!-- End of Sidebar -->  */}
    </>
  );
}
