import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import { fetchUserList } from "../../actions/User action/fetchuserList";
import ReactTooltip from "react-tooltip";
import { getUser } from "../../actions/User action/GetUser";
import EditUser from "./EditUser";

function Users(props) {
  const matches = useMediaQuery("(max-width:400px)");
  const history = useHistory();
  const [paginationvalues, setPaginationValues] = useState({
    pagenumber: 1,
    pagesize: 10,
    activepage: "",
    totalrecords: 0,
    totalpages: 0,
    filtercol: null,
    filtervalue: null,
    CurrentRecord: 0,
    currentData: "",
  });

  const CurrentUser = localStorage.getItem("UserId");

  const [UserId, setUserId] = useState("");

  //------------------------------ SHOWING PAGE SEARCH AND ORDER -------------------//
  const [showingpage, setShowingpage] = useState({
    from: 1,
    to: 10,
    sortingorder: "desc",
    search: null,
    columnname: "",
    contactdropdown: false,
  });

  const {
    fetchUserListData,
    loading,
    user,
    error,
    paginationcurrentRecords,
    fetchUserdata,
    Userdetails,
  } = props;

  useEffect(() => {
    localStorage.setItem("View_Name", "user");
    checkRoute();
    fetchUserList();
    // fetchColumnFilterData1();
  }, [user]);

  const fetchUserList = () => {
    let column = null;
    let order = null;
    let filterdata = JSON.parse(localStorage.getItem("UserFilter"));

    if (user.length === 0) {
      fetchUserListData(
        paginationvalues.pagenumber,
        paginationvalues.pagesize,
        column,
        order,
        showingpage.search
      );
    }
    // if (user !== undefined && user.length !== 0) {
    let currentRecordsString = paginationcurrentRecords(
      user.pageSize,
      paginationvalues.pagenumber,
      user.total,
      user.total_Pages
    );
    setPaginationValues({
      ...paginationvalues,
      pagesize: user.pageSize,
      activepage: user.pagenumber,
      totalrecords: user.total,
      totalpages: user.total_Pages,
      currentData: currentRecordsString,
    });
    // }
  };

  useEffect(() => {
    let currentRecordsString = "";
    currentRecordsString = paginationcurrentRecords(
      user.pageSize,
      user.pageNumber,
      user.total,
      user.total_Pages
    );
    setPaginationValues({
      ...paginationvalues,
      pagenumber: user.pageNumber,
      pagesize: user.pageSize,
      totalpages: user.total_Pages,
      totalrecords: user.total === undefined ? 0 : user.total,
      currentData: currentRecordsString,
    });
  }, [user]);

  const handleUserSearch = (e) => {
    let order = showingpage.sortingorder;
    let column = showingpage.columnname !== "" ? showingpage.columnname : null;
    let search = showingpage.search !== "" ? showingpage.search : null;
    let filtercol =
      paginationvalues.filtercol !== "" ? paginationvalues.filtercol : null;
    let filterval =
      paginationvalues.filtervalue !== "" ? paginationvalues.filtervalue : null;

    let pagesize =
      paginationvalues.pagesize > 9 ? paginationvalues.pagesize : 10;
    if (e === "search") {
      fetchUserListData(
        paginationvalues.pagenumber,
        pagesize,
        column,
        order,
        null,
        filtercol,
        filterval
      );
    }
    if (e === "button") {
      fetchUserListData(
        paginationvalues.pagenumber,
        pagesize,
        column,
        order,
        search,
        filtercol,
        filterval
      );
    }
  };

  const setColumnRunSorting = (name) => {
    setShowingpage({ ...showingpage, columnname: name });

    sortingOnColumn(name);
  };

  const sortingOnColumn = (columnname) => {
    let order = showingpage.sortingorder === "desc" ? "asc" : "desc";
    let key = showingpage.search !== "" ? showingpage.search : null;
    let filtercol =
      paginationvalues.filtercol !== "" ? paginationvalues.filtercol : null;
    let filterval =
      paginationvalues.filtervalue !== "" ? paginationvalues.filtervalue : null;

    if (
      showingpage.sortingorder === "" ||
      showingpage.sortingorder === "desc"
    ) {
      setShowingpage({ ...showingpage, sortingorder: "asc" });
    } else if (showingpage.sortingorder === "asc") {
      setShowingpage({ ...showingpage, sortingorder: "desc" });
    }

    fetchUserListData(
      paginationvalues.pagenumber,
      paginationvalues.pagesize,
      columnname,
      order,
      key,
      filtercol,
      filterval
    );
  };

  const handleUserTablePagination = (e) => {
    let order = showingpage.sortingorder;
    let column = showingpage.columnname !== "" ? showingpage.columnname : null;
    let key = showingpage.search !== "" ? showingpage.search : null;
    setPaginationValues({ ...paginationvalues, pagenumber: e });

    fetchUserListData(
      e,
      paginationvalues.pagesize,
      column,
      order,
      key,
      paginationvalues.filtercol,
      paginationvalues.filtervalue
    );

    showRange(e);
  };

  const showRange = (e) => {
    let pagesize = paginationvalues.pagesize;
    let total = paginationvalues.totalrecords;
    let active = paginationvalues.pagenumber;
    let range = Math.floor(total / pagesize);

    if (e > active) {
      if (e > range) {
        let dist = (e - active) * pagesize;
        setShowingpage({
          ...showingpage,
          to: total,
          from: showingpage.from + dist,
        });
      } else {
        let dist = (e - active) * pagesize;
        setShowingpage({
          ...showingpage,
          to: showingpage.to + dist,
          from: showingpage.from + dist,
        });
      }
    } else if (e < active) {
      // going back from last page ----------//
      if (showingpage.to === total) {
        let remianingitem =
          showingpage.to === total ? total - range * pagesize : 0;
        let dist = (active - e) * pagesize;
        let dist1 = showingpage.to - remianingitem;
        let dist2 =
          remianingitem === 0
            ? (active - e) * pagesize
            : (active - 1 - e) * pagesize;
        setShowingpage({
          ...showingpage,
          to: dist1 - dist2,
          from: showingpage.from - dist,
        });
      } else {
        // going backward from any further index -----//
        let dist = (active - e) * pagesize;
        setShowingpage({
          ...showingpage,
          to: showingpage.to - dist,
          from: showingpage.from - dist,
        });
      }
    }
  };

  const handleCallback = (filtercol, filterval) => {
    setPaginationValues({
      ...paginationvalues,
      filtercol: filtercol,
      filtervalue: filterval,
    });
  };
  return !loading ? (
    error ? (
      <div className="cover-spin"></div>
    ) : (
      <>
        <div className="container-fluid">
          {/* <!-- Page Heading --> */}
          <div className="page-breadcrumb">
            <div className="row">
              <div className="col-5 align-self-center">
                <h4 className="page-title">Users</h4>
                <div className="d-flex align-items-center">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-1">
                      <li className="breadcrumb-item cursor">
                        <a
                          className="breadcrumb-link"
                          onClick={() => history.push("Booking_Request")}
                          href={() => false}
                        >
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Users
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-7 align-self-center">
                <div className="d-flex no-block justify-content-end align-items-center">
                  <div className="m-r-10">
                    <div className="lastmonth"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr className="cursor">
                          <th
                          // onClick={() => setColumnRunSorting("displayName")}
                          >
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Image</div>
                            </div>
                          </th>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Name</div>
                            </div>
                          </th>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Email</div>
                            </div>
                          </th>

                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Phone</div>
                            </div>
                          </th>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Aadhaar_No</div>
                            </div>
                          </th>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Pan Number</div>
                            </div>
                          </th>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Last Paymentdate</div>
                            </div>
                          </th>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Next Paymentdate</div>
                            </div>
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user !== undefined &&
                          user !== null &&
                          user.length !== 0 ? (
                          user.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {item.userimagePath !== "" ? (
                                    <img
                                      src={
                                        // process.env.REACT_APP_API_KEY_LAYOUT +
                                        item.userimagePath
                                      }
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="img/noimage.png"
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  )}
                                </td>
                                <td>
                                  <a
                                    className="breadcrumb-link"
                                    href={() => false}
                                    onClick={() => {
                                      history.push({
                                        pathname: "/Userdetails",
                                      });
                                      localStorage.setItem("UserId", item.userId);
                                    }}
                                  >
                                    {item.name}
                                  </a>
                                </td>
                                <td>
                                  {item.email ? item.email : "Not available"}
                                </td>
                                <td>
                                  {item.phone ? item.phone : "Not available"}
                                </td>

                                <td>
                                  {item.aadhaarno
                                    ? item.aadhaarno
                                    : "Not available"}
                                </td>
                                <td>
                                  {item.panNumber
                                    ? item.panNumber
                                    : "Not available"}
                                </td>

                                <td>
                                  {item.lastPaymentdate
                                    ? item.lastPaymentdate
                                    : "Not available"}
                                </td>
                                <td>
                                  {item.nextPaymentdate
                                    ? item.nextPaymentdate
                                    : "Not available"}
                                </td>
                                <td>
                                  <a
                                    href={() => false}
                                    data-toggle="modal"
                                    data-target="#editCustomerModal"
                                    onClick={() => fetchUserdata(item.userId)}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>{" "}
                                </td>
                              </tr>
                            );
                          })
                        ) : user.total === 0 ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <div>
                                <p>
                                  <strong style={{ color: "grey" }}>
                                    No records found
                                  </strong>
                                </p>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        ) : (
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <div className="loading1"></div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <CreateUser /> */}
        <EditUser
          Userdetails={Userdetails}
          paginationvalues={paginationvalues}
        />
        {/* <DeleteUser UserId={UserId} paginationvalues={paginationvalues} /> */}
        <ReactTooltip />
      </>
    )
  ) : (
    <div className="cover-spin"></div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    loading: state.userList.loading,
    user: state.userList.userList,
    error: state.userList.error,
    // Userdetails: state.getuser.user,
    Userdetails: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserListData: () => dispatch(fetchUserList()),
    fetchUserdata: async (userid) => dispatch(getUser(userid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
