import {
    UPDATE_BOOKING_REQUEST,
    UPDATE_BOOKING_SUCCESS,
    UPDATE_BOOKING_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
  };
  export const updateBookingReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_BOOKING_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case UPDATE_BOOKING_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: "Update Booking Successfully !!",
        };
      case UPDATE_BOOKING_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "failed",
        };
      default:
        return state;
    }
  };
  