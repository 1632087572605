import {
  FETCH_USER_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
} from "../types.js";
import axios from "axios";
import { fetchLogout } from "../authAction";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
const fetchUserRequest = () => {
  return { type: FETCH_USER_REQUEST };
};

const fetchUserSuccess = (response) => {
  return { type: FETCH_USER_SUCCESS, payload: response.data };
};

const fetchUserFailure = (error) => {
  return { type: FETCH_USER_FAILURE, payload: error };
};

export const fetchUser = (userid) => {

  const headers = {
    "Content-Type": "application/json",
    'Authorization': basicAuth,
  };

  return (dispatch) => {
    dispatch(fetchUserRequest());
    axios
      .get(url + `getuserbyId/${userid}`,{
        headers: headers,
      })
      .then(function (response) {
        localStorage.setItem("user_img", response.data.imageUrl);
        dispatch(fetchUserSuccess(response));
      })
      .catch(function (error) {
        if (error.message === "Request failed with status code 401") {
          dispatch(fetchLogout());
        }
        dispatch(fetchUserFailure(error));
      });
  };
};
