import React from "react";
import Bookings from "../component/manage_booking/booking";
function Booking() {
    return (
        <div>
            <Bookings />
        </div>
    );
}

export default Booking;
