import {
    FETCH_ALL_WEBSITE_BANNER_REQUEST,
    FETCH_ALL_WEBSITE_BANNER_SUCCESS,
    FETCH_ALL_WEBSITE_BANNER_FAILURE
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllWebsiteBannerRequest = () => {
    return {
        type: FETCH_ALL_WEBSITE_BANNER_REQUEST,
    };
};
export const fetchAllWebsiteBannerSuccess = (Data) => {
    return {
        type: FETCH_ALL_WEBSITE_BANNER_SUCCESS,
        payload: Data,
    };
}

export const fetchAllWebsiteBannerFailure = (Error) => {
    return {
        type: FETCH_ALL_WEBSITE_BANNER_FAILURE,
        payload: Error,
    };
};


export const fetchAllWebsiteBanner = () => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllWebsiteBannerRequest);
        axios
            .get(url + "getAllTlsBanner", {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllWebsiteBannerSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllWebsiteBannerFailure(error));
            });

    };
};