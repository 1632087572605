import React from 'react';
import Index from "../component/manage_booking/bookingRequest";

function BookingRequest() {

    return (
        <div>
           <Index/>
        </div>
    )
}

export default BookingRequest
