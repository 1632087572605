import React, { useEffect, useState, useRef } from "react";
import {
    updateTriggerById,
    updateTriggerByIdRequest,
} from "../../actions/triggerActions/updateTrigger";
import { connect } from "react-redux";
//VALIDATION FUNCTION
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import {
    emailValidate,
    stringValidate,
    selectinputValidate,
    contactNumberValidation,
    postalCodeValidation,
    websiteValidation,
    passwordValidate,
    numberValidate,
} from "../../utils/Validations";
import $ from "jquery";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "react-google-autocomplete";
import { Dropdown } from "react-bootstrap";
//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

const UpdateSoundVender = (props) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sameasaddress, setSameAsAddress] = useState(false);
    const [selectedOption, setSelectedOption] = useState('time');
    const [showTimeFields, setShowTimeFields] = useState(true);
    const [showEventFields, setShowEventFields] = useState(false);
    const [showField, setShowField] = useState(false);
    const { handleUpdateTrigger, message, error, getTriggerByIdData, refreshform } = props;
    //----------------  ADD Trigger STATE TO HANDLE INPUT VALUES ------------***//

    const [data, setData] = useState({
        id: "",
        name: "",
        active: false,
        entityName: "",
        webhookUrl: "",
        hasTimeTrigger: true,
        eventType: "",
        dayOffset: "",
        isTriggerTimePrecise: "",
        timeOffset: "",
        timeFieldGetterName: "",

    });

    //----------------  ADD Trigger FIELDS ERROR ------------***//
    const [addtriggererrors, setAddTriggerError] = useState({
        name: null,
        active: null,
        entityName: null,
        webhookUrl: null,
        hasTimeTrigger: null,
        eventType: null,
        dayOffset: null,
        isTriggerTimePrecise: null,
        timeOffset: null,
        timeFieldGetterName: null,
    });

    const entityNameDropDownData = [
        {
            name: "Booking",
        },
        {
            name: "Customer",
        },
    ]
    const DateTimeDropDownData = [
        {
            name: "getCreatedAt",
        },
        {
            name: "getBookingEventDateAndTime",
        },
    ]
    const EventTypeDropDownData = [
        {
            name: "CREATE",
        },
        {
            name: "UPDATE",
        },
    ]

    useEffect(() => {
        setData({
            ...data,
            id: getTriggerByIdData?.flowId,
            name: getTriggerByIdData.name,
            active: getTriggerByIdData.active,
            entityName: getTriggerByIdData.entityName,
            webhookUrl: getTriggerByIdData.webhookUrl,
            hasTimeTrigger: getTriggerByIdData.hasTimeTrigger,
            eventType: getTriggerByIdData.eventType,
            dayOffset: getTriggerByIdData.dayOffset,
            isTriggerTimePrecise: getTriggerByIdData.triggerTimePrecise,
            timeOffset: getTriggerByIdData.timeOffset,
            timeFieldGetterName: getTriggerByIdData.timeFieldGetterName,
        });
        setShowTimeFields(getTriggerByIdData.hasTimeTrigger === true ? true : false);
        setShowEventFields(getTriggerByIdData.hasTimeTrigger === true ? false : true);

    }, [getTriggerByIdData])

    const handleValidation = () => {
        if (data.hasTimeTrigger) {
            setAddTriggerError({
                ...addtriggererrors,
                name: stringValidate(data.name, "Trigger Name"),
                entityName: stringValidate(data.entityName, "Entity Name"),
                dayOffset: numberValidate(data.dayOffset, "Day Offset"),
                timeFieldGetterName: stringValidate(data.timeFieldGetterName, "Time Date"),
                webhookUrl: websiteValidation(data.webhookUrl, "Webhook URL"),
            });
        }
        else {
            setAddTriggerError({
                ...addtriggererrors,
                name: stringValidate(data.name, "Trigger Name"),
                entityName: stringValidate(data.entityName, "Entity Name"),
                eventType: stringValidate(data.eventType, "Event Type"),
                webhookUrl: websiteValidation(data.webhookUrl, "Webhook URL"),
            });
        }
    };

    const handleUpdateTriggerFun = (e) => {

        handleValidation();
        if (data.hasTimeTrigger) {
            if (
                stringValidate(data.name, "Trigger Name") === null &&
                stringValidate(data.entityName, "Entity Name") === null &&
                numberValidate(data.dayOffset, "Day Offset") === null &&
                stringValidate(data.timeFieldGetterName, "Time Date") === null &&
                websiteValidation(data.webhookUrl, "Webhook URL") === null
            ) {
                handleUpdateTrigger(data);
            }
        }
        else {
            if (

                stringValidate(data.name, "Trigger Name") === null &&
                stringValidate(data.entityName, "Entity Name") === null &&
                stringValidate(data.eventType, "Event Type") === null &&
                websiteValidation(data.webhookUrl, "Webhook URL") === null
            ) {
                handleUpdateTrigger(data);
            }

        }
    };

    useEffect(() => {
        if (localStorage.getItem("message") === "RecordUpdatedFailed") {
            enqueueSnackbar("Trigger Updated Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "RecordUpdatedSuccessfully") {
            handleClearValidationError();
            enqueueSnackbar("Trigger Updated successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);


    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
    const handleClearValidationError = () => {
        setAddTriggerError({
            ...addtriggererrors,
            name: null,
            active: null,
            entityName: null,
            webhookUrl: null,
            hasTimeTrigger: null,
            eventType: null,
            dayOffset: null,
            isTriggerTimePrecise: null,
            timeOffset: null,
            timeFieldGetterName: null,
        });
        //Clear State
        setData({
            ...data,
            name: "",
            active: false,
            entityName: "",
            webhookUrl: "",
            hasTimeTrigger: true,
            eventType: "",
            dayOffset: "",
            isTriggerTimePrecise: "",
            timeOffset: "",
            timeFieldGetterName: "",
        });
    };
    const handleCheckboxChange = () => {
        setShowField(!showField);
        setData({
            ...data,
            isTriggerTimePrecise: !showField,
        })
    };

    const handleOptionChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            hasTimeTrigger: value === 'time' ? true : false
        })
        setSelectedOption(value);
        setShowTimeFields(value === 'time');
        setShowEventFields(value === 'event');
    };
    const handleCheckTimeBaseTriggerActive = (e) => {
        setData({
            ...data,
            active: e.target.checked,
        })
    }

    return (
        // <!-- The Modal -->
        <div className="modal" id="updateTriggerModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Update Trigger</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">
                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        Trigger Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Trigger Name"
                                                        id="subs"
                                                        value={data.name}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                name: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddTriggerError({
                                                                ...addtriggererrors,
                                                                name: stringValidate(
                                                                    data.name,
                                                                    "Trigger Name"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addtriggererrors.name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Entity Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>

                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {data.entityName
                                                                ? data.entityName
                                                                : "Entity Name"}

                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {entityNameDropDownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        setData({
                                                                            ...data,
                                                                            entityName: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddTriggerError({
                                                                            ...addtriggererrors,
                                                                            entityName: stringValidate(
                                                                                data.entityName,
                                                                                "Entity Name"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addtriggererrors.entityName}
                                                    </p>
                                                </div>
                                            </div>

                                            {showTimeFields && (
                                                <>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Day Offset{" "}
                                                                <span style={{ color: "#d20d0d" }}>*</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Day Offset"
                                                                id="subs"
                                                                min="0"
                                                                value={data.dayOffset}
                                                                onChange={(e) =>
                                                                    setData({
                                                                        ...data,
                                                                        dayOffset: e.target.value,
                                                                    })
                                                                }
                                                                onBlur={() =>
                                                                    setAddTriggerError({
                                                                        ...addtriggererrors,
                                                                        dayOffset: numberValidate(
                                                                            data.dayOffset,
                                                                            "Day Offset"
                                                                        ),
                                                                    })
                                                                }
                                                            />
                                                            <p className="profile-input-errors">
                                                                {addtriggererrors.dayOffset}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Is Trigger At Precise Time{" "}

                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-control mt-1"
                                                                placeholder="Is Trigger At Precise Time"
                                                                id="subs"
                                                                checked={data.isTriggerTimePrecise}
                                                                onChange={handleCheckboxChange}


                                                            />
                                                        </div>
                                                    </div>
                                                    {showField &&
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>
                                                                    Time Offset{" "}
                                                                    {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Time Offset"
                                                                    min="0"
                                                                    id="subs"
                                                                    value={data.timeOffset}
                                                                    onChange={(e) =>
                                                                        setData({
                                                                            ...data,
                                                                            timeOffset: e.target.value,
                                                                        })
                                                                    }

                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Time Date{" "}
                                                                <span style={{ color: "#d20d0d" }}>*</span>
                                                            </label>

                                                            <Dropdown class="formDropDown">
                                                                <Dropdown.Toggle
                                                                    id="dropdown-basic"
                                                                    class="formDropDownToggle"
                                                                    style={{
                                                                        width: "100%",
                                                                        background: "#D7263D",
                                                                        outline: "none",
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    {data.timeFieldGetterName
                                                                        ? data.timeFieldGetterName
                                                                        : "Time Date"}

                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu style={{ width: "100%" }}>
                                                                    {DateTimeDropDownData.map((option) => (
                                                                        <Dropdown.Item
                                                                            key={option}
                                                                            onClick={() =>
                                                                                setData({
                                                                                    ...data,
                                                                                    timeFieldGetterName: option.name,
                                                                                })
                                                                            }
                                                                            onBlur={() =>
                                                                                setAddTriggerError({
                                                                                    ...addtriggererrors,
                                                                                    timeFieldGetterName: stringValidate(
                                                                                        data.timeFieldGetterName,
                                                                                        "Time Date"
                                                                                    ),
                                                                                })
                                                                            }
                                                                        >
                                                                            {option.name}
                                                                        </Dropdown.Item>
                                                                    ))}

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <p className="profile-input-errors">
                                                                {addtriggererrors.timeFieldGetterName}
                                                            </p>
                                                        </div>

                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Webhook URL{" "}
                                                                <span style={{ color: "#d20d0d" }}>*</span>
                                                            </label>
                                                            <input
                                                                type="subs"
                                                                className="form-control"
                                                                placeholder="Webhook URL"
                                                                id="subs"
                                                                value={data.webhookUrl}
                                                                onChange={(e) =>
                                                                    setData({
                                                                        ...data,
                                                                        webhookUrl: e.target.value,
                                                                    })
                                                                }
                                                                onBlur={() =>
                                                                    setAddTriggerError({
                                                                        ...addtriggererrors,
                                                                        webhookUrl: stringValidate(
                                                                            data.webhookUrl,
                                                                            "Webhook URL"
                                                                        ),
                                                                    })
                                                                }
                                                            />
                                                            <p className="profile-input-errors">
                                                                {addtriggererrors.webhookUrl}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Active{" "}
                                                                {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-control mt-1"
                                                                placeholder="Active"
                                                                id="subs"
                                                                checked={data.active}
                                                                onChange={(e) => handleCheckTimeBaseTriggerActive(e)}


                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {showEventFields && (
                                                <>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Event Type{" "}
                                                                <span style={{ color: "#d20d0d" }}>*</span>
                                                            </label>
                                                            <Dropdown class="formDropDown">
                                                                <Dropdown.Toggle
                                                                    id="dropdown-basic"
                                                                    class="formDropDownToggle"
                                                                    style={{
                                                                        width: "100%",
                                                                        background: "#D7263D",
                                                                        outline: "none",
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    {data.eventType
                                                                        ? data.eventType
                                                                        : "Event Type"}

                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu style={{ width: "100%" }}>
                                                                    {EventTypeDropDownData.map((option) => (
                                                                        <Dropdown.Item
                                                                            key={option}
                                                                            onClick={() =>
                                                                                setData({
                                                                                    ...data,
                                                                                    eventType: option.name,
                                                                                })
                                                                            }
                                                                            onBlur={() =>
                                                                                setAddTriggerError({
                                                                                    ...addtriggererrors,
                                                                                    eventType: stringValidate(
                                                                                        data.eventType,
                                                                                        "Event Type"
                                                                                    ),
                                                                                })
                                                                            }
                                                                        >
                                                                            {option.name}
                                                                        </Dropdown.Item>
                                                                    ))}

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <p className="profile-input-errors">
                                                                {addtriggererrors.eventType}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Webhook URL{" "}
                                                                <span style={{ color: "#d20d0d" }}>*</span>
                                                            </label>
                                                            <input
                                                                type="subs"
                                                                className="form-control"
                                                                placeholder="Webhook URL"
                                                                id="subs"
                                                                value={data.webhookUrl}
                                                                onChange={(e) =>
                                                                    setData({
                                                                        ...data,
                                                                        webhookUrl: e.target.value,
                                                                    })
                                                                }
                                                                onBlur={() =>
                                                                    setAddTriggerError({
                                                                        ...addtriggererrors,
                                                                        webhookUrl: stringValidate(
                                                                            data.webhookUrl,
                                                                            "Webhook URL"
                                                                        ),
                                                                    })
                                                                }
                                                            />
                                                            <p className="profile-input-errors">
                                                                {addtriggererrors.webhookUrl}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Active{" "}
                                                                {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-control mt-1"
                                                                placeholder="Active"
                                                                id="subs"
                                                                checked={data.active}
                                                                onChange={(e) => handleCheckTimeBaseTriggerActive(e)}

                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleUpdateTriggerFun}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.updateTriggerById.loading,
        message: state.updateTriggerById.message,
        error: state.updateTriggerById.error,
        getTriggerByIdData: state.getTriggerById.data,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleUpdateTrigger: (data) => dispatch(updateTriggerById(data)),
        refreshform: () => dispatch(updateTriggerByIdRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdateSoundVender));
