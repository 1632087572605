import {
    ADD_AGENT_REQUEST,
    ADD_AGENT_SUCCESS,
    ADD_AGENT_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllAgent } from "./getAllAgents";

export const addAgentRequest = () => {
    return {
        type: ADD_AGENT_REQUEST,
    };
};

export const addAgentSuccess = (Data) => {
    return {
        type: ADD_AGENT_SUCCESS,
        payload: { data: Data.data, message: Data.message },
    };
};

export const addAgentFailure = (Error) => {
    return {
        type: ADD_AGENT_FAILURE,
        payload: Error,
    };
};
export const addAgent = (data) => {
    var headers = {
        Accept: "Application/json",
        "Content-Type": "multipart/formdata",
        'Authorization': basicAuth,
    };

    let formdata = new FormData();
    var myData = {
        agentUser: {
            userName: data.userName,
            password: data.password,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            secondaryPhone: data.secondaryPhone
        }
    };
    let dataa = JSON.stringify(myData);
    formdata.append("data", dataa);

    return (dispatch) => {
        dispatch(addAgentRequest());
        axios
            .post(url + `addAgentUser`, formdata, { headers: headers })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addAgentSuccess");
                    await dispatch(addAgentSuccess(response.data));
                    await dispatch(fetchAllAgent());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "addAgentfailed");
                dispatch(
                    addAgentFailure("Please Check Your Internet Connection !!")
                );
            });
    };
};
