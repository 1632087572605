import {
  ADD_BOOKING_REQUEST,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllBooking } from "./getAllBooking"
export const addBookingRequest = () => {
  return {
    type: ADD_BOOKING_REQUEST,
  };
};

export const addBookingSuccess = (Data) => {
  return {
    type: ADD_BOOKING_SUCCESS,
    payload: { data: Data.data, message: Data.message },
  };
};

export const addBookingFailure = (Error) => {
  return {
    type: ADD_BOOKING_FAILURE,
    payload: Error,
  };
};
export const addBooking = (data, addressData, customerUserData) => {
  var headers = {
    Accept: "Application/json",
    // "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  return (dispatch) => {
    dispatch(addBookingRequest());
    axios
      .post(url + `createBookingRequest`, {

        customerType: data.customerType,
        bookingState: data.bookingState,
        startTime: data.startTime,
        eventDate: data.eventDate,
        endTime: data.endTime,
        hostName: customerUserData.hostName,
        hostPhone: customerUserData.hostPhone,
        hostPhone2: data.hostPhone2 ? data.hostPhone2 : "",
        specialRequest: data.specialRequest,
        numberOfGuests: data.numberOfGuests,
        occasion: data.occasion,
        artistSound: data.isArtistSound == "true" ? true : false,
        bookingClosingDate: today,
        companyShare: data.companyShare,
        venueAddress: {
          addressType: "Venue Address",
          googleMapLocation: data.googleMapLocation,
          street: addressData.street,
          city: addressData.city,
          state: addressData.state,
          zipCode: data.zipCode,
          country: addressData.country,
          longitude: addressData.longitude,
          latitude: addressData.latitude,
          addressName: addressData.addressName
        },
        profileSetupType: {
          setupTypeId: data.setupTypeId
        },
        profileDuration: {
          durationId: data.durationId
        },
        customer: {
          customerId: data.customerId
        },
        artist: {
          artistId: data.artistId
        },
        selectedSoundSystem: data.soundSystemId ? {
          soundSystemId: data.soundSystemId
        } : null,
        soundVendor: data.soundVendorId ? {
          soundVendorId: data.soundVendorId
        } : null

      }, { headers: headers })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "addBookingSuccess");
          await dispatch(addBookingSuccess(response.data));
          await dispatch(fetchAllBooking());
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "addBookingfailed");
        dispatch(
          addBookingFailure("Please Check Your Internet Connection !!")
        );
      });
  };
};
