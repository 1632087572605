
import {
    FETCH_ARTIST_BY_ID_REQUEST,
    FETCH_ARTIST_BY_ID_SUCCESS,
    FETCH_ARTIST_BY_ID_FAILURE,
} from "../types.js";
import axios from "axios";

import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
const fetchArtistByIdRequest = () => {
    return { type: FETCH_ARTIST_BY_ID_REQUEST };
};

const fetchArtistByIdSuccess = (Data) => {
    return { type: FETCH_ARTIST_BY_ID_SUCCESS, payload: Data };
};

const fetchArtistByIdFailure = (error) => {
    return { type: FETCH_ARTIST_BY_ID_FAILURE, payload: error };
};

export const fetchArtistById = (id) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };


    const artistId = id || localStorage.getItem("artistId");


    return (dispatch) => {
        dispatch(fetchArtistByIdRequest());
        axios
            .get(url + `getArtistById/${artistId}`, {
                headers: headers,
            })

            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "successfatched");
                    await dispatch(fetchArtistByIdSuccess(response.data));
                }
            })
            .catch(function (error) {
                localStorage.setItem("message", "fatchfailed");

                if (error.message === "Request failed with status code 401") {
                }
                dispatch(fetchArtistByIdFailure(error));
            });
    };
};
