import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import { getGlobalDiscount } from "../../actions/globalDiscount/getGlobalDiscount"
import UpdateDiscount from "./updateDiscount";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
function Discount(props) {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:400px)");
  const [contactId, setcontactId] = useState(0);
  const [paginationvalues, setPaginationValues] = useState({});

  const [showingpage, setShowingpage] = useState({
    from: 1,
    to: 2,
    sortingorder: "desc",
    search: null,
    columnname: "",
  });

  const {
    loading,
    error,
    message,
    handleGetGlobalDiscount,
    GlobalDiscountData
  } = props;

  useEffect(() => {
    checkRoute();

  }, [GlobalDiscountData]);

  useEffect(() => {
    handleGetGlobalDiscount()
  }, [])

  return  (
      <>
        <div className="container-fluid">
          {/* <!-- Page Heading --> */}
          <div className="page-breadcrumb">
            <div className="row">
              <div className="col-5 align-self-center">
                <h4 className="page-title">Discount</h4>
                <div className="d-flex align-items-center">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-1">
                      <li className="breadcrumb-item">
                        <a
                          className="breadcrumb-link"
                          onClick={() => history.push("/Booking_Request")}
                          href={() => false}
                        >
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Discount
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-7 align-self-center">
                <div className="d-flex no-block justify-content-end align-items-center">
                  <div className="m-r-10">
                    <div className="lastmonth"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 pt-2"></div>
                    <div className="col-lg-4 pt-2 text-right">
                      {" "}
                      <a
                        href={() => false}
                        className="btn btn-primary "
                        data-toggle="modal"
                        data-target="#updateDiscountModal"
                        onClick={() => handleGetGlobalDiscount()}

                      >
                       Update
                      </a>{" "}
                      
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>TLS Margin</div>
                            </div>
                          </th>
                          <td>
                            {GlobalDiscountData.tlsMargin}%
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Overall Discount</div>
                            </div>
                          </th>
                          <td>
                            {GlobalDiscountData.globalDiscountId}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Customer Discount  </div>
                            </div>
                          </th>
                          <td>
                            {GlobalDiscountData.customerDiscount}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Running Discount</div>
                            </div>
                          </th>
                          <td>
                            {GlobalDiscountData.runningDiscount}%
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              <div>Transportation cost per km</div>
                            </div>
                          </th>
                          <td>
                            {GlobalDiscountData.travelRate} rs
                          </td>
                        </tr>
                      </thead>
                      {/* <tbody>
                                                {soundSystemData !== null && soundSystemData !== undefined ? (
                                                    soundSystemData.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <a
                                                                        className="breadcrumb-link"
                                                                        href={() => false}
                                                                        onClick={() => {
                                                                            history.push({
                                                                                pathname: "/propertyDeatils",
                                                                            });
                                                                            localStorage.setItem(
                                                                                "propertyId",
                                                                                item.propertyId
                                                                            );
                                                                        }}
                                                                    >

                                                                        {item.properName}
                                                                    </a>
                                                                </td>
                                                                <td>{item.specification}</td>
                                                                <td>{item.maxNumberOfGuest}</td>
                                                                <td>{item.soundSystemPhotoPath}</td>
                                                                <td>{item.soundSystemVideoPath}</td>
                                                                <td>{item.chargeAmount}</td>
                                                                <td>
                                                                    <a
                                                                        href={() => false}
                                                                        className="cursor"
                                                                        data-toggle="modal"
                                                                        data-target="#editBookingModal"
                                                                    // onClick={() => getBookingDetailsById(item.bookingId)}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                    </a>{" "}
                                                                    <a
                                                                        href={() => false}
                                                                        className="cursor"
                                                                        data-toggle="modal"
                                                                        data-target="#deleteBookingModal"
                                                                    // onClick={() => {
                                                                    //     setBookingId(item.bookingId);
                                                                    // }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : soundSystemData.length === 0 ? (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div>
                                                                <p>
                                                                    <strong style={{ color: "grey" }}>
                                                                        No records found
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div className="loading1"></div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </tbody> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UpdateDiscount
          GlobalDiscountData={GlobalDiscountData}
        />

        <ReactTooltip />
      </>
    )
  
}

const mapStateToProps = (state, props) => {

  return {
    GlobalDiscountData: state.fetchGlobalDiscount.data,
    loading: state.fetchGlobalDiscount.loading,
    error: state.fetchGlobalDiscount.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    handleGetGlobalDiscount: () => dispatch(getGlobalDiscount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Discount);

