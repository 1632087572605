import React, { useEffect, useState, useRef } from "react";
import {
    updateArtist,
    updateArtistRequest,
} from "../../actions/artists/updateArtists";
import { connect } from "react-redux";
import { fetchAllPriceSlabDropdown } from "../../actions/common/getAllDropdown"

//VALIDATION FUNCTION
import {
    emailValidate,
    stringValidate,
    numberValidate,
    contactNumberValidation,
    postalCodeValidation,
    passwordValidate,
    // websiteValidation,
} from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import $ from "jquery";
import { Dropdown } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

const PublishedOrUnpublishedArtist = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const [selectedPriceSlab, setSelectedPriceSlab] = useState("");

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { AddArtist, message, error, refreshform, PriceSLabDropdownData, handlePriceSLabDropdownData, getArtistDataById } = props;

    //----------------  ADD artist STATE TO HANDLE INPUT VALUES ------------***//
    useEffect(() => {
        handlePriceSLabDropdownData()


    }, [])
    const hasOwnSoundSystemData = [
        {
            name: "true",
        },
        {
            name: "false",
        },
    ];
    const [artist, setArtist] = useState({
        id: "",
        profileName: "",
        instagramProfileLink: "",
        genres: "",
        rating: "",
        about: "",
        inspirations: "",
        numberOfShowsPerformed: "",
        yearsOfExperience: "",
        achievements: "",
        individualSkill: "",
        samplePlaylist: "",
        hasOwnSoundSystem: "",
        baseLocationUrl: "",
        maxServiceaablekm: "",
        gSTIN: "",
        operatingState: "",
        published: "",
        region: "",
        area: "",
        userName: "",
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        secondaryPhone: "",
        googleMapLocation: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        latitude: "",
        longitude: "",
        priceSlabId: "",
        backgroundImage: ""
    });
    const [addressData, setAddressData] = useState({
        longitude: "",
        latitude: "",
    })
    useEffect(() => {
        setArtist({
            ...artist,
            id: getArtistDataById.artistId,
            profileName: getArtistDataById.profileName,
            instagramProfileLink: getArtistDataById.instagramProfileLink,
            genres: getArtistDataById.genres,
            rating: getArtistDataById.rating,
            about: getArtistDataById.about,
            inspirations: getArtistDataById.inspirations,
            numberOfShowsPerformed: getArtistDataById.numberOfShowsPerformed,
            yearsOfExperience: getArtistDataById.yearsOfExperience,
            achievements: getArtistDataById.achievements,
            individualSkill: getArtistDataById.individualSkill,
            samplePlaylist: getArtistDataById.samplePlaylist,
            hasOwnSoundSystem: getArtistDataById.hasOwnSoundSystem,
            baseLocationUrl: getArtistDataById.baseLocationUrl,
            maxServiceaablekm: getArtistDataById.maxServiceaablekm,
            gSTIN: getArtistDataById.gSTIN,
            operatingState: getArtistDataById.operatingState,
            published: getArtistDataById.published,
            region: getArtistDataById.region,
            userName: getArtistDataById?.artistUser?.userName,
            password: getArtistDataById?.artistUser?.password,
            email: getArtistDataById?.artistUser?.email,
            firstName: getArtistDataById?.artistUser?.firstName,
            lastName: getArtistDataById?.artistUser?.lastName,
            phoneNumber: getArtistDataById?.artistUser?.phoneNumber,
            secondaryPhone: getArtistDataById?.artistUser?.secondaryPhone,
            googleMapLocation: getArtistDataById?.baseLocation?.googleMapLocation,
            street: getArtistDataById?.baseLocation?.street,
            city: getArtistDataById?.baseLocation?.city,
            state: getArtistDataById?.baseLocation?.state,
            zipCode: getArtistDataById?.baseLocation?.zipCode,
            country: getArtistDataById?.baseLocation?.country,
            latitude: getArtistDataById?.baseLocation?.latitude,
            longitude: getArtistDataById?.baseLocation?.longitude,
            priceSlabId: getArtistDataById?.priceSlab?.priceSlabId,
            backgroundImage: getArtistDataById.backgroundPhotoPath
        });
        setSelectedPriceSlab(getArtistDataById?.priceSlab?.amount)
        setAddressData({
            ...addressData,
            latitude: getArtistDataById?.baseLocation?.latitude,
            longitude: getArtistDataById?.baseLocation?.longitude,
        })
    }, [getArtistDataById])

    //----------------  ADD artist FIELDS ERROR ------------***//
    const [addartistserrors, setAddArtistsErrors] = useState({
        profileName: null,
        instagramProfileLink: null,
        genres: null,
        rating: null,
        about: null,
        inspirations: null,
        numberOfShowsPerformed: null,
        yearsOfExperience: null,
        achievements: null,
        individualSkill: null,
        samplePlaylist: null,
        hasOwnSoundSystem: null,
        baseLocationUrl: null,
        maxServiceaablekm: null,
        gSTIN: null,
        operatingState: null,
        published: null,
        area: null,
        region: null,
        userName: null,
        password: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        secondaryPhone: null,
        googleMapLocation: null,
        street: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        latitude: null,
        longitude: null,
        priceSlabId: null,
        backgroundImage: null
    });

    const clearState = () => {
        setArtist({
            ...artist,
            profileName: "",
            instagramProfileLink: "",
            genres: "",
            rating: "",
            about: "",
            inspirations: "",
            numberOfShowsPerformed: "",
            yearsOfExperience: "",
            achievements: "",
            individualSkill: "",
            samplePlaylist: "",
            hasOwnSoundSystem: "",
            baseLocationUrl: "",
            maxServiceaablekm: "",
            gSTIN: "",
            operatingState: "",
            published: "",
            region: "",
            userName: "",
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            secondaryPhone: "",
            googleMapLocation: "",
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            latitude: "",
            longitude: "",
            priceSlabId: "",
            backgroundImage: ""
        });
    };

    const handleValidation = () => {
        setAddArtistsErrors({
            ...addartistserrors,

            published: stringValidate(artist.published, "Published"),

        });
    };

    //---------------------------------------  ADD artist FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleAddartist = (e) => {
        handleValidation();
        if (

            stringValidate(artist.published, "Published") === null
        ) {
            AddArtist(artist, addressData);
        }
    };

    //------------------------- USEFFECT HOOK EXECUTING ON FIRST RENDER OF VIEW -------------------------**//

    useEffect(() => {
        if (localStorage.getItem("message") === "Record Updated Failed") {
            enqueueSnackbar("Artist Updated Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "Record Updated Successfully") {
            setSameAsAddress(false);
            handleClearValidationError();

            enqueueSnackbar("Artist Updated Successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//

    const handleClearValidationError = () => {
        setAddArtistsErrors({
            ...addartistserrors,
            profileName: null,
            instagramProfileLink: null,
            genres: null,
            rating: null,
            about: null,
            inspirations: null,
            numberOfShowsPerformed: null,
            yearsOfExperience: null,
            achievements: null,
            individualSkill: null,
            samplePlaylist: null,
            hasOwnSoundSystem: null,
            baseLocationUrl: null,
            maxServiceaablekm: null,
            gSTIN: null,
            operatingState: null,
            published: null,
            region: null,
            userName: null,
            password: null,
            email: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            secondaryPhone: null,
            googleMapLocation: null,
            street: null,
            city: null,
            state: null,
            zipCode: null,
            country: null,
            latitude: null,
            longitude: null,
            priceSlabId: null,
            backgroundImage: null
        });

        clearState();
    };

    const onPlacesChanged = (place) => {
        const { geometry } = place;

        if (geometry && geometry.location) {
            const { lat, lng } = geometry.location;

            setAddressData({
                ...addressData,
                latitude: lat(),
                longitude: lng()
            })


        }
    };
    const handleGetDropdownValue = (option) => {

        setSelectedPriceSlab(option.label)
        setArtist({
            ...artist,
            priceSlabId: option.value,
        })
    }


    return (
        // <!-- The Modal -->
        <div className="modal" id="PublishorunpublishArtist" >
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Update Artist</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body" style={{ height: "35vh" }}>
                        <div id="accordion">
                            <div className="card">
                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        Published{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {/* {artist.published
                                                                ? artist.published.toString()
                                                                : "Published"} */}
                                                            {artist?.published?.toString()}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {hasOwnSoundSystemData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        setArtist({
                                                                            ...artist,
                                                                            published: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddArtistsErrors({
                                                                            ...addartistserrors,
                                                                            published: stringValidate(
                                                                                artist.published,
                                                                                "Published"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.published}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleAddartist}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {

    return {
        loading: state.updateArtist.loading,
        message: state.updateArtist.message,
        error: state.updateArtist.error,
        PriceSLabDropdownData: state.PriceSlabDropdown.PriceSlabDropdown,
        getArtistDataById: state.getArtistById.data

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handlePriceSLabDropdownData: () => dispatch(fetchAllPriceSlabDropdown()),
        AddArtist: (artist, addressData) => dispatch(updateArtist(artist, addressData)),
        refreshform: () => dispatch(updateArtistRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PublishedOrUnpublishedArtist));
