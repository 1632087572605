import React from "react";
import SoundVenders from "../component/soundVenders/soundVender";
function SoundVender() {
  return (
    <div>
      <SoundVenders />
    </div>
  );
}

export default SoundVender;
