import React, { useEffect, useState } from "react";
import {
    updateArtistVideo,
    updateArtistVideoRequest,
} from "../../../actions/artists/videoManagement/updateArtistVideo";
import { connect } from "react-redux";

//VALIDATION FUNCTION
import {
    emailValidate,
    stringValidate,
    numberValidate,
    contactNumberValidation,
    postalCodeValidation,
    // websiteValidation,
} from "../../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS

import $ from "jquery";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const UpdateArtistVideo = (props) => {

    const [sameasaddress, setSameAsAddress] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { handleUpdateArtistVideo, message, error, refreshform, getArtistVideosByIdData } = props;

    //----------------  ADD artist STATE TO HANDLE INPUT VALUES ------------***//

    const [artist, setArtist] = useState({
        id: "",
        videoLink: "",
        videoDesc: "",
        videoType: "",
        videoFile: "",
        videoName: ""

    });
    //----------------  ADD artist FIELDS ERROR ------------***//
    const [addartistserrors, setAddArtistsErrors] = useState({
        videoLink: null,
        videoDesc: null,
        videoType: null,
        videoFile: null
    });

    const clearState = () => {
        setArtist({
            ...artist,
            videoLink: "",
            videoDesc: "",
            videoType: "",
            videoFile: ""
        });
    };
    useEffect(() => {
        setArtist({
            ...artist,
            id: getArtistVideosByIdData.videoId,
            videoLink: getArtistVideosByIdData.videoLink,
            videoDesc: getArtistVideosByIdData.videoDesc,
            videoType: getArtistVideosByIdData.videoType,
            videoFile: getArtistVideosByIdData.videoPath,
            videoName: getArtistVideosByIdData.video

        });
    }, [getArtistVideosByIdData])

    const handleValidation = () => {
        setAddArtistsErrors({
            ...addartistserrors,
            // videoLink: stringValidate(artist.videoLink, "VideoLink"),
            // videoDesc: stringValidate(artist.videoDesc, "VideoDesc"),
            // videoType: stringValidate(artist.videoType, "VideoType"),
            videoFile: stringValidate(artist.videoFile, "VideoFile")

        });
    };

    //---------------------------------------  ADD artist FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleUpdateVideo = (e) => {
        handleValidation();
        if (
            // stringValidate(artist.videoLink, "VideoLink") === null &&
            // stringValidate(artist.videoDesc, "VideoDesc") === null &&
            // stringValidate(artist.videoType, "VideoType") === null &&
            stringValidate(artist.videoFile, "VideoFile") === null
        ) {
            handleUpdateArtistVideo(artist);
        }
    };

    //------------------------- USEFFECT HOOK EXECUTING ON FIRST RENDER OF VIEW -------------------------**//

    useEffect(() => {
        if (localStorage.getItem("message") === "updateArtistVideofailed") {
            enqueueSnackbar("Update Artist Video Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "updateArtistVideoSuccess") {
            setSameAsAddress(false);
            handleClearValidationError();

            enqueueSnackbar("Update Artist Video Success", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//

    const handleClearValidationError = () => {
        setAddArtistsErrors({
            ...addartistserrors,
            videoLink: null,
            videoDesc: null,
            videoType: null,
            videoFile: null
        });

        clearState();
    };

    return (
        // <!-- The Modal -->
        <div className="modal" id="updateVideoModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Add Artist</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">
                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Video Link{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Video Link"
                                                        id="subs"
                                                        value={artist.videoLink}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                videoLink: e.target.value,
                                                            })
                                                        }
                                                        // onBlur={() =>
                                                        //     setAddArtistsErrors({
                                                        //         ...addartistserrors,
                                                        //         videoLink: stringValidate(
                                                        //             artist.videoLink,
                                                        //             "VideoLink"
                                                        //         ),
                                                        //     })
                                                        // }
                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.videoLink}
                                                    </p> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Video Description
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Video Description"
                                                        id=""
                                                        value={artist.videoDesc}

                                                        onChange={(e) => {
                                                            setArtist({
                                                                ...artist,
                                                                videoDesc: e.target.value,
                                                            });
                                                        }}
                                                        // ref={FromPriceRef}
                                                        // onBlur={() => {
                                                        //     setAddArtistsErrors({
                                                        //         ...addartistserrors,
                                                        //         videoDesc: stringValidate(
                                                        //             artist.videoDesc,
                                                        //             "VideoDesc"
                                                        //         ),
                                                        //     });
                                                        // }}
                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.videoDesc}
                                                    </p> */}
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Video Type{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="Video Type"
                                                        value={artist.videoType}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                videoType: e.target.value,
                                                            })
                                                        }
                                                        // onBlur={() =>
                                                        //     setAddArtistsErrors({
                                                        //         ...addartistserrors,
                                                        //         videoType: stringValidate(
                                                        //             artist.videoType,
                                                        //             "VideoType"
                                                        //         ),
                                                        //     })
                                                        // }
                                                    />

                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.videoType}
                                                    </p> */}
                                                </div>

                                            </div>

                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Video File <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="Video File"
                                                        id=""

                                                        // onChange={(e) => onlyNumberInputPhone(e)}
                                                        onChange={(e) => {
                                                            setArtist({
                                                                ...artist,
                                                                videoFile: e.target.files[0],
                                                            });
                                                        }}
                                                        onBlur={() => {
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                videoFile: stringValidate(
                                                                    artist.videoFile,
                                                                    "VideoFile"
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.videoFile}
                                                    </p>
                                                    <p>Video in use : <span style={{ color: "green" }}>{artist.videoName}</span></p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleUpdateVideo}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.updateArtistVideos.loading,
        message: state.updateArtistVideos.message,
        error: state.updateArtistVideos.error,
        getArtistVideosByIdData: state.getArtistVideosById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleUpdateArtistVideo: (artist) => dispatch(updateArtistVideo(artist)),
        refreshform: () => dispatch(updateArtistVideoRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdateArtistVideo));
