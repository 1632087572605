import React from 'react'
import LoginPage from "../component/auth/login"

function Login() {
    return (
        <div>
            <LoginPage/>
        </div>
    )
}

export default Login
