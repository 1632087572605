import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import { connect } from "react-redux";
import { fetchUser } from "../../actions/User action/fetchuser";

function Userdetails(props) {
  // to go to parent module
  const { getUser, user, error, loading } = props;
  const history = useHistory();
  let id = localStorage.getItem("UserId");

  useEffect(() => {
    localStorage.setItem("View_Name", "user");
    checkRoute();
    fetchUserData(id);
  }, []);

  const fetchUserData = (Id) => {
    // getUser(Id);
  };
  return !loading ? (
    error ? (
      "oops....something goes wrong"
    ) : (
      <>
        <div className="container-fluid">
          {/* <!-- Page Heading --> */}
          <div className="page-breadcrumb">
            <div className="row">
              <div className="col-5 align-self-center">
                <h4 className="page-title">Users</h4>
                <div className="d-flex align-items-center">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-1">
                      <li className="breadcrumb-item cursor">
                        <a
                          className="breadcrumb-link"
                          onClick={() => history.push("Booking_Request")}
                          href={() => false}
                        >
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item cursor"
                        aria-current="page"
                      >
                        <a
                          className="breadcrumb-link"
                          onClick={() => history.push("user")}
                          href={() => false}
                        >
                          Users
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        User Details
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-7 align-self-center">
                <div className="d-flex no-block justify-content-end align-items-center">
                  <div className="m-r-10">
                    <div className="lastmonth"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-md-flex align-items-center bg-light p-2">
                    <h5>Users</h5>
                    <div className="ml-auto d-flex no-block align-items-center">
                      <a
                        onClick={() => {
                          history.goBack();
                          localStorage.removeItem("UserId");
                        }}
                        className="btn btn-dark mr-3 "
                        href={() => false}
                      >
                        Back
                      </a>{" "}
                    </div>
                  </div>
                  <br className="clearfix" />
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="card">
                        <h5 className="card-header">Main Information</h5>
                        <div className="card-body pb-1">
                          <table className="table table1 table-borderless table-responsive table-sm">
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <td>{user.name}</td>
                              </tr>
                              <tr>
                                <th>Image</th>
                                <td>
                                  {user.userimagePath !== "" ? (
                                    <img
                                      src={user.userimagePath}
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="img/noimage.png"
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  )}
                                </td>
                              </tr>
                              {/* <tr>
                                <th>Description</th>
                                <td>{data.description}</td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br className="clearfix" />
                      <div className="card">
                        <h5 className="card-header">Documents Infromation</h5>
                        <div className="card-body pb-1">
                          <table className="table table1 table-borderless table-responsive table-sm">
                            <tbody>
                              <tr>
                                <th>Adhar Card Number </th>
                                <td>{user.aadhaarno}</td>
                              </tr>
                              <tr>
                                <th>Adhar Image</th>
                                <td>
                                  {user.adharimagePath !== "" ? (
                                    <img
                                      src={user.adharimagePath}
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="img/noimage.png"
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Pan Card Number </th>
                                <td>{user.panNumber} </td>
                              </tr>
                              <tr>
                                <th>Pan Card Image</th>
                                <td>
                                  {user.panimagePath !== "" ? (
                                    <img
                                      src={user.panimagePath}
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="img/noimage.png"
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <h5 className="card-header">Contact Information</h5>
                        <div className="card-body pb-1">
                          <table className="table table1 table-borderless table-responsive table-sm">
                            <tbody>
                              <tr>
                                <th>Email Address</th>
                                <td>
                                  <a
                                    href="*"
                                    onClick={() =>
                                      window.open(
                                        `mailto:${user.email}`,
                                        "_blank"
                                      )
                                    }
                                    target="blank"
                                  >
                                    {user.email}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <th>Phone Number</th>
                                <td>{user.phone}</td>
                              </tr>
                              <tr>
                                <th>ID</th>
                                <td>{user.userId}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br className="clearfix" />
                      <div className="card">
                        <h5 className="card-header">Payments Infromation</h5>
                        <div className="card-body pb-1">
                          <table className="table table1 table-borderless table-responsive table-sm">
                            <tbody>
                              <tr>
                                <th>Last Payment Date </th>
                                <td>{user.lastPaymentdate}</td>
                              </tr>
                              <tr>
                                <th>Next Payment Date </th>
                                <td>{user.nextPaymentdate} </td>
                              </tr>
                              <tr>
                                <th>Current Investment </th>
                                <td>{user.currentInvestment} </td>
                              </tr>
                              <tr>
                                <th>CurrentNoInvestPro </th>
                                <td>{user.currentNoInvestPro} </td>
                                <td> </td>
                              </tr>
                              <tr>
                                <th>Current Holdings</th>
                                <td>{user.currentHoldings} </td>
                              </tr>
                              <tr>
                                <th>Current FracValue</th>
                                <td>{user.currentFracValue} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      {/* <div className="card">
                        <h5 className="card-header">Property Information</h5>
                        <div className="card-body pb-1">
                          <table className="table table1 table-borderless table-responsive table-sm">
                            <tbody>
                              <tr>
                                <th>Bought Property</th>
                                {
                                  user.boughtProperty.map((e)=>(
                                    <td>{user.boughtProperty}</td>
                                  ))
                                }
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /.container-fluid -->  */}
        {/* <Edit_customers customerdata={data} /> */}
      </>
    )
  ) : (
    ""
  );
}

const mapStateToProps = (state, props) => {

  return {
    loading: state.user.loggedIn,
    user: state.user.user,
    error: state.user.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (user_ID) => dispatch(fetchUser(user_ID)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Userdetails);
