import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
import PlacesAutocomplete, { geocodeByAddress } from "react-google-autocomplete";
import TextField from "@material-ui/core/TextField";
import { Dropdown } from "react-bootstrap";
import {
    numberValidate,
    emailValidate,
    stringValidate,
    selectinputValidate,
    contactNumberValidation,
    postalCodeValidation,
    websiteValidation,
} from "../../utils/Validations";
import $ from "jquery";
import {
    updateBooking,
    updateBookingRequest,
} from "../../actions/manageBooking/updateBooking";
import {
    fetchAllProfileSetupType,
    fetchAllProfileDuration,
    fetchAllCustomerDropdown,
    fetchAllArtistDropdown,
    fetchAllSoundSystemDropdown,
    fetchAllSoundVenderDropdown
} from "../../actions/common/getAllDropdown"
import { fetchCustomerById } from "../../actions/cutomer/getCustomerById"

import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { GoogleComponent } from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import axios from "axios";
import Select from 'react-select';

import { geocodeByLatLng, getLatLng } from 'google-maps-react';
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const UpdateBooking = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [profileSetupTypeselectedOption, setProfileSetupTypeselectedOption] = useState("");
    const [profileDurationselectedOption, setProfileDurationselectedOption] = useState("");
    const [soundSystemSelectedOption, setSoundSystemSelectedOption] = useState("");
    const [soundVendorSelectedOption, setSoundVendorSelectedOption] = useState("");
    const [customerselectedOption, setCustomerselectedOption] = useState("");
    const [artistselectedOption, setArtistselectedOption] = useState("");
    const [address, setAddress] = useState('');
    const [artistSoundSelectedOption, setArtistSoundSelectedOption] = useState("");

    const {
        updateBookingDetails,
        loading,
        message,
        error,
        bookingDetailsById,
        refreshform,
        handleFetchAllProfileSetupType,
        handleFetchAllProfileDuration,
        handleFetchAllCustomerDropdown,
        handleFetchAllSoundSystemDropdown,
        handleFetchAllSoundVenderDropdown,
        handleFetchAllArtistDropdown,
        ProfileSetupTypeData,
        ProfileDurationData,
        CustomerDropdownData,
        ArtistDropdownData,
        hnaldeFetchCustomerById,
        customerByIdData,
        soundSystemDropdownData,
        soundVendorDropdownData

    } = props;

    const CurrentOptions = [
        { title: "Yes", value: true },
        { title: "No", value: false },
    ];


    //----------------  ADD CUSTOMER STATE TO HANDLE INPUT VALUES ------------***//
    const isArtistSoundData = [

        { value: "true", label: "Yes" },
        { value: "false", label: "No" },

    ];
    const bookingStateData = [
        {
            name: "Enquiry",
        },
        {
            name: "Requested",
        },
        {
            name: "Deleted",
        },
        {
            name: "PaymentAwaited",
        },
        {
            name: "Abandoned",
        },
        {
            name: "Confirmed",
        },
        {
            name: "Completed",
        },
        {
            name: "Settled",
        },
        {
            name: "Canceled",
        },
    ];

    const customerTypeDropDownData = [
        {
            name: "Individual",
        },
        {
            name: "Venue",
        },
        {
            name: "Event Manager",
        },
        {
            name: "Corporate",
        },
    ]
    const addressTypeDropDownData = [
        {
            name: "Home",
        },
        {
            name: "Work",
        },
        {
            name: "Other",
        },

    ]

    useEffect(() => {
        handleFetchAllCustomerDropdown()
        handleFetchAllProfileDuration()
        handleFetchAllProfileSetupType()
        handleFetchAllArtistDropdown()
        handleFetchAllSoundSystemDropdown()
        handleFetchAllSoundVenderDropdown()
    }, [])
    const [selectdata, setselectdata] = useState({});
    const [addressData, setAddressData] = useState({
        longitude: "",
        latitude: "",
        addressName: "",
        street: "",
        city: "",
        state: "",
        country: "",
    })
    const [formatedAddressData, setFormatedAddressData] = useState({
        address: ""
    })
    const [customerUserData, setCustomerUserData] = useState({
        hostName: "",
        hostPhone: "",
    })
    const [updatebooking, setUpdateBooking] = useState({
        bookingId: "",
        customerType: "",
        bookingState: "",
        startTime: "",
        eventDate: "",
        endTime: "",
        hostName: "",
        hostPhone: "",
        hostPhone2: "",
        specialRequest: "",
        numberOfGuests: "",
        occasion: "",
        location: "",
        isArtistSound: "",
        artistPerformanceCharge: "",
        soundSetupCharge: "",
        subtotal: "",
        discountCode: "",
        discountPercentage: "",
        discountAmount: "",
        artistTransportationCharge: "",
        gST: "",
        grandTotal: "",
        creationDate: "",
        modificationDate: "",
        bookingClosingDate: "",
        companyShare: "",
        artistShare: "",
        soundVendorShare: "",
        totalAmount: "",
        addressType: "",
        googleMapLocation: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        longitude: "",
        latitude: "",
        setupTypeId: "",
        soundSystemId: "",
        soundVendorId: "",
        setupTypeId: "",
        durationId: "",
        customerId: "",
        artistId: "",
    });
    const clearAddressState = () => {
        setAddressData({
            ...addressData,
            longitude: "",
            latitude: "",
        })
    }
    const handleProfileSetupType = (option) => {

        setProfileSetupTypeselectedOption(option.label)
        setUpdateBooking({
            ...updatebooking,
            setupTypeId: option.value,
        })
    }
    const handleProfileDuration = (option) => {

        setProfileDurationselectedOption(option.label)
        setUpdateBooking({
            ...updatebooking,
            durationId: option.value,
        })
    }
    const handleCustomer = (option) => {
        hnaldeFetchCustomerById(option.value)
        setCustomerselectedOption(option.label)
        setUpdateBooking({
            ...updatebooking,
            customerId: option.value,
        })
    }
    const handleSoundSystem = (option) => {

        setSoundSystemSelectedOption(option.label)
        setUpdateBooking({
            ...updatebooking,
            soundSystemId: option.value,
        })
    }
    const handleSoundVendor = (option) => {
        setSoundVendorSelectedOption(option.label)
        setUpdateBooking({
            ...updatebooking,
            soundVendorId: option.value,
        })
    }
    const handleArtist = (option) => {

        setArtistselectedOption(option.label)
        setUpdateBooking({
            ...updatebooking,
            artistId: option.value,
        })
    }
    const [imageData, setImagedata] = useState({
        imageBase: "",
        imageError: "",
    });

    useEffect(() => {
        setUpdateBooking({
            ...updatebooking,
            id: bookingDetailsById.bookingId,
            customerType: bookingDetailsById.customerType,
            bookingState: bookingDetailsById.bookingState,
            startTime: bookingDetailsById.startTime,
            eventDate: bookingDetailsById.eventDate,
            endTime: bookingDetailsById.endTime,
            hostName: bookingDetailsById.hostName,
            hostPhone: bookingDetailsById.hostPhone,
            hostPhone2: bookingDetailsById.hostPhone2,
            specialRequest: bookingDetailsById.specialRequest,
            numberOfGuests: bookingDetailsById.numberOfGuests,
            occasion: bookingDetailsById.occasion,
            location: bookingDetailsById.location,
            isArtistSound: bookingDetailsById.artistSound,
            artistPerformanceCharge: bookingDetailsById.artistPerformanceCharge,
            soundSetupCharge: bookingDetailsById.soundSetupCharge,
            subtotal: bookingDetailsById.subtotal,
            discountCode: bookingDetailsById.discountCode,
            discountPercentage: bookingDetailsById.discountPercentage,
            discountAmount: bookingDetailsById.discountAmount,
            artistTransportationCharge: bookingDetailsById.artistTransportationCharge,
            gST: bookingDetailsById.gST,
            grandTotal: bookingDetailsById.grandTotal,
            creationDate: bookingDetailsById.creationDate,
            modificationDate: bookingDetailsById.modificationDate,
            bookingClosingDate: bookingDetailsById.bookingClosingDate,
            companyShare: bookingDetailsById.companyShare,
            artistShare: bookingDetailsById.artistShare,
            soundVendorShare: bookingDetailsById.soundVendorShare,
            totalAmount: bookingDetailsById.totalAmount,
            addressType: bookingDetailsById?.venueAddress?.addressType,
            googleMapLocation: bookingDetailsById?.venueAddress?.googleMapLocation,
            street: bookingDetailsById?.venueAddress?.street,
            city: bookingDetailsById?.venueAddress?.city,
            state: bookingDetailsById?.venueAddress?.state,
            zipCode: bookingDetailsById?.venueAddress?.zipCode,
            country: bookingDetailsById?.venueAddress?.country,
            longitude: bookingDetailsById?.venueAddress?.longitude,
            latitude: bookingDetailsById?.venueAddress?.latitude,
            customerId: bookingDetailsById?.customer?.customerId,
            artistId: bookingDetailsById?.artist?.artistId,
            setupTypeId: bookingDetailsById?.profileSetupType?.setupTypeId,
            durationId: bookingDetailsById?.profileDuration?.durationId,
            soundSystemId: bookingDetailsById?.selectedSoundSystem?.soundSystemId,
            soundVendorId: bookingDetailsById?.soundVendor?.soundVendorId
        });
        setArtistSoundSelectedOption(bookingDetailsById.artistSound == true ? "Yes" : "No")

        setAddressData({
            ...addressData,
            longitude: bookingDetailsById?.venueAddress?.longitude,
            latitude: bookingDetailsById?.venueAddress?.latitude,
            addressName: bookingDetailsById?.venueAddress?.addressName,
            street: bookingDetailsById?.venueAddress?.street,
            city: bookingDetailsById?.venueAddress?.city,
            state: bookingDetailsById?.venueAddress?.state,
            country: bookingDetailsById?.venueAddress?.country,
        })
        setFormatedAddressData({
            ...formatedAddressData,
            address: bookingDetailsById?.venueAddress?.googleMapLocation
        })
        setProfileSetupTypeselectedOption(bookingDetailsById?.profileSetupType?.setupType)
        setProfileDurationselectedOption(bookingDetailsById?.profileDuration?.durationType)
        setCustomerselectedOption(bookingDetailsById?.customer?.customerUser?.userName)
        setArtistselectedOption(bookingDetailsById?.artist?.profileName)
        setSoundSystemSelectedOption(bookingDetailsById?.selectedSoundSystem?.title)
        setSoundVendorSelectedOption(bookingDetailsById?.soundVendor?.companyName)
    }, [bookingDetailsById]);



    //----------------  ADD CUSTOMER FIELDS ERROR ------------***//
    const [addupdatebookingerrors, setAddUpdateBookingError] = useState({
        customerType: null,
        bookingState: null,
        startTime: null,
        eventDate: null,
        endTime: null,
        hostName: null,
        hostPhone: null,
        hostPhone2: null,
        specialRequest: null,
        numberOfGuests: null,
        occasion: null,
        location: null,
        isArtistSound: null,
        artistPerformanceCharge: null,
        soundSetupCharge: null,
        subtotal: null,
        discountCode: null,
        discountPercentage: null,
        discountAmount: null,
        artistTransportationCharge: null,
        gST: null,
        grandTotal: null,
        creationDate: null,
        modificationDate: null,
        bookingClosingDate: null,
        companyShare: null,
        artistShare: null,
        soundVendorShare: null,
        totalAmount: null,
        addressType: null,
        googleMapLocation: null,
        street: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        longitude: null,
        latitude: null,
        setupTypeId: null,
        soundSystemId: null,
        soundVendorId: null,
        durationId: null,
        customerId: null,
        artistId: null,

    });

    const clearState = () => {
        setUpdateBooking({
            ...updatebooking,
            bookingId: "",
            customerType: "",
            bookingState: "",
            startTime: "",
            eventDate: "",
            endTime: "",
            hostName: "",
            hostPhone: "",
            hostPhone2: "",
            specialRequest: "",
            numberOfGuests: "",
            occasion: "",
            location: "",
            isArtistSound: "",
            artistPerformanceCharge: "",
            soundSetupCharge: "",
            subtotal: "",
            discountCode: "",
            discountPercentage: "",
            discountAmount: "",
            artistTransportationCharge: "",
            gST: "",
            grandTotal: "",
            creationDate: "",
            modificationDate: "",
            bookingClosingDate: "",
            companyShare: "",
            artistShare: "",
            soundVendorShare: "",
            totalAmount: "",
            addressType: "",
            googleMapLocation: "",
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            longitude: "",
            latitude: "",
            setupTypeId: "",
            soundSystemId: "",
            soundVendorId: "",
            durationId: "",
            customerId: "",
            artistId: "",
        });
    };

    const handleValidation = () => {
        setAddUpdateBookingError({
            ...addupdatebookingerrors,
            // customerType: stringValidate(updatebooking.customerType, "CustomerType"),
            bookingState: stringValidate(updatebooking.bookingState, "BookingState"),
            startTime: stringValidate(updatebooking.startTime, "StartTime"),
            eventDate: stringValidate(updatebooking.eventDate, "EventDate"),
            // endTime: stringValidate(updatebooking.endTime, "EndTime"),
            // hostName: stringValidate(updatebooking.hostName, "HostName"),
            // hostPhone: contactNumberValidation(updatebooking.hostPhone, "HostPhone"),
            numberOfGuests: numberValidate(updatebooking.numberOfGuests, "NumberOfGuests"),
            occasion: stringValidate(updatebooking.occasion, "Occasion"),
            // location: stringValidate(updatebooking.location, "Location"),
            // isArtistSound: stringValidate(updatebooking.isArtistSound, "IsArtistSound"),
            // artistPerformanceCharge: numberValidate(updatebooking.artistPerformanceCharge, "ArtistPerformanceCharge"),
            // soundSetupCharge: numberValidate(updatebooking.soundSetupCharge, "SoundSetupCharge"),

            // discountPercentage: numberValidate(updatebooking.discountPercentage, "DiscountPercentage"),
            // discountAmount: numberValidate(updatebooking.discountAmount, "DiscountAmount"),
            // artistTransportationCharge: numberValidate(updatebooking.artistTransportationCharge, "ArtistTransportationCharge"),
            // gST: numberValidate(updatebooking.gST, "GST"),
            // grandTotal: numberValidate(updatebooking.grandTotal, "GrandTotal"),
            // bookingClosingDate: stringValidate(updatebooking.bookingClosingDate, "BookingClosingDate"),
            // companyShare: numberValidate(updatebooking.companyShare, "CompanyShare"),
            // artistShare: numberValidate(updatebooking.artistShare, "ArtistShare"),
            // soundVendorShare: numberValidate(updatebooking.soundVendorShare, "SoundVendorShare"),
            // totalAmount: numberValidate(updatebooking.totalAmount, "TotalAmount"),
            // addressType: stringValidate(updatebooking.addressType, "AddressType"),
            // googleMapLocation: stringValidate(updatebooking.googleMapLocation, "GoogleMapLocation"),
            // street: stringValidate(updatebooking.street, "Street"),
            // city: stringValidate(updatebooking.city, "City"),
            // state: stringValidate(updatebooking.state, "State"),
            // zipCode: numberValidate(updatebooking.zipCode, "ZipCode"),
            // country: stringValidate(updatebooking.country, "Country"),
            longitude: stringValidate(addressData.longitude, "Longitude"),
            latitude: stringValidate(addressData.latitude, "Latitude"),
            setupTypeId: numberValidate(updatebooking.setupTypeId, "SetupTypeId"),
            durationId: numberValidate(updatebooking.durationId, "DurationId"),
            customerId: numberValidate(updatebooking.customerId, "CustomerId"),
            artistId: numberValidate(updatebooking.artistId, "ArtistId"),
        });
    };

    useEffect(() => {
        if (localStorage.getItem("message") === "RecordUpdatedFailed") {
            enqueueSnackbar("Booking Updated Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform();
        }

        if (localStorage.getItem("message") === "RecordUpdatedSuccessfully") {
            handleClearValidationError();

            enqueueSnackbar("Booking Updated successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //---------------------------------------  ADD userS FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleUpdateBooking = (e) => {
        handleValidation();
        if (
            // stringValidate(updatebooking.customerType, "CustomerType") === null &&
            stringValidate(updatebooking.bookingState, "BookingState") === null &&
            stringValidate(updatebooking.startTime, "StartTime") === null &&
            stringValidate(updatebooking.eventDate, "EventDate") === null &&
            // stringValidate(updatebooking.endTime, "EndTime") === null &&
            stringValidate(updatebooking.occasion, "Occasion") === null &&
            // contactNumberValidation(updatebooking.hostPhone, "HostPhone") === null &&
            numberValidate(updatebooking.numberOfGuests, "NumberOfGuests") === null &&
            // stringValidate(updatebooking.isArtistSound, "IsArtistSound") === null &&
            // numberValidate(updatebooking.artistPerformanceCharge, "ArtistPerformanceCharge") === null &&
            // numberValidate(updatebooking.soundSetupCharge, "SoundSetupCharge") === null &&
            // numberValidate(updatebooking.subtotal, "Subtotal") === null &&
            // stringValidate(updatebooking.discountCode, "DiscountCode") === null &&
            // // numberValidate(updatebooking.discountPercentage, "DiscountPercentage") === null &&
            // numberValidate(updatebooking.discountAmount, "DiscountAmount") === null &&
            // numberValidate(updatebooking.artistTransportationCharge, "ArtistTransportationCharge") === null &&
            // numberValidate(updatebooking.gST, "GST") === null &&
            // numberValidate(updatebooking.grandTotal, "GrandTotal") === null &&
            // stringValidate(updatebooking.bookingClosingDate, "BookingClosingDate") === null &&
            // numberValidate(updatebooking.companyShare, "CompanyShare") === null &&
            // numberValidate(updatebooking.artistShare, "ArtistShare") === null &&
            // numberValidate(updatebooking.soundVendorShare, "SoundVendorShare") === null &&
            // numberValidate(updatebooking.totalAmount, "TotalAmount") === null &&
            // stringValidate(updatebooking.addressType, "AddressType") === null &&
            // stringValidate(updatebooking.googleMapLocation, "GoogleMapLocation") === null &&
            // stringValidate(updatebooking.street, "Street") === null &&
            // stringValidate(updatebooking.city, "City") === null &&
            // stringValidate(updatebooking.state, "State") === null &&
            // numberValidate(updatebooking.zipCode, "ZipCode") === null &&
            // stringValidate(updatebooking.country, "Country") === null &&
            stringValidate(addressData.longitude, "Longitude") === null &&
            stringValidate(addressData.latitude, "Latitude") === null &&
            numberValidate(updatebooking.setupTypeId, "SetupTypeId") === null &&
            numberValidate(updatebooking.durationId, "DurationId") === null &&
            numberValidate(updatebooking.customerId, "CustomerId") === null &&
            numberValidate(updatebooking.artistId, "ArtistId") === null
        ) {
            handleClearValidationError();

            updateBookingDetails(updatebooking, addressData, customerUserData);
        }
    };

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
    const handleClearValidationError = () => {
        setAddUpdateBookingError({
            ...addupdatebookingerrors,
            customerType: null,
            bookingState: null,
            startTime: null,
            eventDate: null,
            endTime: null,
            hostName: null,
            hostPhone: null,
            hostPhone2: null,
            specialRequest: null,
            numberOfGuests: null,
            occasion: null,
            location: null,
            isArtistSound: null,
            artistPerformanceCharge: null,
            soundSetupCharge: null,
            subtotal: null,
            discountCode: null,
            discountPercentage: null,
            discountAmount: null,
            artistTransportationCharge: null,
            gST: null,
            grandTotal: null,
            creationDate: null,
            modificationDate: null,
            bookingClosingDate: null,
            companyShare: null,
            artistShare: null,
            soundVendorShare: null,
            totalAmount: null,
            addressType: null,
            googleMapLocation: null,
            street: null,
            city: null,
            state: null,
            zipCode: null,
            country: null,
            longitude: null,
            latitude: null,

            setupTypeId: null,
            durationId: null,
            customerId: null,
            artistId: null,
        });

        clearState();
    };

    const onPlacesChanged = (place) => {
        const formattedAddress = place.formatted_address;
        const { geometry, address_components, formatted_address } = place;

        if (address_components && geometry && geometry.location && formatted_address) {
            const { lat, lng } = geometry.location;
            const addressParts = formattedAddress.split(', ');

            const street = addressParts[0]; // The street
            const city = addressParts[1]; // The city
            const state = addressParts[2]; // The state
            const country = addressParts[3]; // The country
            let pinCode = '';
            for (let i = addressParts.length - 1; i >= 0; i--) {
                if (/\d/.test(addressParts[i])) {
                    pinCode = addressParts[i];
                    break;
                }
            }
            // setUpdateBooking({
            //     ...updatebooking,
            //     street: street,
            //     city: city,
            //     state: state,
            //     country: country,
            // })

            setFormatedAddressData({
                ...formatedAddressData,
                address: formattedAddress
            })
            setAddressData({
                ...addressData,
                latitude: lat(),
                longitude: lng(),
                addressName: formattedAddress,
                street: street,
                city: city,
                state: state,
                country: country,
            })

        }
    };
    const handleArtistSound = (option) => {

        setArtistSoundSelectedOption(option.label)
        setUpdateBooking({
            ...updatebooking,
            isArtistSound: option.value,
        })
    }
    const handleTimeChange = (event) => {
        // setUpdateBooking({
        //         ...updatebooking,
        if (profileDurationselectedOption == "1 hour") {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 1);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setUpdateBooking({
                ...updatebooking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }
        else if (profileDurationselectedOption == "2 hour") {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 2);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setUpdateBooking({
                ...updatebooking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }
        else if (profileDurationselectedOption == "3 hour") {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 3);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setUpdateBooking({
                ...updatebooking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }
        else {
            const selectedTime = event.target.value;
            const [hours, minutes] = selectedTime.split(':');
            let date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setHours(date.getHours() + 4);
            const newTime = date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            setUpdateBooking({
                ...updatebooking,
                startTime: selectedTime,
                endTime: newTime,
            })
        }
    };

    useEffect(() => {
        setCustomerUserData({
            ...customerUserData,
            hostName: customerByIdData?.customerUser?.userName,
            hostPhone: customerByIdData?.customerUser?.phoneNumber,
        });
    }, [customerByIdData])

    return (
        <>
            {/* // <!-- The Modal --> */}
            <div className="modal" id="editBookingModal">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
                            <h4 className="modal-title">Update Booking</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={handleClearValidationError}
                            >
                                &times;
                            </button>
                        </div>

                        {/* <!-- Modal body --> */}
                        <div className="modal-body">
                            <div id="accordion">
                                <div className="card mt-2">
                                    <div className="card-header Accordian" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-text d-flex w-100"
                                                data-toggle="collapse"
                                                data-target="#contactinfo"
                                                aria-expanded="true"
                                                aria-controls="collapseTwo"
                                                style={{ height: "50px" }}
                                            >
                                                <h5 className="pb-4">Booking Information</h5>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id="contactinfo"
                                        className="collapse show"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Customer Type{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        {/* <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="customer Type"
                                                        id="subs"
                                                        value={updatebooking.customerType}
                                                        disabled
                                                    onChange={(e) =>
                                                        setUpdateBooking({
                                                            ...updatebooking,
                                                            customerType: e.target.value,
                                                        })
                                                    }
                                                    onBlur={() =>
                                                        setAddUpdateBookingError({
                                                            ...addupdatebookingerrors,
                                                            customerType: stringValidate(
                                                                updatebooking.customerType,
                                                                "CustomerType"
                                                            ),
                                                        })
                                                    }
                                                    /> */}
                                                        <Dropdown class="formDropDown">
                                                            <Dropdown.Toggle
                                                                id="dropdown-basic"
                                                                class="formDropDownToggle"
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                {updatebooking.customerType
                                                                    ? updatebooking.customerType
                                                                    : "Customer Type"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {customerTypeDropDownData.map((option) => (
                                                                    <Dropdown.Item
                                                                        key={option}
                                                                        onClick={() =>
                                                                            setUpdateBooking({
                                                                                ...updatebooking,
                                                                                customerType: option.name,
                                                                            })
                                                                        }
                                                                        onBlur={() =>
                                                                            setAddUpdateBookingError({
                                                                                ...addupdatebookingerrors,
                                                                                customerType: stringValidate(
                                                                                    updatebooking.customerType,
                                                                                    "CustomerType"
                                                                                ),
                                                                            })
                                                                        }
                                                                    >
                                                                        {option.name}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.customerType}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Booking State<span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>

                                                        <Dropdown class="formDropDown">
                                                            <Dropdown.Toggle
                                                                id="dropdown-basic"
                                                                class="formDropDownToggle"
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                {updatebooking.bookingState
                                                                    ? updatebooking.bookingState
                                                                    : "Booking State"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {bookingStateData.map((option) => (
                                                                    <Dropdown.Item
                                                                        key={option}
                                                                        onClick={() =>
                                                                            setUpdateBooking({
                                                                                ...updatebooking,
                                                                                bookingState: option.name,
                                                                            })
                                                                        }
                                                                        onBlur={() => {
                                                                            setAddUpdateBookingError({
                                                                                ...addupdatebookingerrors,
                                                                                bookingState: stringValidate(
                                                                                    updatebooking.bookingState,
                                                                                    "BookingState"
                                                                                ),
                                                                            });
                                                                        }}
                                                                    >
                                                                        {option.name}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.bookingState}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Artist{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {artistselectedOption ? artistselectedOption : "Select Artist"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {/* {ArtistDropdownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleArtist(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddUpdateBookingError({
                                                                            ...addupdatebookingerrors,
                                                                            artistId: numberValidate(
                                                                                updatebooking.artistId,
                                                                                "ArtistId"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))} */}
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={ArtistDropdownData}
                                                                    value={artistselectedOption}
                                                                    onChange={handleArtist}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.artistId}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Customer{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {customerselectedOption ? customerselectedOption : "Select Customer"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {/* {CustomerDropdownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleCustomer(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddUpdateBookingError({
                                                                            ...addupdatebookingerrors,
                                                                            customerId: numberValidate(
                                                                                updatebooking.customerId,
                                                                                "CustomerId"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))} */}
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={CustomerDropdownData}
                                                                    value={customerselectedOption}
                                                                    onChange={handleCustomer}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.customerId}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Profile Setup Type{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {profileSetupTypeselectedOption ? profileSetupTypeselectedOption : "Profile Setup Type"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {ProfileSetupTypeData.map((option) => (
                                                                    <Dropdown.Item
                                                                        key={option}
                                                                        onClick={() =>
                                                                            handleProfileSetupType(option)
                                                                        }
                                                                        onBlur={() =>
                                                                            setAddUpdateBookingError({
                                                                                ...addupdatebookingerrors,
                                                                                setupTypeId: numberValidate(
                                                                                    updatebooking.setupTypeId,
                                                                                    "SetupTypeId"
                                                                                ),
                                                                            })
                                                                        }
                                                                    >
                                                                        {option.label}
                                                                    </Dropdown.Item>
                                                                ))}
                                                                {/* <Select
                                                                style={{ width: "100%" }}
                                                                options={ProfileSetupTypeData}
                                                                value={profileSetupTypeselectedOption}
                                                                onChange={handleProfileSetupType}
                                                                onBlur={() =>
                                                                    setAddUpdateBookingError({
                                                                        ...addupdatebookingerrors,
                                                                        setupTypeId: numberValidate(
                                                                            updatebooking.setupTypeId,
                                                                            "SetupTypeId"
                                                                        ),
                                                                    })
                                                                }
                                                                isSearchable
                                                            /> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.setupTypeId}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Profile Duration{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {profileDurationselectedOption ? profileDurationselectedOption : "Profile Duration"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>
                                                                {ProfileDurationData.map((option) => (
                                                                    <Dropdown.Item
                                                                        key={option}
                                                                        onClick={() =>
                                                                            handleProfileDuration(option)
                                                                        }
                                                                        onBlur={() =>
                                                                            setAddUpdateBookingError({
                                                                                ...addupdatebookingerrors,
                                                                                durationId: numberValidate(
                                                                                    updatebooking.durationId,
                                                                                    "DurationId"
                                                                                ),
                                                                            })
                                                                        }
                                                                    >
                                                                        {option.label}
                                                                    </Dropdown.Item>
                                                                ))}
                                                                {/* <Select
                                                                style={{ width: "100%" }}
                                                                options={ProfileDurationData}
                                                                value={profileDurationselectedOption}
                                                                onChange={handleProfileDuration}
                                                                onBlur={() =>
                                                                    setAddUpdateBookingError({
                                                                        ...addupdatebookingerrors,
                                                                        durationId: numberValidate(
                                                                            updatebooking.durationId,
                                                                            "DurationId"
                                                                        ),
                                                                    })
                                                                }
                                                                isSearchable
                                                            /> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.durationId}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Event Date{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Event Date"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={updatebooking.eventDate}
                                                            onChange={(e) =>
                                                                setUpdateBooking({
                                                                    ...updatebooking,
                                                                    eventDate: e.target.value,
                                                                })
                                                            }
                                                            onBlur={() =>
                                                                setAddUpdateBookingError({
                                                                    ...addupdatebookingerrors,
                                                                    eventDate: stringValidate(
                                                                        updatebooking.eventDate,
                                                                        "EventDate"
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.eventDate}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Start Time{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            rows="5"
                                                            id="comment"
                                                            placeholder="Start Time"
                                                            value={updatebooking.startTime}
                                                            onChange={(e) =>
                                                                handleTimeChange(e)
                                                            }
                                                            onBlur={() =>
                                                                setAddUpdateBookingError({
                                                                    ...addupdatebookingerrors,
                                                                    startTime: stringValidate(
                                                                        updatebooking.startTime,
                                                                        "StartTime"
                                                                    ),
                                                                })
                                                            }
                                                        />

                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.startTime}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            End Time
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="End Time"
                                                            id=""
                                                            // ref={ConfigurationRef}
                                                            value={updatebooking.endTime}
                                                            disabled
                                                        // onChange={(e) =>
                                                        //     setUpdateBooking({
                                                        //         ...updatebooking,
                                                        //         endTime: e.target.value,
                                                        //     })
                                                        // }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         endTime: stringValidate(updatebooking.endTime, "EndTime"),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.endTime}
                                                    </p> */}
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Host Name
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type="subs"
                                                            className="form-control"
                                                            placeholder="Host Name"
                                                            id="subs"
                                                            // ref={ToPriceRef}
                                                            value={customerUserData.hostName}
                                                            onChange={(e) =>
                                                                setCustomerUserData({
                                                                    ...customerUserData,
                                                                    hostName: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         hostName: stringValidate(
                                                        //             customerUserData.hostName,
                                                        //             "HostName"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.hostName}
                                                    </p> */}
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Host Phone
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type="subs"
                                                            className="form-control"
                                                            placeholder="Host Phone"
                                                            id="subs"
                                                            // ref={DiscountRef}
                                                            value={customerUserData.hostPhone}
                                                            onChange={(e) =>
                                                                setCustomerUserData({
                                                                    ...customerUserData,
                                                                    hostPhone: e.target.value,
                                                                })
                                                            }
                                                            onBlur={() =>
                                                                setAddUpdateBookingError({
                                                                    ...addupdatebookingerrors,
                                                                    hostPhone: contactNumberValidation(
                                                                        updatebooking.hostPhone,
                                                                        "HostPhone"
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.hostPhone}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Host Phone 2{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type="subs"
                                                            className="form-control"
                                                            placeholder="hostPhone2"
                                                            id="subs"
                                                            // ref={AreaRef}
                                                            value={updatebooking.hostPhone2}
                                                            onChange={(e) =>
                                                                setUpdateBooking({
                                                                    ...updatebooking,
                                                                    hostPhone2: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         hostPhone2: stringValidate(
                                                        //             booking.hostPhone2,
                                                        //             "HostPhone2"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.hostPhone2}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Special Request{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Special Request"
                                                            id="subs"
                                                            // ref={FileRef}
                                                            value={updatebooking.specialRequest}
                                                            onChange={(e) =>
                                                                setUpdateBooking({
                                                                    ...updatebooking,
                                                                    specialRequest: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         specialRequest: stringValidate(
                                                        //             booking.specialRequest,
                                                        //             "SpecialRequest"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />

                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.specialRequest}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Number Of Guests{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Number Of Guests"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={updatebooking.numberOfGuests}
                                                            onChange={(e) =>
                                                                setUpdateBooking({
                                                                    ...updatebooking,
                                                                    numberOfGuests: e.target.value,
                                                                })
                                                            }
                                                            onBlur={() =>
                                                                setAddUpdateBookingError({
                                                                    ...addupdatebookingerrors,
                                                                    numberOfGuests: numberValidate(
                                                                        updatebooking.numberOfGuests,
                                                                        "NumberOfGuests"
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.numberOfGuests}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Occasion{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Occasion"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={updatebooking.occasion}
                                                            onChange={(e) =>
                                                                setUpdateBooking({
                                                                    ...updatebooking,
                                                                    occasion: e.target.value,
                                                                })
                                                            }
                                                            onBlur={() =>
                                                                setAddUpdateBookingError({
                                                                    ...addupdatebookingerrors,
                                                                    occasion: stringValidate(
                                                                        updatebooking.occasion,
                                                                        "Occasion"
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.occasion}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Artist Has Own Sound System?{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                   
                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {artistSoundSelectedOption ? artistSoundSelectedOption
                                                                : "Artist Has Own Sound System?"
                                                            }

                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {isArtistSoundData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleArtistSound(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddUpdateBookingError({
                                                                            ...addupdatebookingerrors,
                                                                            isArtistSound: stringValidate(
                                                                                updatebooking.isArtistSound,
                                                                                "IsArtistSound"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addupdatebookingerrors.isArtistSound}
                                                    </p>
                                                </div>
                                            </div> */}

                                                {/* <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Company Share{" "}
                                                        
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="CompanyShare"
                                                        id=""
                                                        

                                                        value={updatebooking.companyShare}
                                                        onChange={(e) =>
                                                            setUpdateBooking({
                                                                ...updatebooking,
                                                                companyShare: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddUpdateBookingError({
                                                                ...addupdatebookingerrors,
                                                                companyShare: numberValidate(
                                                                    updatebooking.companyShare,
                                                                    "CompanyShare"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addupdatebookingerrors.companyShare}
                                                    </p>
                                                </div>
                                            </div> */}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-header Accordian" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-text d-flex w-100"
                                                data-toggle="collapse"
                                                data-target="#vanueinfo"
                                                aria-expanded="true"
                                                aria-controls="collapseTwo"
                                                style={{ height: "50px" }}
                                            >
                                                <h5 className="pb-4">Sound System and Sound vendor</h5>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id="vanueinfo"
                                        className="collapse show"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Sound System{" "}

                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {soundSystemSelectedOption ? soundSystemSelectedOption : "Sound System"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>

                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={soundSystemDropdownData}
                                                                    value={soundSystemSelectedOption}
                                                                    onChange={handleSoundSystem}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {/* {addbookingerrors.setupTypeId} */}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Sound Vendor{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <Dropdown class="formDropDown mt-4 mb-4">
                                                            <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                                style={{
                                                                    width: "100%",
                                                                    background: "#D7263D",
                                                                    outline: "none",
                                                                    border: "none",
                                                                }}>
                                                                {soundVendorSelectedOption ? soundVendorSelectedOption : "Sound Vendor"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu style={{ width: "100%" }}>

                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    options={soundVendorDropdownData}
                                                                    value={soundVendorSelectedOption}
                                                                    onChange={handleSoundVendor}
                                                                    isSearchable
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <p className="profile-input-errors">
                                                            {/* {addbookingerrors.setupTypeId} */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-header Accordian" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-text d-flex w-100"
                                                data-toggle="collapse"
                                                data-target="#contactinfo"
                                                aria-expanded="true"
                                                aria-controls="collapseTwo"
                                                style={{ height: "50px" }}
                                            >
                                                <h5 className="pb-4">Venue Address</h5>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id="contactinfo"
                                        className="collapse show"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Location{" "}
                                                            <span style={{ color: "#d20d0d" }}>*</span>
                                                        </label>
                                                        <PlacesAutocomplete
                                                            apiKey={apiKey}
                                                            style={{ width: "100%", height: "38px", borderRadius: "5px" }}
                                                            onPlaceSelected={(place) => {
                                                                onPlacesChanged(place);
                                                            }}

                                                            coordinates={true}
                                                            options={{
                                                                // types: ["(regions)"],
                                                                types: ["establishment"],
                                                                componentRestrictions: { country: "IND" },
                                                            }}
                                                            defaultValue={addressData.addressName}
                                                        />
                                                        <p className="profile-input-errors">
                                                            {addupdatebookingerrors.longitude}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Street{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="street"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.street}
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    street: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         street: stringValidate(
                                                        //             updatebooking.street,
                                                        //             "Street"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.street}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            City{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="City"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.city}
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    city: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         city: stringValidate(
                                                        //             updatebooking.city,
                                                        //             "City"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.city}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            State{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="state"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.state}
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    state: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         state: stringValidate(
                                                        //             updatebooking.state,
                                                        //             "state"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.state}
                                                    </p> */}
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Country{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="Country"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={addressData.country}
                                                            onChange={(e) =>
                                                                setAddressData({
                                                                    ...addressData,
                                                                    country: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         country: stringValidate(
                                                        //             updatebooking.country,
                                                        //             "Country"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.country}
                                                    </p> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>
                                                            Zip Code{" "}
                                                            {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                        </label>
                                                        <input
                                                            type=""
                                                            className="form-control"
                                                            placeholder="zipCode"
                                                            id=""
                                                            // ref={OfferRef}
                                                            value={updatebooking.zipCode}
                                                            onChange={(e) =>
                                                                setUpdateBooking({
                                                                    ...updatebooking,
                                                                    zipCode: e.target.value,
                                                                })
                                                            }
                                                        // onBlur={() =>
                                                        //     setAddUpdateBookingError({
                                                        //         ...addupdatebookingerrors,
                                                        //         zipCode: numberValidate(
                                                        //             updatebooking.zipCode,
                                                        //             "ZipCode"
                                                        //         ),
                                                        //     })
                                                        // }
                                                        />
                                                        {/* <p className="profile-input-errors">
                                                        {addupdatebookingerrors.zipCode}
                                                    </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                // data-dismiss="modal"
                                onClick={handleUpdateBooking}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="cover-spin"></div> : ""}
        </>
    );
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.updateBooking.loading,
        message: state.updateBooking.message,
        error: state.updateBooking.error,
        bookingDetailsById: state.getBookingById.data,
        ProfileSetupTypeData: state.ProfileSetupType.ProfileSetupTypeData,
        ProfileDurationData: state.ProfileDuration.ProfileDurationData,
        CustomerDropdownData: state.customerDropdown.CustomerDropdown,
        ArtistDropdownData: state.artistDropdown.ArtistDropdown,
        customerByIdData: state.getCustomerById.data,
        soundSystemDropdownData: state.soundSystemDropdown.SoundSystemDropdown,
        soundVendorDropdownData: state.soundVendorDropdown.SoundVenderDropdown
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBookingDetails: (data, addressData, customerUserData) =>
            dispatch(updateBooking(data, addressData, customerUserData)),
        refreshform: () => dispatch(updateBookingRequest()),
        handleFetchAllProfileSetupType: () => dispatch(fetchAllProfileSetupType()),
        handleFetchAllProfileDuration: () => dispatch(fetchAllProfileDuration()),
        handleFetchAllCustomerDropdown: () => dispatch(fetchAllCustomerDropdown()),
        handleFetchAllArtistDropdown: () => dispatch(fetchAllArtistDropdown()),
        handleFetchAllSoundSystemDropdown: () => dispatch(fetchAllSoundSystemDropdown()),
        handleFetchAllSoundVenderDropdown: () => dispatch(fetchAllSoundVenderDropdown()),
        hnaldeFetchCustomerById: (id) => dispatch(fetchCustomerById(id))

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdateBooking));
