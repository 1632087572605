import {
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllCustomer } from "../cutomer/getAllCustomer"
export const addCustomerRequest = () => {
  return {
    type: ADD_CUSTOMER_REQUEST,
  };
};

export const addCustomerSuccess = (Data) => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: { data: Data.data, message: Data.message },
  };
};

export const addCustomerFailure = (Error) => {
  return {
    type: ADD_CUSTOMER_FAILURE,
    payload: Error,
  };
};
export const postCustomer = (data, addressData, billingAddressData) => {
  var headers = {
    "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,
  };


  let formdata = new FormData();
  var myData = {
    "source": data.source,
    "customerType": data.customerType,
    "customerUser": {
      "userName": "",
      "password": "",
      "email": data.email,
      "firstName": data.firstName,
      "lastName": data.lastName,
      "phoneNumber": data.phoneNumber,
      "secondaryPhone": data.secondaryPhone
    },
    "defaultVenueAddress": {
      "googleMapLocation": "",
      "street": "",
      "city": "",
      "state": "",
      "zipCode": "",
      "country": "",
      "longitude": "",
      "latitude": "",
      "addressName": ""
    },
    "billingAddress": {
      "googleMapLocation": data.billingAddressGoogleMapLocation,
      "street": billingAddressData.billingAddressStreet,
      "city": billingAddressData.billingAddressCity,
      "state": billingAddressData.billingAddressState,
      "zipCode": data.billingAddressZipCode,
      "country": billingAddressData.billingAddressCountry,
      "longitude": billingAddressData.longitude,
      "latitude": billingAddressData.latitude,
      "addressName": billingAddressData.addressName
    }
  };
  let dataa = JSON.stringify(myData);
  formdata.append("data", dataa);
  if (data.imagefile) {
    formdata.append("imagefile", data.imagefile);
  }

  return (dispatch) => {
    dispatch(addCustomerRequest());
    axios
      .post(url + `addCustomer`, formdata, { headers: headers })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "addCustomerSuccess");
          await dispatch(addCustomerSuccess(response.data));
          await dispatch(fetchAllCustomer());
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "addCustomerfailed");
        dispatch(
          addCustomerFailure("Please Check Your Internet Connection !!")
        );
      });
  };
};
