import React from "react";
import PriceSlab from "../component/priceSlab/getAllPriceSlab";
function PriceSlabPage() {
    return (
        <div>
            <PriceSlab />
        </div>
    );
}

export default PriceSlabPage;
