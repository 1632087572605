import {
    FETCH_ALL_PROFILE_SETUP_TYPE_REQUEST,
    FETCH_ALL_PROFILE_SETUP_TYPE_SUCCESS,
    FETCH_ALL_PROFILE_SETUP_TYPE_FAILURE,
    //
    FETCH_ALL_PROFILE_PROFILE_DURATION_REQUEST,
    FETCH_ALL_PROFILE_PROFILE_DURATION_SUCCESS,
    FETCH_ALL_PROFILE_PROFILE_DURATION_FAILURE,
    //
    FETCH_ALL_PRICE_SLAB_DROPDOWN_REQUEST,
    FETCH_ALL_PRICE_SLAB_DROPDOWN_SUCCESS,
    FETCH_ALL_PRICE_SLAB_DROPDOWN_FAILURE,
    //
    FETCH_ALL_CUSTOMER_DROPDOWN_REQUEST,
    FETCH_ALL_CUSTOMER_DROPDOWN_SUCCESS,
    FETCH_ALL_CUSTOMER_DROPDOWN_FAILURE,
    //
    FETCH_ALL_ARTIST_DROPDOWN_REQUEST,
    FETCH_ALL_ARTIST_DROPDOWN_SUCCESS,
    FETCH_ALL_ARTIST_DROPDOWN_FAILURE,
    //
    FETCH_ALL_SOUND_SYSTEM_DROPDOWN_REQUEST,
    FETCH_ALL_SOUND_SYSTEM_DROPDOWN_SUCCESS,
    FETCH_ALL_SOUND_SYSTEM_DROPDOWN_FAILURE,
    //
    FETCH_ALL_SOUND_VENDER_DROPDOWN_REQUEST,
    FETCH_ALL_SOUND_VENDER_DROPDOWN_SUCCESS,
    FETCH_ALL_SOUND_VENDER_DROPDOWN_FAILURE
} from "../../actions/types";

const initialState = {
    fetching: false,
    error: "",
    ProfileSetupTypeData: [],
    ProfileDurationData: [],
    PriceSlabDropdown: [],
    CustomerDropdown: [],
    ArtistDropdown: [],
    SoundSystemDropdown: [],
    SoundVenderDropdown: []

};

//ProfileSetupType
export const ProfileSetupTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PROFILE_SETUP_TYPE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_ALL_PROFILE_SETUP_TYPE_SUCCESS:
            return {
                ...state,
                fetching: false,
                ProfileSetupTypeData: action.payload,
                error: "",
            };
        case FETCH_ALL_PROFILE_SETUP_TYPE_FAILURE:
            return {
                ...state,
                fetching: false,
                ProfileSetupTypeData: [],
                error: action.payload,
            };
        default:
            return state;
    }
};
//PriceSlabDropdown
export const PriceSlabDropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PRICE_SLAB_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_ALL_PRICE_SLAB_DROPDOWN_SUCCESS:
            return {
                ...state,
                fetching: false,
                PriceSlabDropdown: action.payload,
                error: "",
            };
        case FETCH_ALL_PRICE_SLAB_DROPDOWN_FAILURE:
            return {
                ...state,
                fetching: false,
                PriceSlabDropdown: [],
                error: action.payload,
            };
        default:
            return state;
    }
};
//ProfileDuration
export const ProfileDurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PROFILE_PROFILE_DURATION_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_ALL_PROFILE_PROFILE_DURATION_SUCCESS:
            return {
                ...state,
                fetching: false,
                ProfileDurationData: action.payload,
                error: "",
            };
        case FETCH_ALL_PROFILE_PROFILE_DURATION_FAILURE:
            return {
                ...state,
                fetching: false,
                ProfileDurationData: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

//Customer Dropdown 
export const customerDropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_CUSTOMER_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_ALL_CUSTOMER_DROPDOWN_SUCCESS:
            return {
                ...state,
                fetching: false,
                CustomerDropdown: action.payload,
                error: "",
            };
        case FETCH_ALL_CUSTOMER_DROPDOWN_FAILURE:
            return {
                ...state,
                fetching: false,
                CustomerDropdown: [],
                error: action.payload,
            };
        default:
            return state;
    }
};
//Artist 
export const artistDropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_ARTIST_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_ALL_ARTIST_DROPDOWN_SUCCESS:
            return {
                ...state,
                fetching: false,
                ArtistDropdown: action.payload,
                error: "",
            };
        case FETCH_ALL_ARTIST_DROPDOWN_FAILURE:
            return {
                ...state,
                fetching: false,
                ArtistDropdown: [],
                error: action.payload,
            };
        default:
            return state;
    }
};
// Sound System
export const soundSystemDropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_SOUND_SYSTEM_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_ALL_SOUND_SYSTEM_DROPDOWN_SUCCESS:
            return {
                ...state,
                fetching: false,
                SoundSystemDropdown: action.payload,
                error: "",
            };
        case FETCH_ALL_SOUND_SYSTEM_DROPDOWN_FAILURE:
            return {
                ...state,
                fetching: false,
                SoundSystemDropdown: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

//Sound Vendor
export const soundVendorDropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_SOUND_VENDER_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case FETCH_ALL_SOUND_VENDER_DROPDOWN_SUCCESS:
            return {
                ...state,
                fetching: false,
                SoundVenderDropdown: action.payload,
                error: "",
            };
        case FETCH_ALL_SOUND_VENDER_DROPDOWN_FAILURE:
            return {
                ...state,
                fetching: false,
                SoundVenderDropdown: [],
                error: action.payload,
            };
        default:
            return state;
    }
};