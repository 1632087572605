
import {
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: true,
    data: [],
    error: "",
    message: "",
  };
  
  export const UpdateUsersReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_USER_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          // data : action.payload.data,
          error: "",
          message: action.payload.message,
        };
      case UPDATE_USER_FAILURE:
        return {
          ...state,
          loading: false,
          // data: {},
          error: action.payload.error,
          message: action.payload.message,
        };
      default:
        return state;
    }
  };
  