import React, { useEffect } from "react";
import { deleteBooking, deleteBookingRequest } from "../../actions/manageBooking/deleteBooking";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
function Delete_Booking(props) {
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { DeleteBookingById, bookingId, message, refreshform, loading } = props;

    useEffect(() => {
        if (localStorage.getItem("message") === "BookingDeleteSuccess") {
            const message = "Booking deleted successfully";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()
            // history.push("/All_Bookings");
        }
        if (message === "BookingDeleteFailed") {
            const message = "Booking Deleted Failed";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()
        }
    }, [message]);

    return (
        <>
            <div
                className="modal fade"
                id="deleteBookingModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Delete Booking
                            </h5>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                {" "}
                                <span aria-hidden="true">×</span>{" "}
                            </button>
                        </div>
                        <div className="modal-body">
                            Do you really want to this Booking ?
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={async () => await DeleteBookingById(bookingId)}
                            >
                                Delete
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="cover-spin"></div> : ""}
        </>
    );
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.deleteBooking.loading,
        message: state.deleteBooking.message,
        user: state.userList.userList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteBookingById: (bookingId) => dispatch(deleteBooking(bookingId)),
        refreshform: () => dispatch(deleteBookingRequest()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Delete_Booking);
