import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
import { connect } from "react-redux";
import { fetchSoundVenderById } from "../../actions/soundVender/getSoundVenderById";

function SoundVenderdetails(props) {
    // to go to parent module
    const { getUser, user, error, loading } = props;
    const history = useHistory();
    let id = localStorage.getItem("soundVendorId");

    useEffect(() => {
        localStorage.setItem("View_Name", "Sound_Venders");
        checkRoute();
        fetchUserData(id);
    }, []);

    const fetchUserData = (Id) => {
        getUser(Id);
    };
    const handleHistoryDetailView = async (id) => {
        history.push({
            pathname: `/Booking_Details/${id}`,
            state: { bookingid: id },
        });

    };


    return !loading ? (
        error ? (
            "oops....something goes wrong"
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Sound Vendor</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item cursor">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("/Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item cursor"
                                                aria-current="page"
                                            >
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("/Sound_Venders")}
                                                    href={() => false}
                                                >
                                                    Sound Vendor
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Sound Vendor Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex align-items-center bg-light p-2">
                                        {/* <h5></h5> */}
                                        <div className="ml-auto d-flex no-block align-items-center">
                                            <a
                                                onClick={() => {
                                                    history.goBack();
                                                    localStorage.removeItem("soundVendorId");
                                                }}
                                                className="btn btn-dark mr-3 "
                                                href={() => false}
                                            >
                                                Back
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <br className="clearfix" />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <h5 className="card-header">General Information</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Sound Vendor ID

                                                                </th>
                                                                <td>{user.soundVendorId

                                                                }</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Company Name</th>
                                                                <td>{user?.companyName} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Designation
                                                                </th>
                                                                <td>{user.designation
                                                                }</td>
                                                            </tr>
                                                            <tr>
                                                                <th>GSTIN
                                                                </th>
                                                                <td>{user.gSTIN
                                                                }</td>
                                                            </tr>
                                                            <tr>
                                                                <th> State

                                                                </th>
                                                                <td>{user.OperatingState
                                                                }</td>
                                                            </tr>


                                                            <tr>
                                                                <th>Website Url
                                                                </th>
                                                                <td>{user.websiteUrl

                                                                }</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Sound Vendor User</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>

                                                            <tr>
                                                                <th>User Id</th>
                                                                <td>{user?.soundVendorUser?.userId}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>First Name</th>
                                                                <td>{user?.soundVendorUser?.firstName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Last Name</th>
                                                                <td>{user?.soundVendorUser?.lastName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>User Type</th>
                                                                <td>{user?.soundVendorUser?.userType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Phone Number</th>
                                                                <td>{user?.soundVendorUser?.phoneNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Secondary Phone</th>
                                                                <td>{user?.soundVendorUser?.secondaryPhone}</td>
                                                            </tr>



                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <h5 className="card-header">Office Address</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>

                                                            <tr>
                                                                <th>Address Type</th>
                                                                <td> {user?.officeAddress?.addressType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Street</th>
                                                                <td> {user?.officeAddress?.street
                                                                }</td>
                                                            </tr>
                                                            <tr>
                                                                <th>City</th>
                                                                <td> {user?.officeAddress?.city}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>ZipCode</th>
                                                                <td> {user?.officeAddress?.zipCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>state</th>
                                                                <td> {user?.officeAddress?.state}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Country</th>
                                                                <td> {user?.officeAddress?.country}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Booking History</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            {user.soundVendorBookingList === undefined || user.soundVendorBookingList === null || Object.keys(user.soundVendorBookingList).length === 0 ? (
                                                                ""
                                                            ) : (
                                                                Object.values(user.soundVendorBookingList).map((item, index) => (
                                                                    <tr key={index}>
                                                                        <th>Booking ID</th>
                                                                        <td>
                                                                            <a
                                                                                className="breadcrumb-link"
                                                                                href={() => false}
                                                                                onClick={() => handleHistoryDetailView(item.bookingId)}
                                                                            >
                                                                                {item.bookingId}
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )).reverse()
                                                            )
                                                            }
                                                            <tr>
                                                                <th>Total Number of booking
                                                                </th>

                                                                <td>{user?.soundVendorBookingList?.length}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    ) : (
        ""
    );
}

const mapStateToProps = (state, props) => {

    return {
        loading: state.getSoundVenderById.loading,
        user: state.getSoundVenderById.data,
        error: state.getSoundVenderById.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (user_ID) => dispatch(fetchSoundVenderById(user_ID)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SoundVenderdetails);
