import {
  ADD_SOUND_SYSTEM_REQUEST,
  ADD_SOUND_SYSTEM_SUCCESS,
  ADD_SOUND_SYSTEM_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllSoundSystem } from "./soundSystem";

export const addSoundSystemRequest = () => {
  return {
    type: ADD_SOUND_SYSTEM_REQUEST,
  };
};

export const addSoundSystemSuccess = (Data) => {
  return {
    type: ADD_SOUND_SYSTEM_SUCCESS,
    payload: { data: Data.data, message: Data.message },
  };
};

export const addSoundSystemFailure = (Error) => {
  return {
    type: ADD_SOUND_SYSTEM_FAILURE,
    payload: Error,
  };
};
export const addSoundSystem = (data) => {
  var headers = {
    Accept: "Application/json",
    "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,
  };

  let formdata = new FormData();
  var myData = {

    title: data.title,
    specification: data.specification,
    maxNumberOfGuest: data.maxNumberOfGuest,
    chargeAmount: data.chargeAmount
  };

  let dataa = JSON.stringify(myData);
  formdata.append("data", dataa);
  formdata.append("soundSystemImage", data.imageFile);
  formdata.append("videoFile", data.videoFile);


  return (dispatch) => {
    dispatch(addSoundSystemRequest());
    axios
      .post(url + `addSoundSystemSpecification`, formdata, { headers: headers })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "addSoundSystemSuccess");
          await dispatch(addSoundSystemSuccess(response.data));
          await dispatch(fetchAllSoundSystem());
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "addSoundSystemfailed");
        dispatch(
          addSoundSystemFailure("Please Check Your Internet Connection !!")
        );
      });
  };
};
