
import {
    FETCH_ALL_ARTIST_PREFERRED_SETUP_REQUEST,
    FETCH_ALL_ARTIST_PREFERRED_SETUP_SUCCESS,
    FETCH_ALL_ARTIST_PREFERRED_SETUP_FAILURE
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
export const fetchAllArtistPreferredSetupRequest = () => {
    return {
        type: FETCH_ALL_ARTIST_PREFERRED_SETUP_REQUEST,
    };
};
export const fetchAllArtistPreferredSetupSuccess = (Data) => {
    return {
        type: FETCH_ALL_ARTIST_PREFERRED_SETUP_SUCCESS,
        payload: Data,
    };
}

export const fetchAllArtistPreferredSetupFailure = (Error) => {
    return {
        type: FETCH_ALL_ARTIST_PREFERRED_SETUP_FAILURE,
        payload: Error,
    };
};


export const fetchAllArtistPreferredSetup = () => {
    const artistId = localStorage.getItem("artistId")
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {

        dispatch(fetchAllArtistPreferredSetupRequest);
        axios
            .get(url + `getAllArtistPreferredSetupByArtistId/${artistId}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllArtistPreferredSetupSuccess(response.data));

            })
            .catch(function (error) {
                dispatch(fetchAllArtistPreferredSetupFailure(error));
            });

    };
};