import {
    DELETE_SOUNDER_VENDER_REQUEST,
    DELETE_SOUNDER_VENDER_SUCCESS,
    DELETE_SOUNDER_VENDER_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
  };
  export const deleteSoundVenderReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_SOUNDER_VENDER_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
          error: "",
        };
      case DELETE_SOUNDER_VENDER_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: "Sound Vender Deleted Successfully !!",
        };
      case DELETE_SOUNDER_VENDER_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "failed",
        };
      default:
        return state;
    }
  };
  