import {
  DELETE_ARTISTS_REQUEST,
  DELETE_ARTISTS_SUCCESS,
  DELETE_ARTISTS_FAILURE,
} from "../../actions/types";

const initialState = {
  loading: false,
  data: [],
  error: "",
  message: "",
};
export const deleteArtistReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ARTISTS_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
      };
    case DELETE_ARTISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
        message: "Artist Deleted Successfully !!",
      };
    case DELETE_ARTISTS_FAILURE:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
        message: "Artist Deleted Failed",
      };
    default:
      return state;
  }
};
