import {
    ADD_PRICE_SLAB_REQUEST,
    ADD_PRICE_SLAB_SUCCESS,
    ADD_PRICE_SLAB_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllPriceSlab } from "./getAllPriceSlab"
export const addPriceSlabRequest = () => {
    return {
        type: ADD_PRICE_SLAB_REQUEST,
    };
};

export const addPriceSlabSuccess = (Data) => {
    return {
        type: ADD_PRICE_SLAB_SUCCESS,
        payload: { data: Data.data, message: Data.message },
    };
};

export const addPriceSlabFailure = (Error) => {
    return {
        type: ADD_PRICE_SLAB_FAILURE,
        payload: Error,
    };
};
export const addPriceSlab = (data, charges) => {
    var headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(addPriceSlabRequest());
        axios
            .post(url + `addPriceSlab`,
                {
                    "priceSlabName": data.priceSlabName,
                    "charges": charges
                }
                , { headers: headers })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addPriceSlabSuccess");
                    await dispatch(addPriceSlabSuccess(response.data));
                    await dispatch(fetchAllPriceSlab());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "addPriceSlabfailed");
                dispatch(
                    addPriceSlabFailure("Please Check Your Internet Connection !!")
                );
            });
    };
};
