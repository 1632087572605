import {
    FETCH_ALL_TRIGGERS_REQUEST,
    FETCH_ALL_TRIGGERS_SUCCESS,
    FETCH_ALL_TRIGGERS_FAILURE
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllTriggerRequest = () => {
    return {
        type: FETCH_ALL_TRIGGERS_REQUEST,
    };
};
export const fetchAllTriggerSuccess = (Data) => {
    return {
        type: FETCH_ALL_TRIGGERS_SUCCESS,
        payload: Data,
    };
}

export const fetchAllTriggerFailure = (Error) => {
    return {
        type: FETCH_ALL_TRIGGERS_FAILURE,
        payload: Error,
    };
};


export const fetchAllTrigger = () => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(fetchAllTriggerRequest);
        axios
            .get(url + "api/flows/getAllFlows", {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllTriggerSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllTriggerFailure(error));
            });

    };
};