import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../styles/css/style.css";
import { checkRoute, checkLoggedIn } from "../../utils/checkroute";
import {
  fetchDashboardDetails,
  fetchDashboardInvoiceList,
} from "../../actions/dashboard/getdashboarddetails";
//currencyFormat
import { currencyFormat, quantityFormat } from "../../utils/Validations";
import { UiDateFormatter } from "../../utils/uidateformatter";

import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { fetchUserList } from "../../actions/User action/fetchuserList";
// import { fetchallPropertyListing } from "../../actions/PropertyListing/propertyListing";

function Index(props) {
  // object destruct from props of component
  const history = useHistory();
  const {
    loggedIn,
    isActive,
    getDashboardDetails,
    dashboardData,
    getDashboardInvoicesList,
    InvoicesList,
    paginationcurrentRecords,
    user,
    fetchUserListData,

    fetchPaymentData,
    contacts,

  } = props;
  const [dashboardStatics, setDashboardStatics] = useState({
    totalInvoices: 0,
    totalAmount: 0,
    totalCustomers: 0,
    totalContracts: 0,
    xeroSyncPending: 0,
    upcominginvoices: 0,
    upcomingInvoicesTotal: 0,
  });
  const matches = useMediaQuery("(max-width:400px)");
  const [paginationvalues, setPaginationValues] = useState({
    pagenumber: 1,
    pagesize: 10,
    activepage: "",
    totalrecords: 0,
    totalpages: 0,
    CurrentRecord: 0,
    currentData: "",
  });
  const [showingpage, setShowingpage] = useState({
    pagenumber: 1,
    pagesize: 10,
    columnname: "null",
    sortingorder: "desc",
    total: 0,
  });

  const loggeIn = localStorage.getItem("loggedIn");
  const remember_me = localStorage.getItem("Remember_be");
  const timeout = localStorage.getItem("timeout");

  useEffect(() => {
    localStorage.setItem("View_Name", "dashboard");
    checkRoute();
    // fetchStaticsdata();
    // fetchinvoicelist();
    fetchUserListData();

  }, [loggedIn, dashboardData, InvoicesList]);

  // const fetchStaticsdata = () => {
  //   if (dashboardData !== null && typeof dashboardData !== "undefined") {
  //     if (dashboardData.length === 0) {
  //       getDashboardDetails();
  //     }
  //   }
  //   if (dashboardData !== null && typeof dashboardData !== "undefined") {
  //     setDashboardStatics({
  //       ...dashboardStatics,
  //       totalInvoices: dashboardData.totalInvoices,
  //       totalAmount: dashboardData.totalAmount,
  //       totalCustomers: dashboardData.totalCustomers,
  //       totalContracts: dashboardData.totalContracts,
  //       xeroSyncPending: dashboardData.xeroSyncPending,
  //       upcominginvoices: dashboardData.upcominginvoices,
  //       upcomingInvoicesTotal: dashboardData.upcomingInvoicesTotal,
  //     });
  //   }
  // };

  // const fetchinvoicelist = () => {
  //   let currentRecordsString = "";
  //   if (InvoicesList !== null) {
  //     if (InvoicesList.length === 0) {
  //       getDashboardInvoicesList(
  //         paginationvalues.pagenumber,
  //         paginationvalues.pagesize,
  //         null,
  //         null
  //       );
  //     }
  //   }
  //   currentRecordsString = paginationcurrentRecords(
  //     InvoicesList.pageSize,
  //     InvoicesList.pageNumber,
  //     InvoicesList.total,
  //     InvoicesList.total_Pages
  //   );

  //   setPaginationValues({
  //     ...paginationvalues,
  //     pagenumber: InvoicesList.pageNumber,
  //     pagesize: InvoicesList.pageSize,
  //     totalpages: InvoicesList.total_Pages,
  //     totalrecords: InvoicesList.total === undefined ? 0 : InvoicesList.total,
  //     currentData: currentRecordsString,
  //   });
  // };

  ///-------------------------------------- SHOW RANGE FUNCTION SHOWING FROM AND TO OF FULL ENTRIES IN THE TABLES -----------------------////
  // const showRange = (e) => {
  //   let pagesize = paginationvalues.pagesize;
  //   let total = paginationvalues.totalrecords;
  //   let active = paginationvalues.pagenumber;
  //   let range = Math.floor(total / pagesize);

  //   if (e > active) {
  //     if (e > range) {
  //       let dist = (e - active) * pagesize;
  //       setShowingpage({
  //         ...showingpage,
  //         to: total,
  //         from: showingpage.from + dist,
  //       });
  //     } else {
  //       let dist = (e - active) * pagesize;

  //       setShowingpage({
  //         ...showingpage,
  //         to: showingpage.to + dist,
  //         from: showingpage.from + dist,
  //       });
  //     }
  //   } else if (e < active) {
  //     // going back from last page ----------//
  //     if (showingpage.to === total) {
  //       let remianingitem =
  //         showingpage.to === total ? total - range * pagesize : 0;
  //       let dist = (active - e) * pagesize;
  //       let dist1 = showingpage.to - remianingitem;
  //       let dist2 =
  //         remianingitem === 0
  //           ? (active - e) * pagesize
  //           : (active - 1 - e) * pagesize;
  //       setShowingpage({
  //         ...showingpage,
  //         to: dist1 - dist2,
  //         from: showingpage.from - dist,
  //       });
  //     } else {
  //       // going backward from any further index -----//
  //       let dist = (active - e) * pagesize;
  //       setShowingpage({
  //         ...showingpage,
  //         to: showingpage.to - dist,
  //         from: showingpage.from - dist,
  //       });
  //     }
  //   }
  // };

  //-------------------------  FUNCTION HANDLING ORDER SORTING AND FETCH INVOICE API CALL -----------------------------//
  // const handleInvoiceTablePagination = (e) => {
  //   let order = showingpage.sortingorder;
  //   let column = showingpage.columnname !== "" ? showingpage.columnname : null;
  //   setPaginationValues({ ...paginationvalues, pagenumber: e });

  //   getDashboardInvoicesList(e, paginationvalues.pagesize, column, order);
  //   showRange(e);
  // };

  return (
    <div>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-5 align-self-center">
              <h4 className="page-title">Dashboard</h4>
              <div className="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-1">
                    <li className="breadcrumb-item">
                      <a className="breadcrumb-link" href={() => false}>
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-7 align-self-center">
              <div className="d-flex no-block justify-content-end align-items-center">
                <div className="m-r-10">
                  <div className="lastmonth"></div>
                </div>
                {/* <div className="">
                <small>LAST MONTH</small>
                <h4 className="text-info m-b-0 font-medium">$58,256</h4>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-md-flex align-items-center">
                  <div>
                    <h4 className="card-title">Users</h4>
                    <p className="text-muted"> {user.length}</p>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="d-md-flex align-items-center">
                  <div>
                    <h4 className="card-title">Artists</h4>
                    <p className="text-muted">{5}</p>
                  </div>
                </div>
              </div>
              {/* <!-- ============================================================== -->  */}
              {/* <!-- Info Box -->  */}
              {/* <!-- ============================================================== --> */}
              <div className="card-body border-top">
                <div className="row m-b-0">
                  {/* <!-- col -->  */}
                  {/* <!-- col --> */}
                  <div className="col-lg-4 col-md-6">
                    <div className="d-flex align-items-center">
                      <div className="m-r-10">
                        <span className="pr-3">
                          <img
                            src="img/customers-icon.png"
                            className="img-fluid"
                            alt=""
                          />
                        </span>
                      </div>
                      <div>
                        <span className="text-muted"> Total Users</span>
                        <h3 className="font-medium m-b-0">{user.length}</h3>
                      </div>
                    </div>
                  </div>
                  {/* <!-- col --> */}
                  <div className="col-lg-4 col-md-6">
                    <div className="d-flex align-items-center">
                      <div className="m-r-10">
                        <span className="pr-3">
                          <img
                            src="img/wallet-icon.png"
                            className="img-fluid"
                            alt=""
                          />
                        </span>
                      </div>
                      <div>
                        <span className="text-muted">Total Artists</span>
                        <h3 className="font-medium m-b-0">{4}</h3>
                      </div>
                    </div>
                  </div>
                  {/* <!-- col -->  */}
                  {/* <!-- col --> */}
                  <div className="col-lg-4 col-md-6">
                    <div className="d-flex align-items-center">
                      <div className="m-r-10">
                        <span className="pr-3">
                          <img
                            src="img/money-icon.png"
                            className="img-fluid"
                            alt=""
                          />
                        </span>
                      </div>
                      {/* <div>
                        <span className="text-muted">Total Payments</span>
                        <h3 className="font-medium m-b-0">
                          {payments.length}
                        </h3>
                      </div> */}
                    </div>
                  </div>
                  {/* <!-- col -->  */}
                  {/* <!-- col --> */}

                  {/* <!-- col -->  */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br className="clearfix" />
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    loggedIn: state.loggedIn,
    isActive: state.isActive,
    loading: state.fetchDashboardDetails.loading,
    dashboardData: state.fetchDashboardDetails.data,
    error: state.fetchDashboardDetails.error,
    InvoicesList: state.fetchDashboardInvoiceList.data,
    user: state.userList.userList,
  
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserListData: () => dispatch(fetchUserList()),


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
