import React from 'react';
import ChangepasswordPage from '../component/profile/ChangepasswordPage';

function ChangePassword() {
    let search = window.location.pathname;
    let userid = search.split('/')[2]; 

    return (
        <div>
            <ChangepasswordPage userid={userid}/>
        </div>
    )
}

export default ChangePassword
