import React, { useEffect, useState, useRef } from "react";

import { connect } from "react-redux";

//VALIDATION FUNCTION

import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import {
    emailValidate,
    stringValidate,
    selectinputValidate,
    contactNumberValidation,
    postalCodeValidation,
    websiteValidation,
    passwordValidate,
    numberValidate,
} from "../../utils/Validations";
import $ from "jquery";
import { updateGlobalDiscount, updateGlobalDiscountRequest } from "../../actions/globalDiscount/updateGlobalDiscount"
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const UpdateDiscount = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { handleupdatediscount, message, error, GlobalDiscountData, refreshform } = props;

    //----------------  ADD CUSTOMER STATE TO HANDLE INPUT VALUES ------------***//

    const [data, setData] = useState({
        tlsMargin: "",
        travelRate: "",
        runningDiscount: "",
        customerDiscount: ""
    });


    //----------------  ADD CUSTOMER FIELDS ERROR ------------***//
    const [discounterrors, setDiscountError] = useState({
        tlsMargin: null,
        travelRate: null,
        runningDiscount: null,
        customerDiscount: null
    });
    useEffect(() => {
        setData({
            ...data,
            tlsMargin: GlobalDiscountData.tlsMargin,
            travelRate: GlobalDiscountData.travelRate,
            runningDiscount: GlobalDiscountData.runningDiscount,
            customerDiscount: GlobalDiscountData.customerDiscount,
        });

    }, [GlobalDiscountData])
    const clearState = () => {
        setData({
            ...data,
            tlsMargin: "",
            travelRate: "",
            runningDiscount: "",
            customerDiscount: ""
        });
    };

    const handleValidation = () => {
        setDiscountError({
            ...discounterrors,
            tlsMargin: numberValidate(data.tlsMargin, "TlsMargin"),
            travelRate: numberValidate(data.travelRate, "TravelRate"),
            runningDiscount: numberValidate(data.runningDiscount, "RunningDiscount"),
            customerDiscount: numberValidate(data.customerDiscount, "CustomerDiscount"),
        });
    };

    useEffect(() => {
        if (localStorage.getItem("message") === "RecordUpdatedFailed") {
            enqueueSnackbar("Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "RecordUpdatedSuccessfully") {
            setSameAsAddress(false);
            handleClearValidationError();

            enqueueSnackbar("Updated Successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //---------------------------------------  ADD dataS FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleUpdateGlobalDiscount = (e) => {
        handleValidation();
        if (
            numberValidate(data.tlsMargin, "TlsMargin") === null &&
            numberValidate(data.travelRate, "TravelRate") === null &&
            numberValidate(data.runningDiscount, "RunningDiscount") === null &&
            numberValidate(data.customerDiscount, "CustomerDiscount") === null

        ) {
            handleClearValidationError();
            handleupdatediscount(data);
        }
    };

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
    const handleClearValidationError = () => {
        setDiscountError({
            ...discounterrors,
            tlsMargin: null,
            travelRate: null,
            runningDiscount: null,
            customerDiscount: null
        });
        clearState();
    };

    return (
        // <!-- The Modal -->
        <div className="modal" id="updateDiscountModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Update Discount</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">

                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        TLS Margin{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="TLS Margin"
                                                        id="subs"
                                                        value={data.tlsMargin}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                tlsMargin: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setDiscountError({
                                                                ...discounterrors,
                                                                tlsMargin: numberValidate(
                                                                    data.tlsMargin,
                                                                    "TlsMargin"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {discounterrors.tlsMargin}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Running Discount{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Running Discount"
                                                        id="subs"
                                                        value={data.runningDiscount}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                runningDiscount: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setDiscountError({
                                                                ...discounterrors,
                                                                runningDiscount: numberValidate(
                                                                    data.runningDiscount,
                                                                    "RunningDiscount"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {discounterrors.runningDiscount}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Travel Rate / KM{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Travel Rate / KM"
                                                        id="subs"
                                                        value={data.travelRate}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                travelRate: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setDiscountError({
                                                                ...discounterrors,
                                                                travelRate: numberValidate(
                                                                    data.travelRate,
                                                                    "TravelRate"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {discounterrors.travelRate}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Customer Discount{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Customer Discount"
                                                        id="subs"
                                                        value={data.customerDiscount}
                                                        onChange={(e) =>
                                                            setData({
                                                                ...data,
                                                                customerDiscount: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setDiscountError({
                                                                ...discounterrors,
                                                                customerDiscount: numberValidate(
                                                                    data.customerDiscount,
                                                                    "CustomerDiscount"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {discounterrors.customerDiscount}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleUpdateGlobalDiscount}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.updateGlobalDiscount.loading,
        message: state.updateGlobalDiscount.message,
        error: state.updateGlobalDiscount.error,
        GlobalDiscountData: state.fetchGlobalDiscount.data,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleupdatediscount: (data) => dispatch(updateGlobalDiscount(data)),
        refreshform: () => dispatch(updateGlobalDiscountRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdateDiscount));
