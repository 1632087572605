import React from "react";
import TriggerPage from "../component/trigger/trigger";
function Trigger() {
    return (
        <div>
            <TriggerPage />
        </div>
    );
}

export default Trigger;
