import {
    FETCH_ALL_ARTIST_VIDEOS_REQUEST,
    FETCH_ALL_ARTIST_VIDEOS_SUCCESS,
    FETCH_ALL_ARTIST_VIDEOS_FAILURE,
  } from "../../../actions/types";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
  };
  export const getAllArtistVideosReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ALL_ARTIST_VIDEOS_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case FETCH_ALL_ARTIST_VIDEOS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: "Property Created Successfully !!",
        };
      case FETCH_ALL_ARTIST_VIDEOS_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "failed",
        };
      default:
        return state;
    }
  };
  