import React from "react";
import Customer from "../component/customer/customer";
function Customers() {
    return (
        <div>
            <Customer />
        </div>
    );
}

export default Customers;
