
import {
    FETCH_ALL_ARTIST_CALENDAR_DATE_REQUEST,
    FETCH_ALL_ARTIST_CALENDAR_DATE_SUCCESS,
    FETCH_ALL_ARTIST_CALENDAR_DATE_FAILURE
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
export const fetchAllArtistCalendarEventRequest = () => {
    return {
        type: FETCH_ALL_ARTIST_CALENDAR_DATE_REQUEST,
    };
};
export const fetchAllArtistCalendarEventSuccess = (Data) => {
    return {
        type: FETCH_ALL_ARTIST_CALENDAR_DATE_SUCCESS,
        payload: Data,
    };
}

export const fetchAllArtistCalendarEventFailure = (Error) => {
    return {
        type: FETCH_ALL_ARTIST_CALENDAR_DATE_FAILURE,
        payload: Error,
    };
};


export const fetchAllArtistCalendarEvent = () => {
    const artistId = localStorage.getItem("artistId");
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };

    return (dispatch) => {

        dispatch(fetchAllArtistCalendarEventRequest);
        axios
            .get(url + `getAllAvailabilityCalendarEventByArtistId/${artistId}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchAllArtistCalendarEventSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchAllArtistCalendarEventFailure(error));
            });

    };
};