
import React from "react";
import { Redirect } from "react-router-dom";

// routes views
import Layout from "../component/Layout/Layout"
import BookingRequest from "../pages/bookingRequest"

export const bookingrequestroutes = [

    {
        path: "/",
        exact: true,
        layout:Layout,
        component: () => <Redirect to="/login" />
      },

      {
        path: "/Booking_Request",
        exact: true,
        layout:Layout,
        component:BookingRequest
      },
]