import React from "react";
import { Redirect } from "react-router-dom";

// routes views
import Layout from "../component/Layout/Layout"
import Dashboard from "../pages/dashboard"

export const dashboardroutes = [

    {
        path: "/",
        exact: true,
        layout:Layout,
        component: () => <Redirect to="/login" />
      },

      {
        path: "/dashboard",
        exact: true,
        layout:Layout,
        component:Dashboard
      },
]