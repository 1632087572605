import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ChangeforgetpasswordUser,
  fetchChangeforgetpasswordRequest,
} from "../../actions/Auth/Changepasswordmail";
import { useHistory } from "react-router-dom";
import { passwordValidate } from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";

function ChangePassword(props) {
  const history = useHistory();
  const { changepassword, message, error, refreshForm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmpassword: "",
    CheckBox: false,
  });

  useEffect(() => {
    // worked  here
    if (localStorage.getItem("message") === "Success") {
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 2000,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });
      refreshForm();
      localStorage.removeItem("message");
      history.push("/login");
    }
    if (localStorage.getItem("message") === "Failed") {
      const message = "Oops!! Something went wrong";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 2000,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
    }
  }, [message]);

  const [err, setErr] = useState({
    password: "",
    match: "",
  });

  const changepasswordBtn = () => {
    setErr({
      ...err,
      password: passwordValidate(user.password, "Password"),
    });

    if (passwordValidate(user.password, "Password") === null) {
      if (user.password === user.confirmpassword) {
        setErr({ ...err, password: "", match: "" });
        changepassword(user.password, props.userid);
      } else {
        setErr({ ...err, password: "", match: "Passwords didn't match" });
      }
    }
  };

  return (
    <>
    <div>
      <div
        className="auth-wrapper d-flex no-block justify-content-center align-items-center"
        style={{
          background: "#f4f4f4",
          // backgroundImage: "url('img/login-bg.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <div className="auth-box">
          <div id="recoverform">
            <div className="logo">
              <span>
                <img src="/img/logo1.png" alt="Redmap" />
              </span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-horizontal m-t-20">
                  <div className="form-group row">
                    <div className="col-12 ">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        onChange={(e) =>
                          setUser({
                            ...user,
                            password: e.target.value,
                          })
                        }
                      />
                      <p className="profile-input-errors">{err.password}</p>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 ">
                      <input
                        className="form-control "
                        type="password"
                        placeholder="Confirm Password"
                        onChange={(e) =>
                          setUser({
                            ...user,
                            confirmpassword: e.target.value,
                          })
                        }
                      />
                      <p className="profile-input-errors">{err.match}</p>
                    </div>
                  </div>

                  <div className="form-group text-center ">
                    <div className="col-xs-12 p-b-20 ">
                      <button
                        className="btn btn-block btn-info "
                        type="submit "
                        onClick={() => changepasswordBtn()}
                        // disabled={!user.CheckBox ? true : false}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                  <p className="text-success">{message}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    error: state.ChangePasswordMail.error,
    loading: state.ChangePasswordMail.loading,
    message: state.ChangePasswordMail.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changepassword: async (password, userid) =>
      await dispatch(ChangeforgetpasswordUser(password, userid)),
    refreshForm: () => dispatch(fetchChangeforgetpasswordRequest()),
    // updateUserProfile: async (userdata) => await dispatch(updateUser(userdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
// export default ChangePassword;
