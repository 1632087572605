import {
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_REQUEST,
  FETCH_USER_LIST_FAILURE,
} from "../types.js";
import axios from "axios";
import { fetchLogout } from "../authAction";

export const fetchUserListRequest = () => {
  return { type: FETCH_USER_LIST_REQUEST };
};

export const fetchUserListSuccess = (response) => {
  return { type: FETCH_USER_LIST_SUCCESS, payload: response.data };
};

export const fetchUserListFailure = (error) => {
  return { type: FETCH_USER_LIST_FAILURE, payload: error };
};

export const fetchUserList = (
  pagenumber = 1,
  pagesize = 10,
  columnname = null,
  orderdirection = null,
  search = null,
  filtercolumn = null,
  filtervalue = null
) => {
  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${localStorage.getItem("Auth_token")}`,
  };

  return (dispatch) => {
    dispatch(fetchUserListRequest());
    var baseUrl = "https://fracspace.webbulldesign.com/";

    axios
      .get(baseUrl + `getallusers`, {
        headers: headers,
      })
      .then(function (response) {
        dispatch(fetchUserListSuccess(response));
      })
      .catch(function (error) {
        if (error.message === "Request failed with status code 401") {
          dispatch(fetchLogout());
        }
        dispatch(fetchUserListFailure(error));
      });
  };
};
