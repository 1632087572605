import { combineReducers } from "redux";
import { loginReducer } from "./auth/loginReducer";
import { logoutReducer } from "./auth/LogoutReducer";
import { userReducer } from "./user/UserReducer";
import { updateuserReducer } from "./user/UserReducer";
import { userListReducer } from "./user/UserListReducer";
import { DeleteUserReducer } from "./user/DeleteUserReducer";
import { loginAsAgentReducer } from "./auth/loginAsAgent"
//dashboard
import {
  fetchDashboardDetailsReducer,
  fetchDashboardInvoiceListReducer,
} from "./dashboard/fetchDashboardDetailsReducer";

import { createUserReducer } from "./user/AddUserReducer";
import { UpdateUsersReducer } from "./user/UpdateUserReducer";
import { getuserReducer } from "./user/GetUser";
import { alluserReducer } from "./user/AllUserReducer";
import { ChangePasswordMail } from "./auth/ChangePasswordMail";

//Artists Reducers
import { getAllArtistReducer } from "./artists/getAllArtists";
import { addArtistReducer } from "./artists/addArtists";
import { deleteArtistReducer } from "./artists/deleteArtists";
import { updateArtistReducer } from "./artists/updateArtists";
import { getArtistByIdReducer } from "./artists/getArtistById"
import { getArtistCalendarDateByIdReducer } from "./artists/getArtistCalendarDates"
//Customer Reducers
import { addCustomerReducer } from "./cutomer/addCustomer";
import { deleteCustomerReducer } from "./cutomer/deleteCustomer";
import { fetchAllCustomerReducer } from "./cutomer/getAllCustomer";
import { updateCustomerReducer } from "./cutomer/updateCustomer";
import { getCustomerByIdReducer } from "./cutomer/getCustomerById"
//Booking Reducers
import { addBookingReducer } from "./manageBooking/addBooking";
import { deleteBookingReducer } from "./manageBooking/deleteBooking";
import { updateBookingReducer } from "./manageBooking/updateBooking";
import { getAllBookingReducer } from "./manageBooking/getAllBooking";
import { getBookingByIdReducer } from "./manageBooking/fetchBookingById";

//SoundVender Reducers
import { addSoundVenderReducer } from "./soundVender/addSoundVender";
import { deleteSoundVenderReducer } from "./soundVender/deleteSoundVender";
import { getAllSoundVenderReducer } from "./soundVender/getAllSoundVender";
import { updateSoundVenderReducer } from "./soundVender/updateSoundVender";
import { getSoundVenderByIdReducer } from "./soundVender/getSoundVenderById"
//Sound System 
import { getAllSoundSystemReducer } from "./soundSystem/soundSystem"
import { updateBookingStateReducer } from "./manageBooking/updateBookingState"
import { addSoundSystemReducer } from "./soundSystem/addSoundSystem"
import { getSoundSystemByIdReducer } from "./soundSystem/getSoundSystemById"
import { updateSoundSystemReducer } from "./soundSystem/updateSoundSystem"
import { deleteSoundSystemReducer } from "./soundSystem/deleteSoundSystem"
//Agent Reducers
import { fetchAllAgentReducer } from "./agents/getAllAgents";
import { deleteAgentReducer } from "./agents/deleteAgentById"
import { getAgentByIdReducer } from "./agents/getAgentById"
import { updateAgentByIdReducer } from "./agents/updateAgentById"
import { addAgentReducer } from "./agents/addAgent"
//Price Slab
import { fetchAllPriceSlabReducer } from "./priceSlab/getAllPriceSlab"
import { deletePriceSlabReducer } from "./priceSlab/deletePriceSlab"
import { addPriceSlabReducer } from "./priceSlab/addPriceSlab"
import { getPriceSlabByIdReducer } from "./priceSlab/getPriceSlabById"
import { updatePriceSlabByIdReducer } from "./priceSlab/updatePriceSlab"

//Dropdown
import { ProfileSetupTypeReducer } from "./common/getAllDropdown"
import { ProfileDurationReducer } from "./common/getAllDropdown"
import { PriceSlabDropdownReducer } from "./common/getAllDropdown"
import { customerDropdownReducer } from "./common/getAllDropdown"
import { artistDropdownReducer } from "./common/getAllDropdown"
import { soundSystemDropdownReducer } from "./common/getAllDropdown"
import { soundVendorDropdownReducer } from "./common/getAllDropdown"
//Surge Price 
import { addSurgePriceReducer } from "./surgePrice/addSurgePrice"
import { getAllSurgePriceReducer } from "./surgePrice/getAllSurgePrice"
import { updateSurgePriceReducer } from "./surgePrice/updateSurgePrice"
import { getSurgePriceByIdReducer } from "./surgePrice/getSurgePriceById"
import { deleteSurgePriceByIdReducer } from "./surgePrice/deleteSurgePrice"
//Global Discount
import { fetchGlobalDiscountReducer } from "./globalDiscount/getGlobalDiscount"
import { updateGlobalDiscountReducer } from "./globalDiscount/updateGlobalDiscount"

//Website Banner 
import { getAllWebsiteBannerReducer } from "./websiteBanner/getAllWebsiteBanner"
import { addWebsiteBannerReducer } from "./websiteBanner/addWebsiteBanner"
import { deleteWebsiteBannerReducer } from "./websiteBanner/deleteWebsiteBannerById"
import { getWebsiteBannerByIdReducer } from "./websiteBanner/getWebsiteBannerById"
import { updateWebsiteBannerByIdReducer } from "./websiteBanner/updateWebsiteBannerById"

//Trigger
import { getAllTriggerReducer } from "./trigger/getAllTrigger"
import { addTriggerReducer } from "./trigger/addTrigger"
import { deleteTriggerByIdReducer } from "./trigger/deleteTriggerById"
import { getTriggerByIdReducer } from "./trigger/getTriggerById"
import { updateTriggerByIdReducer } from "./trigger/updateTriggerById"

//Artist Preferred Setup 
import { addArtistPreferredReducer } from "./artists/artistPreferredSetup/addArtistPreferredSetup"
import { fetchAllArtistPreferredReducer } from "./artists/artistPreferredSetup/getAllPreferredSetup"
import { fetchArtistPreferredByIdReducer } from "./artists/artistPreferredSetup/getPreferredSetupById"
import { updateArtistPreferredByIdReducer } from "./artists/artistPreferredSetup/updatePreferredSetupById"
import { deletePreferredSetupReducer } from "./artists/artistPreferredSetup/deleteArtistPreferredSetup"

import { updateArtistProfileByIdReducer } from "./artists/updateArtistProfile"


//Video Management
import { getAllArtistVideosReducer } from "./artists/videoManagement/getAllArtistVideos"
import { addArtistVideosReducer } from "./artists/videoManagement/addArtistVideo"
import { updateArtistVideosReducer } from "./artists/videoManagement/updateArtistVideo"
import { getArtistVideosByIdReducer } from "./artists/videoManagement/getArtistVideosById"
import { deleteVideoReducer } from "./artists/videoManagement/deleteVideo"
//Calendar Management
import { fetchArtistCalendarDatesReducer } from "./artists/calendarManagement/getArtistCalendarDates"
import { addArtistCalendarDatesReducer } from "./artists/calendarManagement/addArtistCalendarDates"
import { deleteArtistCalendarEventReducer } from "./artists/calendarManagement/deleteArtistCalendarEvent"

//------------------------------- FUNCTION TO COMBINE ALL REDUCER INTO ROOTREDUCER --------------------------------//
export const rootReducer = combineReducers({
  login: loginReducer,
  logout: logoutReducer,
  loginAsAgent: loginAsAgentReducer,

  //users
  user: userReducer,
  userList: userListReducer,
  updateuser: updateuserReducer,
  createUser: createUserReducer,
  DeleteUser: DeleteUserReducer,
  UpdateUsers: UpdateUsersReducer,
  getuser: getuserReducer,
  alluser: alluserReducer,
  //dashboard
  fetchDashboardDetails: fetchDashboardDetailsReducer,
  fetchDashboardInvoiceList: fetchDashboardInvoiceListReducer,

  //Dropdown
  ProfileSetupType: ProfileSetupTypeReducer,
  ProfileDuration: ProfileDurationReducer,
  PriceSlabDropdown: PriceSlabDropdownReducer,
  customerDropdown: customerDropdownReducer,
  artistDropdown: artistDropdownReducer,
  soundSystemDropdown: soundSystemDropdownReducer,
  soundVendorDropdown: soundVendorDropdownReducer,
  //Artists Reducers

  getAllArtist: getAllArtistReducer,
  addArtist: addArtistReducer,
  deleteArtist: deleteArtistReducer,
  updateArtist: updateArtistReducer,
  getArtistById: getArtistByIdReducer,
  getArtistCalendarDateById: getArtistCalendarDateByIdReducer,
  //Customer Reducers
  addCustomer: addCustomerReducer,
  deleteCustomer: deleteCustomerReducer,
  fetchAllCustomer: fetchAllCustomerReducer,
  updateCustomer: updateCustomerReducer,
  getCustomerById: getCustomerByIdReducer,
  //Booking Reducers
  addBooking: addBookingReducer,
  deleteBooking: deleteBookingReducer,
  updateBooking: updateBookingReducer,
  getAllBooking: getAllBookingReducer,
  getBookingById: getBookingByIdReducer,

  //SoundVender Reducers
  addSoundVender: addSoundVenderReducer,
  deleteSoundVender: deleteSoundVenderReducer,
  getAllSoundVender: getAllSoundVenderReducer,
  updateSoundVender: updateSoundVenderReducer,
  getSoundVenderById: getSoundVenderByIdReducer,
  // Sound System
  getAllSoundSystem: getAllSoundSystemReducer,
  updateBookingState: updateBookingStateReducer,
  addSoundSystem: addSoundSystemReducer,
  getSoundSystemById: getSoundSystemByIdReducer,
  updateSoundSystem: updateSoundSystemReducer,
  deleteSoundSystem: deleteSoundSystemReducer,
  //Agent Reducers
  fetchAllAgent: fetchAllAgentReducer,
  deleteAgent: deleteAgentReducer,
  getAgentById: getAgentByIdReducer,
  updateAgentById: updateAgentByIdReducer,
  addAgent: addAgentReducer,
  //price Slab
  fetchAllPriceSlab: fetchAllPriceSlabReducer,
  deletePriceSlab: deletePriceSlabReducer,
  addPriceSlab: addPriceSlabReducer,
  getPriceSlabById: getPriceSlabByIdReducer,
  updatePriceSlabById: updatePriceSlabByIdReducer,
  //Surge Price 
  addSurgePrice: addSurgePriceReducer,
  getAllSurgePrice: getAllSurgePriceReducer,
  updateSurgePrice: updateSurgePriceReducer,
  getSurgePriceById: getSurgePriceByIdReducer,
  deleteSurgePriceById: deleteSurgePriceByIdReducer,
  //Global Discount
  fetchGlobalDiscount: fetchGlobalDiscountReducer,

  updateGlobalDiscount: updateGlobalDiscountReducer,
  //Website Banner 
  getAllWebsiteBanner: getAllWebsiteBannerReducer,
  addWebsiteBanner: addWebsiteBannerReducer,
  deleteWebsiteBanner: deleteWebsiteBannerReducer,
  getWebsiteBannerById: getWebsiteBannerByIdReducer,
  updateWebsiteBannerById: updateWebsiteBannerByIdReducer,

  //Trigger
  getAllTrigger: getAllTriggerReducer,
  addTrigger: addTriggerReducer,
  deleteTriggerById: deleteTriggerByIdReducer,
  getTriggerById: getTriggerByIdReducer,
  updateTriggerById: updateTriggerByIdReducer,

  // Preferred Setup
  addArtistPreferredSetup: addArtistPreferredReducer,
  fetchAllArtistPreferredSetup: fetchAllArtistPreferredReducer,
  fetchArtistPreferredSetupById: fetchArtistPreferredByIdReducer,
  updateArtistPreferredSetupById: updateArtistPreferredByIdReducer,
  deletePreferredSetup: deletePreferredSetupReducer,

  updateArtistProfileById: updateArtistProfileByIdReducer,
  // Video Management 
  getAllArtistVideos: getAllArtistVideosReducer,
  addArtistVideos: addArtistVideosReducer,
  updateArtistVideos: updateArtistVideosReducer,
  getArtistVideosById: getArtistVideosByIdReducer,
  deleteVideo: deleteVideoReducer,

  //Calendar 
  fetchArtistCalendarDates: fetchArtistCalendarDatesReducer,
  addArtistCalendarDates: addArtistCalendarDatesReducer,
  deleteArtistCalendarEvent: deleteArtistCalendarEventReducer
});
