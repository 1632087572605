import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import {
    fetchCustomerById,
} from "../../actions/cutomer/getCustomerById";
import AddCustomer from "./addCustomer";
import EditCustomerListing from "./updateCustomer"
import Delete_Customer from "./deleteCustomer"
import { fetchAllCustomer } from "../../actions/cutomer/getAllCustomer"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
import '../../styles/css/pagination.css';
function Customer(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [contactId, setcontactId] = useState(0);
    const [paginationvalues, setPaginationValues] = useState({});
    const [customerId, setCustomerId] = useState("")
    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 2,
        sortingorder: "desc",
        search: null,
        columnname: "",
    });

    const {
        loading,
        customerData,
        error,
        message,
        getCustomerDetailsById,
        handleGetAllCustomerData,
        customerDataById
    } = props;

    useEffect(() => {

        localStorage.setItem("View_Name", "Customer_Management");
        checkRoute();
    }, [customerData]);
    useEffect(() => {

        handleGetAllCustomerData()
    }, [])


    const handleContactDetailView = async (id) => {
        history.push({
            pathname: "/propertyDeatils",
            state: { contactid: id },
        });
    };

    const [data, setData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
        setCurrentPage(1); // Reset page when search keyword changes
    };
    // Filter the data based on the search keyword

    const filteredData = Object.entries(customerData).length !== 0 ? customerData?.filter(item =>
        item?.customerUser?.userName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.customerUser?.firstName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.customerUser?.phoneNumber?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.customerType?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.source?.toLowerCase().includes(searchKeyword.toLowerCase())
    ) : []

    // 

    const sortedData = filteredData?.slice().sort((a, b) => {
        const aValue = (sortColumn == "userName" ? a?.customerUser?.userName : sortColumn == "modifiedDate" ? a?.customerUser?.modifiedDate : sortColumn == "phoneNumber" ? a?.customerUser?.phoneNumber : a[sortColumn])
        const bValue = (sortColumn == "userName" ? b?.customerUser?.userName : sortColumn == "modifiedDate" ? b?.customerUser?.modifiedDate : sortColumn == "phoneNumber" ? b?.customerUser?.phoneNumber : b[sortColumn]);
        if (sortDirection === 'asc') {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
            return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
    });

    // Calculate the total number of pages
    const totalPages = Math.ceil(sortedData.length / itemsPerPage);

    // Get the current page of items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle column sorting
    const handleColumnSort = (column) => {
        if (column === sortColumn) {
            setSortDirection((prevSortDirection) =>
                prevSortDirection === 'asc' ? 'desc' : 'asc'
            );
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const handleCustomerDetailView = async (id) => {
        history.push({
            pathname: `/Customer_Details/${id}`,
            state: { bookingid: id },
        });
        // "/Customer_Details/:id"

    };

    return !loading ? (
        <>
            <div className="container-fluid">
                {/* <!-- Page Heading --> */}
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-5 align-self-center">
                            <h4 className="page-title"> Customer Management </h4>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-1">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                onClick={() => history.push("/Booking_Request")}
                                                href={() => false}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Customer
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-7 align-self-center">
                            <div className="d-flex no-block justify-content-end align-items-center">
                                <div className="m-r-10">
                                    <div className="lastmonth"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                    <div className="col-lg-4 pt-2">
                                        {/* <input
                                                type="text"
                                                value={searchKeyword}
                                                onChange={handleSearchChange}
                                                placeholder="Search..."
                                            /> */}
                                        <div class="search-box">
                                            <input class="search-input" type="text" placeholder="Search.." value={searchKeyword}
                                                onChange={handleSearchChange} />
                                            <button class="search-btn"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-lg-4"></div>

                                    <div className="col-lg-4 pt-2 text-right">
                                        {" "}
                                        <a
                                            href={() => false}
                                            className="btn btn-primary "
                                            data-toggle="modal"
                                            data-target="#createCustomerModal"
                                        >
                                            Add Customer
                                        </a>{" "}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th onClick={() => handleColumnSort('userName')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        Name {sortColumn === 'userName' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}
                                                    </div>
                                                </th>
                                                <th onClick={() => handleColumnSort('modifiedDate')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        Modified Date {sortColumn === 'modifiedDate' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}
                                                    </div>
                                                </th>
                                                <th onClick={() => handleColumnSort('phoneNumber')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        Phone {sortColumn === 'phoneNumber' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}

                                                    </div>
                                                </th>
                                                <th onClick={() => handleColumnSort('customerType')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        Type of Contact {sortColumn === 'customerType' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}
                                                    </div>
                                                </th>
                                                <th onClick={() => handleColumnSort('source')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        Lead Source {sortColumn === 'source' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}

                                                    </div>
                                                </th>

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems !== null && currentItems !== undefined && Object.entries(currentItems).length !== 0 ? (
                                                currentItems.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() =>
                                                                        handleCustomerDetailView(item.customerId)
                                                                    }
                                                                >
                                                                    {item?.customerUser?.userName}
                                                                    {/* {item?.customerUser?.lastName} */}
                                                                </a>
                                                            </td>
                                                            {/* {item.customerUser.userName} */}
                                                            <td>{item?.customerUser?.modifiedDate}</td>
                                                            <td>{item?.customerUser?.phoneNumber}</td>
                                                            <td>{item.customerType}</td>
                                                            <td>{item.source}</td>
                                                            <td>
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#updateCustomerModal"
                                                                    onClick={() =>
                                                                        getCustomerDetailsById(item.customerId)
                                                                    }
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </a>{" "}
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#deleteCustomerModal"
                                                                    onClick={() => {
                                                                        setCustomerId(item.customerId);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </td>
                                                            {/* <td>
                                                                    <a
                                                                        href={() => false}
                                                                        data-toggle="modal"
                                                                        data-target="#EditPropertyModal"
                                                                        onClick={() =>
                                                                            fetchpropertybyid(item.customerId)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                    </a>{" "}
                                                                </td> */}
                                                        </tr>
                                                    );
                                                })
                                            ) : currentItems.length === 0 ? (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div>
                                                            <p>
                                                                <strong style={{ color: "grey" }}>
                                                                    No records found
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className="loading1"></div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div>
                                        {Array.from({ length: totalPages }, (_, i) => (
                                            <button
                                                key={i + 1}
                                                onClick={() => handlePageChange(i + 1)}
                                                disabled={currentPage === i + 1}
                                                style={{ borderRadius: "40px", width: "40px", height: "35px" }}
                                            >
                                                {i + 1}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddCustomer />
            <EditCustomerListing
                customerDataById={customerDataById}
            />
            <Delete_Customer customerId={customerId} />

            <ReactTooltip />
        </>
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        customerData: state.fetchAllCustomer.data,
        loading: state.fetchAllCustomer.loading,
        customerDataById: state.getCustomerById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleGetAllCustomerData: () => dispatch(fetchAllCustomer()),
        getCustomerDetailsById: (id) => dispatch(fetchCustomerById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
