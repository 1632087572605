import {
    FETCH_LOGIN_AS_AGENT_REQUEST,
    FETCH_LOGIN_AS_AGENT_SUCCESS,
    FETCH_LOGIN_AS_AGENT_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: false,
    loggedIn: false,
    isActive: "",
    data: [],
    error: "",
    message: '',
  };
  
  
  export const loginAsAgentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LOGIN_AS_AGENT_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case FETCH_LOGIN_AS_AGENT_SUCCESS:
        return {
          ...state,
          loading: false,
          loggedIn: true,
          data: action.payload,
          error: "",
          message: "success"
  
        };
      case FETCH_LOGIN_AS_AGENT_FAILURE:
        return {
          ...state,
          loading: false,
          loggedIn: false,
          data: {},
          error: action.payload,
          message: "failed",
        };
      default:
        return state;
    }
  };
  