import React, { useEffect } from "react";
import { deleteSurgePriceById, deleteSurgePriceByIdRequest } from "../../actions/surgePrice/deleteSurgePrice";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
function Delete_Customer(props) {
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { DeleteSurgePriceById, surgePriceId, message, refreshform, loading, error } = props;

    useEffect(() => {
        if (localStorage.getItem("message") === "SurgePriceDeleteSuccess") {
            const message = "Surge Price Deleted successfully";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()

        }
        if (localStorage.getItem("message") === "SurgePriceDeleteFailed") {
            const message = "Surge Price Deleted Failed";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()
        }
    }, [message, error]);


    return (
        <>
            <div
                className="modal fade"
                id="deleteSurgePriceModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Delete Surge Price
                            </h5>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                {" "}
                                <span aria-hidden="true">×</span>{" "}
                            </button>
                        </div>
                        <div className="modal-body">
                            Do you really want to this Surge Price ?
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={async () => await DeleteSurgePriceById(surgePriceId)}
                            >
                                Delete
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="cover-spin"></div> : ""}
        </>
    );
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.deleteSurgePriceById.loading,
        message: state.deleteSurgePriceById.message,
        error: state.deleteSurgePriceById.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteSurgePriceById: (surgePriceId) => dispatch(deleteSurgePriceById(surgePriceId)),
        refreshform: () => dispatch(deleteSurgePriceByIdRequest()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Delete_Customer);
