import {
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../types";
import axios from "axios";
import { fetchUser } from "./fetchuser";

const updateUserRequest = () => {
  return { type: UPDATE_USER_REQUEST };
};

const updateUserSuccess = (response) => {
  return { type: UPDATE_USER_SUCCESS, payload: response.message };
};

const updateUserFailure = (error) => {
  return { type: UPDATE_USER_FAILURE, payload: error };
};

export const updateUser = (user, img) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("Auth_token")}`,
  };
  return (dispatch) => {
    dispatch(updateUserRequest());
    axios
      .put(
        process.env.REACT_APP_API_KEY + `/Account/UpdateUser`,
        {
          userId: user.userid,
          firstName: user.firstname,
          lastName: user.lastname,
          emailAddress: user.email,
          mobileNumber: "",
          address: user.address,
          postalCode: user.zip,
          city: user.city,
          state: user.state,
          country: user.country,
          roleId: 1,
          isActive: true,
          isDeleted: false,
          createdby: user.userid,
          imageUrl: img,
          // description:user.description
        },
        {
          headers: headers,
        }
      )
      .then(async (response) => {
        localStorage.setItem("message", "UserUpdateSuccess");
        dispatch(updateUserSuccess(response.data));
        dispatch(fetchUser(user.userid));
      })
      .catch((error) => {
        localStorage.setItem("message", "failed");
        dispatch(updateUserFailure(error));
      });
  };
};
