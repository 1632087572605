import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import { fetchAllSoundVender } from "../../actions/soundVender/getAllSoundVender"
import { fetchAllBooking } from "../../actions/manageBooking/getAllBooking"
import { fetchAllCustomer } from "../../actions/cutomer/getAllCustomer"
import { fetchAllArtists } from "../../actions/artists/getAllArtists"
import { fetchAllSoundSystem } from "../../actions/soundSystem/soundSystem"

import { fetchSoundVenderById } from "../../actions/soundVender/getSoundVenderById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
import "../../styles/css/metrics.css"
function Metrics(props) {
    const {
        loading,
        error,
        message,
        getAllSoundVenderData,
        allSoundVendersData,
        getAllCustomerData,
        fetchAllCustomerData,
        getAllSoundSystemData,
        AllSoundSystemData,
        getAllBookingData,
        AllBookingData,
        getAllArtistsData,
        AllArtistsData

    } = props;

    useEffect(() => {
        localStorage.setItem("View_Name", "Metrics");
        checkRoute();
        getAllBookingData()
        getAllSoundVenderData()
        getAllCustomerData()
        getAllSoundSystemData()
        getAllArtistsData()
    }, [])


    return (
        <div>
            {/* <div id="root"> */}
            <div class="container pt-5">
                <div class="row align-items-stretch">
                    <div class="c-dashboardInfo col-lg-6 col-md-6">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total Bookings<svg
                                class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                                </path>
                            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count">{AllBookingData.length}</span>
                        </div>
                    </div>
                    <div class="c-dashboardInfo col-lg-6 col-md-6">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total Artists<svg
                                class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                                </path>
                            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count">{AllArtistsData.length}</span>
                            {/* <span
                                    class="hind-font caption-12 c-dashboardInfo__subInfo">Last month: €30</span> */}
                        </div>
                    </div>
                    <div class="c-dashboardInfo col-lg-6 col-md-6">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total Sound Vendors<svg
                                class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                                </path>
                            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count">
                                {allSoundVendersData.length}
                            </span>
                        </div>
                    </div>
                    <div class="c-dashboardInfo col-lg-6 col-md-6">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total Sound System<svg
                                class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                                </path>
                            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count">{AllSoundSystemData.length}</span>
                        </div>
                    </div>

                    <div class="c-dashboardInfo col-lg-6 col-md-6">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total Customers<svg
                                class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
                                </path>
                            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count">{fetchAllCustomerData.length}</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}
const mapStateToProps = (state, props) => {
  
    return {
        allSoundVendersData: state.getAllSoundVender.data,
        loading: state.getAllSoundVender.loading,
        error: state.getAllSoundVender.error,
        fetchAllCustomerData: state.fetchAllCustomer.data,
        AllSoundSystemData: state.getAllSoundSystem.data,
        AllBookingData: state.getAllBooking.data,
        AllArtistsData: state.getAllArtist.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        getAllSoundVenderData: () => dispatch(fetchAllSoundVender()),
        getAllBookingData: () => dispatch(fetchAllBooking()),
        getAllCustomerData: () => dispatch(fetchAllCustomer()),
        getAllArtistsData: () => dispatch(fetchAllArtists()),
        getAllSoundSystemData: () => dispatch(fetchAllSoundSystem()),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);

