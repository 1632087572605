import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "../types";
import axios from "axios";
import { fetchUserList } from "./fetchuserList";
import { DateFormatter } from "../../utils/dateformatter";
// import { fetchUser } from "./fetchuser";

export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

export const updateUserSuccess = (Data) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: { data: Data.data, message: Data.message },
  };
};

export const updateUserFailure = (Error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: Error,
  };
};

export const updateUserdetails = (data, pagination, imageData) => {
  var baseUrl = "";
 
  const headers = {
    "Content-Type": "multipart/formdata",
    // Authorization: `Bearer ${localStorage.getItem("Auth_token")}`,
  };
  let formdata = new FormData();
  var myData = {
    name: data.name,
    email: data.email,
    phone: data.phone,
    aadhaarno: data.aadhaarno,
    panNumber: data.panNumber,
    currentInvestment: data.currentInvestment,
    currentHoldings: data.currentHoldings,
    currentFracValue: data.currentFracValue,
    currentNoInvestPro: data.currentNoInvestPro,
    lastPaymentdate: data.lastPaymentdate,
    nextPaymentdate: data.nextPaymentdate,
  };
  let dataa = JSON.stringify(myData);
  formdata.append("data", dataa);
  formdata.append("userimage", data.userimagePath);
  formdata.append("panimage", data.panimagePath);
  formdata.append("adharimage", data.adharimagePath);
  return (dispatch) => {
    dispatch(updateUserRequest());
    axios
      .put(baseUrl + `updatebyid/${data.id}`, formdata, { headers: headers })
      .then(async (response) => {
        if (response.data.message === "Email Address Already Exists") {
          localStorage.setItem("message", "UserFailed");
          await dispatch(updateUserFailure("Email Address Already Exists"));
          dispatch(fetchUserList(pagination?.pagenumber, pagination?.pagesize));
          // dispatch(editCustomerSuccess(response.data));
          //   dispatch(fetchUser(data.userId));
        } else {
          localStorage.setItem("message", "UserSuccess");
          await dispatch(updateUserSuccess(response.data));
          dispatch(fetchUserList(pagination?.pagenumber, pagination?.pagesize));
          // dispatch(editCustomerSuccess(response.data));
          //   dispatch(fetchUser(data.userId));
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "UserFailed");
        dispatch(updateUserFailure("Please Check Your Internet Connection !!"));
      });
  };
};
