import {
  DELETE_BOOKING_BY_ID_REQUEST,
  DELETE_BOOKING_BY_ID_SUCCESS,
  DELETE_BOOKING_BY_ID_FAILURE,
} from "../types";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllBooking } from "./getAllBooking"
export const deleteBookingRequest = () => {
  return {
    type: DELETE_BOOKING_BY_ID_REQUEST,
  };
};

export const deleteBookingSuccess = (Data) => {
  return {
    type: DELETE_BOOKING_BY_ID_SUCCESS,
    payload: Data,
  };
};

export const deleteBookingFailure = (Error) => {
  return {
    type: DELETE_BOOKING_BY_ID_FAILURE,
    payload: Error,
  };
};

export const deleteBooking = (bookingId) => {

  const headers = {
    "Content-Type": "application/json",
    'Authorization': basicAuth,
  };
  return (dispatch) => {
    dispatch(deleteBookingRequest());
    axios
      .delete(url + `deleteBookingById/${bookingId}`, {
        headers: headers,
      })
      .then(async (response) => {
        await dispatch(deleteBookingSuccess(response.data));
        localStorage.setItem("message", "BookingDeleteSuccess");
        dispatch(fetchAllBooking())
      })
      .catch((error) => {
        dispatch(deleteBookingFailure(error));
        localStorage.setItem("message", "BookingDeleteFailed");

      });
  };
};
