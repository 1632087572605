import {
    FETCH_LOGIN_AS_AGENT_REQUEST,
    FETCH_LOGIN_AS_AGENT_SUCCESS,
    FETCH_LOGIN_AS_AGENT_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchLoginAsAgentRequest = () => {
    return {
        type: FETCH_LOGIN_AS_AGENT_REQUEST,
    };
};

export const fetchLoginAsAgentSuccess = (Data) => {
    return {
        type: FETCH_LOGIN_AS_AGENT_SUCCESS,
        payload: Data,
    };
};

export const fetchLoginAsAgentFailure = (Error) => {
    return {
        type: FETCH_LOGIN_AS_AGENT_FAILURE,
        payload: Error,
    };
};


const headers = {
    Accept: "Application/json",
    'Authorization': basicAuth,
};

export const fetchLoginAsAgent = (data) => {
    const logintime = new Date();
    return (dispatch) => {
        dispatch(fetchLoginAsAgentRequest());
        axios
            .post(url + "loginAgent", {
                email: data.username,
                password: data.password
            }, {
                headers: headers,
            })
            .then(function (response) {

                localStorage.setItem("loggedIn", "true");
                localStorage.setItem("adminId", response.data.agentId);
                localStorage.setItem("Super_Admin", false)
                // localStorage.setItem("Email", response.data.adminEmail);
                // localStorage.setItem("Name", response.data.name);
                // localStorage.setItem("userType", response.data.userType);
                localStorage.setItem("Auth_token", "dummy01010101token");
                localStorage.setItem("LoggedIn_On", logintime);
                localStorage.setItem("timeout", "false");
                localStorage.setItem("message", "User LoggedIn Successfully !!");
                dispatch(fetchLoginAsAgentSuccess("Login Success"));
            })
            .catch(function (error, msg) {
                localStorage.setItem("message", "Unauthorized Login Access !!");
                dispatch(fetchLoginAsAgentFailure("Login Faild"));


            });
    };
};


