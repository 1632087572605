import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  FETCH_LOGOUT_REQUEST,
} from "./types";
import axios from "axios";
import { url } from "./apiUrl/url";
import { basicAuth } from "./apiUrl/basicAuth";
export const fetchLoginRequest = () => {
  return {
    type: FETCH_LOGIN_REQUEST,
  };
};

export const fetchLoginSuccess = (Data) => {
  return {
    type: FETCH_LOGIN_SUCCESS,
    payload: Data,
  };
};

export const fetchLoginFailure = (Error) => {
  return {
    type: FETCH_LOGIN_FAILURE,
    payload: Error,
  };
};

// logout function
export const fetchLogoutRequest = () => {
  return {
    type: FETCH_LOGOUT_REQUEST,
  };
};

const headers = {
  Accept: "Application/json",
  'Authorization': basicAuth,
};

export const fetchLogin = (data) => {
  const logintime = new Date();
  return (dispatch) => {
    dispatch(fetchLoginRequest());
    axios
      .post(url + "adminLogin", {
        adminEmail: data.username,
        adminPassword: data.password
      }, {
        headers: headers,
        // credentials: "include",
      })
      .then(function (response) {

        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("adminId", response.data.adminId);
        localStorage.setItem("Email", response.data.adminEmail);
        localStorage.setItem("Name", response.data.name);
        localStorage.setItem("userType", response.data.userType);
        localStorage.setItem("Super_Admin", true)
        localStorage.setItem("Auth_token", "a4df65a4sd65f464654as6df");
        // localStorage.setItem("Remember_be", data.remember_be);
        localStorage.setItem("LoggedIn_On", logintime);
        localStorage.setItem("timeout", "false");
        localStorage.setItem("message", "User LoggedIn Successfully !!");
        // window.location.href = "dashboard";
        dispatch(fetchLoginSuccess("Login Success"));
      })
      .catch(function (error, msg) {
        localStorage.setItem("message", "Unauthorized Login Access !!");
        dispatch(fetchLoginFailure("Login Faild"));


      });
  };
};

export const fetchLogout = () => {
  return (dispatch) => {
    dispatch(fetchLogoutRequest());
    localStorage.removeItem("Remember_be");
    localStorage.removeItem("Super_Admin");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("LoggedIn_On");
    localStorage.removeItem("Auth_token");
    localStorage.removeItem("timeout");
    localStorage.removeItem("userType");
    localStorage.removeItem("message");
    localStorage.removeItem("Email");
    localStorage.removeItem("Name");
    localStorage.removeItem("adminId");
    window.location.href = "/login";
  };
};
