import {
    FETCH_ARTIST_BY_ID_REQUEST,
    FETCH_ARTIST_BY_ID_SUCCESS,
    FETCH_ARTIST_BY_ID_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
  };
  export const getArtistByIdReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ARTIST_BY_ID_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case FETCH_ARTIST_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: "Artist Created Successfully !!",
        };
      case FETCH_ARTIST_BY_ID_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "Artist Created Failed",
        };
      default:
        return state;
    }
  };
  