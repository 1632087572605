
// export const basicAuth = 'Basic ' + btoa("TheLiveShow@tls" + ':' + "tls@123");

// export const basicAuth = 'Basic ' + btoa("TheLiveShow@tls:tls@123");

const username = 'TheLiveShow@tls';
const password = 'tls@123';

// Create a base64 encoded string of the credentials
export const basicAuth = 'Basic ' + btoa(`TheLiveShow@tls:tls@123`);

// const base64Credentials = btoa(`${username}:${password}`);



// export const basicAuth = Buffer.from(`TheLiveShow@tls:tls@123`).toString('base64');
