import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddSoundVender from "./addSoundVender";
import UpdateSoundVender from "./updateSoundVender"
import Delete_Sound_Vendor from "./deleteSoundVender"
import { fetchAllSoundVender } from "../../actions/soundVender/getAllSoundVender"
import { fetchSoundVenderById } from "../../actions/soundVender/getSoundVenderById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
import '../../styles/css/pagination.css';

function SoundVenders(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [soundVendorId, setSoundVendorId] = useState("");
    const [paginationvalues, setPaginationValues] = useState({});

    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 2,
        sortingorder: "desc",
        search: null,
        columnname: "",
    });

    const {
        fetchContactList,
        loading,
        error,
        message,
        handleFetchSoundVenderById,
        getSoundVenderByIdData,
        getSoundVenderRequest,
        allSoundVendersData,
    } = props;

    useEffect(() => {
        localStorage.setItem("View_Name", "Sound_Venders");
        checkRoute();

    }, [allSoundVendersData]);
    useEffect(() => {
        getSoundVenderRequest()
    }, [])

    const [data, setData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
        setCurrentPage(1); // Reset page when search keyword changes
    };

    // Filter the data based on the search keyword

    const filteredData = Object.entries(allSoundVendersData).length !== 0 ? allSoundVendersData?.filter(item =>
        item?.companyName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.designation.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item?.gSTIN?.toLowerCase().includes(searchKeyword.toLowerCase())

    ) : []

    const sortedData = filteredData?.slice().sort((a, b) => {
        const aValue = (sortColumn == "companyName" ? a.companyName : sortColumn == "designation" ? a.designation : sortColumn == "gSTIN" ? a.gSTIN : a[sortColumn])
        const bValue = (sortColumn == "companyName" ? b.companyName : sortColumn == "designation" ? b.designation : sortColumn == "gSTIN" ? b.gSTIN : b[sortColumn]);
        if (sortDirection === 'asc') {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
            return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
        }
    });

    // Calculate the total number of pages
    const totalPages = Math.ceil(sortedData.length / itemsPerPage);

    // Get the current page of items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle column sorting
    const handleColumnSort = (column) => {
        if (column === sortColumn) {
            setSortDirection((prevSortDirection) =>
                prevSortDirection === 'asc' ? 'desc' : 'asc'
            );
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    return !loading ? (
        <>
            <div className="container-fluid">
                {/* <!-- Page Heading --> */}
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-5 align-self-center">
                            <h4 className="page-title">Sound Vendors</h4>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-1">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                onClick={() => history.push("/Booking_Request")}
                                                href={() => false}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Sound Vendors
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-7 align-self-center">
                            <div className="d-flex no-block justify-content-end align-items-center">
                                <div className="m-r-10">
                                    <div className="lastmonth"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                    <div className="col-lg-4">
                                        <div class="search-box">
                                            <input class="search-input" type="text" placeholder="Search.." value={searchKeyword}
                                                onChange={handleSearchChange} />
                                            <button class="search-btn"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 pt-2"></div>
                                    <div className="col-lg-4 pt-2 text-right">
                                        {" "}
                                        <a
                                            href={() => false}
                                            className="btn btn-primary "
                                            data-toggle="modal"
                                            data-target="#createCustomerModal"
                                        >
                                            Add Sound Vendors
                                        </a>{" "}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th onClick={() => handleColumnSort('companyName')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        Company Name {sortColumn === 'companyName' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}
                                                    </div>
                                                </th>
                                                <th onClick={() => handleColumnSort('designation')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        Designation {sortColumn === 'designation' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}
                                                    </div>
                                                </th>
                                                <th onClick={() => handleColumnSort('gSTIN')}>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        GSTIN {sortColumn === 'gSTIN' && (
                                                            <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                        )}
                                                    </div>
                                                </th>


                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>WebsiteUrl</div>
                                                    </div>
                                                </th>


                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems !== null && currentItems !== undefined && Object.entries(currentItems).length !== 0 ? (
                                                currentItems?.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: "/Sound_Vender_Details",
                                                                        });
                                                                        localStorage.setItem(
                                                                            "soundVendorId",
                                                                            item.soundVendorId
                                                                        );
                                                                    }}
                                                                >

                                                                    {item.companyName}
                                                                </a>
                                                            </td>
                                                            {/* <td> {item.companyName}</td> */}
                                                            <td>{item.designation}</td>
                                                            <td>{item.gSTIN}</td>
                                                            <td>{item.websiteUrl}</td>
                                                            <td>
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#updateSoundVenderModal"
                                                                    onClick={() => handleFetchSoundVenderById(item.soundVendorId)}
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </a>{" "}
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#deleteSoundVendorModal"
                                                                    onClick={() => {
                                                                        setSoundVendorId(item.soundVendorId);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </td>

                                                        </tr>
                                                    );
                                                })
                                            ) : currentItems.length === 0 ? (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div>
                                                            <p>
                                                                <strong style={{ color: "grey" }}>
                                                                    No records found
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className="loading1"></div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div>
                                        {Array.from({ length: totalPages }, (_, i) => (
                                            <button
                                                key={i + 1}
                                                onClick={() => handlePageChange(i + 1)}
                                                disabled={currentPage === i + 1}
                                                style={{ borderRadius: "40px", width: "40px", height: "35px" }}
                                            >
                                                {i + 1}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddSoundVender />
            <UpdateSoundVender
                getSoundVenderByIdData={getSoundVenderByIdData}

            />
            <Delete_Sound_Vendor soundVendorId={soundVendorId} />
            <ReactTooltip />
        </>
    )
        : (
            <div className="cover-spin"></div>
        );
}

const mapStateToProps = (state, props) => {
    return {
        allSoundVendersData: state.getAllSoundVender.data,
        loading: state.getAllSoundVender.loading,
        error: state.getAllSoundVender.error,
        getSoundVenderByIdData: state.getSoundVenderById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        getSoundVenderRequest: () => dispatch(fetchAllSoundVender()),
        handleFetchSoundVenderById: (id) => dispatch(fetchSoundVenderById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundVenders);
