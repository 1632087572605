
import {
  FETCH_ALL_ARTIST_VIDEOS_REQUEST,
  FETCH_ALL_ARTIST_VIDEOS_SUCCESS,
  FETCH_ALL_ARTIST_VIDEOS_FAILURE
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
export const fetchAllArtistVideoRequest = () => {
  return {
    type: FETCH_ALL_ARTIST_VIDEOS_REQUEST,
  };
};
export const fetchAllArtistVideoSuccess = (Data) => {
  return {
    type: FETCH_ALL_ARTIST_VIDEOS_SUCCESS,
    payload: Data,
  };
}

export const fetchAllArtistVideoFailure = (Error) => {
  return {
    type: FETCH_ALL_ARTIST_VIDEOS_FAILURE,
    payload: Error,
  };
};


export const fetchAllArtistVideo = () => {
  const artistId = localStorage.getItem("artistId")
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
    'Authorization': basicAuth,
  };

  return (dispatch) => {

    dispatch(fetchAllArtistVideoRequest);
    axios
      .get(url + `getAllVideosByArtistId/${artistId}`, {
        headers: headers,
      })
      .then(function (response) {
        dispatch(fetchAllArtistVideoSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(fetchAllArtistVideoFailure(error));
      });

  };
};