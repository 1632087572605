import React, { useEffect } from "react";
import $ from "jquery";
import { fetchUser } from "../../actions/User action/fetchuser";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Messages from "../Notifications/messages";
import Notification from "../Notifications/notification";

const Topbar = (props) => {
  const history = useHistory();
  const { fetchUserProfile, user, error } = props;
  const sidebartoggle = () => {
    $(" sidebarToggle,  sidebarToggleTop", function () {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      $(".sidebar").hasClass("toggled");
    });
  };

  // useEffect(() => {
  //   const getUserData = async () => {
  //     if (user.length === 0 && !error) {
  //       fetchUserProfile(localStorage.getItem("UserId"));
  //     }
  //   };
  //   getUserData();
  // }, [user, error, fetchUserProfile]);

  return (
    <nav className="navbar navbar-expand navbar-light bg-blue topbar mb-4 static-top shadow">
      {/* <!-- Sidebar Toggle (Topbar) --> */}
      <button
        id="sidebarToggleTop cursor"
        onClick={sidebartoggle}
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        {" "}
        <i className="fa fa-bars"></i>{" "}
      </button>

      <div className="text-center d-none d-md-inline cursor">
        <a href={() => false} id="sidebarToggle cursor" onClick={sidebartoggle}>
          <i className="fa fa-bars text-white"></i>
        </a>
      </div>

      <ul className="navbar-nav ml-auto">
        {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
        <li className="nav-item dropdown no-arrow d-sm-none">
          {" "}
          <a
            className="nav-link dropdown-toggle"
            href={() => false}
            id="searchDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {" "}
            <i className="fas fa-search fa-fw"></i>{" "}
          </a>
          {/* <!-- Dropdown - Messages --> */}
          <div
            className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown"
          >
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    {" "}
                    <i className="fas fa-search fa-sm"></i>{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

        {/* alert center start */}
        {/* <Notification /> */}

        {/* alert center end */}
        {/*start ----- Nav Item message center  */}

        {/* <Messages /> */}

        {/* end ---------- message center */}

        {/* <!-- Nav Item - User Information --> */}
        <li className="nav-item dropdown no-arrow">
          {" "}
          <a
            className="nav-link dropdown-toggle"
            href={() => false}
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {/* {" "}
            <img
              className="img-profile rounded-circle  mr-3"
              alt=""
              src={
                user.imageUrl !== "" &&
                user.imageUrl !== null &&
                user.imageUrl !== undefined
                  ? process.env.REACT_APP_API_KEY_LAYOUT + user.imageUrl
                  : "img/noimage.png"
              }
            />{" "} */}
            {/* <span className=" d-none d-lg-inline mt-2 text-white small text-center">
              Name : {localStorage.getItem("Name")}<br />
              Email : {localStorage.getItem("Email")}
             
            </span>{" "} */}
          </a>
          {/* <!-- Dropdown - User Information --> */}
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            {/* {" "}
            <a
              className="dropdown-item cursor"
              href={() => false}
              onClick={() => history.push("/profile")}
            >
              {" "}
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>{" "}
              Profile{" "}
            </a>{" "}
            <div className="dropdown-divider"></div> */}
            <a
              className="dropdown-item cursor"
              href={() => false}
              data-toggle="modal"
              data-target="#logoutModal"
            >
              {" "}
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>{" "}
              Logout{" "}
            </a>{" "}
          </div>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    error: state.user.error,
    loading: state.user.loading,
    //   hshs: state
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // fetchUserProfile: async (userid) => dispatch(fetchUser(userid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));
