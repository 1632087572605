import React, { useEffect, useState, useRef } from "react";
import {
    addSoundVender,
    addSoundVenderRequest,
} from "../../actions/soundVender/addSoundVender";
import { connect } from "react-redux";

//VALIDATION FUNCTION

import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import {
    emailValidate,
    stringValidate,
    selectinputValidate,
    contactNumberValidation,
    postalCodeValidation,
    websiteValidation,
    passwordValidate,
    numberValidate,
} from "../../utils/Validations";
import $ from "jquery";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "react-google-autocomplete";
//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

const AddSoundVender = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const { handleAddSoundVender, message, error, AccountManagerdata, refreshform } = props;

    //----------------  ADD CUSTOMER STATE TO HANDLE INPUT VALUES ------------***//

    const [user, setuser] = useState({
        companyName: "",
        gSTIN: "",
        websiteUrl: "",
        designation: "",
        operatingState: "",
        userName: "",
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        secondaryPhone: "",
        googleMapLocation: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        longitude: "",
        latitude: ""
    });
    const [addressData, setAddressData] = useState({
        longitude: "",
        latitude: "",
        addressName: "",
        street: "",
        city: "",
        state: "",
        country: "",
    })

    //----------------  ADD CUSTOMER FIELDS ERROR ------------***//
    const [addusererrors, setAdduserError] = useState({
        companyName: null,
        gSTIN: null,
        websiteUrl: null,
        designation: null,
        operatingState: null,
        userName: null,
        password: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        secondaryPhone: null,
        googleMapLocation: null,
        street: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        longitude: null,
        latitude: null
    });

    const clearState = () => {
        setuser({
            companyName: "",
            gSTIN: "",
            websiteUrl: "",
            designation: "",
            operatingState: "",
            userName: "",
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            secondaryPhone: "",
            googleMapLocation: "",
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            longitude: "",
            latitude: ""
        });
    };

    const handleValidation = () => {
        setAdduserError({
            ...addusererrors,
            companyName: stringValidate(user.companyName, "CompanyName"),
            gSTIN: stringValidate(user.gSTIN, "GSTIN"),
            websiteUrl: websiteValidation(user.websiteUrl, "WebsiteUrl"),
            designation: stringValidate(user.designation, "Designation"),
            operatingState: stringValidate(user.operatingState, "OperatingState"),
            // userName: stringValidate(user.userName, "UserName"),
            // password: stringValidate(user.password, "Password"),
            email: emailValidate(user.email, "Email"),
            firstName: stringValidate(user.firstName, "FirstName"),
            password: passwordValidate(user.password, "Password"),
            lastName: stringValidate(user.lastName, "LastName"),
            phoneNumber: contactNumberValidation(user.phoneNumber, "PhoneNumber"),
            // street: stringValidate(user.street, "Street"),
            city: stringValidate(addressData.city, "City"),
            state: stringValidate(addressData.state, "State"),
            zipCode: postalCodeValidation(user.zipCode, "ZipCode"),
            country: stringValidate(addressData.country, "Country"),
            longitude: numberValidate(addressData.longitude, "Longitude"),
            latitude: numberValidate(addressData.latitude, "Latitude"),


        });
    };

    const handleAddSoundVenderFun = (e) => {
        handleValidation();
        if (
            stringValidate(user.companyName, "CompanyName") === null &&
            stringValidate(user.gSTIN, "GSTIN") === null &&
            websiteValidation(user.websiteUrl, "WebsiteUrl") === null &&
            stringValidate(user.designation, "Designation") === null &&
            stringValidate(user.operatingState, "OperatingState") === null &&
            // stringValidate(user.userName, "UserName") === null &&
            // passwordValidate(user.password, "Password") === null &&
            emailValidate(user.email, "Email") === null &&
            stringValidate(user.firstName, "FirstName") === null &&
            stringValidate(user.lastName, "LastName") === null &&
            contactNumberValidation(user.phoneNumber, "PhoneNumber") === null &&
            // stringValidate(user.street, "Street") === null &&
            stringValidate(addressData.city, "City") === null &&
            stringValidate(addressData.state, "State") === null &&
            postalCodeValidation(user.zipCode, "ZipCode") === null &&
            stringValidate(addressData.country, "Country") === null &&
            numberValidate(addressData.longitude, "Longitude") === null &&
            numberValidate(addressData.latitude, "Latitude") === null
        ) {
            handleClearValidationError();
            handleAddSoundVender(user, addressData);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("message") === "addSoundVenderFailed") {
            enqueueSnackbar("Creation Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "addSoundVenderSuccess") {
            handleClearValidationError();
            enqueueSnackbar("Created successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);


    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//
    const handleClearValidationError = () => {
        setAdduserError({
            ...addusererrors,
            companyName: null,
            gSTIN: null,
            websiteUrl: null,
            designation: null,
            operatingState: null,
            userName: null,
            password: null,
            email: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            secondaryPhone: null,
            googleMapLocation: null,
            street: null,
            city: null,
            state: null,
            zipCode: null,
            country: null,
            longitude: null,
            latitude: null
        });

        clearState();
    };

    const onPlacesChanged = (place) => {
        const { geometry, formatted_address } = place;

        if (geometry && geometry.location) {
            const { lat, lng } = geometry.location
            const addressParts = formatted_address.split(', ');
            const street = addressParts[0]; // The street
            const city = addressParts[1]; // The city
            const state = addressParts[2]; // The state
            const country = addressParts[3]; // The country
            let pinCode = '';
            for (let i = addressParts.length - 1; i >= 0; i--) {
                if (/\d/.test(addressParts[i])) {
                    pinCode = addressParts[i];
                    break;
                }
            }

            setAddressData({
                ...addressData,
                latitude: lat(),
                longitude: lng(),
                addressName: formatted_address,
                street: street,
                city: city,
                state: state,
                country: country,
            })
        }
    };

    return (
        // <!-- The Modal -->
        <div className="modal" id="createCustomerModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Add New Sound Vender</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">
                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Company Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Company Name"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={user.companyName}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                companyName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                companyName: stringValidate(
                                                                    user.companyName,
                                                                    "CompanyName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.companyName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        GSTIN{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="GSTIN"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={user.gSTIN}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                gSTIN: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                gSTIN: stringValidate(
                                                                    user.gSTIN,
                                                                    "GSTIN"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.gSTIN}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Website Url{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="website Url"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={user.websiteUrl}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                websiteUrl: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                websiteUrl: websiteValidation(
                                                                    user.websiteUrl,
                                                                    "WebsiteUrl"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.websiteUrl}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Designation{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Designation"
                                                        id="subs"
                                                        value={user.designation}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                designation: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                designation: stringValidate(
                                                                    user.designation,
                                                                    "Designation"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.designation}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Operating State{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Operating State"
                                                        id="subs"

                                                        value={user.operatingState}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                operatingState: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                operatingState: stringValidate(
                                                                    user.operatingState,
                                                                    "OperatingState"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.operatingState}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-2">
                                <div className="card-header Accordian" id="headingTwo">
                                    <h5 className="mb-0">
                                        {/* <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#SoundVendorinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        > */}
                                        <h5
                                            className="pb-4"

                                        >
                                            Sound Vendor User
                                        </h5>

                                        {/* </button> */}
                                    </h5>
                                </div>
                                <div
                                    id="SoundVendorinfo"
                                    className="collapse show"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">

                                            {/* <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        User Name<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="User Name"
                                                        id=""

                                                        value={user.userName}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                userName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                userName: stringValidate(
                                                                    user.userName,
                                                                    "UserName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.userName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Password
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Password"
                                                        id="subs"
                                                        // ref={NameRef}
                                                        value={user.password}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                password: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                password: passwordValidate(
                                                                    user.password,
                                                                    "Password"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.password}
                                                    </p>
                                                </div>
                                            </div> */}

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Email <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Email"
                                                        id=""

                                                        value={user.email}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                email: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                email: emailValidate(
                                                                    user.email,
                                                                    "Email"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.email}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        First Name <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="firstName"
                                                        id=""
                                                        // ref={EmailRef}
                                                        value={user.firstName}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                firstName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                firstName: stringValidate(
                                                                    user.firstName,
                                                                    "FirstName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.firstName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Last Name <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Last Name"
                                                        id=""
                                                        value={user.lastName}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                lastName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                lastName: stringValidate(
                                                                    user.lastName,
                                                                    "LastName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.lastName}
                                                    </p>
                                                </div>
                                            </div>




                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Phone Number<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Phone No"
                                                        id=""
                                                        value={user.phoneNumber}
                                                        onChange={(e) => {
                                                            setuser({
                                                                ...user,
                                                                phoneNumber: e.target.value,
                                                            });
                                                        }}

                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                phoneNumber: contactNumberValidation(
                                                                    user.phoneNumber,
                                                                    "PhoneNumber"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.phoneNumber}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Secondary Phone

                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Secondary Phone"
                                                        id=""
                                                        value={user.secondaryPhone}
                                                        onChange={(e) => {
                                                            setuser({
                                                                ...user,
                                                                secondaryPhone: e.target.value,
                                                            });
                                                        }}

                                                    />

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-2">
                                <div className="card-header Accordian" id="headingTwo">
                                    <h5 className="mb-0">
                                        {/* <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#contactinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        > */}
                                        <h5
                                            className="pb-4"
                                        >
                                            Office Address
                                        </h5>
                                        {/* </button> */}
                                    </h5>
                                </div>
                                <div
                                    id="contactinfo"
                                    className="collapse show"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Address{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Autocomplete
                                                        apiKey={apiKey}
                                                        style={{ width: "100%", height: "38px", borderRadius: "5px" }}
                                                        onPlaceSelected={(place) => {
                                                            onPlacesChanged(place);
                                                        }}
                                                        options={{
                                                            types: ["(regions)"],
                                                            // types: [
                                                            //     "cafe",
                                                            //     "restaurant",
                                                            //     "stadium",
                                                            //     "school",
                                                            //     "amusement_park",
                                                            //   ],
                                                            componentRestrictions: { country: "IND" },
                                                        }}
                                                        defaultValue="Noida"
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.longitude}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Street
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Street"
                                                        id=""
                                                        // ref={EmailRef}
                                                        value={addressData.street}
                                                        onChange={(e) =>
                                                            setAddressData({
                                                                ...addressData,
                                                                street: e.target.value,
                                                            })
                                                        }
                                                    // onBlur={() =>
                                                    //     setAdduserError({
                                                    //         ...addusererrors,
                                                    //         street: stringValidate(
                                                    //             user.street,
                                                    //             "Street"
                                                    //         ),
                                                    //     })
                                                    // }
                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addusererrors.street}
                                                    </p> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        City<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="city"
                                                        id=""
                                                        // ref={EmailRef}
                                                        value={addressData.city}
                                                        onChange={(e) =>
                                                            setAddressData({
                                                                ...addressData,
                                                                city: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                city: stringValidate(
                                                                    addressData.city,
                                                                    "City"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.city}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        ZipCode <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="zipCode"
                                                        id=""
                                                        value={user.zipCode}
                                                        // onChange={(e) => onlyNumberInputPhone(e)}
                                                        onChange={(e) => {
                                                            setuser({
                                                                ...user,
                                                                zipCode: e.target.value,
                                                            });
                                                        }}
                                                        // ref={zipCodeRef}
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                zipCode: postalCodeValidation(
                                                                    user.zipCode,
                                                                    "ZipCode"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.zipCode}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        State <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="state"
                                                        id=""
                                                        // ref={stateRef}
                                                        value={addressData.state}
                                                        onChange={(e) =>
                                                            setAddressData({
                                                                ...addressData,
                                                                state: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                state: stringValidate(
                                                                    addressData.state,
                                                                    "State"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.state}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Country<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="country"
                                                        id=""
                                                        value={addressData.country}
                                                        // onChange={(e) => onlyNumberInputPhone(e)}
                                                        onChange={(e) => {
                                                            setAddressData({
                                                                ...addressData,
                                                                country: e.target.value,
                                                            });
                                                        }}
                                                        // ref={countryRef}
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                country: stringValidate(
                                                                    addressData.country,
                                                                    "Country"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.country}
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card mt-2">
                                <div className="card-header Accordian" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#contactinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        >
                                            <h5
                                                className="pb-4"
                                           
                                            >
                                                Sound System
                                            </h5>
                                           
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="contactinfo"
                                    className="collapse show"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Select Sound System<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Sound System"
                                                        id=""
                                                       
                                                        value={user.addressType}
                                                        onChange={(e) =>
                                                            setuser({
                                                                ...user,
                                                                addressType: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAdduserError({
                                                                ...addusererrors,
                                                                addressType: emailValidate(
                                                                    user.addressType,
                                                                    "AddressType"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addusererrors.addressType}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleAddSoundVenderFun}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {
    return {
        loading: state.addSoundVender.loading,
        message: state.addSoundVender.message,
        error: state.addSoundVender.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleAddSoundVender: (user, addressData) => dispatch(addSoundVender(user, addressData)),
        refreshform: () => dispatch(addSoundVenderRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddSoundVender));
