import React, { useEffect, useState } from "react";
import { checkRoute } from "../../utils/checkroute";
import ChangePassword from "../auth/changepassword";
import { connect } from "react-redux";
import { fetchUser } from "../../actions/User action/fetchuser";
import { updateUser } from "../../actions/User action/updateuser";
import {
  emailValidate,
  stringValidate,
  selectinputValidate,
} from "../../utils/Validations";
import { withRouter } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";

function Profilepage(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    fetchUserProfile,
    user,
    error,
    loading,
    updateUserProfile,
    history,
    message,
  } = props;
  const [userdata, setUserData] = useState({
    userid: "",
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    address: "",
    country: 0,
    city: 0,
    state: 0,
    zip: "",
    description: "",
    userImage: "",
    uploadpreview: "",
  });
  const [imageData, setImagedata] = useState({
    imageBase: "",
    imageError: "",
  });

  const [errors, setErrors] = useState({
    firstnameerror: "",
    lastnameerror: "",
    emailerror: "",
    addresserror: "",
    stateerror: "",
    cityerror: "",
    countryerror: "",
    ziperror: "",
    titleerror: "",
    descriptionerror: "",
  });

  useEffect(() => {
    localStorage.setItem("View_Name", "profile");
    checkRoute();
    getUserData();
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem("message") === "UserUpdateSuccess") {
      const message = "User updated successfully";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("message");
    }
    if (message === "failed") {
      const message = "Failed To Update Profile";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });
    }
  }, [message, error]);

  //------------------------------ FETCHING USER PROFILE DATA FROM API ------------------------//

  const getUserData = async () => {
    if (user.length === 0) {
      fetchUserProfile(localStorage.getItem("UserId"));
    }

    setUserData({
      ...userdata,
      userid: user.userId,
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.emailAddress,
      title: user.displayName,
      address: user.address,
      city: user.city,
      zip: user.postalCode,
      state: user.state,
      country: user.country,
      description: user.description,
      userImage: user.imageUrl, //user.imageUrl
    });
  };

  //------------------------------------- FUNCTION HANDLING USER PROFILE UPDATE FORM INPUT VALIDATION ---------------------------//
  const handleProfileValidation = () => {
    setErrors({
      ...errors,
      firstnameerror: stringValidate(userdata.firstname, "Firstname"),
      lastnameerror: stringValidate(userdata.lastname, "Lastname"),
      emailerror: emailValidate(userdata.email, "Email"),
      // addresserror: stringValidate(userdata.address, "Address"),
      // stateerror: stringValidate(userdata.state, "State"),
      // cityerror: stringValidate(userdata.city, "City"),
      // countryerror: stringValidate(userdata.country, "Country"),
      // ziperror: numberValidate(userdata.zip, "Zip"),
      // titleerror: stringValidate(userdata.title, "Title"),
      // descriptionerror: stringValidate(userdata.description, "Description"),
    });
  };

  //-------------------------------------------- USER UPDATE FUNCTION FIRST CHEKING VALIDATION AFTERWARDS FETCHING UPDATING DATA --------------------------//
  const handleUserUpdate = async (e) => {
    handleProfileValidation();

    if (
      emailValidate(userdata.email, "Email") === null &&
      stringValidate(userdata.firstname, "Firstname") === null &&
      stringValidate(userdata.lastname, "Lastname") === null
    ) {
      setErrors({
        ...errors,
        firstnameerror: "",
        lastnameerror: "",
        emailerror: "",
        addresserror: "",
        stateerror: "",
        cityerror: "",
        countryerror: "",
        ziperror: "",
        titleerror: "",
        descriptionerror: "",
      });
      updateUserProfile(userdata, imageData.imageBase);
    } else {
    
    }
  };

  //--------------------------------- IMAGE UPLOAD FUNCTION --------------------------//
  const changeimage = (event) => {
    setUserData({
      ...userdata,
      uploadpreview: "",
    });
    const file = event.target.files[0];
    setUserData({
      ...userdata,
      uploadpreview: URL.createObjectURL(event.target.files[0]),
    });
    var reader = new FileReader();
    if (file.size > 2e6) {
      setImagedata({
        ...imageData,
        imageError: "File size can not be more than 2 MB",
        imageBase: "",
      });
    } else {
      reader.onload = (evt) => {
        setImagedata({
          ...imageData,
          imageError: "",
          imageBase: evt.target.result,
        });
      };
    }
    reader.readAsDataURL(file);
  };

  return (
    <>
      {loading ? (
        "loading ...."
      ) : error ? (
        "oops....something goes wrong"
      ) : (
        <>
          <div className="container-fluid">
            {/* <!-- Page Heading --> */}
            <div className="page-breadcrumb">
              <div className="row">
                <div className="col-5 align-self-center">
                  <h4 className="page-title">Profile</h4>
                  <div className="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-1">
                        <li className="breadcrumb-item cursor">
                          <a
                            className="breadcrumb-link"
                            onClick={() => history.push("Booking_Request")}
                          >
                            Home
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Profile
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div className="col-7 align-self-center">
                  <div className="d-flex no-block justify-content-end align-items-center">
                    <div className="m-r-10">
                      <div className="lastmonth"></div>
                    </div>
                    {/* <div className="">
                      <small>LAST MONTH</small>
                      <h4 className="text-info m-b-0 font-medium">$58,256</h4>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body p-3 ">
                    {/* <!-- / .main-navbar --> */}
                    {/* <div
                      className="alert alert-success alert-dismissible fade show mb-0"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        {" "}
                        <span aria-hidden="true">×</span>{" "}
                      </button>
                      <i className="fa fa-check mx-2"></i>{" "}
                      <strong>Success!</strong> Your profile has been updated!{" "}
                    </div> */}

                    {/* <!-- Default Light Table --> */}
                    <div className="row pt-3">
                      <div className="col-lg-4">
                        <div className="card border-0 ">
                          <div className="card-header bg-white text-center">
                            <div className="mb-1 mx-auto">
                              <div className="image">
                                {" "}
                                {userdata.userImage !== "" ? (
                                  <img
                                    src={
                                      userdata.uploadpreview === ""
                                        ? process.env.REACT_APP_API_KEY_LAYOUT +
                                          userdata.userImage
                                        : userdata.uploadpreview
                                    }
                                    className="rounded-circle noimage"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="img/noimage.png"
                                    className="rounded-circle noimage"
                                    alt=""
                                  />
                                )}
                                <div className=" mr-3  bottomright">
                                  <input
                                    type="file"
                                    id="actual-btn"
                                    hidden
                                    onChange={(e) => changeimage(e)}
                                  />
                                  <label htmlFor="actual-btn">
                                    <i className="bg-warning p-2 border-white rounded-circle fa fa-pencil-alt"></i>
                                  </label>
                                </div>
                              </div>
                              <h4 className="mb-0">
                                {userdata.firstname} {userdata.lastname}
                              </h4>
                              <span className="text-muted d-block mb-2">
                                {/* Project Manager */}
                                {userdata.title}
                              </span>
                              {/* <hr /> */}
                              {/* <ul className="list-group list-group-flush text-left">
                                <li className="list-group-item ">
                                  {" "}
                                  <strong className="text-muted d-block mb-2">
                                    Description
                                  </strong>{" "}
                                  <span>{userdata.description}</span>{" "}
                                </li>
                              </ul> */}
                            </div>
                          </div>
                        </div>
                        {/* <!-- End Default Light Table -->  */}
                      </div>
                      <div className="col-lg-8">
                        <div className="card shadow rounded mb-4 p-3">
                          <div className="card-header border-bottom">
                            <div className="row">
                              <div className="col">
                                {" "}
                                <h5 className="m-0">Account Details</h5>
                              </div>
                              <div className="col text-right">
                                <a
                                  href={() => false}
                                  className="cursor"
                                  data-toggle="modal"
                                  data-target="#changepassword"
                                >
                                  Change Password
                                </a>
                              </div>
                            </div>
                          </div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feFirstName">
                                        First Name{" "}
                                        <span style={{ color: "#d20d0d" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feFirstName"
                                        placeholder="First Name"
                                        value={userdata.firstname}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            firstname: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            firstnameerror: stringValidate(
                                              userdata.firstname,
                                              "First Name"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.firstnameerror}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feLastName">
                                        Last Name{" "}
                                        <span style={{ color: "#d20d0d" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="feLastName"
                                        placeholder="Last Name"
                                        value={userdata.lastname}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            lastname: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            lastnameerror: stringValidate(
                                              userdata.lastname,
                                              "Last Name"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.lastnameerror}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label htmlFor="feEmailAddress">
                                        Email{" "}
                                        <span style={{ color: "#d20d0d" }}>
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="feEmailAddress"
                                        placeholder="Email"
                                        value={userdata.email}
                                        readOnly
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            email: e.target.value,
                                          })
                                        }
                                        onBlur={() =>
                                          setErrors({
                                            ...errors,
                                            emailerror: emailValidate(
                                              userdata.email,
                                              "Email"
                                            ),
                                          })
                                        }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.emailerror}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="fePassword">Title </label>
                                      <input
                                        type="title"
                                        className="form-control"
                                        id="title"
                                        placeholder="title"
                                        value={userdata.title}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            title: e.target.value,
                                          })
                                        }
                                        // onBlur={() =>
                                        //   setErrors({
                                        //     ...errors,
                                        //     titleerror:  stringValidate(userdata.title, "Title")
                                        //   })
                                        // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.titleerror}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="feInputAddress">
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="feInputAddress"
                                      placeholder="Address"
                                      value={userdata.address}
                                      onChange={(e) =>
                                        setUserData({
                                          ...userdata,
                                          address: e.target.value,
                                        })
                                      }
                                      onBlur={() =>
                                        setErrors({
                                          ...errors,
                                          addresserror: selectinputValidate(
                                            userdata.address,
                                            "Address"
                                          ),
                                        })
                                      }
                                    />
                                    <p className="profile-input-errors">
                                      {errors.addresserror}
                                    </p>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label>City </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        id=""
                                        value={userdata.city}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            city: e.target.value,
                                          })
                                        }
                                        // onBlur={() =>
                                        //   setErrors({
                                        //     ...errors,
                                        //     stateerror:  stringValidate(userdata.city, "State")
                                        //   })
                                        // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.cityerror}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label>State </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="State"
                                        id=""
                                        value={userdata.state}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            state: e.target.value,
                                          })
                                        }
                                        // onBlur={() =>
                                        //   setErrors({
                                        //     ...errors,
                                        //     stateerror:  stringValidate(userdata.state, "State")
                                        //   })
                                        // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.stateerror}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="inputZip">Zip </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="inputZip"
                                        placeholder="Zip"
                                        value={userdata.zip}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            zip: e.target.value,
                                          })
                                        }
                                        // onBlur={() =>
                                        //   setErrors({
                                        //     ...errors,
                                        //     ziperror:  selectinputValidate(userdata.zip, "Zip")
                                        //   })
                                        // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.ziperror}
                                      </p>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label>Country </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Country"
                                        id=""
                                        value={userdata.country}
                                        onChange={(e) =>
                                          setUserData({
                                            ...userdata,
                                            country: e.target.value,
                                          })
                                        }
                                        // onBlur={() =>
                                        //   setErrors({
                                        //     ...errors,
                                        //     countryerror:  stringValidate(userdata.country, "Country")
                                        //   })
                                        // }
                                      />
                                      <p className="profile-input-errors">
                                        {errors.countryerror}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="text-center">
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={(e) => handleUserUpdate(e)}
                                    >
                                      Update Account
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.container-fluid -->  */}
          {/* modal to change password of user */}
          <ChangePassword email={userdata.email} />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    user: state.user.user,
    error: state.user.error,
    loading: state.user.loading,
    message: state.updateuser.message,
    fetching: state.countries.fetching,
    countries: state.countries.countries,
    states: state.states.states,
    cities: state.cities.cities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProfile: async (userid) => dispatch(fetchUser(userid)),
    updateUserProfile: async (userdata, imageData) =>
      dispatch(updateUser(userdata, imageData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profilepage));
