import {
    ADD_CUSTOMER_REQUEST,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
  };
  export const addCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_CUSTOMER_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case ADD_CUSTOMER_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: "Artist Created Successfully !!",
        };
      case ADD_CUSTOMER_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "Artist Created Failed",
        };
      default:
        return state;
    }
  };
  