import {
    FETCH_DASHBOARDDETAILS_REQUEST,
    FETCH_DASHBOARDDETAILS_SUCCESS,
    FETCH_DASHBOARDDETAILS_FAILURE,

    FETCH_DASHBOARDINVOICELIST_REQUEST,
    FETCH_DASHBOARDINVOICELIST_SUCCESS,
    FETCH_DASHBOARDINVOICELIST_FAILURE
  } from "../../actions/types";
  
  const initialState = {
    loading: true,
    data: [],
    error: "",
  };
  export const fetchDashboardDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DASHBOARDDETAILS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_DASHBOARDDETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
        };
      case FETCH_DASHBOARDDETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  ///--------------------------Dashboard Invoice List------------------------//
  export const fetchDashboardInvoiceListReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DASHBOARDINVOICELIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_DASHBOARDINVOICELIST_SUCCESS:
        return {
          ...state,
          loading: false,
          data : action.payload,
          error: "",
        };
      case FETCH_DASHBOARDINVOICELIST_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  