import React from 'react';
import Index from "../component/dashboard/index";

function Dashboard() {

    return (
        <div>
           <Index/>
        </div>
    )
}

export default Dashboard
