import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import Trigger from "../pages/trigger";
import TriggerDetail from "../component/trigger/triggerDetails";
export const triggerroutes = [

    {
        path: "/",
        exact: true,
        layout: Layout,
        component: () => <Redirect to="/login" />,
    },

    {
        path: "/Trigger",
        exact: true,
        layout: Layout,
        component: Trigger,
    },
    {
        path: "/Trigger_Detail",
        exact: true,
        layout: Layout,
        component: TriggerDetail,
    },
];
