import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Update_Booking from "./updateBooking"
import { checkRoute } from "../../utils/checkroute";
import $ from "jquery";
import { connect } from "react-redux";
import { fetchBookingById } from "../../actions/manageBooking/fetchBookingById";
import Delete_Booking from "./deleteBooking"
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import Geocode from 'react-geocode';

const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';
function BookingDetails(props) {
    const { id } = useParams();
    // to go to parent module
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { getBooking, data, error, loading, deleteMessage, updateError, message } = props;
    const [bookingId, setBookingId] = useState("")
    const [address, setAddress] = useState('');

    const history = useHistory();
    // let id = localStorage.getItem("BookingId");

    useEffect(() => {
        localStorage.setItem("View_Name", "Bookings");
        checkRoute();
        getBookingDetailsByID(id);
    }, [id]);
    const getBookingDetailsByID = () => {
        getBooking(id);
    };

    useEffect(() => {
        if (localStorage.getItem("message") === "BookingDeleteSuccess") {
            const message = "Booking deleted successfully";
            enqueueSnackbar("Booking deleted successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            history.goBack();
            // refreshform()
        }
        if (localStorage.getItem("message") === "BookingDeleteFailed") {
            const message = "Booking Deleted Failed";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform()
        }
    }, [message, updateError]);


    //Update Message 
    useEffect(() => {
        if (localStorage.getItem("message") === "RecordUpdatedFailed") {
            enqueueSnackbar("Booking Updated Failed", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "RecordUpdatedSuccessfully") {
            enqueueSnackbar("Booking Updated successfully", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            // refreshform();
            getBookingDetailsByID()
        }
    }, [message, error]);


    const handleCustomerDetailView = async (id) => {
        history.push({
            pathname: `/Customer_Details/${id}`,
            state: { bookingid: id },
        });
    };



    return !loading ? (
        error ? (
            "oops....something goes wrong"
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Booking</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item cursor">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("/Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item cursor"
                                                aria-current="page"
                                            >
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("/Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Booking
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Booking Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex align-items-center bg-light p-2">
                                        <h5>Booking</h5>
                                        <div className="ml-auto d-flex no-block align-items-center">
                                            <a
                                                onClick={() => {
                                                    history.goBack();
                                                    localStorage.removeItem("BookingId");
                                                }}
                                                className="btn btn-dark mr-3 "
                                                href={() => false}
                                            >
                                                Back
                                            </a>{" "}
                                            <a
                                                href={() => false}
                                                className="cursor"
                                                data-toggle="modal"
                                                data-target="#editBookingModal"
                                                onClick={() => {
                                                    setBookingId(id);
                                                }}
                                            >
                                                <button type="button" class="btn btn-info"> Edit</button>
                                            </a>

                                            {/* <a
                                                href={() => false}
                                                className="cursor"
                                                data-toggle="modal"
                                                data-target="#deleteBookingModal"
                                                onClick={() => {
                                                    setBookingId(id);
                                                }}
                                            >
                                                <button type="button" class="btn btn-info">Delete</button>
                                            </a> */}
                                        </div>
                                    </div>
                                    <br className="clearfix" />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <h5 className="card-header">Basic Information</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>State</th>
                                                                <td>{data.bookingState}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>BookingID</th>
                                                                <td>{data.bookingId}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Customer Details</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Customer Id</th>
                                                                <td><a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() =>
                                                                        handleCustomerDetailView(data?.customer?.customerId)
                                                                    }
                                                                >
                                                                    {data?.customer?.customerId}

                                                                </a></td>

                                                            </tr>

                                                            <tr>
                                                                <th>Customer Name</th>
                                                                <td>{data.hostName}</td>
                                                                {/* <td>{data?.customer?.customerUser?.firstName} {data?.customer?.customerUser?.lastName}</td> */}
                                                            </tr>
                                                            <tr>
                                                                <th>Type of Customer</th>
                                                                <td>{data.customerType}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>Customer Booking Link</th>
                                                                {/* <td>{data.specialRequest} </td> */}
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Service Details</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Profile Name</th>
                                                                <td>
                                                                    <a
                                                                        className="breadcrumb-link"
                                                                        href={() => false}
                                                                        onClick={() => {
                                                                            history.push({
                                                                                pathname: "/Artists_Details",
                                                                            });
                                                                            localStorage.setItem(
                                                                                "artistId",
                                                                                data?.artist?.artistId
                                                                            );
                                                                        }}
                                                                    >

                                                                        {data?.artist?.profileName}
                                                                    </a>

                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th>Setup</th>
                                                                <td>{data?.profileSetupType?.setupType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Duration</th>
                                                                <td>{data?.profileDuration?.durationType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Selected Sound Setup</th>
                                                                <td>{data?.profileSetupType?.setupType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sound Vendor ID</th>
                                                                <td>{data?.soundVendor?.soundVendorId} </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <h5 className="card-header">Event Details</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>


                                                            <tr>
                                                                <th>Event Date</th>
                                                                <td>
                                                                    {data.eventDate}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th>Start Time </th>
                                                                <td>{data.startTime}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>End Time</th>
                                                                <td>{data.endTime}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Location</th>
                                                                <td>{data?.venueAddress?.addressName}  </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Number Of Guests</th>
                                                                <td>{data.numberOfGuests} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Occasion</th>
                                                                <td>{data.occasion} </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <br className="clearfix" />
                                            <div className="card">
                                                <h5 className="card-header">Venue Address </h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Address Type</th>
                                                                <td>{data?.venueAddress?.addressType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Street
                                                                </th>
                                                                <td>{data?.venueAddress?.street} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>City</th>
                                                                <td>{data?.venueAddress?.city} </td>
                                                                <td> </td>
                                                            </tr>
                                                            <tr>
                                                                <th>ZipCode
                                                                </th>
                                                                <td>{data?.venueAddress?.zipCode} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>State
                                                                </th>
                                                                <td>{data?.venueAddress?.state} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Country</th>
                                                                <td>{data?.venueAddress?.country} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>HostName</th>
                                                                <td>{data?.hostName} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>HostPhone</th>
                                                                <td>{data?.hostPhone} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>HostPhone 2</th>
                                                                <td>{data?.hostPhone2} </td>
                                                            </tr>
                                                            <tr>
                                                                <th> Special Request</th>
                                                                <td>{data?.specialRequest} </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <h5 className="card-header">Booking Information</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>BookingID</th>
                                                                <td>{data.bookingId}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Booking Amount
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.bookingAmount} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Discount Code </th>
                                                                <td>{data?.bookingFinancialDetail?.discountCode} </td>
                                                                <td> </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Discount Percentage
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.discountPercentage} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Discount Amount
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.discountAmount} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Created date</th>
                                                                <td>{data?.bookingFinancialDetail?.createdDate} </td>
                                                            </tr>

                                                            <tr>
                                                                <th>Updated date</th>
                                                                <td>{data?.bookingFinancialDetail?.updateDate} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <h5 className="card-header">Consumer Charges</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Performance Listing Charge</th>
                                                                <td>{data?.bookingFinancialDetail?.performanceListingCharge}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Performance GST Applied
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.performanceGSTApplied} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Performance Discount</th>
                                                                <td>{data?.bookingFinancialDetail?.performanceDiscount} </td>
                                                                <td> </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Performance Selling Charge
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.performanceSellingCharge} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sound Service Listing Charge
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.soundServiceListingCharge} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sound Service GST Applied</th>
                                                                <td>{data?.bookingFinancialDetail?.soundServiceGSTApplied} </td>
                                                            </tr>

                                                            <tr>
                                                                <th>Sound Service Discount </th>
                                                                <td>{data?.bookingFinancialDetail?.soundServiceDiscount} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sound Service Selling Charge </th>
                                                                <td>{data?.bookingFinancialDetail?.soundServiceSellingCharge} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Performance Transportation Charge</th>
                                                                <td>{data?.bookingFinancialDetail?.performanceTransportationCharge} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <h5 className="card-header">Advance Breakup</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Performance Advance</th>
                                                                <td>{data?.bookingFinancialDetail?.performanceAdvance}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sound Service Advance
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.soundServiceAdvance} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <h5 className="card-header">Actual Earnings</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Artist Earnings</th>
                                                                <td>{data?.bookingFinancialDetail?.artistEarnings}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>TLS Earnings
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.tLSEarnings} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sound Vendor Earnings
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.soundVendorEarnings} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Gateway Charges
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.gatewayCharges} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Government Taxes
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.governmentTaxes} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <h5 className="card-header">ServiceProvider Payout</h5>
                                                <div className="card-body pb-1">
                                                    <table className="table table1 table-borderless table-responsive table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <th>Artist Payout</th>
                                                                <td>{data?.bookingFinancialDetail?.artistPayout}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Sound Vendor Payout
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.soundVendorPayout} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>TLS Payout
                                                                </th>
                                                                <td>{data?.bookingFinancialDetail?.tLSPayout} </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid -->  */}
                {/* <Delete_Booking bookingId={bookingId} /> */}
                <Update_Booking
                    bookingDetailsById={data} />
            </>
        )
    ) : (
        ""
    );
}

const mapStateToProps = (state, props) => {

    return {
        deleteMessage: state.deleteBooking.message,
        loading: state.getBookingById.loading,
        data: state.getBookingById.data,
        error: state.getBookingById.error,
        // loading: state.updateBooking.loading,
        message: state.updateBooking.message,
        updateError: state.updateBooking.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBooking: (Booking_ID) => dispatch(fetchBookingById(Booking_ID)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
