import { useEffect, useState, useRef } from "react"
import {
    addArtist,
    addArtistRequest,
} from "../../actions/artists/addArtists";
import { connect } from "react-redux";
import { fetchAllPriceSlabDropdown } from "../../actions/common/getAllDropdown"

//VALIDATION FUNCTION
import {
    emailValidate,
    stringValidate,
    numberValidate,
    contactNumberValidation,
    postalCodeValidation,
    passwordValidate,
    // websiteValidation,
} from "../../utils/Validations";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
//IMPORTING ACTION CREATERS TO DISPATCH ACTIONS
import $ from "jquery";

import { Dropdown } from "react-bootstrap";

import Autocomplete from "react-google-autocomplete";
//------------------ CREATE CUSTOMER COMPONENT ----------------------//
const apiKey = 'AIzaSyB3gZ5M7wJ21UaSLJqlUsDuWL_cK0JVb94';

const AddartistListing = (props) => {
    const [sameasaddress, setSameAsAddress] = useState(false);
    const [selectedPriceSlab, setSelectedPriceSlab] = useState("");
    const [selectedOwnSoundSystem, setSelectedOwnSoundSystem] = useState("");
    const [selectedPublished, setSelectedPublished] = useState("");

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { AddArtist, message, error, refreshform, PriceSLabDropdownData, handlePriceSLabDropdownData } = props;
    //UseReff
    const inputFile = useRef(null);
    //----------------  ADD artist STATE TO HANDLE INPUT VALUES ------------***//
    useEffect(() => {
        handlePriceSLabDropdownData()

    }, [])
    const hasOwnSoundSystemData = [
        {
            name: "true",
        },
        {
            name: "false",
        },
    ];
    const [artist, setArtist] = useState({
        profileName: "",
        instagramProfileLink: "",
        genres: "",
        rating: "",
        about: "",
        inspirations: "",
        numberOfShowsPerformed: "",
        yearsOfExperience: "",
        achievements: "",
        individualSkill: "",
        samplePlaylist: "",
        hasOwnSoundSystem: "",
        baseLocationUrl: "",
        maxServiceaablekm: "",
        gSTIN: "",
        operatingState: "",
        published: "",
        region: "",
        area: "",
        userName: "",
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        secondaryPhone: "",
        googleMapLocation: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        latitude: "",
        longitude: "",
        priceSlabId: "",
        backgroundImage: ""
    });
    const [addressData, setAddressData] = useState({
        longitude: "",
        latitude: "",
        addressName: "",
        street: "",
        city: "",
        state: "",
        country: "",
    })
    //----------------  ADD artist FIELDS ERROR ------------***//
    const [addartistserrors, setAddArtistsErrors] = useState({
        profileName: null,
        instagramProfileLink: null,
        genres: null,
        rating: null,
        about: null,
        inspirations: null,
        numberOfShowsPerformed: null,
        yearsOfExperience: null,
        achievements: null,
        individualSkill: null,
        samplePlaylist: null,
        hasOwnSoundSystem: null,
        baseLocationUrl: null,
        maxServiceaablekm: null,
        gSTIN: null,
        operatingState: null,
        published: null,
        area: null,
        region: null,
        userName: null,
        password: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        secondaryPhone: null,
        googleMapLocation: null,
        street: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        latitude: null,
        longitude: null,
        priceSlabId: null,
        backgroundImage: null
    });

    const clearState = () => {
        setArtist({
            ...artist,
            profileName: "",
            instagramProfileLink: "",
            genres: "",
            rating: "",
            about: "",
            inspirations: "",
            numberOfShowsPerformed: "",
            yearsOfExperience: "",
            achievements: "",
            individualSkill: "",
            samplePlaylist: "",
            hasOwnSoundSystem: "",
            baseLocationUrl: "",
            maxServiceaablekm: "",
            gSTIN: "",
            operatingState: "",
            published: "",
            region: "",
            userName: "",
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            secondaryPhone: "",
            googleMapLocation: "",
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            latitude: "",
            longitude: "",
            priceSlabId: "",
            backgroundImage: ""
        });

        setAddressData({
            ...addressData,
            longitude: "",
            latitude: "",
            addressName: "",
            street: "",
            city: "",
            state: "",
            country: "",
        })

    };
    const handleOwnSoundSystem = (option) => {

        setSelectedOwnSoundSystem(option.label)
        setArtist({
            ...artist,
            hasOwnSoundSystem: option.value,
        })
    }
    const handleArtistPublished = (option) => {

        setSelectedPublished(option.label)
        setArtist({
            ...artist,
            published: option.value,
        })
    }
    const handleValidation = () => {
        setAddArtistsErrors({
            ...addartistserrors,
            profileName: stringValidate(artist.profileName, "ProfileName"),
            instagramProfileLink: stringValidate(artist.instagramProfileLink, "InstagramProfileLink"),
            genres: stringValidate(artist.genres, "Genres"),
            // rating: stringValidate(artist.rating, "Rating"),
            about: stringValidate(artist.about, "About"),
            inspirations: stringValidate(artist.inspirations, "Inspirations"),
            // numberOfShowsPerformed: numberValidate(artist.numberOfShowsPerformed, "NumberOfShowsPerformed"),
            yearsOfExperience: numberValidate(artist.yearsOfExperience, "YearsOfExperience"),
            achievements: stringValidate(artist.achievements, "Achievements"),
            individualSkill: stringValidate(artist.individualSkill, "IndividualSkill"),
            samplePlaylist: stringValidate(artist.samplePlaylist, "SamplePlaylist"),
            hasOwnSoundSystem: stringValidate(artist.hasOwnSoundSystem, "HasOwnSoundSystem"),
            // baseLocationUrl: stringValidate(artist.baseLocationUrl, "BseLocationUrl"),
            maxServiceaablekm: numberValidate(artist.maxServiceaablekm, "MaxServiceaablekm"),
            // gSTIN: stringValidate(artist.gSTIN, "GSTIN"),
            operatingState: stringValidate(artist.operatingState, "OperatingState"),
            published: stringValidate(artist.published, "Published"),
            region: stringValidate(artist.region, "Region"),
            userName: stringValidate(artist.userName, "UserName"),
            password: passwordValidate(artist.password, "Password"),
            email: emailValidate(artist.email, "Email"),
            firstName: stringValidate(artist.firstName, "FirstName"),
            lastName: stringValidate(artist.lastName, "LastName"),
            phoneNumber: contactNumberValidation(artist.phoneNumber, "phoneNumber"),
            city: stringValidate(addressData.city, "City"),
            state: stringValidate(addressData.state, "State"),
            zipCode: postalCodeValidation(artist.zipCode, "ZipCode"),
            country: stringValidate(addressData.country, "Country"),
            longitude: numberValidate(addressData.longitude, "Longitude"),
            latitude: numberValidate(addressData.latitude, "Latitude"),
            priceSlabId: numberValidate(artist.priceSlabId, "PriceSlabId"),
            backgroundImage: stringValidate(artist.backgroundImage, "BackgroundImage"),

        });
    };

    //---------------------------------------  ADD artist FUNCTION WHICH CHECKING INPUT VALIDATION AT FIRST   ------------------------***//
    const handleAddartist = (e) => {
        handleValidation();
        if (
            stringValidate(artist.profileName, "ProfileName") === null &&
            stringValidate(artist.instagramProfileLink, "InstagramProfileLink") === null &&
            stringValidate(artist.genres, "Genres") === null &&
            // stringValidate(artist.rating, "Rating") === null &&
            stringValidate(artist.about, "About") === null &&
            stringValidate(artist.inspirations, "Inspirations") === null &&
            // numberValidate(artist.numberOfShowsPerformed, "NumberOfShowsPerformed") === null &&
            numberValidate(artist.yearsOfExperience, "YearsOfExperience") === null &&
            stringValidate(artist.achievements, "Achievements") === null &&
            stringValidate(artist.individualSkill, "IndividualSkill") === null &&
            stringValidate(artist.samplePlaylist, "SamplePlaylist") === null &&
            stringValidate(artist.hasOwnSoundSystem, "HasOwnSoundSystem") === null &&
            // stringValidate(artist.baseLocationUrl, "BaseLocationUrl") === null &&
            numberValidate(artist.maxServiceaablekm, "MaxServiceaablekm") === null &&
            // stringValidate(artist.gSTIN, "GSTIN") === null &&
            stringValidate(artist.operatingState, "OperatingState") === null &&
            stringValidate(artist.published, "Published") === null &&
            stringValidate(artist.region, "Region") === null &&
            stringValidate(artist.userName, "UserName") === null &&
            passwordValidate(artist.password, "Password") === null &&
            emailValidate(artist.email, "Email") === null &&
            stringValidate(artist.firstName, "FirstName") === null &&
            stringValidate(artist.lastName, "LastName") === null &&
            contactNumberValidation(artist.phoneNumber, "phoneNumber") === null &&
            stringValidate(addressData.city, "City") === null &&
            stringValidate(addressData.state, "State") === null &&
            postalCodeValidation(artist.zipCode, "ZipCode") === null &&
            stringValidate(addressData.country, "Country") === null &&
            numberValidate(addressData.longitude, "Longitude") === null &&
            numberValidate(addressData.latitude, "Latitude") === null &&
            numberValidate(artist.priceSlabId, "PriceSlabId") === null &&
            stringValidate(artist.backgroundImage, "BackgroundImage") === null
        ) {
            handleClearValidationError()
            AddArtist(artist, addressData);

        }
    };

    //------------------------- USEFFECT HOOK EXECUTING ON FIRST RENDER OF VIEW -------------------------**//

    useEffect(() => {
        if (localStorage.getItem("message") === "addArtistfailed") {
            enqueueSnackbar(error, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            // refreshform();
        }

        if (localStorage.getItem("message") === "addArtistSuccess") {
            handleClearValidationError();
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            $(".close").click();
            refreshform();
        }
    }, [message, error]);

    //-----------------------------------  FUNCTION TO CLEAR VALIDATION ERROR STATE ON FUNCTION CALL--------------------------------------//

    const handleClearValidationError = () => {
        setAddArtistsErrors({
            ...addartistserrors,
            profileName: null,
            instagramProfileLink: null,
            genres: null,
            rating: null,
            about: null,
            inspirations: null,
            numberOfShowsPerformed: null,
            yearsOfExperience: null,
            achievements: null,
            individualSkill: null,
            samplePlaylist: null,
            hasOwnSoundSystem: null,
            baseLocationUrl: null,
            maxServiceaablekm: null,
            gSTIN: null,
            operatingState: null,
            published: null,
            region: null,
            userName: null,
            password: null,
            email: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            secondaryPhone: null,
            googleMapLocation: null,
            street: null,
            city: null,
            state: null,
            zipCode: null,
            country: null,
            latitude: null,
            longitude: null,
            priceSlabId: null,
            backgroundImage: null
        });

        setArtist({
            ...artist,
            profileName: "",
            instagramProfileLink: "",
            genres: "",
            rating: "",
            about: "",
            inspirations: "",
            numberOfShowsPerformed: "",
            yearsOfExperience: "",
            achievements: "",
            individualSkill: "",
            samplePlaylist: "",
            hasOwnSoundSystem: "",
            baseLocationUrl: "",
            maxServiceaablekm: "",
            gSTIN: "",
            operatingState: "",
            published: "",
            region: "",
            userName: "",
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            secondaryPhone: "",
            googleMapLocation: "",
            street: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            latitude: "",
            longitude: "",
            priceSlabId: "",
            backgroundImage: ""
        });
        setAddressData({
            ...addressData,
            longitude: "",
            latitude: "",
            addressName: "",
            street: "",
            city: "",
            state: "",
            country: "",
        })
        setSelectedOwnSoundSystem("")
        setSelectedPriceSlab("")
        setSelectedPublished("")
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    };

    const onPlacesChanged = (place) => {
        const { geometry, formatted_address } = place;

        if (geometry && geometry && formatted_address) {
            const { lat, lng } = geometry.location;
            const addressParts = formatted_address.split(', ');

            const street = addressParts[0]; // The street
            const city = addressParts[1]; // The city
            const state = addressParts[2]; // The state
            const country = addressParts[3]; // The country
            let pinCode = '';
            for (let i = addressParts.length - 1; i >= 0; i--) {
                if (/\d/.test(addressParts[i])) {
                    pinCode = addressParts[i];
                    break;
                }
            }

            setAddressData({
                ...addressData,
                latitude: lat(),
                longitude: lng(),
                addressName: formatted_address,
                street: street,
                city: city,
                state: state,
                country: country,
            })

        }
    };
    const handleGetDropdownValue = (option) => {

        setSelectedPriceSlab(option.label)
        setArtist({
            ...artist,
            priceSlabId: option.value,
        })
    }


    return (
        // <!-- The Modal -->
        <div className="modal" id="createCustomerModal">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                        <h4 className="modal-title">Add Artist</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={handleClearValidationError}
                        >
                            &times;
                        </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                        <div id="accordion">
                            <div className="card">
                                <div
                                    id="maininfo"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Profile Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Profile Name"
                                                        id="subs"
                                                        value={artist.profileName}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                profileName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                profileName: stringValidate(
                                                                    artist.profileName,
                                                                    "ProfileName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.profileName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Cover Image{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="Cover Image"
                                                        id="subs"
                                                        ref={inputFile}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                backgroundImage: e.target.files[0],
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                backgroundImage: stringValidate(
                                                                    artist.backgroundImage,
                                                                    "BackgroundImage"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.backgroundImage}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Instagram Profile Link{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="Instagram Profile Link"
                                                        id="subs"

                                                        value={artist.instagramProfileLink}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                instagramProfileLink: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                instagramProfileLink: stringValidate(
                                                                    artist.instagramProfileLink,
                                                                    "InstagramProfileLink"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.instagramProfileLink}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Genres{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Genres"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.genres}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                genres: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                genres: stringValidate(
                                                                    artist.genres,
                                                                    "Genres"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.genres}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Likes{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Likes"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.rating}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                rating: e.target.value,
                                                            })
                                                        }
                                                    // onBlur={() =>
                                                    //     setAddArtistsErrors({
                                                    //         ...addartistserrors,
                                                    //         rating: stringValidate(
                                                    //             artist.rating,
                                                    //             "Rating"
                                                    //         ),
                                                    //     })
                                                    // }
                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.rating}
                                                    </p> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        About<span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="About"
                                                        id=""
                                                        value={artist.about}
                                                        onChange={(e) => {
                                                            setArtist({
                                                                ...artist,
                                                                about: e.target.value,
                                                            });
                                                        }}
                                                        // ref={FromPriceRef}
                                                        onBlur={() => {
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                about: stringValidate(
                                                                    artist.about,
                                                                    "About"
                                                                ),
                                                            });
                                                        }}
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.about}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Inspirations{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Inspirations"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.inspirations}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                inspirations: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                inspirations: stringValidate(
                                                                    artist.inspirations,
                                                                    "Inspirations"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.inspirations}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Number Of Shows Performed
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type="subs"
                                                        className="form-control"
                                                        placeholder="NumberOf Shows Performed"
                                                        id="subs"

                                                        value={artist.numberOfShowsPerformed}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                numberOfShowsPerformed: e.target.value,
                                                            })
                                                        }
                                                    // onBlur={() =>
                                                    //     setAddArtistsErrors({
                                                    //         ...addartistserrors,
                                                    //         numberOfShowsPerformed: numberValidate(
                                                    //             artist.numberOfShowsPerformed,
                                                    //             "NumberOfShowsPerformed"
                                                    //         ),
                                                    //     })
                                                    // }
                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.numberOfShowsPerformed}
                                                    </p> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Years Of Experience{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder=" Years Of Experience"
                                                        id=""

                                                        value={artist.yearsOfExperience}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                yearsOfExperience: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                yearsOfExperience: numberValidate(
                                                                    artist.yearsOfExperience,
                                                                    "YearsOfExperience"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.yearsOfExperience}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Achievements{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Achievements"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.achievements}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                achievements: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                achievements: stringValidate(
                                                                    artist.achievements,
                                                                    "Achievements"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.achievements}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Individual Skill{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Individual Skill"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.individualSkill}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                individualSkill: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                individualSkill: stringValidate(
                                                                    artist.individualSkill,
                                                                    "IndividualSkill"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.individualSkill}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Sample Play list <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Sample Play list"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.samplePlaylist}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                samplePlaylist: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                samplePlaylist: stringValidate(
                                                                    artist.samplePlaylist,
                                                                    "SamplePlaylist"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.samplePlaylist}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Artist Has Own Sound System?{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>

                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {artist?.hasOwnSoundSystem
                                                                ? artist?.hasOwnSoundSystem.toString()
                                                                : "Artist Has Own Sound System?"}
                                                            {/* {selectedOwnSoundSystem
                                                                ? selectedOwnSoundSystem
                                                                : "Artist Has Own Sound System?"} */}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {hasOwnSoundSystemData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    // onClick={() =>
                                                                    //     handleOwnSoundSystem(option)
                                                                    // }
                                                                    onClick={() =>
                                                                        setArtist({
                                                                            ...artist,
                                                                            hasOwnSoundSystem: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddArtistsErrors({
                                                                            ...addartistserrors,
                                                                            hasOwnSoundSystem: stringValidate(
                                                                                artist.hasOwnSoundSystem,
                                                                                "HasOwnSoundSystem"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.hasOwnSoundSystem}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Max. Service available km{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Max. Service available km"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.maxServiceaablekm}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                maxServiceaablekm: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                maxServiceaablekm: numberValidate(
                                                                    artist.maxServiceaablekm,
                                                                    "MaxServiceaablekm"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.maxServiceaablekm}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        GSTIN{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="GSTIN"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.gSTIN}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                gSTIN: e.target.value,
                                                            })
                                                        }
                                                    // onBlur={() =>
                                                    //     setAddArtistsErrors({
                                                    //         ...addartistserrors,
                                                    //         gSTIN: stringValidate(
                                                    //             artist.gSTIN,
                                                    //             "GSTIN"
                                                    //         ),
                                                    //     })
                                                    // }
                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.gSTIN}
                                                    </p> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Operating State{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Operating State"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.operatingState}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                operatingState: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                operatingState: stringValidate(
                                                                    artist.operatingState,
                                                                    "OperatingState"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.operatingState}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Published{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Dropdown class="formDropDown">
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            class="formDropDownToggle"
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            {/* {selectedPublished
                                                                ? selectedPublished
                                                                : "Published"} */}
                                                            {artist?.published
                                                                ? artist?.published.toString()
                                                                : "Published ?"}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {hasOwnSoundSystemData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        setArtist({
                                                                            ...artist,
                                                                            published: option.name,
                                                                        })
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddArtistsErrors({
                                                                            ...addartistserrors,
                                                                            published: stringValidate(
                                                                                artist.published,
                                                                                "Published"
                                                                            ),
                                                                        })
                                                                    }
                                                                >
                                                                    {option.name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.published}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Region{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Region"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.region}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                region: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                region: stringValidate(
                                                                    artist.region,
                                                                    "Region"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.region}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Area{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Area"
                                                        id=""
                                                        value={artist.area}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                area: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                area: stringValidate(
                                                                    artist.area,
                                                                    "area"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.area}
                                                    </p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />


                            <div className="card mt-2">
                                <div className="card-header Accordian" id="headingT">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#artistuserinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseT"
                                            style={{ height: "50px" }}
                                        >
                                            <h5
                                                className="pb-4"
                                            >
                                                Artist User
                                            </h5>

                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="artistuserinfo"
                                    className="collapse show"
                                    aria-labelledby="headingT"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        User Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="User Name"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={artist.userName}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                userName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                userName: stringValidate(
                                                                    artist.userName,
                                                                    "UserName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.userName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Password{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Password"
                                                        id=""

                                                        value={artist.password}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                password: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                password: passwordValidate(
                                                                    artist.password,
                                                                    "Password"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.password}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Email{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"

                                                        placeholder="Email"
                                                        value={artist.email}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                email: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                email: emailValidate(
                                                                    artist.email,
                                                                    "Email"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.email}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        First Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="First Name"
                                                        value={artist.firstName}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                firstName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                firstName: stringValidate(
                                                                    artist.firstName,
                                                                    "firstName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.firstName}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Last Name{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="Last Name"
                                                        value={artist.lastName}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                lastName: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                lastName: stringValidate(
                                                                    artist.lastName,
                                                                    "lastName"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.lastName}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Phone Number{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="Phone Number"
                                                        value={artist.phoneNumber}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                phoneNumber: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                phoneNumber: contactNumberValidation(
                                                                    artist.phoneNumber,
                                                                    "PhoneNumber"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.phoneNumber}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Secondary Phone{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="SecondaryPhone"
                                                        value={artist.secondaryPhone}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                secondaryPhone: e.target.value,
                                                            })
                                                        }

                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.secondaryPhone}
                                                    </p> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mt-2">
                                <div className="card-header Accordian" id="headingo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-text d-flex w-100"
                                            data-toggle="collapse"
                                            data-target="#locationinfo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                            style={{ height: "50px" }}
                                        >
                                            <h5
                                                className="pb-4"
                                            >
                                                Base Location
                                            </h5>

                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="locationinfo"
                                    className="collapse show"
                                    aria-labelledby="headingo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Address{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Autocomplete
                                                        apiKey={apiKey}
                                                        style={{ width: "100%", height: "38px", borderRadius: "5px" }}
                                                        onPlaceSelected={(place) => {
                                                            onPlacesChanged(place);
                                                        }}
                                                        options={{
                                                            types: ["(regions)"],
                                                            // types: [
                                                            //     "cafe",
                                                            //     "restaurant",
                                                            //     "stadium",
                                                            //     "school",
                                                            //     "amusement_park",
                                                            //   ],
                                                            componentRestrictions: { country: "IND" },
                                                        }}
                                                        defaultValue="Noida"
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.longitude}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Street{" "}
                                                        {/* <span style={{ color: "#d20d0d" }}>*</span> */}
                                                    </label>
                                                    <input
                                                        type=""
                                                        className="form-control"
                                                        placeholder="Street"
                                                        id=""
                                                        // ref={OfferRef}
                                                        value={addressData.street}
                                                        onChange={(e) =>
                                                            setAddressData({
                                                                ...addressData,
                                                                street: e.target.value,
                                                            })
                                                        }
                                                    // onBlur={() =>
                                                    //     setAddArtistsErrors({
                                                    //         ...addartistserrors,
                                                    //         street: stringValidate(
                                                    //             artist.street,
                                                    //             "Street"
                                                    //         ),
                                                    //     })
                                                    // }
                                                    />
                                                    {/* <p className="profile-input-errors">
                                                        {addartistserrors.street}
                                                    </p> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        City{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="City"
                                                        value={addressData.city}
                                                        onChange={(e) =>
                                                            setAddressData({
                                                                ...addressData,
                                                                city: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                city: stringValidate(
                                                                    addressData.city,
                                                                    "City"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.city}
                                                    </p>
                                                </div>

                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        ZipCode{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="ZipCode"
                                                        value={artist.zipCode}
                                                        onChange={(e) =>
                                                            setArtist({
                                                                ...artist,
                                                                zipCode: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                zipCode: postalCodeValidation(
                                                                    artist.zipCode,
                                                                    "zipCode"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.zipCode}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        State{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="State"
                                                        value={addressData.state}
                                                        onChange={(e) =>
                                                            setAddressData({
                                                                ...addressData,
                                                                state: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                state: stringValidate(
                                                                    artist.state,
                                                                    "State"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.state}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Country{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        rows="5"
                                                        id="comment"
                                                        placeholder="country"
                                                        value={addressData.country}
                                                        onChange={(e) =>
                                                            setAddressData({
                                                                ...addressData,
                                                                country: e.target.value,
                                                            })
                                                        }
                                                        onBlur={() =>
                                                            setAddArtistsErrors({
                                                                ...addartistserrors,
                                                                country: stringValidate(
                                                                    addressData.country,
                                                                    "Country"
                                                                ),
                                                            })
                                                        }
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.country}
                                                    </p>
                                                </div>

                                            </div>



                                            {/* <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Latitude/Longitude{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Autocomplete
                                                        apiKey={apiKey}
                                                        style={{ width: "100%", height: "38px", borderRadius: "5px" }}
                                                        onPlaceSelected={(place) => {
                                                            onPlacesChanged(place);
                                                        }}
                                                        options={{
                                                            types: ["(regions)"],
                                                            componentRestrictions: { country: "IND" },
                                                        }}
                                                        defaultValue="Noida"
                                                    />
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.longitude}
                                                    </p>
                                                </div>

                                            </div> */}
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>
                                                        Price Slab{" "}
                                                        <span style={{ color: "#d20d0d" }}>*</span>
                                                    </label>
                                                    <Dropdown class="formDropDown mt-4 mb-4">
                                                        <Dropdown.Toggle id="dropdown-basic" class="formDropDownToggle "
                                                            style={{
                                                                width: "100%",
                                                                background: "#D7263D",
                                                                outline: "none",
                                                                border: "none",
                                                            }}>
                                                            {selectedPriceSlab ? selectedPriceSlab : "Price Slab"}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {PriceSLabDropdownData.map((option) => (
                                                                <Dropdown.Item
                                                                    key={option}
                                                                    onClick={() =>
                                                                        handleGetDropdownValue(option)
                                                                    }
                                                                    onBlur={() =>
                                                                        setAddArtistsErrors({
                                                                            ...addartistserrors,
                                                                            priceSlabId: stringValidate(
                                                                                artist.priceSlabId,
                                                                                "PriceSlabId"
                                                                            ),
                                                                        })
                                                                    }

                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p className="profile-input-errors">
                                                        {addartistserrors.priceSlabId}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Modal footer --> */}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            // data-dismiss="modal"
                            onClick={handleAddartist}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {

    return {
        loading: state.addArtist.loading,
        message: state.addArtist.message,
        error: state.addArtist.error,
        PriceSLabDropdownData: state.PriceSlabDropdown.PriceSlabDropdown
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handlePriceSLabDropdownData: () => dispatch(fetchAllPriceSlabDropdown()),
        AddArtist: (artist, addressData) => dispatch(addArtist(artist, addressData)),
        refreshform: () => dispatch(addArtistRequest()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddartistListing));
