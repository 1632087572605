import React from 'react'
import User from '../component/Users/Users'
function Users() {
    return (
        <div>
            <User/>
        </div>
    )
}

export default Users
