import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddSurgePrice from "./addSurgePrice";
import UpdateSurgePrice from "./updateSurgePrice"
import Delete_Surge_Price from "./deleteSurgePriceById"
import { fetchAllSurgePrice } from "../../actions/surgePrice/getAllSurgePrice"
import { fetchAllSurgePriceById } from "../../actions/surgePrice/getSurgePriceById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";

function SurgePrice(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [surgePriceId, setSurgePriceId] = useState(0);
    const [paginationvalues, setPaginationValues] = useState({});

    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 2,
        sortingorder: "desc",
        search: null,
        columnname: "",
    });

    const {
        loading,
        error,
        message,
        fetchSurgePriceById,
        handleGetSurgePrice,
        SurgePriceData,
        SurgePriceByIdData
    } = props;

    useEffect(() => {
        checkRoute();
    }, [SurgePriceData]);
    useEffect(() => {
        localStorage.setItem("View_Name", "surge_price");
        checkRoute();
        handleGetSurgePrice()
    }, []);


    return !loading ? (
        error ? (
            <div className="cover-spin"></div>
        ) : (
            <>
                <div className="container-fluid">
                    {/* <!-- Page Heading --> */}
                    <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-5 align-self-center">
                                <h4 className="page-title">Surge</h4>
                                <div className="d-flex align-items-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-1">
                                            <li className="breadcrumb-item">
                                                <a
                                                    className="breadcrumb-link"
                                                    onClick={() => history.push("/Booking_Request")}
                                                    href={() => false}
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Surge Price
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-7 align-self-center">
                                <div className="d-flex no-block justify-content-end align-items-center">
                                    <div className="m-r-10">
                                        <div className="lastmonth"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-4 pt-2"></div>
                                        <div className="col-lg-4 pt-2 text-right">
                                            {" "}
                                            <a
                                                href={() => false}
                                                className="btn btn-primary "
                                                data-toggle="modal"
                                                data-target="#createSurgePriceModal"
                                            >
                                                Add Surge Price
                                            </a>{" "}
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Surgre Pricing Name</div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Start Date</div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>End Date</div>
                                                        </div>
                                                    </th>

                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Start Time</div>
                                                        </div>
                                                    </th>

                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>End Time</div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div
                                                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                            id="column"
                                                        >
                                                            <div>Surge Multiplier</div>
                                                        </div>
                                                    </th>

                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {SurgePriceData !== null && SurgePriceData !== undefined && Object.entries(SurgePriceData).length !== 0 ? (
                                                    SurgePriceData.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.surgrePricingName}</td>


                                                                <td>{item.startDate}</td>
                                                                <td>{item.endDate}</td>
                                                                <td>{item.startTime}</td>
                                                                <td>{item.endTime}</td>
                                                                <td>{item.surgeMultiplier}</td>

                                                                <td>
                                                                    <a
                                                                        href={() => false}
                                                                        className="cursor"
                                                                        data-toggle="modal"
                                                                        data-target="#updateSurgePriceModal"
                                                                        onClick={() => fetchSurgePriceById(item.surgePriceId)}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                    </a>{" "}
                                                                    <a
                                                                        href={() => false}
                                                                        className="cursor"
                                                                        data-toggle="modal"
                                                                        data-target="#deleteSurgePriceModal"
                                                                        onClick={() => {
                                                                            setSurgePriceId(item.surgePriceId);
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : SurgePriceData.length === 0 ? (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div>
                                                                <p>
                                                                    <strong style={{ color: "grey" }}>
                                                                        No records found
                                                                    </strong>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <div className="loading1"></div>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddSurgePrice />
                <UpdateSurgePrice
                    SurgePriceByIdData={SurgePriceByIdData}
                />
                <Delete_Surge_Price surgePriceId={surgePriceId} />
                <ReactTooltip />
            </>
        )
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {

    return {
        SurgePriceData: state.getAllSurgePrice.data,
        loading: state.getAllSurgePrice.loading,
        error: state.getAllSurgePrice.error,
        SurgePriceByIdData: state.getSurgePriceById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleGetSurgePrice: () => dispatch(fetchAllSurgePrice()),
        fetchSurgePriceById: (id) => dispatch(fetchAllSurgePriceById(id)),


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurgePrice);
