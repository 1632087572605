import {
    DELETE_PRICE_SLAB_BY_ID_REQUEST,
    DELETE_PRICE_SLAB_BY_ID_SUCCESS,
    DELETE_PRICE_SLAB_BY_ID_FAILURE,
} from "../types";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllPriceSlab } from "./getAllPriceSlab"

export const deletePriceSlabByIdRequest = () => {
    return {
        type: DELETE_PRICE_SLAB_BY_ID_REQUEST,
    };
};

export const deletePriceSlabByIdSuccess = (Data) => {
    return {
        type: DELETE_PRICE_SLAB_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const deletePriceSlabByIdFailure = (Error) => {
    return {
        type: DELETE_PRICE_SLAB_BY_ID_FAILURE,
        payload: Error,
    };
};

export const deletePriceSlabById = (surgePriceId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deletePriceSlabByIdRequest());
        axios
            .delete(url + `deletePriceSlabById/${surgePriceId}`, {
                headers: headers,
            })
            .then(async (response) => {
                await dispatch(deletePriceSlabByIdSuccess(response.data));
                localStorage.setItem("message", "PriceSlabDeleteSuccess");
                dispatch(fetchAllPriceSlab())
            })
            .catch((error) => {
                dispatch(deletePriceSlabByIdFailure(error));
                localStorage.setItem("message", "PriceSlabDeleteFailed");

            });
    };
};
