

import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import SurgePricePage from "../pages/surgeprice";

export const surgepriceroutes = [

    {
        path: "/",
        exact: true,
        layout: Layout,
        component: () => <Redirect to="/login" />,
    },

    {
        path: "/surge_price",
        exact: true,
        layout: Layout,
        component: SurgePricePage,
    },

];
