//*************************************************************Login and profile************************************************** */

//*******************************auth login********************* */
export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";

//*******************************auth login********************* */
export const FETCH_LOGIN_AGENT_REQUEST = "FETCH_LOGIN_AGENT_REQUEST";
export const FETCH_LOGIN_AGENT_SUCCESS = "FETCH_LOGIN_AGENT_SUCCESS";
export const FETCH_LOGIN_AGENT_FAILURE = "FETCH_LOGIN_AGENT_FAILURE";

//*******************************auth login********************* */
export const FETCH_LOGIN_AS_AGENT_REQUEST = "FETCH_LOGIN_AS_AGENT_REQUEST";
export const FETCH_LOGIN_AS_AGENT_SUCCESS = "FETCH_LOGIN_AS_AGENT_SUCCESS";
export const FETCH_LOGIN_AS_AGENT_FAILURE = "FETCH_LOGIN_AS_AGENT_FAILURE";

//*******************************auth logout********************* */

export const FETCH_LOGOUT_REQUEST = "FETCH_LOGOUT_REQUEST";
export const FETCH_LOGOUT_SUCCESS = "FETCH_LOGOUT_SUCCESS";
export const FETCH_LOGOUT_FAILURE = "FETCH_LOGOUT_FAILURE";

//*******************************register********************* */
export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

//*******************************ForgetPassword********************* */
export const POST_FORGETPASSWORD_REQUEST = "POST_FORGETPASSWORD_REQUEST";
export const POST_FORGETPASSWORD_SUCCESS = "POST_FORGETPASSWORD_SUCCESS";
export const POST_FORGETPASSWORD_FAILURE = "POST_FORGETPASSWORD_FAILURE";

//*******************************ForgetPassword********************* */
export const POST_CHANGEFORGETPASSWORD_REQUEST =
  "POST_CHANGEFORGETPASSWORD_REQUEST";
export const POST_CHANGEFORGETPASSWORD_SUCCESS =
  "POST_CHANGEFORGETPASSWORD_SUCCESS";
export const POST_CHANGEFORGETPASSWORD_FAILURE =
  "POST_CHANGEFORGETPASSWORD_FAILURE";

//*******************************ChangePassword********************* */
export const POST_CHANGEPASSWORD_REQUEST = "POST_CHANGEPASSWORD_REQUEST";
export const POST_CHANGEPASSWORD_SUCCESS = "POST_CHANGEPASSWORD_SUCCESS";
export const POST_CHANGEPASSWORD_FAILURE = "POST_CHANGEPASSWORD_FAILURE";

// *********************************     user data fetch action *********************     */

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

// *********************************     users list fetch action *********************     */

export const FETCH_USER_LIST_REQUEST = "FETCH_USER_LIST_REQUEST";
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS";
export const FETCH_USER_LIST_FAILURE = "FETCH_USER_LIST_FAILURE";

// *********************************     user data update action *********************     */

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

// *********************************     user data Post action *********************     */

export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAILURE = "POST_USER_FAILURE";

// *********************************     Delete User action *********************     */

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const FETCH_ALLUSER_REQUEST = "FETCH_ALLUSER_REQUEST";
export const FETCH_ALLUSER_SUCCESS = "FETCH_ALLUSER_SUCCESS";
export const FETCH_ALLUSER_FAILURE = "FETCH_ALLUSER_FAILURE";

//*******************************  dashboard fetch action   ********************* */

export const FETCH_DASHBOARDDETAILS_REQUEST = "FETCH_DASHBOARDDETAILS_REQUEST";
export const FETCH_DASHBOARDDETAILS_SUCCESS = "FETCH_DASHBOARDDETAILS_SUCCESS";
export const FETCH_DASHBOARDDETAILS_FAILURE = "FETCH_DASHBOARDDETAILS_FAILURE";

//*******************************  dashboard invoice list fetch  ********************* */

export const FETCH_DASHBOARDINVOICELIST_REQUEST =
  "FETCH_DASHBOARDINVOICELIST_REQUEST";
export const FETCH_DASHBOARDINVOICELIST_SUCCESS =
  "FETCH_DASHBOARDINVOICELIST_SUCCESS";
export const FETCH_DASHBOARDINVOICELIST_FAILURE =
  "FETCH_DASHBOARDINVOICELIST_FAILURE";

//*************************************************************Customer************************************************** */

//*******************************  customer data fetch action   ********************* */

export const FETCH_VILLA_REQUEST = "FETCH_VILLA_REQUEST";
export const FETCH_VILLA_SUCCESS = "FETCH_VILLA_SUCCESS";
export const FETCH_VILLA_FAILURE = "FETCH_VILLA_FAILURE";

//*******************************  all customer data fetch action   ********************* */

export const FETCH_ALLCUSTOMER_REQUEST = "FETCH_ALLCUSTOMER_REQUEST";
export const FETCH_ALLCUSTOMER_SUCCESS = "FETCH_ALLCUSTOMER_SUCCESS";
export const FETCH_ALLCUSTOMER_FAILURE = "FETCH_ALLCUSTOMER_FAILURE";

//******************************* custmer data fectch by id action  ********************* */

export const FETCH_CUSTOMER_REQUEST_BY_ID = "FETCH_CUSTOMER_REQUEST_BY_ID";
export const FETCH_CUSTOMER_SUCCESS_BY_ID = "FETCH_CUSTOMER_SUCCESS_BY_ID";
export const FETCH_CUSTOMER_FAILURE_BY_ID = "FETCH_CUSTOMER_FAILURE_BY_ID";

//******************************* custmer with contracts export to excel action  ********************* */
export const FETCH_CUSTOMER_EXPORTTOEXCEL_REQUEST =
  "FETCH_CUSTOMER_EXPORTTOEXCEL_REQUEST";
export const FETCH_CUSTOMER_EXPORTTOEXCEL_SUCCESS =
  "FETCH_CUSTOMER_EXPORTTOEXCEL_SUCCESS";
export const FETCH_CUSTOMER_EXPORTTOEXCEL_FAILURE =
  "FETCH_CUSTOMER_EXPORTTOEXCEL_FAILURE";

//******************************* custmer with contracts Import excel action  ********************* */
export const FETCH_CUSTOMER_IMPORTEXCEL_REQUEST =
  "FETCH_CUSTOMER_IMPORTEXCEL_REQUEST";
export const FETCH_CUSTOMER_IMPORTEXCEL_SUCCESS =
  "FETCH_CUSTOMER_IMPORTEXCEL_SUCCESS";
export const FETCH_CUSTOMER_IMPORTEXCEL_FAILURE =
  "FETCH_CUSTOMER_IMPORTEXCEL_FAILURE";

//*******************************  custmoer data create action     ********************* */

export const POST_CUSTOMER_REQUEST = "POST_CUSTOMER_REQUEST";
export const POST_CUSTOMER_SUCCESS = "POST_CUSTOMER_SUCCESS";
export const POST_CUSTOMER_FAILURE = "POST_CUSTOMER_FAILURE";

//******************************* custmoer data update  action  ********************* */

export const EDIT_CUSTOMER_REQUEST = "EDIT_CUSTOMER_REQUEST";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILURE = "EDIT_CUSTOMER_FAILURE";

//******************************* custmoer data delete action  ********************* */

export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";

//*************************************************************Contact************************************************** */

//******************************* contacts data create action ********************* */

export const POST_CONTACTS_REQUEST = "POST_CONTACTS_REQUEST";
export const POST_CONTACTS_SUCCESS = "POST_CONTACTS_SUCCESS";
export const POST_CONTACTS_FAILURE = "POST_CONTACTS_FAILURE";

//******************************* contacts data fetch action ********************* */

export const FETCH_CONTACTS_REQUEST = "FETCH_CONTACTS_REQUEST";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_FAILURE = "FETCH_CONTACTS_FAILURE";

//******************************* all contacts data fetch action ********************* */

export const FETCH_ALLCONTACTS_REQUEST = "FETCH_ALLCONTACTS_REQUEST";
export const FETCH_ALLCONTACTS_SUCCESS = "FETCH_ALLCONTACTS_SUCCESS";
export const FETCH_ALLCONTACTS_FAILURE = "FETCH_ALLCONTACTS_FAILURE";

//******************************* contacts data update action ********************* */

export const UPDATE_CONTACTS_REQUEST = "UPDATE_CONTACTS_REQUEST";
export const UPDATE_CONTACTS_SUCCESS = "UPDATE_CONTACTS_SUCCESS";
export const UPDATE_CONTACTS_FAILURE = "UPDATE_CONTACTS_FAILURE";

//******************************* contacts data delete  action ********************* */

export const DELETE_CONTACTS_REQUEST = "DELETE_CONTACTS_REQUEST";
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS";
export const DELETE_CONTACTS_FAILURE = "DELETE_CONTACTS_FAILURE";

//******************************* contact data fectch by id action  ********************* */

export const FETCH_CONTACT_REQUEST_BY_ID = "FETCH_CONTACT_REQUEST_BY_ID";
export const FETCH_CONTACT_SUCCESS_BY_ID = "FETCH_CONTACT_SUCCESS_BY_ID";
export const FETCH_CONTACT_FAILURE_BY_ID = "FETCH_CONTACT_FAILURE_BY_ID";

//******************************* contact data fectch by id action  ********************* */
export const FETCH_CONTACT_REQUEST_BY_CUSTOMER_ID =
  "FETCH_CONTACT_REQUEST_BY_CUSTOMER_ID";
export const FETCH_CONTACT_SUCCESS_BY_CUSTOMER_ID =
  "FETCH_CONTACT_SUCCESS_BY_CUSTOMER_ID";
export const FETCH_CONTACT_FAILURE_BY_CUSTOMER_ID =
  "FETCH_CONTACT_FAILURE_BY_CUSTOMER_ID";

//*******************************  edit contact data  action  ********************* */
export const EDIT_CONTACT_REQUEST = "EDIT_CONTACT_REQUEST";
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
export const EDIT_CONTACT_FAILURE = "EDIT_CONTACT_FAILURE";

//******************************* contact type fectch action  ********************* */
export const FETCH_CONTACTTYPE_REQUEST = "FETCH_CONTACTTYPE_REQUEST";
export const FETCH_CONTACTTYPE_SUCCESS = "FETCH_CONTACTTYPE_SUCCESS";
export const FETCH_CONTACTTYPE_FAILURE = "FETCH_CONTACTTYPE_FAILURE";

//*************************************************************Adresss************************************************** */

//******************************* country  action ********************* */

export const FETCH_COUNTRY_REQUEST = "FETCH_COUNTRY_REQUEST";
export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_FAILURE = "FETCH_COUNTRY_FAILURE";

//******************************* state  action ********************* */

export const FETCH_STATE_REQUEST = "FETCH_STATE_REQUEST";
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS";
export const FETCH_STATE_FAILURE = "FETCH_STATE_FAILURE";

//******************************* city  action ********************* */

export const FETCH_CITY_REQUEST = "FETCH_CITY_REQUEST";
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAILURE = "FETCH_CITY_FAILURE";

//******************************* billing country  action ********************* */

export const FETCH_BILLING_COUNTRY_REQUEST = "FETCH_BILLING_COUNTRY_REQUEST";
export const FETCH_BILLING_COUNTRY_SUCCESS = "FETCH_BILLING_COUNTRY_SUCCESS";
export const FETCH_BILLING_COUNTRY_FAILURE = "FETCH_BILLING_COUNTRY_FAILURE";

//******************************* billing state  action ********************* */

export const FETCH_BILLING_STATE_REQUEST = "FETCH_BILLING_STATE_REQUEST";
export const FETCH_BILLING_STATE_SUCCESS = "FETCH_BILLING_STATE_SUCCESS";
export const FETCH_BILLING_STATE_FAILURE = "FETCH_BILLING_STATE_FAILURE";

//******************************* billing city  action ********************* */

export const FETCH_BILLING_CITY_REQUEST = "FETCH_BILLING_CITY_REQUEST";
export const FETCH_BILLING_CITY_SUCCESS = "FETCH_BILLING_CITY_SUCCESS";
export const FETCH_BILLING_CITY_FAILURE = "FETCH_BILLING_CITY_FAILURE";

//*************************************************************Dropdown************************************************** */

//*******************************Filter column  action ********************* */

export const FETCH_FILTER_COLUMN_REQUEST = "FETCH_FILTER_COLUMN_REQUEST";
export const FETCH_FILTER_COLUMN_SUCCESS = "FETCH_FILTER_COLUMN_SUCCESS";
export const FETCH_FILTER_COLUMN_FAILURE = "FETCH_FILTER_COLUMN_FAILURE";

//******************************* DynamicFilter column  action ********************* */

export const FETCH_DYNAMIC_FILTER_COLUMN_REQUEST =
  "FETCH_DYNAMIC_FILTER_COLUMN_REQUEST";
export const FETCH_DYNAMIC_FILTER_COLUMN_SUCCESS =
  "FETCH_DYNAMIC_FILTER_COLUMN_SUCCESS";
export const FETCH_DYNAMIC_FILTER_COLUMN_FAILURE =
  "FETCH_DYNAMIC_FILTER_COLUMN_FAILURE";

//******************************* Acc Manager dropdown  action ********************* */

export const FETCH_ACCOUNT_MANAGER_DATA_REQUEST =
  "FETCH_ACCOUNT_MANAGER_DATA_REQUEST";
export const FETCH_ACCOUNT_MANAGER_DATA_SUCCESS =
  "FETCH_ACCOUNT_MANAGER_DATA_SUCCESS";
export const FETCH_ACCOUNT_MANAGER_DATA_FAILURE =
  "FETCH_ACCOUNT_MANAGER_DATA_FAILURE";

//******************************* sales rep dropdown  action ********************* */

export const FETCH_SALES_REP_DATA_REQUEST = "FETCH_SALES_REP_DATA_REQUEST";
export const FETCH_SALES_REP_DATA_SUCCESS = "FETCH_SALES_REP_DATA_SUCCESS";
export const FETCH_SALES_REP_DATA_FAILURE = "FETCH_SALES_REP_DATA_FAILURE";

//******************************* erp system dropdown  action ********************* */

export const FETCH_ERP_SYSTEM_DATA_REQUEST = "FETCH_ERP_SYSTEM_DATA_REQUEST";
export const FETCH_ERP_SYSTEM_DATA_SUCCESS = "FETCH_ERP_SYSTEM_DATA_SUCCESS";
export const FETCH_ERP_SYSTEM_DATA_FAILURE = "FETCH_ERP_SYSTEM_DATA_FAILURE";

//******************************* industry dropdown  action ********************* */

export const FETCH_INDUSTRY_REQUEST = "FETCH_INDUSTRY_REQUEST";
export const FETCH_INDUSTRY_SUCCESS = "FETCH_INDUSTRY_SUCCESS";
export const FETCH_INDUSTRY_FAILURE = "FETCH_INDUSTRY_FAILURE";

//*************************************************************Subscription************************************************** */

//*******************************  SUBSCRIPTION data fetch action   ********************* */

export const FETCH_SUBSCRIPTION_REQUEST = "FETCH_SUBSCRIPTION_REQUEST";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

// *******************************  all SUBSCRIPTION data fetch action   ********************* */

export const FETCH_ALLSUBSCRIPTION_REQUEST = "FETCH_ALLSUBSCRIPTION_REQUEST";
export const FETCH_ALLSUBSCRIPTION_SUCCESS = "FETCH_ALLSUBSCRIPTION_SUCCESS";
export const FETCH_ALLSUBSCRIPTION_FAILURE = "FETCH_ALLSUBSCRIPTION_FAILURE";

//******************************* custmoer data fectch by id action  ********************* */

export const FETCH_SUBSCRIPTION_REQUEST_BY_ID =
  "FETCH_SUBSCRIPTION_REQUEST_BY_ID";
export const FETCH_SUBSCRIPTION_SUCCESS_BY_ID =
  "FETCH_SUBSCRIPTION_SUCCESS_BY_ID";
export const FETCH_SUBSCRIPTION_FAILURE_BY_ID =
  "FETCH_SUBSCRIPTION_FAILURE_BY_ID";

//*******************************  custmoer data create action     ********************* */

export const POST_SUBSCRIPTION_REQUEST = "POST_SUBSCRIPTION_REQUEST";
export const POST_SUBSCRIPTION_SUCCESS = "POST_SUBSCRIPTION_SUCCESS";
export const POST_SUBSCRIPTION_FAILURE = "POST_SUBSCRIPTION_FAILURE";

//******************************* custmoer data update  action  ********************* */

export const EDIT_SUBSCRIPTION_REQUEST = "EDIT_SUBSCRIPTION_REQUEST";
export const EDIT_SUBSCRIPTION_SUCCESS = "EDIT_SUBSCRIPTION_SUCCESS";
export const EDIT_SUBSCRIPTION_FAILURE = "EDIT_SUBSCRIPTION_FAILURE";

export const EDIT_SUBSCRIPTIONPRICE_REQUEST = "EDIT_SUBSCRIPTIONPRICE_REQUEST";
export const EDIT_SUBSCRIPTIONPRICE_SUCCESS = "EDIT_SUBSCRIPTIONPRICE_SUCCESS";
export const EDIT_SUBSCRIPTIONPRICE_FAILURE = "EDIT_SUBSCRIPTIONPRICE_FAILURE";

//******************************* custmoer data delete action  ********************* */

export const DELETE_SUBSCRIPTION_REQUEST = "DELETE_SUBSCRIPTION_REQUEST";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAILURE = "DELETE_SUBSCRIPTION_FAILURE";

//******************************* Change Sequence action  ********************* */

export const CHANGE_SEQUENCE_REQUEST = "CHANGE_SEQUENCE_REQUEST";
export const CHANGE_SEQUENCE_SUCCESS = "CHANGE_SEQUENCE_SUCCESS";
export const CHANGE_SEQUENCE_FAILURE = "CHANGE_SEQUENCE_FAILURE";

//********************************************* CUSTOMER dropdown **************************************************** */

export const FETCH_CUSTOMER_DROPDOWN_REQUEST =
  "FETCH_CUSTOMER_DROPDOWN_REQUEST";
export const FETCH_CUSTOMER_DROPDOWN_SUCCESS =
  "FETCH_CUSTOMER_DROPDOWN_SUCCESS";
export const FETCH_CUSTOMER_DROPDOWN_FAILURE =
  "FETCH_CUSTOMER_DROPDOWN_FAILURE";

//********************************************* CLASSIFICATION  dropdown **************************************************** */

export const FETCH_CLASSIFICATION_DROPDOWN_REQUEST =
  "FETCH_CLASSIFICATION_DROPDOWN_REQUEST";
export const FETCH_CLASSIFICATION_DROPDOWN_SUCCESS =
  "FETCH_CLASSIFICATION_DROPDOWN_SUCCESS";
export const FETCH_CLASSIFICATION_DROPDOWN_FAILURE =
  "FETCH_CLASSIFICATION_DROPDOWN_FAILURE";

//********************************************* COMMISSION  dropdown **************************************************** */

export const FETCH_COMMISSION_DROPDOWN_REQUEST =
  "FETCH_COMMISSION_DROPDOWN_REQUEST";
export const FETCH_COMMISSION_DROPDOWN_SUCCESS =
  "FETCH_COMMISSION_DROPDOWN_SUCCESS";
export const FETCH_COMMISSION_DROPDOWN_FAILURE =
  "FETCH_COMMISSION_DROPDOWN_FAILURE";

//********************************************* CPI DATA  dropdown **************************************************** */

export const FETCH_CPIDATA_DROPDOWN_REQUEST = "FETCH_CPIDATA_DROPDOWN_REQUEST";
export const FETCH_CPIDATA_DROPDOWN_SUCCESS = "FETCH_CPIDATA_DROPDOWN_SUCCESS";
export const FETCH_CPIDATA_DROPDOWN_FAILURE = "FETCH_CPIDATA_DROPDOWN_FAILURE";

//********************************************* FREQUENCY  dropdown **************************************************** */

export const FETCH_FREQUENCY_DROPDOWN_REQUEST =
  "FETCH_FREQUENCY_DROPDOWN_REQUEST";
export const FETCH_FREQUENCY_DROPDOWN_SUCCESS =
  "FETCH_FREQUENCY_DROPDOWN_SUCCESS";
export const FETCH_FREQUENCY_DROPDOWN_FAILURE =
  "FETCH_FREQUENCY_DROPDOWN_FAILURE";

//********************************************* ITEMS  dropdown **************************************************** */

export const FETCH_ITEMS_DROPDOWN_REQUEST = "FETCH_ITEMS_DROPDOWN_REQUEST";
export const FETCH_ITEMS_DROPDOWN_SUCCESS = "FETCH_ITEMS_DROPDOWN_SUCCESS";
export const FETCH_ITEMS_DROPDOWN_FAILURE = "FETCH_ITEMS_DROPDOWN_FAILURE";

//*********************************************  GET CONTACTS DROPDOWN  **************************************************** */

export const FETCH_CONTACTS_DROPDOWN_REQUEST =
  "FETCH_CONTACTS_DROPDOWN_REQUEST";
export const FETCH_CONTACTS_DROPDOWN_SUCCESS =
  "FETCH_CONTACTS_DROPDOWN_SUCCESS";
export const FETCH_CONTACTS_DROPDOWN_FAILURE =
  "FETCH_CONTACTS_DROPDOWN_FAILURE";

//********************************************* ITEMS  dropdown **************************************************** */

export const FETCH_CUSTOMERS_SUBSCRIPTIONS_REQUEST =
  "FETCH_CUSTOMERS_SUBSCRIPTIONS_REQUEST";
export const FETCH_CUSTOMERS_SUBSCRIPTIONS_SUCCESS =
  "FETCH_CUSTOMERS_SUBSCRIPTIONS_SUCCESS";
export const FETCH_CUSTOMERS_SUBSCRIPTIONS_FAILURE =
  "FETCH_CUSTOMERS_SUBSCRIPTIONS_FAILURE";

//*********************************************  GET INVOICE BY ID **************************************************** */
export const FETCH_INVOICE_GET_BY_ID_REQUEST =
  "FETCH_INVOICE_GET_BY_ID_REQUEST";
export const FETCH_INVOICE_GET_BY_ID_SUCCESS =
  "FETCH_INVOICE_GET_BY_ID_SUCCESS";
export const FETCH_INVOICE_GET_BY_ID_FAILURE =
  "FETCH_INVOICE_GET_BY_ID_FAILURE";

//********************************************* Add PROPERTY_LISTING **************************************************** */
export const POST_PROPERTY_LISTING_REQUEST = "POST_PROPERTY_LISTING_REQUEST";
export const POST_PROPERTY_LISTING_SUCCESS = "POST_PROPERTY_LISTING_SUCCESS";
export const POST_PROPERTY_LISTING_FAILURE = "POST_PROPERTY_LISTING_FAILURE";

//********************************************* Update PROPERTY_LISTING **************************************************** */
export const UPDATE_PROPERTY_LISTING_REQUEST =
  "UPDATE_PROPERTY_LISTING_REQUEST";
export const UPDATE_PROPERTY_LISTING_SUCCESS =
  "UPDATE_PROPERTY_LISTING_SUCCESS";
export const UPDATE_PROPERTY_LISTING_FAILURE =
  "UPDATE_PROPERTY_LISTING_FAILURE";

//********************************************* Get PROPERTY_LISTING By Id **************************************************** */
export const GET_PROPERTY_LISTING_BY_ID_REQUEST =
  "GET_PROPERTY_LISTING_BY_ID_REQUEST";
export const GET_PROPERTY_LISTING_BY_ID_SUCCESS =
  "GET_PROPERTY_LISTING_BY_ID_SUCCESS";
export const GET_PROPERTY_LISTING_BY_ID_FAILURE =
  "GET_PROPERTY_LISTING_BY_ID_FAILURE";
//********************************************* Get PROPERTY_LISTING By Id **************************************************** */
export const DELETE_PROPERTY_LISTING_REQUEST =
  "DELETE_PROPERTY_LISTING_REQUEST";
export const DELETE_PROPERTY_LISTING_SUCCESS =
  "DELETE_PROPERTY_LISTING_SUCCESS";
export const DELETE_PROPERTY_LISTING_FAILURE =
  "DELETE_PROPERTY_LISTING_FAILURE";
//********************************************* PROPERTY_LISTING  **************************************************** */
export const FETCH_ALL_PROPERTY_LISTING_REQUEST =
  "FETCH_ALL_PROPERTY_LISTING_REQUEST";
export const FETCH_ALL_PROPERTY_LISTING_SUCCESS =
  "FETCH_ALL_PROPERTY_LISTING_SUCCESS";
export const FETCH_ALL_PROPERTY_LISTING_FAILURE =
  "FETCH_ALL_PROPERTY_LISTING_FAILURE";

//*********************************************Get All ARTISTS  **************************************************** */
export const FETCH_ALL_ARTISTS_REQUEST = "FETCH_ALL_ARTISTS_REQUEST";
export const FETCH_ALL_ARTISTS_SUCCESS = "FETCH_ALL_ARTISTS_SUCCESS";
export const FETCH_ALL_ARTISTS_FAILURE = "FETCH_ALL_ARTISTS_FAILURE";

//*********************************************Get All ARTISTS  **************************************************** */
export const FETCH_ARTIST_BY_ID_REQUEST = "FETCH_ARTIST_BY_ID_REQUEST";
export const FETCH_ARTIST_BY_ID_SUCCESS = "FETCH_ARTIST_BY_ID_SUCCESS";
export const FETCH_ARTIST_BY_ID_FAILURE = "FETCH_ARTIST_BY_ID_FAILURE";

//********************************************* DELETE ARTISTS **************************************************** */
export const DELETE_ARTISTS_REQUEST = "DELETE_ARTISTS_REQUEST";
export const DELETE_ARTISTS_SUCCESS = "DELETE_ARTISTS_SUCCESS";
export const DELETE_ARTISTS_FAILURE = "DELETE_ARTISTS_FAILURE";

//********************************************* Add ARTISTS **************************************************** */
export const ADD_ARTISTS_REQUEST = "ADD_ARTISTS_REQUEST";
export const ADD_ARTISTS_SUCCESS = "ADD_ARTISTS_SUCCESS";
export const ADD_ARTISTS_FAILURE = "ADD_ARTISTS_FAILURE";

//********************************************* FETCH_ALL_ARTIST_CALENDAR_DATE **************************************************** */
export const FETCH_ALL_ARTIST_CALENDAR_DATE_REQUEST = "FETCH_ALL_ARTIST_CALENDAR_DATE_REQUEST";
export const FETCH_ALL_ARTIST_CALENDAR_DATE_SUCCESS = "FETCH_ALL_ARTIST_CALENDAR_DATE_SUCCESS";
export const FETCH_ALL_ARTIST_CALENDAR_DATE_FAILURE = "FETCH_ALL_ARTIST_CALENDAR_DATE_FAILURE";

//********************************************* UpdateARTISTS **************************************************** */
export const UPDATE_ARTISTS_REQUEST = "UPDATE_ARTISTS_REQUEST";
export const UPDATE_ARTISTS_SUCCESS = "UPDATE_ARTISTS_SUCCESS";
export const UPDATE_ARTISTS_FAILURE = "UPDATE_ARTISTS_FAILURE";

//*********************************************Get All CUSTOMER  **************************************************** */
export const FETCH_ALL_CUSTOMER_REQUEST = "FETCH_ALL_CUSTOMER_REQUEST";
export const FETCH_ALL_CUSTOMER_SUCCESS = "FETCH_ALL_CUSTOMER_SUCCESS";
export const FETCH_ALL_CUSTOMER_FAILURE = "FETCH_ALL_CUSTOMER_FAILURE";

//********************************************* Update CUSTOMER **************************************************** */
export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

//********************************************* Add CUSTOMER **************************************************** */
export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

//********************************************* DELETE CUSTOMER **************************************************** */

export const DELETE_CUSTOMER_BY_ID_REQUEST = "DELETE_CUSTOMER_BY_ID_REQUEST";
export const DELETE_CUSTOMER_BY_ID_SUCCESS = "DELETE_CUSTOMER_BY_ID_SUCCESS";
export const DELETE_CUSTOMER_BY_ID_FAILURE = "DELETE_CUSTOMER_BY_ID_FAILURE";

//********************************************* Add BOOKING **************************************************** */
export const ADD_BOOKING_REQUEST = "ADD_BOOKING_REQUEST";
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_FAILURE = "ADD_BOOKING_FAILURE";

//********************************************* DELETE BOOKING **************************************************** */

export const DELETE_BOOKING_BY_ID_REQUEST = "DELETE_BOOKING_BY_ID_REQUEST";
export const DELETE_BOOKING_BY_ID_SUCCESS = "DELETE_BOOKING_BY_ID_SUCCESS";
export const DELETE_BOOKING_BY_ID_FAILURE = "DELETE_BOOKING_BY_ID_FAILURE";

//********************************************* Update BOOKING **************************************************** */
export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAILURE = "UPDATE_BOOKING_FAILURE";

//********************************************* Update Booking State **************************************************** */
export const UPDATE_BOOKING_STATE_BY_ID_REQUEST = "UPDATE_BOOKING_STATE_BY_ID_REQUEST";
export const UPDATE_BOOKING_STATE_BY_ID_SUCCESS = "UPDATE_BOOKING_STATE_BY_ID_SUCCESS";
export const UPDATE_BOOKING_STATE_BY_ID_FAILURE = "UPDATE_BOOKING_STATE_BY_ID_FAILURE";


//*********************************************Get All BOOKING  **************************************************** */

export const FETCH_ALL_BOOKING_REQUEST = "FETCH_ALL_BOOKING_REQUEST";
export const FETCH_ALL_BOOKING_SUCCESS = "FETCH_ALL_BOOKING_SUCCESS";
export const FETCH_ALL_BOOKING_FAILURE = "FETCH_ALL_BOOKING_FAILURE";

//********************************************* Add SOUND_VENDER **************************************************** */
export const ADD_SOUND_VENDER_REQUEST = "ADD_SOUND_VENDER_REQUEST";
export const ADD_SOUND_VENDER_SUCCESS = "ADD_SOUND_VENDER_SUCCESS";
export const ADD_SOUND_VENDER_FAILURE = "ADD_SOUND_VENDER_FAILURE";

//********************************************* DELETE SOUNDER_VENDER **************************************************** */

export const DELETE_SOUNDER_VENDER_REQUEST = "DELETE_SOUNDER_VENDER_REQUEST";
export const DELETE_SOUNDER_VENDER_SUCCESS = "DELETE_SOUNDER_VENDER_SUCCESS";
export const DELETE_SOUNDER_VENDER_FAILURE = "DELETE_SOUNDER_VENDER_FAILURE";
//********************************************* Update SOUNDER_VENDER **************************************************** */
export const UPDATE_SOUNDER_VENDER_REQUEST = "UPDATE_SOUNDER_VENDER_REQUEST";
export const UPDATE_SOUNDER_VENDER_SUCCESS = "UPDATE_SOUNDER_VENDER_SUCCESS";
export const UPDATE_SOUNDER_VENDER_FAILURE = "UPDATE_SOUNDER_VENDER_FAILURE";

//*********************************************Get All SOUNDER_VENDER  **************************************************** */
export const FETCH_ALL_SOUNDER_VENDER_REQUEST = "FETCH_ALL_SOUNDER_VENDER_REQUEST";
export const FETCH_ALL_SOUNDER_VENDER_SUCCESS = "FETCH_ALL_SOUNDER_VENDER_SUCCESS";
export const FETCH_ALL_SOUNDER_VENDER_FAILURE = "FETCH_ALL_SOUNDER_VENDER_FAILURE";

//*********************************************GET SOUNDER_VENDER BY ID  **************************************************** */
export const FETCH_SOUNDER_VENDER_BY_ID_REQUEST = "FETCH_SOUNDER_VENDER_BY_ID_REQUEST";
export const FETCH_SOUNDER_VENDER_BY_ID_SUCCESS = "FETCH_SOUNDER_VENDER_BY_ID_SUCCESS";
export const FETCH_SOUNDER_VENDER_BY_ID_FAILURE = "FETCH_SOUNDER_VENDER_BY_ID_FAILURE";

//*********************************************Get Booking By Id  **************************************************** */
export const FETCH_BOOKING_BY_ID_REQUEST = "FETCH_BOOKING_BY_ID_REQUEST";
export const FETCH_BOOKING_BY_ID_SUCCESS = "FETCH_BOOKING_BY_ID_SUCCESS";
export const FETCH_BOOKING_BY_ID_FAILURE = "FETCH_BOOKING_BY_ID_FAILURE";

//*********************************************Get Agent By Id  **************************************************** */
export const FETCH_AGENT_BY_ID_REQUEST = "FETCH_AGENT_BY_ID_REQUEST";
export const FETCH_AGENT_BY_ID_SUCCESS = "FETCH_AGENT_BY_ID_SUCCESS";
export const FETCH_AGENT_BY_ID_FAILURE = "FETCH_AGENT_BY_ID_FAILURE";

//*********************************************Get All Agent  **************************************************** */
export const FETCH_ALL_AGENT_REQUEST = "FETCH_ALL_AGENT_REQUEST";
export const FETCH_ALL_AGENT_SUCCESS = "FETCH_ALL_AGENT_SUCCESS";
export const FETCH_ALL_AGENT_FAILURE = "FETCH_ALL_AGENT_FAILURE";

//********************************************* Update AGENT **************************************************** */
export const UPDATE_AGENT_BY_ID_REQUEST = "UPDATE_AGENT_BY_ID_REQUEST";
export const UPDATE_AGENT_BY_ID_SUCCESS = "UPDATE_AGENT_BY_ID_SUCCESS";
export const UPDATE_AGENT_BY_ID_FAILURE = "UPDATE_AGENT_BY_ID_FAILURE";


//********************************************* Add SAGENT **************************************************** */
export const ADD_AGENT_REQUEST = "ADD_AGENT_REQUEST";
export const ADD_AGENT_SUCCESS = "ADD_AGENT_SUCCESS";
export const ADD_AGENT_FAILURE = "ADD_AGENT_FAILURE";
//********************************************* DELETE AGENT **************************************************** */

export const DELETE_AGENT_BY_ID_REQUEST = "DELETE_AGENT_BY_ID_REQUEST";
export const DELETE_AGENT_BY_ID_SUCCESS = "DELETE_AGENT_BY_ID_SUCCESS";
export const DELETE_AGENT_BY_ID_FAILURE = "DELETE_AGENT_BY_ID_FAILURE";

//*********************************************Get All Sound System  **************************************************** */
export const FETCH_ALL_SOUND_SYSTEM_REQUEST = "FETCH_ALL_SOUND_SYSTEM_REQUEST";
export const FETCH_ALL_SOUND_SYSTEM_SUCCESS = "FETCH_ALL_SOUND_SYSTEM_SUCCESS";
export const FETCH_ALL_SOUND_SYSTEM_FAILURE = "FETCH_ALL_SOUND_SYSTEM_FAILURE";
//********************************************* Update Sound System **************************************************** */
export const UPDATE_SOUND_SYSTEM_BY_ID_REQUEST = "UPDATE_SOUND_SYSTEM_BY_ID_REQUEST";
export const UPDATE_SOUND_SYSTEM_BY_ID_SUCCESS = "UPDATE_SOUND_SYSTEM_BY_ID_SUCCESS";
export const UPDATE_SOUND_SYSTEM_BY_ID_FAILURE = "UPDATE_SOUND_SYSTEM_BY_ID_FAILURE";

//********************************************* Delete Sound System **************************************************** */
export const DELETE_SOUND_SYSTEM_BY_ID_REQUEST = "DELETE_SOUND_SYSTEM_BY_ID_REQUEST";
export const DELETE_SOUND_SYSTEM_BY_ID_SUCCESS = "DELETE_SOUND_SYSTEM_BY_ID_SUCCESS";
export const DELETE_SOUND_SYSTEM_BY_ID_FAILURE = "DELETE_SOUND_SYSTEM_BY_ID_FAILURE";

//*********************************************Get SOUND_SYSTEM By Id  **************************************************** */
export const FETCH_SOUND_SYSTEM_BY_ID_REQUEST = "FETCH_SOUND_SYSTEM_BY_ID_REQUEST";
export const FETCH_SOUND_SYSTEM_BY_ID_SUCCESS = "FETCH_SOUND_SYSTEM_BY_ID_SUCCESS";
export const FETCH_SOUND_SYSTEM_BY_ID_FAILURE = "FETCH_SOUND_SYSTEM_BY_ID_FAILURE";

//********************************************* Add Sound System **************************************************** */
export const ADD_SOUND_SYSTEM_REQUEST = "ADD_SOUND_SYSTEM_REQUEST";
export const ADD_SOUND_SYSTEM_SUCCESS = "ADD_SOUND_SYSTEM_SUCCESS";
export const ADD_SOUND_SYSTEM_FAILURE = "ADD_SOUND_SYSTEM_FAILURE";
//*********************************************Get All Price Slab  **************************************************** */
export const FETCH_ALL_PRICE_SLAB_REQUEST = "FETCH_ALL_PRICE_SLAB_REQUEST";
export const FETCH_ALL_PRICE_SLAB_SUCCESS = "FETCH_ALL_PRICE_SLAB_SUCCESS";
export const FETCH_ALL_PRICE_SLAB_FAILURE = "FETCH_ALL_PRICE_SLAB_FAILURE";

//********************************************* Add Price Slab **************************************************** */
export const ADD_PRICE_SLAB_REQUEST = "ADD_PRICE_SLAB_REQUEST";
export const ADD_PRICE_SLAB_SUCCESS = "ADD_PRICE_SLAB_SUCCESS";
export const ADD_PRICE_SLAB_FAILURE = "ADD_PRICE_SLAB_FAILURE";

//********************************************* DELETE Price Slab **************************************************** */

export const DELETE_PRICE_SLAB_BY_ID_REQUEST = "DELETE_PRICE_SLAB_BY_ID_REQUEST";
export const DELETE_PRICE_SLAB_BY_ID_SUCCESS = "DELETE_PRICE_SLAB_BY_ID_SUCCESS";
export const DELETE_PRICE_SLAB_BY_ID_FAILURE = "DELETE_PRICE_SLAB_BY_ID_FAILURE";

//*********************************************Get Price Slab By Id **************************************************** */
export const FETCH_PRICE_SLAB_BY_ID_REQUEST = "FETCH_PRICE_SLAB_BY_ID_REQUEST";
export const FETCH_PRICE_SLAB_BY_ID_SUCCESS = "FETCH_PRICE_SLAB_BY_ID_SUCCESS";
export const FETCH_PRICE_SLAB_BY_ID_FAILURE = "FETCH_PRICE_SLAB_BY_ID_FAILURE";

//********************************************* Update Price Slab **************************************************** */
export const UPDATE_PRICE_SLAB_BY_ID_REQUEST = "UPDATE_PRICE_SLAB_BY_ID_REQUEST";
export const UPDATE_PRICE_SLAB_BY_ID_SUCCESS = "UPDATE_PRICE_SLAB_BY_ID_SUCCESS";
export const UPDATE_PRICE_SLAB_BY_ID_FAILURE = "UPDATE_PRICE_SLAB_BY_ID_FAILURE";

//*********************************************Get All ProfileSetypType **************************************************** */
export const FETCH_ALL_PROFILE_SETUP_TYPE_REQUEST = "FETCH_ALL_PROFILE_SETUP_TYPE_REQUEST";
export const FETCH_ALL_PROFILE_SETUP_TYPE_SUCCESS = "FETCH_ALL_PROFILE_SETUP_TYPE_SUCCESS";
export const FETCH_ALL_PROFILE_SETUP_TYPE_FAILURE = "FETCH_ALL_PROFILE_SETUP_TYPE_FAILURE";


//*********************************************Get All Profile Duration **************************************************** */
export const FETCH_ALL_PROFILE_PROFILE_DURATION_REQUEST = "FETCH_ALL_PROFILE_PROFILE_DURATION_REQUEST";
export const FETCH_ALL_PROFILE_PROFILE_DURATION_SUCCESS = "FETCH_ALL_PROFILE_PROFILE_DURATION_SUCCESS";
export const FETCH_ALL_PROFILE_PROFILE_DURATION_FAILURE = "FETCH_ALL_PROFILE_PROFILE_DURATION_FAILURE";

//*********************************************Get All Profile Duration **************************************************** */
export const FETCH_ALL_PRICE_SLAB_DROPDOWN_REQUEST = "FETCH_ALL_PRICE_SLAB_DROPDOWN_REQUEST";
export const FETCH_ALL_PRICE_SLAB_DROPDOWN_SUCCESS = "FETCH_ALL_PRICE_SLAB_DROPDOWN_SUCCESS";
export const FETCH_ALL_PRICE_SLAB_DROPDOWN_FAILURE = "FETCH_ALL_PRICE_SLAB_DROPDOWN_FAILURE";

//********************************************* SURGE_PRICE **************************************************** */
export const FETCH_ALL_SURGE_PRICE_REQUEST = "FETCH_ALL_SURGE_PRICE_REQUEST";
export const FETCH_ALL_SURGE_PRICE_SUCCESS = "FETCH_ALL_SURGE_PRICE_SUCCESS";
export const FETCH_ALL_SURGE_PRICE_FAILURE = "FETCH_ALL_SURGE_PRICE_FAILURE";

export const FETCH_SURGE_PRICE_BY_ID_REQUEST = "FETCH_SURGE_PRICE_BY_ID_REQUEST";
export const FETCH_SURGE_PRICE_BY_ID_SUCCESS = "FETCH_SURGE_PRICE_BY_ID_SUCCESS";
export const FETCH_SURGE_PRICE_BY_ID_FAILURE = "FETCH_SURGE_PRICE_BY_ID_FAILURE";

export const DELETE_SURGE_PRICE_BY_ID_REQUEST = "DELETE_SURGE_PRICE_BY_ID_REQUEST";
export const DELETE_SURGE_PRICE_BY_ID_SUCCESS = "DELETE_SURGE_PRICE_BY_ID_SUCCESS";
export const DELETE_SURGE_PRICE_BY_ID_FAILURE = "DELETE_SURGE_PRICE_BY_ID_FAILURE";

export const UPDATE_SURGE_PRICE_BY_ID_REQUEST = "UPDATE_SURGE_PRICE_BY_ID_REQUEST";
export const UPDATE_SURGE_PRICE_BY_ID_SUCCESS = "UPDATE_SURGE_PRICE_BY_ID_SUCCESS";
export const UPDATE_SURGE_PRICE_BY_ID_FAILURE = "UPDATE_SURGE_PRICE_BY_ID_FAILURE";

export const ADD_SURGE_PRICE_REQUEST = "ADD_SURGE_PRICE_REQUEST";
export const ADD_SURGE_PRICE_SUCCESS = "ADD_SURGE_PRICE_SUCCESS";
export const ADD_SURGE_PRICE_FAILURE = "ADD_SURGE_PRICE_FAILURE";


//********************************************* CUSTOMER_DROPDOWN **************************************************** */
export const FETCH_ALL_CUSTOMER_DROPDOWN_REQUEST = "FETCH_ALL_CUSTOMER_DROPDOWN_REQUEST";
export const FETCH_ALL_CUSTOMER_DROPDOWN_SUCCESS = "FETCH_ALL_CUSTOMER_DROPDOWN_SUCCESS";
export const FETCH_ALL_CUSTOMER_DROPDOWN_FAILURE = "FETCH_ALL_CUSTOMER_DROPDOWN_FAILURE";

//********************************************* CUSTOMER_DROPDOWN **************************************************** */
export const FETCH_ALL_ARTIST_DROPDOWN_REQUEST = "FETCH_ALL_ARTIST_DROPDOWN_REQUEST";
export const FETCH_ALL_ARTIST_DROPDOWN_SUCCESS = "FETCH_ALL_ARTIST_DROPDOWN_SUCCESS";
export const FETCH_ALL_ARTIST_DROPDOWN_FAILURE = "FETCH_ALL_ARTIST_DROPDOWN_FAILURE";

//********************************************* Global Discount **************************************************** */
export const FETCH_GLOBAL_DISCOUNT_REQUEST = "FETCH_GLOBAL_DISCOUNT_REQUEST";
export const FETCH_GLOBAL_DISCOUNT_SUCCESS = "FETCH_GLOBAL_DISCOUNT_SUCCESS";
export const FETCH_GLOBAL_DISCOUNT_FAILURE = "FETCH_GLOBAL_DISCOUNT_FAILURE";

export const FETCH_GLOBAL_DISCOUNT_BY_ID_REQUEST = "FETCH_GLOBAL_DISCOUNT_BY_ID_REQUEST";
export const FETCH_GLOBAL_DISCOUNT_BY_ID_SUCCESS = "FETCH_GLOBAL_DISCOUNT_BY_ID_SUCCESS";
export const FETCH_GLOBAL_DISCOUNT_BY_ID_FAILURE = "FETCH_GLOBAL_DISCOUNT_BY_ID_FAILURE";

export const UPDATE_GLOBAL_DISCOUNT_REQUEST = "UPDATE_GLOBAL_DISCOUNT_REQUEST";
export const UPDATE_GLOBAL_DISCOUNT_SUCCESS = "UPDATE_GLOBAL_DISCOUNT_SUCCESS";
export const UPDATE_GLOBAL_DISCOUNT_FAILURE = "UPDATE_GLOBAL_DISCOUNT_FAILURE";

//********************************************* SOUND_SYSTEM_DROPDOWN **************************************************** */
export const FETCH_ALL_SOUND_SYSTEM_DROPDOWN_REQUEST = "FETCH_ALL_SOUND_SYSTEM_DROPDOWN_REQUEST";
export const FETCH_ALL_SOUND_SYSTEM_DROPDOWN_SUCCESS = "FETCH_ALL_SOUND_SYSTEM_DROPDOWN_SUCCESS";
export const FETCH_ALL_SOUND_SYSTEM_DROPDOWN_FAILURE = "FETCH_ALL_SOUND_SYSTEM_DROPDOWN_FAILURE";

//********************************************* SOUND_VENDER_DROPDOWN **************************************************** */
export const FETCH_ALL_SOUND_VENDER_DROPDOWN_REQUEST = "FETCH_ALL_SOUND_VENDER_DROPDOWN_REQUEST";
export const FETCH_ALL_SOUND_VENDER_DROPDOWN_SUCCESS = "FETCH_ALL_SOUND_VENDER_DROPDOWN_SUCCESS";
export const FETCH_ALL_SOUND_VENDER_DROPDOWN_FAILURE = "FETCH_ALL_SOUND_VENDER_DROPDOWN_FAILURE";

//********************************************* WEBSITE_BANNER **************************************************** */

export const FETCH_ALL_WEBSITE_BANNER_REQUEST = "FETCH_ALL_WEBSITE_BANNER_REQUEST";
export const FETCH_ALL_WEBSITE_BANNER_SUCCESS = "FETCH_ALL_WEBSITE_BANNER_SUCCESS";
export const FETCH_ALL_WEBSITE_BANNER_FAILURE = "FETCH_ALL_WEBSITE_BANNER_FAILURE";

export const FETCH_WEBSITE_BANNER_BY_ID_REQUEST = "FETCH_WEBSITE_BANNER_BY_ID_REQUEST";
export const FETCH_WEBSITE_BANNER_BY_ID_SUCCESS = "FETCH_WEBSITE_BANNER_BY_ID_SUCCESS";
export const FETCH_WEBSITE_BANNER_BY_ID_FAILURE = "FETCH_WEBSITE_BANNER_BY_ID_FAILURE";

export const ADD_WEBSITE_BANNER_REQUEST = "ADD_WEBSITE_BANNER_REQUEST";
export const ADD_WEBSITE_BANNER_SUCCESS = "ADD_WEBSITE_BANNER_SUCCESS";
export const ADD_WEBSITE_BANNER_FAILURE = "ADD_WEBSITE_BANNER_FAILURE";

export const UPDATE_WEBSITE_BANNER_REQUEST = "UPDATE_WEBSITE_BANNER_REQUEST";
export const UPDATE_WEBSITE_BANNER_SUCCESS = "UPDATE_WEBSITE_BANNER_SUCCESS";
export const UPDATE_WEBSITE_BANNER_FAILURE = "UPDATE_WEBSITE_BANNER_FAILURE";

export const DELETE_WEBSITE_BANNER_REQUEST = "DELETE_WEBSITE_BANNER_REQUEST";
export const DELETE_WEBSITE_BANNER_SUCCESS = "DELETE_WEBSITE_BANNER_SUCCESS";
export const DELETE_WEBSITE_BANNER_FAILURE = "DELETE_WEBSITE_BANNER_FAILURE";

//        -----------------------------       TRIGGERS                   -------------------------------

export const FETCH_ALL_TRIGGERS_REQUEST = "FETCH_ALL_TRIGGERS_REQUEST";
export const FETCH_ALL_TRIGGERS_SUCCESS = "FETCH_ALL_TRIGGERS_SUCCESS";
export const FETCH_ALL_TRIGGERS_FAILURE = "FETCH_ALL_TRIGGERS_FAILURE";

export const FETCH_TRIGGERS_BY_ID_REQUEST = "FETCH_TRIGGERS_BY_ID_REQUEST";
export const FETCH_TRIGGERS_BY_ID_SUCCESS = "FETCH_TRIGGERS_BY_ID_SUCCESS";
export const FETCH_TRIGGERS_BY_ID_FAILURE = "FETCH_TRIGGERS_BY_ID_FAILURE";

export const DELETE_TRIGGERS_BY_ID_REQUEST = "DELETE_TRIGGERS_BY_ID_REQUEST";
export const DELETE_TRIGGERS_BY_ID_SUCCESS = "DELETE_TRIGGERS_BY_ID_SUCCESS";
export const DELETE_TRIGGERS_BY_ID_FAILURE = "DELETE_TRIGGERS_BY_ID_FAILURE";

export const UPDATE_TRIGGERS_BY_ID_REQUEST = "UPDATE_TRIGGERS_BY_ID_REQUEST";
export const UPDATE_TRIGGERS_BY_ID_SUCCESS = "UPDATE_TRIGGERS_BY_ID_SUCCESS";
export const UPDATE_TRIGGERS_BY_ID_FAILURE = "UPDATE_TRIGGERS_BY_ID_FAILURE";

export const ADD_TRIGGERS_REQUEST = "ADD_TRIGGERS_REQUEST";
export const ADD_TRIGGERS_SUCCESS = "ADD_TRIGGERS_SUCCESS";
export const ADD_TRIGGERS_FAILURE = "ADD_TRIGGERS_FAILURE";


//********************************************* ADD_ARTIST_PREFERRED_SETUP **************************************************** */
export const ADD_ARTIST_PREFERRED_SETUP_REQUEST = "ADD_ARTIST_PREFERRED_SETUP_REQUEST";
export const ADD_ARTIST_PREFERRED_SETUP_SUCCESS = "ADD_ARTIST_PREFERRED_SETUP_SUCCESS";
export const ADD_ARTIST_PREFERRED_SETUP_FAILURE = "ADD_ARTIST_PREFERRED_SETUP_FAILURE";

//********************************************* DELETE_ARTIST_PREFERRED_SETUP **************************************************** */
export const DELETE_ARTIST_PREFERRED_SETUP_REQUEST = "DELETE_ARTIST_PREFERRED_SETUP_REQUEST";
export const DELETE_ARTIST_PREFERRED_SETUP_SUCCESS = "DELETE_ARTIST_PREFERRED_SETUP_SUCCESS";
export const DELETE_ARTIST_PREFERRED_SETUP_FAILURE = "DELETE_ARTIST_PREFERRED_SETUP_FAILURE";

//********************************************* FETCH_ARTIST_PREFERRED_SETUP **************************************************** */

export const FETCH_ALL_ARTIST_PREFERRED_SETUP_REQUEST = "FETCH_ALL_ARTIST_PREFERRED_SETUP_REQUEST";
export const FETCH_ALL_ARTIST_PREFERRED_SETUP_SUCCESS = "FETCH_ALL_ARTIST_PREFERRED_SETUP_SUCCESS";
export const FETCH_ALL_ARTIST_PREFERRED_SETUP_FAILURE = "FETCH_ALL_ARTIST_PREFERRED_SETUP_FAILURE";

//********************************************* FETCH_ARTIST_PREFERRED_SETUP_BY_ID **************************************************** */

export const FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_REQUEST = "FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_REQUEST";
export const FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_SUCCESS = "FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_SUCCESS";
export const FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_FAILURE = "FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_FAILURE";

//********************************************* UPDATE_ARTIST_PREFERRED_SETUP_BY_ID **************************************************** */

export const UPDATE_ARTIST_PREFERRED_SETUP_BY_ID_REQUEST = "UPDATE_ARTIST_PREFERRED_SETUP_BY_ID_REQUEST";
export const UPDATE_ARTIST_PREFERRED_SETUP_BY_ID_SUCCESS = "UPDATE_ARTIST_PREFERRED_SETUP_BY_ID_SUCCESS";
export const UPDATE_ARTIST_PREFERRED_SETUP_BY_ID_FAILURE = "UPDATE_ARTIST_PREFERRED_SETUP_BY_ID_FAILURE";
//********************************************* UPDATE_ARTIST_PROFILE **************************************************** */
export const UPDATE_ARTIST_PROFILE_REQUEST = "UPDATE_ARTIST_PROFILE_REQUEST";
export const UPDATE_ARTIST_PROFILE_SUCCESS = "UPDATE_ARTIST_PROFILE_SUCCESS";
export const UPDATE_ARTIST_PROFILE_FAILURE = "UPDATE_ARTIST_PROFILE_FAILURE";


//*********************************************Get All Artist Videos **************************************************** */

export const FETCH_ALL_ARTIST_VIDEOS_REQUEST = "FETCH_ALL_ARTIST_VIDEOS_REQUEST";
export const FETCH_ALL_ARTIST_VIDEOS_SUCCESS = "FETCH_ALL_ARTIST_VIDEOS_SUCCESS";
export const FETCH_ALL_ARTIST_VIDEOS_FAILURE = "FETCH_ALL_ARTIST_VIDEOS_FAILURE";

//********************************************* Add ARTIST_VIDEOS **************************************************** */
export const ADD_ARTIST_VIDEOS_REQUEST = "ADD_ARTIST_VIDEOS_REQUEST";
export const ADD_ARTIST_VIDEOS_SUCCESS = "ADD_ARTIST_VIDEOS_SUCCESS";
export const ADD_ARTIST_VIDEOS_FAILURE = "ADD_ARTIST_VIDEOS_FAILURE";

//********************************************* Update SOUNDER_VENDER **************************************************** */
export const UPDATE_ARTIST_VIDEOS_REQUEST = "UPDATE_ARTIST_VIDEOS_REQUEST";
export const UPDATE_ARTIST_VIDEOS_SUCCESS = "UPDATE_ARTIST_VIDEOS_SUCCESS";
export const UPDATE_ARTIST_VIDEOS_FAILURE = "UPDATE_ARTIST_VIDEOS_FAILURE";

//********************************************* FETCH_ARTIST_VIDEOS_BY_ID **************************************************** */

export const FETCH_ARTIST_VIDEOS_BY_ID_REQUEST = "FETCH_ARTIST_VIDEOS_BY_ID_REQUEST";
export const FETCH_ARTIST_VIDEOS_BY_ID_SUCCESS = "FETCH_ARTIST_VIDEOS_BY_ID_SUCCESS";
export const FETCH_ARTIST_VIDEOS_BY_ID_FAILURE = "FETCH_ARTIST_VIDEOS_BY_ID_FAILURE";

//********************************************* DELETE ARTIST_VIDEOS_BY_ID **************************************************** */

export const DELETE_ARTIST_VIDEOS_BY_ID_REQUEST = "DELETE_ARTIST_VIDEOS_BY_ID_REQUEST";
export const DELETE_ARTIST_VIDEOS_BY_ID_SUCCESS = "DELETE_ARTIST_VIDEOS_BY_ID_SUCCESS";
export const DELETE_ARTIST_VIDEOS_BY_ID_FAILURE = "DELETE_ARTIST_VIDEOS_BY_ID_FAILURE";


//********************************************* ADD_ARTIST_CALENDAR_DATE **************************************************** */
export const ADD_ARTIST_CALENDAR_DATE_REQUEST = "ADD_ARTIST_CALENDAR_DATE_REQUEST";
export const ADD_ARTIST_CALENDAR_DATE_SUCCESS = "ADD_ARTIST_CALENDAR_DATE_SUCCESS";
export const ADD_ARTIST_CALENDAR_DATE_FAILURE = "ADD_ARTIST_CALENDAR_DATE_FAILURE";

//********************************************* DELETE_ARTIST_CALENDAR_DATE **************************************************** */
export const DELETE_ARTIST_CALENDAR_DATE_REQUEST = "DELETE_ARTIST_CALENDAR_DATE_REQUEST";
export const DELETE_ARTIST_CALENDAR_DATE_SUCCESS = "DELETE_ARTIST_CALENDAR_DATE_SUCCESS";
export const DELETE_ARTIST_CALENDAR_DATE_FAILURE = "DELETE_ARTIST_CALENDAR_DATE_FAILURE";

