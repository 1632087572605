import {
    DELETE_ARTIST_VIDEOS_BY_ID_REQUEST,
    DELETE_ARTIST_VIDEOS_BY_ID_SUCCESS,
    DELETE_ARTIST_VIDEOS_BY_ID_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
import { fetchAllArtistVideo } from "./getAllArtistVideos"
export const deleteArtistVideoByIdRequest = () => {
    return {
        type: DELETE_ARTIST_VIDEOS_BY_ID_REQUEST,
    };
};

export const deleteArtistVideoByIdSuccess = (Data) => {
    return {
        type: DELETE_ARTIST_VIDEOS_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const deleteArtistVideoByIdFailure = (Error) => {
    return {
        type: DELETE_ARTIST_VIDEOS_BY_ID_FAILURE,
        payload: Error,
    };
};

export const deleteArtistVideoById = (videoId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deleteArtistVideoByIdRequest());
        axios
            .delete(url + `deleteArtistVideoById/${videoId}`, {
                headers: headers,
            })
            .then(async (response) => {
                await dispatch(deleteArtistVideoByIdSuccess(response.data));
                localStorage.setItem("message", "ArtistVideoDeleteSuccess");
                dispatch(fetchAllArtistVideo())
            })
            .catch((error) => {
                dispatch(deleteArtistVideoByIdFailure(error));
                localStorage.setItem("message", "ArtistVideoDeleteFailed");

            });
    };
};
