import {
  FETCH_ALL_BOOKING_REQUEST,
  FETCH_ALL_BOOKING_SUCCESS,
  FETCH_ALL_BOOKING_FAILURE
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllBookingRequest = () => {
  return {
    type: FETCH_ALL_BOOKING_REQUEST,
  };
};
export const fetchAllBookingSuccess = (Data) => {
  return {
    type: FETCH_ALL_BOOKING_SUCCESS,
    payload: Data,
  };
}

export const fetchAllBookingFailure = (Error) => {
  return {
    type: FETCH_ALL_BOOKING_FAILURE,
    payload: Error,
  };
};

// var baseUrl = "https://ridhira.webbulldesign.com/";

export const fetchAllBooking = () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
    'Authorization': basicAuth,
  };

  return (dispatch) => {

    dispatch(fetchAllBookingRequest);
    axios
      .get(url + "getAllBookingRequests", {
        headers: headers,
      })
      .then(function (response) {
        dispatch(fetchAllBookingSuccess(response.data));
    
      })
      .catch(function (error) {
        dispatch(fetchAllBookingFailure(error));
      });

  };
};