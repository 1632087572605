
import {
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: true,
    data: [],
    error: "",
    message: "",
  };
  export const DeleteUserReducer = (state = initialState, action) => {
    switch (action.type) {
      case DELETE_USER_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case DELETE_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: localStorage.getItem("message"),
        };
      case DELETE_USER_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "failed",
        };
      default:
        return state;
    }
  };
  