import {
  FETCH_BOOKING_BY_ID_REQUEST,
  FETCH_BOOKING_BY_ID_SUCCESS,
  FETCH_BOOKING_BY_ID_FAILURE,
} from "../../actions/types";

const initialState = {
  loading: false,
  data: [],
  error: "",
  message: "",
};
export const getBookingByIdReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_BOOKING_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case FETCH_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
        message: "Successfully !!",
      };
    case FETCH_BOOKING_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
        message: "failed",
      };
    default:
      return state;
  }
};
