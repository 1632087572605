import {
    DELETE_TRIGGERS_BY_ID_REQUEST,
    DELETE_TRIGGERS_BY_ID_SUCCESS,
    DELETE_TRIGGERS_BY_ID_FAILURE,
} from "../types";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllTrigger } from "./getAllTrigger"
export const deleteTriggerByIdRequest = () => {
    return {
        type: DELETE_TRIGGERS_BY_ID_REQUEST,
    };
};

export const deleteTriggerByIdSuccess = (Data) => {
    return {
        type: DELETE_TRIGGERS_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const deleteTriggerByIdFailure = (Error) => {
    return {
        type: DELETE_TRIGGERS_BY_ID_FAILURE,
        payload: Error,
    };
};

export const deleteTriggerById = (triggerId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deleteTriggerByIdRequest());
        axios
            .delete(url + `api/flows/deleteFlowById/${triggerId}`, {
                headers: headers,
            })
            .then(async (response) => {
                await dispatch(deleteTriggerByIdSuccess(response.data));
                localStorage.setItem("message", "TriggerDeleteSuccess");
                dispatch(fetchAllTrigger())
            })
            .catch((error) => {
                dispatch(deleteTriggerByIdFailure(error));
                localStorage.setItem("message", "TriggerDeleteFailed");

            });
    };
};
