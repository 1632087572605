import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import SoundSystem from "../pages/soundSystem";
import SoundSystemDetails from "../component/soundSystem/soundSystemDetails";
export const soundsystemroutes = [

  {
    path: "/",
    exact: true,
    layout: Layout,
    component: () => <Redirect to="/login" />,
  },

  {
    path: "/soundSystem",
    exact: true,
    layout: Layout,
    component: SoundSystem,
  },
  {
    path: "/Sound_System_Details",
    exact: true,
    layout: Layout,
    component: SoundSystemDetails,
  },
];
