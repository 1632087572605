import {
  FETCH_ALL_AGENT_REQUEST,
  FETCH_ALL_AGENT_SUCCESS,
  FETCH_ALL_AGENT_FAILURE
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllAgentRequest = () => {
  return {
    type: FETCH_ALL_AGENT_REQUEST,
  };
};
export const fetchAllAgentSuccess = (Data) => {
  return {
    type: FETCH_ALL_AGENT_SUCCESS,
    payload: Data,
  };
}

export const fetchAllAgentFailure = (Error) => {
  return {
    type: FETCH_ALL_AGENT_FAILURE,
    payload: Error,
  };
};


export const fetchAllAgent = () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
    'Authorization': basicAuth,
  };
  return (dispatch) => {
    dispatch(fetchAllAgentRequest);
    axios
      .get(url + "getAllAgentUser", {
        headers: headers,
      })
      .then(function (response) {
        dispatch(fetchAllAgentSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(fetchAllAgentFailure(error));
      });

  };
};