
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from "./routes/index";
import { job } from "../src/component/auth/session";
import { CommonToast } from "./component/common/commontoast";

function App() {
  job.start();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const SuperAdmin = localStorage.getItem("Super_Admin");
  useEffect(() => {
    if (SuperAdmin == "true") {

      setIsSuperAdmin(true);
    }
    else {

      setIsSuperAdmin(false)
    }
  }, [SuperAdmin]);
  // /Agents
  return (
    <>
      <div>
        {Routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <route.layout>
                  <route.component {...props} />
                </route.layout>
              )}
            />
          );
        })}
      </div>
      <CommonToast />
    </>
  );
}

export default App;
