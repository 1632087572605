import {
  ADD_ARTISTS_REQUEST,
  ADD_ARTISTS_SUCCESS,
  ADD_ARTISTS_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllArtists } from "./getAllArtists"

export const addArtistRequest = () => {
  return {
    type: ADD_ARTISTS_REQUEST,
  };
};

export const addArtistSuccess = (Data) => {
  return {
    type: ADD_ARTISTS_SUCCESS,
    payload: { data: Data.data, message: Data.message },
  };
};

export const addArtistFailure = (Error) => {
  return {
    type: ADD_ARTISTS_FAILURE,
    payload: Error,
  };
};
export const addArtist = (data, addressData) => {
  var headers = {
    Accept: "Application/json",
    "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,
  };
  let formdata = new FormData();
  var myData = {
    "profileName": data.profileName,
    "instagramProfileLink": data.instagramProfileLink,
    "genres": data.genres,
    "rating": data.rating,
    "about": data.about,
    "inspirations": data.inspirations,
    "numberOfShowsPerformed": data.numberOfShowsPerformed,
    "yearsOfExperience": data.yearsOfExperience,
    "achievements": data.achievements,
    "individualSkill": data.individualSkill,
    "samplePlaylist": data.samplePlaylist,
    "hasOwnSoundSystem": data.hasOwnSoundSystem == "true" ? true : false,
    "baseLocationUrl": "",
    "maxServiceaablekm": data.maxServiceaablekm,
    "gSTIN": data.gSTIN,
    "operatingState": data.operatingState,
    "published": data.published == "true" ? true : false,
    "region": data.region,
    "artistUser": {
      "userName": data.userName,
      "password": data.password,
      "email": data.email,
      "firstName": data.firstName,
      "lastName": data.lastName,
      "phoneNumber": data.phoneNumber,
      "secondaryPhone": data.secondaryPhone
    },
    "baseLocation": {
      "googleMapLocation": data.googleMapLocation,
      "street": addressData.street,
      "city": addressData.city,
      "state": addressData.state,
      "zipCode": data.zipCode,
      "country": addressData.country,
      "latitude": addressData.latitude,
      "longitude": addressData.longitude,
      "addressName": addressData.addressName
    },
    "priceSlab": {
      "priceSlabId": data.priceSlabId
    }
  };
  let dataa = JSON.stringify(myData);
  formdata.append("data", dataa);
  formdata.append("backgroundImage", data.backgroundImage);

  return (dispatch) => {
    dispatch(addArtistRequest());
    axios
      .post(url + `addArtist`, formdata, { headers: headers })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "addArtistSuccess");
          await dispatch(addArtistSuccess(response.data));
          await dispatch(fetchAllArtists());
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "addArtistfailed");
        dispatch(
          addArtistFailure("Please Check Your Internet Connection !!")
        );
      });
  };
};
