import Profile from "../pages/Profile"
import Layout from "../component/Layout/Layout"
import { Redirect } from "react-router-dom";



export const profileroutes = [

    {
        path: "/",
        exact: true,
        layout:Layout,
        component: () => <Redirect to='/login'/>
      },
    {
        path:'/profile',
        layout:Layout,
        component:Profile
    }
]