import React from "react";
import Artists from "../component/artists/artists";
function Artist() {
  return (
    <div>
      <Artists />
    </div>
  );
}

export default Artist;
