import {
    FETCH_ALL_ARTIST_CALENDAR_DATE_REQUEST,
    FETCH_ALL_ARTIST_CALENDAR_DATE_SUCCESS,
    FETCH_ALL_ARTIST_CALENDAR_DATE_FAILURE,
} from "../../../actions/types";

const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
};

export const fetchArtistCalendarDatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_ARTIST_CALENDAR_DATE_REQUEST:
            return {
                ...state,
                loading: true,
                message: "",
            };
        case FETCH_ALL_ARTIST_CALENDAR_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: "",
                message: "Successfully!!",
            };
        case FETCH_ALL_ARTIST_CALENDAR_DATE_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload,
                message: "Failed",
            };
        default:
            return state;
    }
};
