import {
    DELETE_ARTIST_CALENDAR_DATE_REQUEST,
    DELETE_ARTIST_CALENDAR_DATE_SUCCESS,
    DELETE_ARTIST_CALENDAR_DATE_FAILURE,
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
import { fetchAllArtistCalendarEvent } from "./getArtistCalendarDates"
export const deleteArtistCalendarEventByIdRequest = () => {
    return {
        type: DELETE_ARTIST_CALENDAR_DATE_REQUEST,
    };
};

export const deleteArtistCalendarEventByIdSuccess = (Data) => {
    return {
        type: DELETE_ARTIST_CALENDAR_DATE_SUCCESS,
        payload: Data,
    };
};

export const deleteArtistCalendarEventByIdFailure = (Error) => {
    return {
        type: DELETE_ARTIST_CALENDAR_DATE_FAILURE,
        payload: Error,
    };
};

export const deleteArtistCalendarEventById = (calendarId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deleteArtistCalendarEventByIdRequest());
        axios
            .delete(url + `deleteCalendarEventById/${calendarId}`, {
                headers: headers,
            })
            .then(async (response) => {
                await dispatch(deleteArtistCalendarEventByIdSuccess(response.data));
                localStorage.setItem("message", "ArtistCalendarEventDleteSuccess");
                dispatch(fetchAllArtistCalendarEvent())
            })
            .catch((error) => {
                dispatch(deleteArtistCalendarEventByIdFailure(error));
                localStorage.setItem("message", "ArtistCalendarEventDleteFailed");

            });
    };
};
