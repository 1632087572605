import React , {useEffect} from "react"
import { useSnackbar } from 'notistack';
import { checkRoute } from "../../utils/checkroute";
import Button from "@material-ui/core/Button";

let checklogout = null ; 

export const CommonToast = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   
    // customized
const action = () => (
    
    <Button onClick={()=> handleStaySignedIn()}>
        STAY SIGNED IN 
    </Button>

);

const handleStaySignedIn = ()=> {
    if(checklogout === false){
        localStorage.setItem('Remember_be' , "false")
        checkRoute()
    }
}
    useEffect(() => {
          displayLogoutWarning()
    }, [checklogout])

    const displayLogoutWarning = ()=> {

        if(checklogout === false){
            enqueueSnackbar("You're about to get signed out", {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    preventDuplicate: true,
                  },
                variant: 'error',
                persist: true,
                action,
            });
        }
    }

    return (
        <>
        </>
    )
}



export const  logoutToast = ()=> {
    
    const logindate = new Date(`${localStorage.getItem("LoggedIn_On")}`);
    const newdate = new Date();
    const newtime = newdate.getTime();
    const logintime = logindate.getTime();

    if(newtime - logintime > 2.88e+7 && newtime - logintime < 2.886e+7){ //2.88e+7   2.886e+7
       
        checklogout = false
    }
    if(newtime - logintime > 2.886e+7){
        localStorage.setItem('Remember_be' , "false")
        checklogout = true
        checkRoute()
    }


    return checklogout
}
