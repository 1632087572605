import {
    ADD_SURGE_PRICE_REQUEST,
    ADD_SURGE_PRICE_SUCCESS,
    ADD_SURGE_PRICE_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllSurgePrice } from "./getAllSurgePrice"
export const addSurgePriceRequest = () => {
    return {
        type: ADD_SURGE_PRICE_REQUEST,
    };
};

export const addSurgePriceSuccess = (Data) => {
    return {
        type: ADD_SURGE_PRICE_SUCCESS,
        payload: { data: Data.data, message: Data.message },
    };
};

export const addSurgePriceFailure = (Error) => {
    return {
        type: ADD_SURGE_PRICE_FAILURE,
        payload: Error,
    };
};
export const addSurgePrice = (data) => {
    var headers = {
        Accept: "Application/json",
        'Authorization': basicAuth,
    };

    return (dispatch) => {
        dispatch(addSurgePriceRequest());
        axios
            .post(url + `addSurgePrice`, {
                surgrePricingName: data.surgrePricingName,
                startDate: data.startDate,
                endDate: data.endDate,
                startTime: data.startTime,
                endTime: data.endTime,
                surgeMultiplier: data.surgeMultiplier

            }, { headers: headers })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "addSurgePriceSuccess");
                    await dispatch(addSurgePriceSuccess(response.data));
                    await dispatch(fetchAllSurgePrice());
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "addSurgePricefailed");
                dispatch(
                    addSurgePriceFailure("Please Check Your Internet Connection !!")
                );
            });
    };
};
