import {
    UPDATE_GLOBAL_DISCOUNT_REQUEST,
    UPDATE_GLOBAL_DISCOUNT_SUCCESS,
    UPDATE_GLOBAL_DISCOUNT_FAILURE,
} from "../types";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { getGlobalDiscount } from "./getGlobalDiscount"

export const updateGlobalDiscountRequest = () => {
    return {
        type: UPDATE_GLOBAL_DISCOUNT_REQUEST,
    };
};

export const updateGlobalDiscountSuccess = (Data) => {
    return {
        type: UPDATE_GLOBAL_DISCOUNT_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updateGlobalDiscountFailure = (Error) => {
    return {
        type: UPDATE_GLOBAL_DISCOUNT_FAILURE,
        payload: { message: " updation failed", error: Error },
    };
};

export const updateGlobalDiscount = (data) => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updateGlobalDiscountRequest());
        axios
            .put(url + `updateGlobalDiscountById/1`, {
                "tlsMargin": data.tlsMargin,
                "travelRate": data.travelRate,
                "runningDiscount": data.runningDiscount,
                "customerDiscount": data.customerDiscount
            }, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "RecordUpdatedSuccessfully");
                    await dispatch(updateGlobalDiscountSuccess(response.data));
                    await dispatch(getGlobalDiscount());

                }
            })
            .catch((error) => {
                localStorage.setItem("message", "RecordUpdatedFailed");
                dispatch(updateGlobalDiscountFailure(error));
            });
    };
};
