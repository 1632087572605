
import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import WebisteBannerPage from "../pages/websiteBanner";
import WebsiteBannerDetails from "../component/websiteBanner/websiteBannerViewPage"
export const websitebannerroutes = [

    {
        path: "/",
        exact: true,
        layout: Layout,
        component: () => <Redirect to="/login" />,
    },

    {
        path: "/website_banner",
        exact: true,
        layout: Layout,
        component: WebisteBannerPage,
    },
    {
        path: "/website_banner_detail",
        exact: true,
        layout: Layout,
        component: WebsiteBannerDetails,
    },

];
