import {
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_REQUEST,
  FETCH_USER_LIST_FAILURE,
} from "../../actions/types";

const initialState = {
  loading: false,
  userList: [],
  error: "",
  message: "",
};

export const userListReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
        error: "",
      };

    case FETCH_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        userList: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
