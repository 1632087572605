import {
    DELETE_SURGE_PRICE_BY_ID_REQUEST,
    DELETE_SURGE_PRICE_BY_ID_SUCCESS,
    DELETE_SURGE_PRICE_BY_ID_FAILURE,
} from "../types";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllSurgePrice } from "./getAllSurgePrice"
export const deleteSurgePriceByIdRequest = () => {
    return {
        type: DELETE_SURGE_PRICE_BY_ID_REQUEST,
    };
};

export const deleteSurgePriceByIdSuccess = (Data) => {
    return {
        type: DELETE_SURGE_PRICE_BY_ID_SUCCESS,
        payload: Data,
    };
};

export const deleteSurgePriceByIdFailure = (Error) => {
    return {
        type: DELETE_SURGE_PRICE_BY_ID_FAILURE,
        payload: Error,
    };
};

export const deleteSurgePriceById = (surgePriceId) => {

    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(deleteSurgePriceByIdRequest());
        axios
            .delete(url + `deleteSurgePriceById/${surgePriceId}`, {
                headers: headers,
            })
            .then(async (response) => {
                await dispatch(deleteSurgePriceByIdSuccess(response.data));
                localStorage.setItem("message", "SurgePriceDeleteSuccess");
                dispatch(fetchAllSurgePrice())
            })
            .catch((error) => {
                dispatch(deleteSurgePriceByIdFailure(error));
                localStorage.setItem("message", "SurgePriceDeleteFailed");

            });
    };
};
