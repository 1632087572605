import {
  FETCH_ALL_SOUNDER_VENDER_REQUEST,
  FETCH_ALL_SOUNDER_VENDER_SUCCESS,
  FETCH_ALL_SOUNDER_VENDER_FAILURE,
} from "../types";
import axios from "axios";
import { url } from "../apiUrl/url";
import { basicAuth } from "../apiUrl/basicAuth";
export const fetchAllSoundVenderRequest = () => {
  return {
    type: FETCH_ALL_SOUNDER_VENDER_REQUEST,
  };
};
export const fetchAllSoundVenderSuccess = (Data) => {
  return {
    type: FETCH_ALL_SOUNDER_VENDER_SUCCESS,
    payload: Data,
  };
};

export const fetchAllSoundVenderFailure = (Error) => {
  return {
    type: FETCH_ALL_SOUNDER_VENDER_FAILURE,
    payload: Error,
  };
};


export const fetchAllSoundVender = () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
    'Authorization': basicAuth,
  };

  return (dispatch) => {
    dispatch(fetchAllSoundVenderRequest);
    axios
      .get(url + "getAllSoundVendor", {
        headers: headers,
      })
      .then(function (response) {
        dispatch(fetchAllSoundVenderSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(fetchAllSoundVenderFailure(error));
      });
  };
};
