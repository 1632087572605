import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddSoundSystem from "./addSoundSystem";
import UpdateSoundSystem from "./updateSoundSystem"
import Delete_Sound_System from "./deleteSoundSystem"
import { fetchAllSoundSystem } from "../../actions/soundSystem/soundSystem"
import { fetchSoundSystemById } from "../../actions/soundSystem/getSoundSystemById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
function SoundSystem(props) {
    const history = useHistory();
    const matches = useMediaQuery("(max-width:400px)");
    const [soundSystemId, setSoundSystemId] = useState(0);
    const [paginationvalues, setPaginationValues] = useState({});
    useEffect(() => {
        localStorage.setItem("View_Name", "soundSystem");
        checkRoute();

    }, []);
    const [showingpage, setShowingpage] = useState({
        from: 1,
        to: 2,
        sortingorder: "desc",
        search: null,
        columnname: "",
    });

    const {
        loading,
        error,
        message,
        getSoundSystemByIdData,
        handleGetSoundSystem,
        soundSystemData,
        handleFetchSoundSystemById
    } = props;

    useEffect(() => {
        checkRoute();
    }, [soundSystemData]);

    useEffect(() => {
        handleGetSoundSystem()
    }, [])

    const handleContactDetailView = async (id) => {
        history.push({
            pathname: "/propertyDeatils",
            state: { contactid: id },
        });
    };

    return !loading ? (
        <>
            <div className="container-fluid">
                {/* <!-- Page Heading --> */}
                <div className="page-breadcrumb">
                    <div className="row">
                        <div className="col-5 align-self-center">
                            <h4 className="page-title">Sound System</h4>
                            <div className="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-1">
                                        <li className="breadcrumb-item">
                                            <a
                                                className="breadcrumb-link"
                                                onClick={() => history.push("/Booking_Request")}
                                                href={() => false}
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Sound Vendors
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-7 align-self-center">
                            <div className="d-flex no-block justify-content-end align-items-center">
                                <div className="m-r-10">
                                    <div className="lastmonth"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4 pt-2"></div>
                                    <div className="col-lg-4 pt-2 text-right">
                                        {" "}
                                        <a
                                            href={() => false}
                                            className="btn btn-primary "
                                            data-toggle="modal"
                                            data-target="#createCustomerModal"
                                        >
                                            Add Sound System
                                        </a>{" "}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Title</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Specification</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Guest limit</div>
                                                    </div>
                                                </th>

                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Image</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Video</div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                                                        id="column"
                                                    >
                                                        <div>Cost</div>
                                                    </div>
                                                </th>

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {soundSystemData !== null && soundSystemData !== undefined && Object.entries(soundSystemData).length !== 0 ? (
                                                soundSystemData?.map((item) => {let soundSystemData = item.soundSystem
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: "/Sound_System_Details",
                                                                        });
                                                                        localStorage.setItem(
                                                                            "soundSystemId",
                                                                            soundSystemData.soundSystemId
                                                                        );
                                                                    }}
                                                                >

                                                                    {soundSystemData.title}
                                                                </a>
                                                            </td>
                                                            {/* <td>{item.title}</td> */}

                                                            <td>{soundSystemData.specification}</td>
                                                            <td>{soundSystemData.maxNumberOfGuest}</td>
                                                            <td>
                                                                {/* <a
                                                                        className="breadcrumb-link"
                                                                        href={item.soundSystemVideoPath}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {item.soundSystemVideoPath}
                                                                    </a> */}
                                                                <a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: "/Sound_System_Details",
                                                                        });
                                                                        localStorage.setItem(
                                                                            "soundSystemId",
                                                                            soundSystemData.soundSystemId
                                                                        );
                                                                    }}
                                                                >

                                                                    View
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {/* <a
                                                                        className="breadcrumb-link"
                                                                        href={item.soundSystemVideoPath}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {item.soundSystemVideoPath}
                                                                    </a> */}
                                                                <a
                                                                    className="breadcrumb-link"
                                                                    href={() => false}
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: "/Sound_System_Details",
                                                                        });
                                                                        localStorage.setItem(
                                                                            "soundSystemId",
                                                                            soundSystemData.soundSystemId
                                                                        );
                                                                    }}
                                                                >

                                                                    View
                                                                </a>
                                                            </td>

                                                            <td>{soundSystemData.chargeAmount}</td>
                                                            <td>
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#updateSoundSystemModal"
                                                                    onClick={() => handleFetchSoundSystemById(soundSystemData.soundSystemId)}
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </a>{" "}
                                                                <a
                                                                    href={() => false}
                                                                    className="cursor"
                                                                    data-toggle="modal"
                                                                    data-target="#deleteSoundSystemModal"
                                                                    onClick={() => {
                                                                        setSoundSystemId(soundSystemData.soundSystemId);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : soundSystemData?.length === 0 ? (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div>
                                                            <p>
                                                                <strong style={{ color: "grey" }}>
                                                                    No records found
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className="loading1"></div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddSoundSystem />
            <UpdateSoundSystem
                getSoundSystemByIdData={getSoundSystemByIdData}
            />
            <Delete_Sound_System soundSystemId={soundSystemId} />

            <ReactTooltip />
        </>
    ) : (
        <div className="cover-spin"></div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        soundSystemData: state.getAllSoundSystem.data,
        loading: state.getAllSoundSystem.loading,
        error: state.getAllSoundSystem.error,
        getSoundSystemByIdData: state.getSoundSystemById.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleGetSoundSystem: () => dispatch(fetchAllSoundSystem()),
        handleFetchSoundSystemById: (id) => dispatch(fetchSoundSystemById(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundSystem);
