import {
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
} from "../types";

import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllCustomer } from "./getAllCustomer"

export const updateCustomerRequest = () => {
  return {
    type: UPDATE_CUSTOMER_REQUEST,
  };
};

export const updateCustomerSuccess = (Data) => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: { message: Data.message },
  };
};

export const updateCustomerFailure = (Error) => {
  return {
    type: UPDATE_CUSTOMER_FAILURE,
    payload: { message: "updation failed", error: Error },
  };
};

export const updateCustomer = (data, addressData, billingAddressData) => {


  let formdata = new FormData();
  var myData = {
    "source": data.source,
    "customerType": data.customerType,
    "customerUser": {
      "userName": data.userName,
      "password": data.password,
      "email": data.email,
      "firstName": data.firstName,
      "lastName": data.lastName,
      "phoneNumber": data.phoneNumber,
      "secondaryPhone": data.secondaryPhone
    },
    "defaultVenueAddress": {
      "googleMapLocation": "",
      "street": "",
      "city": "",
      "state": "",
      "zipCode": "",
      "country": "",
      "longitude": "",
      "latitude": ""
    },
    "billingAddress": {
      "googleMapLocation": data.billingAddressGoogleMapLocation,
      "street": billingAddressData.billingAddressStreet,
      "city": billingAddressData.billingAddressCity,
      "state": billingAddressData.billingAddressState,
      "zipCode": data.billingAddressZipCode,
      "country": billingAddressData.billingAddressCountry,
      "longitude": billingAddressData.longitude,
      "latitude": billingAddressData.latitude,
      "addressName": billingAddressData.addressName
    }
  };
  let dataa = JSON.stringify(myData);
  formdata.append("data", dataa);
  if (data.imagefile) {
    formdata.append("imagefile", data.imagefile);
  }
  const headers = {
    "Content-Type": "multipart/formdata",
    'Authorization': basicAuth,

  };
  return (dispatch) => {
    dispatch(updateCustomerRequest());
    axios
      .put(url + `updateCustomerById/${data.customerId}`, formdata, {
        headers: headers,
      })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("message", "Record Updated Successfully");
          await dispatch(updateCustomerSuccess(response.data));
          await dispatch(fetchAllCustomer())
        }
      })
      .catch((error) => {
        localStorage.setItem("message", "Record Updated Failed");
        dispatch(updateCustomerFailure(error));
      });
  };
};
