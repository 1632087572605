import React, { useEffect } from "react";
import { deleteArtist, deleteArtistRequest } from "../../actions/artists/deleteArtists";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
function Delete_Artist(props) {
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { DeleteArtistById, artistId, message, refreshform, loading, error } = props;

    useEffect(() => {
        if (localStorage.getItem("message") === "ArtistDeleteSuccess") {
            const message = "Artist deleted successfully";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "success",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()

        }
        if (localStorage.getItem("message") === "ArtistDeleteFailed") {
            const message = "Artist Deleted Failed";
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                    autoHideDuration: 1500,
                    preventDuplicate: true,
                },
                variant: "error",
                TransitionComponent: Slide,
            });
            localStorage.removeItem("message");
            refreshform()
        }
    }, [message, error]);

    return (
        <>
            <div
                className="modal fade"
                id="deleteArtistModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Delete Artist
                            </h5>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                {" "}
                                <span aria-hidden="true">×</span>{" "}
                            </button>
                        </div>
                        <div className="modal-body">
                            Do you really want to this Artist ?
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={async () => await DeleteArtistById(artistId)}
                            >
                                Delete
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <div className="cover-spin"></div> : ""}
        </>
    );
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.deleteArtist.loading,
        message: state.deleteArtist.message,
        error: state.deleteArtist.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteArtistById: (artistId) => dispatch(deleteArtist(artistId)),
        refreshform: () => dispatch(deleteArtistRequest()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Delete_Artist);
