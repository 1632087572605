import {
    FETCH_ALL_PRICE_SLAB_REQUEST,
    FETCH_ALL_PRICE_SLAB_SUCCESS,
    FETCH_ALL_PRICE_SLAB_FAILURE,
  } from "../../actions/types";
  
  const initialState = {
    loading: false,
    data: [],
    error: "",
    message: "",
  };
  export const fetchAllPriceSlabReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ALL_PRICE_SLAB_REQUEST:
        return {
          ...state,
          loading: true,
          message: "",
        };
      case FETCH_ALL_PRICE_SLAB_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
          message: "Artist Created Successfully !!",
        };
      case FETCH_ALL_PRICE_SLAB_FAILURE:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.payload,
          message: "Artist Created Failed",
        };
      default:
        return state;
    }
  };
  