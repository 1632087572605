import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  stringValidate,
  emailValidate,
  passwordValidate,
} from "../../utils/Validations";
import { connect } from "react-redux";
import { registerUser } from "../../actions/Auth/register";
import Slide from "@material-ui/core/Slide";
import { useSnackbar } from "notistack";

function Index(props) {
  const { RegisterUser, message } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmpassword: "",
    CheckBox: false,
  });

  const [signformerror, setSignFormError] = useState({
    firstNameerror: "",
    lastNameerror: "",
    passworderror: "",
    emailerror: "",
    passmatch: "",
  });

  const history = useHistory();

  useEffect(() => {}, [signformerror]);

  const userSignup = (e) => {
    e.preventDefault();
    setSignFormError({
      ...signformerror,
      firstNameerror: stringValidate(user.firstName, "First Name"),
      lastNameerror: stringValidate(user.lastName, "Last Name"),
      passworderror: passwordValidate(user.password),
      emailerror: emailValidate(user.email, "Email"),
    });
    if (
      stringValidate(user.firstName, "First Name") === null &&
      stringValidate(user.lastName, "Last Name") === null &&
      passwordValidate(user.password) == null &&
      emailValidate(user.email, "Email") == null
    ) {
      if (user.password !== user.confirmpassword) {
        setSignFormError({
          ...signformerror,
          firstNameerror: "",
          lastNameerror: "",
          passworderror: "",
          emailerror: "",
          passmatch: "passwords didn't match ",
        });
      } else {
        setSignFormError({
          ...signformerror,
          firstNameerror: "",
          lastNameerror: "",
          passworderror: "",
          emailerror: "",
          passmatch: "",
        });
        RegisterUser(user);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("signup") === "success") {
      const message = "Please login";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "success",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("signup");
      history.push("login");
    }
    if (localStorage.getItem("signup") === "failed") {
      const message = "Failed";
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 3000,
          preventDuplicate: true,
        },
        variant: "error",
        TransitionComponent: Slide,
      });
      localStorage.removeItem("signup");
    }
  }, [message, enqueueSnackbar, history]);
  return (
    <div
      className="auth-wrapper d-flex no-block justify-content-center align-items-center"
      style={{
        background: "#f4f4f4",
        // backgroundImage: "url('img/login-bg.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <div className="auth-box">
        <div>
          <div className="logo">
            <span>
              <img src="img/logo1.png" alt="Redmap" />
            </span>
          </div>
          {/* <!-- Form --> */}
          <div className="row">
            <div className="col-12">
              <form className="form-horizontal m-t-20" onSubmit={userSignup}>
                <div className="form-group row ">
                  <div className="col-12 ">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          firstName: e.target.value,
                        })
                      }
                    />
                    <p className="profile-input-errors">
                      {signformerror.firstNameerror}
                    </p>
                  </div>
                </div>
                <div className="form-group row ">
                  <div className="col-12 ">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=" Last Name"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          lastName: e.target.value,
                        })
                      }
                    />
                    <p className="profile-input-errors">
                      {signformerror.lastNameerror}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 ">
                    <input
                      className="form-control "
                      type="text"
                      placeholder="Email"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          email: e.target.value,
                        })
                      }
                    />
                    <p className="profile-input-errors">
                      {signformerror.emailerror}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 ">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          password: e.target.value,
                        })
                      }
                    />
                    <p className="profile-input-errors">
                      {signformerror.passworderror}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 ">
                    <input
                      className="form-control "
                      type="password"
                      placeholder="Confirm Password"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          confirmpassword: e.target.value,
                        })
                      }
                    />
                    <p className="profile-input-errors">
                      {signformerror.passmatch}
                    </p>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-12 ">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        onChange={(e) =>
                          setUser({ ...user, CheckBox: e.target.checked })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        I agree to all <a href={() => false}>Terms</a>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group text-center ">
                  <div className="col-xs-12 p-b-20 ">
                    <button
                      className="btn btn-block btn-info "
                      type="submit "
                      onClick={userSignup}
                      disabled={!user.CheckBox ? true : false}
                    >
                      SIGN UP
                    </button>
                  </div>
                </div>
                <div className="form-group m-b-0 m-t-10 ">
                  <div className="col-sm-12 text-center ">
                    Already have an account?{" "}
                    <a
                      onClick={() => history.push("/login")}
                      className="text-info m-l-5 "
                      href={() => false}
                    >
                      <b>Sign In</b>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    loading: state.register.loading,
    message: state.register.message,
    // data: state.login.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    RegisterUser: (User) => dispatch(registerUser(User)),
  };
};

// export default Index;
export default connect(mapStateToProps, mapDispatchToProps)(Index);
