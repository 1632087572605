import {
    UPDATE_SOUND_SYSTEM_BY_ID_REQUEST,
    UPDATE_SOUND_SYSTEM_BY_ID_SUCCESS,
    UPDATE_SOUND_SYSTEM_BY_ID_FAILURE,
} from "../types";

import axios from "axios";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import { fetchAllSoundSystem } from "./soundSystem"

export const updateSoundSystemRequest = () => {
    return {
        type: UPDATE_SOUND_SYSTEM_BY_ID_REQUEST,
    };
};

export const updateSoundSystemSuccess = (Data) => {
    return {
        type: UPDATE_SOUND_SYSTEM_BY_ID_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updateSoundSystemFailure = (Error) => {
    return {
        type: UPDATE_SOUND_SYSTEM_BY_ID_FAILURE,
        payload: { message: " updation failed", error: Error },
    };
};

export const updateSoundSystem = (data) => {


    let formdata = new FormData();
    var myData = {
        title: data.title,
        specification: data.specification,
        maxNumberOfGuest: data.maxNumberOfGuest,
        chargeAmount: data.chargeAmount
    };
    let dataa = JSON.stringify(myData);
    formdata.append("data", dataa);
    formdata.append("soundSystemImage", data.imageFile);
    formdata.append("videoFile", data.videoFile);

    const headers = {
        "Content-Type": "multipart/formdata",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updateSoundSystemRequest());
        axios
            .put(url + `updateSoundSystemSpecification/${data.id}`, formdata, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "RecordUpdatedSuccessfully");
                    await dispatch(updateSoundSystemSuccess(response.data));
                    await dispatch(fetchAllSoundSystem())
                }
            })
            .catch((error) => {
                localStorage.setItem("message", "RecordUpdatedFailed");
                dispatch(updateSoundSystemFailure(error));
            });
    };
};
