import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import MetricsPage from "../pages/metrics";

export const metricsroutes = [

  {
    path: "/",
    exact: true,
    layout: Layout,
    component: () => <Redirect to="/login" />,
  },

  {
    path: "/Metrics",
    exact: true,
    layout: Layout,
    component: MetricsPage,
  },

];
