import React from "react";
import { Redirect } from "react-router-dom";
// routes views
import Layout from "../component/Layout/Layout";
import Customers from "../pages/customers";
import CustomerDetails from "../component/customer/customerDetails";
export const customersroutes = [

    {
        path: "/",
        exact: true,
        layout: Layout,
        component: () => <Redirect to="/login" />,
    },

    {
        path: "/Customer_Management",
        exact: true,
        layout: Layout,
        component: Customers,
    },
    {
        path: "/Customer_Details/:id",
        exact: true,
        layout: Layout,
        component: CustomerDetails,
    },
];
