import React, { useEffect } from "react";
import { checkRoute } from "../../utils/checkroute";
import Logoutmodal from "./logoutModal";
import Sidebar from "./sidebar";
import Topbar from "./Topbar";
import { CommonToast, logoutToast } from "../common/commontoast";

const Layout = ({ children }) => {
  useEffect(() => {
    checkRoute();
  }, []);

  return (
    <div id="page-top">
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* {SideBar} */}
        <Sidebar />
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper " className="d-flex flex-column main">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />

            {/* <!-- Component --> */}

            {children}
          </div>

          {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                {" "}
                <span>Copyright &copy; TheLiveShow</span>{" "}
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer -->  */}
        </div>
        {/* <!-- End of Content Wrapper -->  */}
      </div>
      {/* <!-- End of Page Wrapper -->  */}

      {/* <!-- Scroll to Top Button-->  */}
      <a className="scroll-to-top rounded" href="#page-top">
        {" "}
        <i className="fas fa-angle-up"></i>{" "}
      </a>

      {/* <!-- Logout Modal--> */}
      <Logoutmodal />
      <CommonToast runCallback={logoutToast} />
    </div>
  );
};

export default Layout;
