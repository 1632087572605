import {
    DELETE_SOUND_SYSTEM_BY_ID_REQUEST,
    DELETE_SOUND_SYSTEM_BY_ID_SUCCESS,
    DELETE_SOUND_SYSTEM_BY_ID_FAILURE,
  } from "../types";
  import { url } from "../apiUrl/url";
  import { basicAuth } from "../apiUrl/basicAuth";
  import axios from "axios";
  import { fetchAllSoundSystem } from "./soundSystem"
  export const deleteSoundSystemByIdRequest = () => {
    return {
      type: DELETE_SOUND_SYSTEM_BY_ID_REQUEST,
    };
  };
  
  export const deleteSoundSystemByIdSuccess = (Data) => {
    return {
      type: DELETE_SOUND_SYSTEM_BY_ID_SUCCESS,
      payload: Data,
    };
  };
  
  export const deleteSoundSystemByIdFailure = (Error) => {
    return {
      type: DELETE_SOUND_SYSTEM_BY_ID_FAILURE,
      payload: Error,
    };
  };
  
  export const deleteSoundSystemById = (soundSystemId) => {
  
    const headers = {
      "Content-Type": "application/json",
      'Authorization': basicAuth,
    };
    return (dispatch) => {
      dispatch(deleteSoundSystemByIdRequest());
      axios
        .delete(url + `deleteSoundSystemById/${soundSystemId}`, {
          headers: headers,
        })
        .then(async (response) => {
          await dispatch(deleteSoundSystemByIdSuccess(response.data));
          localStorage.setItem("message", "SoundSystemDeleteSuccess");
          dispatch(fetchAllSoundSystem())
        })
        .catch((error) => {
          dispatch(deleteSoundSystemByIdFailure(error));
          localStorage.setItem("message", "SoundSystemDeleteFailed");
  
        });
    };
  };
  