import { authroutes } from "./authroutes";
import { dashboardroutes } from "./dashboardroutes";
import { profileroutes } from "./profileroutes";
import { userroutes } from "./usersroutes";

import { artistsroutes } from "./artistsroutes"
import { bookingsroutes } from "./bookingroutes"
import { soundvendersroutes } from "./soundvenderroutes"
import { customersroutes } from "./customerroutes"
import { agentsroutes } from "./agentroutes"
import { soundsystemroutes } from "./soundsystemroutes"
import { bookingrequestroutes } from "./bookingrequestroutes"
import { priceslabroutes } from "./priceslabroutes"
import { surgepriceroutes } from "./surgepriceroutes"
import { discountroutes } from "./discountroute"
import { metricsroutes } from "./metricsroutes"
import { websitebannerroutes } from "./websitebannerroutes"
import { triggerroutes } from "./triggerroutes"
const Routes = [
  ...authroutes,
  ...dashboardroutes,
  ...userroutes,
  ...profileroutes,
  ...artistsroutes,
  ...bookingsroutes,
  ...soundvendersroutes,
  ...customersroutes,
  ...agentsroutes,
  ...soundsystemroutes,
  ...bookingrequestroutes,
  ...priceslabroutes,
  ...surgepriceroutes,
  ...discountroutes,
  ...metricsroutes,
  ...websitebannerroutes,
  ...triggerroutes
];

export default Routes;
