
import {
    FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_REQUEST,
    FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_SUCCESS,
    FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_FAILURE
} from "../../types";
import axios from "axios";
import { url } from "../../apiUrl/url"
import { basicAuth } from "../../apiUrl/basicAuth";
export const fetchArtistPreferredSetupByIdRequest = () => {
    return {
        type: FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_REQUEST,
    };
};
export const fetchArtistPreferredSetupByIdSuccess = (Data) => {
    return {
        type: FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_SUCCESS,
        payload: Data,
    };
}

export const fetchArtistPreferredSetupByIdFailure = (Error) => {
    return {
        type: FETCH_ALL_ARTIST_PREFERRED_SETUP_BY_ID_FAILURE,
        payload: Error,
    };
};


export const fetchArtistPreferredSetupById = (id) => {
    const artistId = localStorage.getItem("artistId")
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded;  charset=UTF-8",
        'Authorization': basicAuth,
    };
    return (dispatch) => {

        dispatch(fetchArtistPreferredSetupByIdRequest);
        axios
            .get(url + `getArtistPreferredSetupById/${id}`, {
                headers: headers,
            })
            .then(function (response) {
                dispatch(fetchArtistPreferredSetupByIdSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(fetchArtistPreferredSetupByIdFailure(error));
            });

    };
};