import {
    UPDATE_BOOKING_STATE_BY_ID_REQUEST,
    UPDATE_BOOKING_STATE_BY_ID_SUCCESS,
    UPDATE_BOOKING_STATE_BY_ID_FAILURE,
} from "../types";
import { url } from "../apiUrl/url"
import { basicAuth } from "../apiUrl/basicAuth";
import axios from "axios";
import { fetchAllBooking } from "./getAllBooking"

export const updateBookingStateRequest = () => {
    return {
        type: UPDATE_BOOKING_STATE_BY_ID_REQUEST,
    };
};

export const updateBookingStateSuccess = (Data) => {
    return {
        type: UPDATE_BOOKING_STATE_BY_ID_SUCCESS,
        payload: { message: Data.message },
    };
};

export const updateBookingStateFailure = (Error) => {
    return {
        type: UPDATE_BOOKING_STATE_BY_ID_FAILURE,
        payload: { message: " updation failed", error: Error },
    };
};

export const updateBookingState = (data) => {
    const headers = {
        "Content-Type": "application/json",
        'Authorization': basicAuth,
    };
    return (dispatch) => {
        dispatch(updateBookingStateRequest());
        axios
            .put(url + `updateBookingById/${data.id}`, {
                bookingState: data.bookingState,
            }, {
                headers: headers,
            })
            .then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem("message", "RecordUpdatedSuccessfully");
                    await dispatch(updateBookingStateSuccess(response.data));
                    await dispatch(fetchAllBooking());

                }
            })
            .catch((error) => {
                localStorage.setItem("message", "RecordUpdatedFailed");
                dispatch(updateBookingStateFailure(error));
            });
    };
};
